import Traininghero from "../../components/products/training/hero";
import FeaturesSection from "../../components/products/training/featuresCards";
import TeamWorks from "../../components/products/training/teamWorks";
import Improvement from "../../components/products/training/improvement";
import CreateWithAi from "../../components/products/training/createWithAi";
import TrainingLibrary from "../../components/products/training/trainingLibrary";
import CreatorTool from "../../components/products/training/creatorTool";
import Management from "../../components/products/training/management";
import License from "../../components/products/training/license";
import AllInOnePlatform from "../../components/products/training/allNOnePlatform";
import ExpertTraining from "../../components/products/training/expert";
import DemoForm from "../../components/products/training/form";
import FAQSection from "../../components/products/training/faqs";
const ProductsPlatform = () => {
  return (
    <div>
      <Traininghero />
      <TeamWorks />
      <FeaturesSection />
      <Improvement />
      <CreateWithAi />
      <TrainingLibrary />
      <CreatorTool />
      <Management />
      <License />
      <AllInOnePlatform />
      <ExpertTraining />
      <DemoForm />
      <FAQSection />
    </div>
  );
};
export default ProductsPlatform;
