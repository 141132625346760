import React from "react";
import { useTranslation } from "react-i18next";

const Workplace = () => {
  const { t } = useTranslation();

  return (
    <section className="py-12">
      <div className="text-center">
        {/* Title */}
        <h1 className="text-[35px] md:text-[3rem] text-[#293745] font-bold leading-[1.2]">
          {t("homepage.connectedworktitle")}
        </h1>

        {/* Description */}
        <p className="text-[#293745] text-lg md:text-xl font-normal mt-4 px-4 md:px-0 max-w-4xl mx-auto">
          {t("homepage.connectworkdescr")}
        </p>
      </div>
    </section>
  );
};

export default Workplace;
