import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import TabOneDetails from "./tabsDetails/tab1";
import TabTwoDetails from "./tabsDetails/tab2";
import TabThreeDetails from "./tabsDetails/tab3";
import TabFourDetails from "./tabsDetails/tab4";
import TabFiveDetails from "./tabsDetails/tab5";
import TabSixDetails from "./tabsDetails/tab6";
import {
  FaBuilding,
  FaTruck,
  FaTools,
  FaConciergeBell,
  FaRegBuilding,
  FaShoppingBag,
} from "react-icons/fa";

// Tab data
const tabs = [
  { id: 1, nameKey: "tabcardspage.tab1", icon: <FaTools /> },
  { id: 2, nameKey: "tabcardspage.tab2", icon: <FaBuilding /> },
  { id: 3, nameKey: "tabcardspage.tab3", icon: <FaTruck /> },
  { id: 4, nameKey: "tabcardspage.tab4", icon: <FaConciergeBell /> },
  { id: 5, nameKey: "tabcardspage.tab5", icon: <FaRegBuilding /> },
  { id: 6, nameKey: "tabcardspage.tab6", icon: <FaShoppingBag /> },
];

const TabbedInterface = () => {
  const isArabic = i18next.language === "ar";
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState(1);

  const renderContent = () => {
    switch (activeTab) {
      case 1:
        return <TabOneDetails />;
      case 2:
        return <TabTwoDetails />;
      case 3:
        return <TabThreeDetails />;
      case 4:
        return <TabFourDetails />;
      case 5:
        return <TabFiveDetails />;
      case 6:
        return <TabSixDetails />;
      default:
        return null;
    }
  };

  return (
    <div
      className={`py-16 ${isArabic ? "text-right" : "text-left"}`} // Adjust text alignment based on language
    >
      <h1 className="text-center text-[#293745] text-[30px] md:text-[40px] lg:text-[50px] font-bold mx-4 md:mx-10 mb-10">
        {t("homepage.TabbedInterfacedescr")}
      </h1>

      {/* Tabs for lg and xl screens */}
      <div
        className={`hidden lg:flex flex-wrap justify-center mb-6 ${
          isArabic ? "flex-row-reverse" : "flex-row"
        }`} // Reverse the tab order for RTL
      >
        {tabs.map((tab) => (
          <button
            key={tab.id}
            className={`flex flex-col hover:border-2 hover:bg-[#f0f0ff] hover:border-[#6559ff] items-center text-[17px] justify-center w-[15%] lg:justify-start py-6 mx-2 text-sm font-semibold rounded-lg
            ${
              activeTab === tab.id
                ? "border-2 border-[#6559ff] text-gray-600"
                : "border-gray-300 text-gray-600"
            }`}
            onClick={() => setActiveTab(tab.id)}
          >
            <span className="text-lg">{tab.icon}</span>
            <span className="pt-3 text-base">{t(tab.nameKey)}</span>
          </button>
        ))}
      </div>

      {/* Dropdown for md, sm, and xs screens */}
      <div className="lg:hidden px-3 mb-6">
        <label htmlFor="tab-select" className="sr-only">
          {t("homepage.selectTab")}
        </label>
        <select
          id="tab-select"
          className="w-full p-3 border border-gray-300 rounded-lg"
          value={activeTab}
          onChange={(e) => setActiveTab(Number(e.target.value))}
        >
          {tabs.map((tab) => (
            <option key={tab.id} value={tab.id}>
              {t(tab.nameKey)}
            </option>
          ))}
        </select>
      </div>

      {/* Render the content based on the active tab */}
      {renderContent()}
    </div>
  );
};

export default TabbedInterface;
