import AnalyticsHero from "../../../assets/hospatility/Incident-Management-Software-img-03.webp";
import TextImageFlex from "../../../shared/textImageFlex";
import { useTranslation } from "react-i18next";
const GainVisibilty = () => {
  const { t } = useTranslation();
  return (
    <div className="py-12 md:py-24">
      <TextImageFlex
        heading={t("hospitalitypage.GainVisibiltyheading")}
        para={t("hospitalitypage.GainVisibiltypara")}
        img={AnalyticsHero}
        widhtClass="w-full"
        reverse
      />
    </div>
  );
};

export default GainVisibilty;
