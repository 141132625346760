import React, { useState } from "react";
import InspectionHome from "./inspectionHome/inspectionhome";
import Archieve from "./archeive";
const InspectionPage = () => {
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [activeTab, setActiveTab] = useState("Inspection");
  const renderContent = () => {
    switch (activeTab) {
      case "Inspection":
        return <InspectionHome />;
      case "Archive":
        return <Archieve />;
      // case "Devices":
      //   return <StateTab />;
      // case "Region":
      //   return <RegionTab />;
      // case "Area":
      //   return <AreaTab />;
      // case "Site":
      //   return <SiteTab />;

      default:
        return null;
    }
  };

  const toggleFilterDropdown = () => {
    setIsFilterOpen(!isFilterOpen);
  };

  return (
    <div className="container mx-auto ">


      {/* Tabs */}
      <div className="mt-6">
        <nav className="flex space-x-7 border-b border-gray-300">
          {["Inspection", "Archive"].map(
            (tab) => (
              <button
                key={tab}
                onClick={() => setActiveTab(tab)}
                className={`pb-2 text-gray-600 ${
                  activeTab === tab
                    ? "border-b-2 border-blue-600 text-blue-600"
                    : ""
                }`}
              >
                {tab}
              </button>
            )
          )}
        </nav>

        {/* Tab Content */}
        <div className="mt-4">{renderContent()}</div>
      </div>
    </div>
  );
};

export default InspectionPage;
