import { useLocation, useNavigate } from "react-router-dom"; // Import useLocation to track current route
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

const Breadcrumb = () => {
  const location = useLocation(); // Get the current location object
  const { t } = useTranslation();
  const isArabic = i18next.language === "ar"; // Check if the current language is Arabic

  const [pageName, setPageName] = useState("");
  const navigate = useNavigate();

  // Function to update the page name based on the current location
  useEffect(() => {
    const path = location.pathname.split("/").filter(Boolean);
    if (path.length > 0) {
      const routeKey = path[path.length - 1].toLowerCase().replace("-", "");
      setPageName(routeKey);
    } else {
      setPageName("homepage"); // Default to homepage
    }
  }, [location]);

  // Hide the Breadcrumb on login and signup pages
  const hideBreadcrumb =
    location.pathname === "/login" ||
    location.pathname === "/signup" ||
    location.pathname === "/forgot-password" ||
    location.pathname.includes("/reset-password");

  if (location.pathname === "/" || hideBreadcrumb) {
    return null; // Don't render on home, login, or signup pages
  }

  return (
    <div
      className={` px-12 flex items-center ${isArabic ? "justify-end" : ""}`} // Apply conditional right alignment for Arabic
    >
      <div
        className={`flex items-center ${isArabic ? "flex-row-reverse" : ""}`}
      >
        {" "}
        {/* Reverse the order in Arabic */}
        {/* Home Icon - Move this to the left or right based on language */}
        <div className="cursor-pointer" onClick={() => navigate("/")}>
          <svg
            clipRule="evenodd"
            className="hover:text-black"
            fill="none"
            fillRule="evenodd"
            height="12"
            viewBox="0 0 24 24"
            width="12"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.635 1.37004C12.6408 0.723819 11.3592 0.72382 10.365 1.37005L2.36503 6.57004C1.51366 7.12344 1 8.06995 1 9.08538V20C1 21.6569 2.34315 23 4 23H20C21.6569 23 23 21.6569 23 20V9.08538C23 8.06995 22.4863 7.12344 21.635 6.57004L13.635 1.37004ZM11.455 3.04693C11.7864 2.83152 12.2136 2.83152 12.545 3.04693L20.545 8.24693C20.8288 8.4314 21 8.7469 21 9.08538V20C21 20.5523 20.5523 21 20 21H15.9998L16 13.4615C16 11.3739 14.0151 10 12.0001 10C9.98507 10 8.00017 11.3739 8.00016 13.4615L8 21L9.18182 21H4C3.44772 21 3 20.5523 3 20V9.08538C3 8.7469 3.17122 8.4314 3.45501 8.24693L11.455 3.04693ZM10 21H15.1817L13.9998 21L14 13.4615C14 12.8302 13.2987 12 12.0001 12C10.7015 12 10.0002 12.8302 10.0002 13.4615L10 21Z"
              fill="#C1C8D3"
            ></path>
          </svg>
        </div>
        {/* Breadcrumb Separator Icon */}
        <div className={`mx-1 ${isArabic ? "rotate-180" : ""}`}>
          <svg
            clipRule="evenodd"
            fill="none"
            fillRule="evenodd"
            height="10"
            viewBox="0 0 24 24"
            width="10"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.29289 3.29289C8.68342 2.90237 9.31658 2.90237 9.70711 3.29289L17.7071 11.2929C18.0976 11.6834 18.0976 12.3166 17.7071 12.7071L9.70711 20.7071C9.31658 21.0976 8.68342 21.0976 8.29289 20.7071C7.90237 20.3166 7.90237 19.6834 8.29289 19.2929L15.5858 12L8.29289 4.70711C7.90237 4.31658 7.90237 3.68342 8.29289 3.29289Z"
              fill="currentColor"
            ></path>
          </svg>
        </div>
        {/* Page Name */}
        <span className="capitalize text-xs">{t(`pageName.${pageName}`)}</span>
      </div>
    </div>
  );
};

export default Breadcrumb;
