import HeroSection from "../../components/products/loneWorker/hero";
import MobileWorkforce from "../../components/products/loneWorker/mobileWorkforce";
import StayConnected from "../../components/products/loneWorker/stayConnected";
import SimpleTool from "../../components/products/loneWorker/simpletool";
import AppStore from "../../components/products/loneWorker/appStore";
import StayCompliant from "../../components/products/loneWorker/stayCompliant";
import IntegrateTools from "../../components/products/loneWorker/integrateTools";
import Testimonial from "../../components/products/loneWorker/testimonial";
import Form from "../../components/products/loneWorker/form";
const LoneWorker = () => {
  return (
    <div>
      <HeroSection />
      <MobileWorkforce/>
      <StayConnected/>
      <SimpleTool/>
      <AppStore/>
      <StayCompliant/>
      <IntegrateTools/>
      <Testimonial/>
      <Form/>
    </div>
  );
};
export default LoneWorker;
