import React from "react";
import { FaRegCircleCheck } from "react-icons/fa6";

const services = [
  "Risk assessments",
  "Preventative maintenance",
  "Toolbox talks",
  "Asset inspections",
  "Pre-start safety inspection",
  "Brand standard audits",
  "Incident reports",
  "Open/closing checks",
  "Fire risk assessment",
  "Food quality assurance",
  "Site safety audit",
  "Asset cleaning instructions",
  "Facility inspections",
  "Equipment inspections",
  "Field inspections",
  "Safety inspections",
];

const IndustriesSection = () => {
  // return (
  //   <div className="py-12 px-4 md:px-12 lg:px-20 text-center ">
  //     <h2 className="text-[2.2rem] md:text-[3.2rem] lg:text-[2.65rem] font-bold text-[#293745] mb-4">
  //       Endless opportunities, no matter your industry
  //     </h2>
  //     <p className="text-md md:text-[1.3rem] text-[#293745] mb-8">
  //       SafetyCulture inspection software can be used across a range of
  //       industries and use cases.
  //     </p>
  //     <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8 text-left">
  //       {services.map((service, index) => (
  //         <div key={index} className="flex items-center">
  //           <FaRegCircleCheck className="text-[#293745] me-3 text-xl" />
  //           <span className="text-[#4740d4] text-md hover:underline">{service}</span>
  //         </div>
  //       ))}
  //     </div>
  //   </div>
  // );
};

export default IndustriesSection;
