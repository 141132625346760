import React from "react";
import { FaRegCheckCircle } from "react-icons/fa";
import i18next from "i18next";

const ListData = ({ title, categories }) => {
  const isArabic = i18next.language === "ar";

  return (
    <section className="bg-[#F3F6FB] py-28 ">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
        <h2 className="text-2xl md:text-3xl font-extrabold text-[#293745] lg:text-5xl">
          {title}
        </h2>
        <div className="mt-12 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8">
          {categories.map((category, idx) => (
            <div key={idx} className={`${isArabic ? "text-right" : "text-left"}`}>
              <h3 className="text-lg font-semibold text-[#293745] mb-6">
                {category.title}
              </h3>
              <ul className="space-y-6">
                {category.items.map((item, index) => (
                  <li
                    key={index}
                    className={`flex items-start ${
                      isArabic ? "flex-row-reverse" : ""
                    }`}
                  >
                    <span className="flex-shrink-0 leading-none">
                      <FaRegCheckCircle
                        className={`${isArabic ? "ml-2" : "mr-2"}`}
                        size={20}
                      />
                    </span>
                    <span className="text-base text-[#293745]">{item}</span>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ListData;
