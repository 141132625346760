import React from "react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import {
  FaClipboardList,
  FaChartLine,
  FaFileAlt,
  FaDesktop,
} from "react-icons/fa";

const FeaturesSection = () => {
  const isArabic = i18next.language === "ar";
  const { t } = useTranslation();

  const features = [
    {
      id: 1,
      icon: <FaClipboardList size={40} className="text-[#273043]" />,
      text: t("inspectionpage.feature1"), // Using the t() function for translation
    },
    {
      id: 2,
      icon: <FaChartLine size={40} className="text-[#273043]" />,
      text: t("inspectionpage.feature2"), // Using the t() function for translation
    },
    {
      id: 3,
      icon: <FaFileAlt size={40} className="text-[#273043]" />,
      text: t("inspectionpage.feature3"), // Using the t() function for translation
    },
    {
      id: 4,
      icon: <FaDesktop size={40} className="text-[#273043]" />,
      text: t("inspectionpage.feature4"), // Using the t() function for translation
    },
  ];

  return (
    <div
      className={` ${isArabic ? "text-right" : "text-left"} ${
        isArabic ? "rtl" : ""
      }`}
    >
      <section className="py-28 px-4 sm:px-6 lg:px-8">
        <div className="max-w-7xl mx-auto grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-12 text-center">
          {features.map((feature) => (
            <div
              key={feature.id}
              className="flex flex-col items-center space-y-9"
            >
              <div>{feature.icon}</div>
              <strong className="text-[1rem] font-semibold text-[#273043]">
                {feature.text}
              </strong>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
};

export default FeaturesSection;
