import React from "react";
import ListData from "../../../shared/listData";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

const ListDataSection = () => { 
  const isArabic = i18next.language === "ar";
  const { t } = useTranslation();

  const categoriesData = [
    {
      title: t("listDataSection.categories.streamlineOperations.title"),
      items: t("listDataSection.categories.streamlineOperations.items", { returnObjects: true }),
    },
    {
      title: t("listDataSection.categories.maintainHighStandards.title"),
      items: t("listDataSection.categories.maintainHighStandards.items", { returnObjects: true }),
    },
    {
      title: t("listDataSection.categories.preventIncidents.title"),
      items: t("listDataSection.categories.preventIncidents.items", { returnObjects: true }),
    },
  ];
};

export default ListDataSection;
