import React from "react";
import ListData from "../../../shared/listData";

const categoriesData = [
  {
    title: "Streamline mine operations",
    items: [
      "Geological assessments",
      "Toolbox talks",
      "Heritage assessments",
      "Issue and action management",
      "Exploration work",
      "Permit to work"
    ],
  },
  {
    title: "Achieve high standards & keep compliant",
    items: [
      "Inspections and audits",
      "Policies and procedures",
      "Paint storage",
      "Pool inspection",
    ],
  },
  {
    title: "Prevent incidents",
    items: [
      "Incident/ near miss reporting and notification",
      "Risk assessments",
    ],
  },
  {
    title: "Improve asset uptime",
    items: [
      "Fire safety risk assessment",
      "Gas safety monitoring",
     " Elevator checks",
      "HVAC maintenance",
      "Legionella risk assessment",
      "Asbestos inspection"
    ],
  },
];

const ListDataSection = () => {
  return (
    <div>
      <ListData title="Drive improvement across every site" categories={categoriesData} />
    </div>
  );
};

export default ListDataSection;
