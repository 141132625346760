import ImprovementSection from "../../../shared/improvementSection";
const Improvement = () => {
  return (
    <ImprovementSection
      heading="Boost workforce productivity today"
      btn1="Get started for free"
      btn2=" Book a demo"
    />
  );
};
export default Improvement;
