import React from "react";
import SafetyHero from "../../../assets/safetyDocs/safetydocs-hero.webp";
import HeroFlex from "../../../shared/heroFlex";
const HeroSection = () => {
  return (
    <HeroFlex
      span="SafetyDocs"
      heading="Compliance made easy with SafetyDocs"
      para="The leading provider of documented Workplace Health and Safety solutions in Australia."
      iconbtn="Visit SafetyDocs"
      img={SafetyHero}
    />
  );
};

export default HeroSection;
