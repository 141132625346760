import React from "react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import ScheduleImage from "../../../assets/inspection/schedule_inspections.webp";
import { FaRegCheckCircle } from "react-icons/fa";
import ContentWithChecked from "../../../shared/contentWithChecked";
const DriveEngagement = () => {
  const isArabic = i18next.language === "ar";
  const { t } = useTranslation();
  return (
    <div
      className={` ${isArabic ? "text-right" : "text-left"} ${
        isArabic ? "rtl" : ""
      }`}
    >
      <div className="py-24">
        <ContentWithChecked
          img={ScheduleImage}
          heading={t("inspectionpage.driveengagnmentheading")}
          para={t("inspectionpage.driveengagnmentpara")}
          icon={<FaRegCheckCircle className="text-[#00d1ff] me-3 " />}
          para1={t("inspectionpage.driveengagnmentpara1")}
          para2={t("inspectionpage.driveengagnmentpara2")}
          para3={t("inspectionpage.driveengagnmentpara3")}
          reverse
        />
      </div>
    </div>
  );
};

export default DriveEngagement;
