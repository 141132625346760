import React from "react";
import { AiOutlineIssuesClose } from "react-icons/ai";
import { HiOutlineSpeakerphone } from "react-icons/hi";
import { FaGraduationCap } from "react-icons/fa6";
import { FaAngleRight } from "react-icons/fa6";
const SummaryProgress = () => {
  return (
    <div className=" px-4">
        <h1 className="text-[#1f2533] mb-3 text-xl font-bold text-start">Summary</h1>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 mb-8">
        <SummaryCard
          icon={<HiOutlineSpeakerphone />}
          title="Heads Up"
          count={0}
        />
        <SummaryCard icon={<FaGraduationCap />} title="Training" count={0} />
        <SummaryCard icon={<AiOutlineIssuesClose />} title="Issues" count={0} />
      </div>
      <div>
      <h1 className="text-[#1f2533] mb-3 text-xl font-bold text-start">In Progress</h1>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 mb-8">
     
     <div className="bg-white p-6 rounded-lg shadow text-start">
       <div className="text-sm text-[#564BE7] font-semibold mb-1">INSPECTION</div>
       <div className="text-base font-bold mb-1">Untitled inspection</div>
       <div className="text-gray-600 text-sm mb-1">
         Colliers International - Property Management Inspection Checklist
       </div>
       <div className="text-gray-400 text-sm">Updated 1 day ago</div>
     </div>
     </div>
      </div>
   
    </div>
  );
};

const SummaryCard = ({ icon, title, count }) => {
  return (
    <div>
    
      <div className="bg-white px-6 py-3 rounded-lg shadow flex items-center justify-between">
        <div className="flex">
          <div className="flex items-center">
            <div className="text-2xl rounded-xl text-gray-500 p-2 bg-[#E9EDF6]">
              {icon}
            </div>
          </div>
          <div className="ms-2 text-start">
            <div className="text-xl font-bold text-gray-800">{count}</div>
            <div className="text-gray-600 text-base">{title}</div>
          </div>
        </div>
        <div>
          <FaAngleRight />
        </div>
      </div>
    </div>
  );
};

export default SummaryProgress;
