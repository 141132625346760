import React from 'react';
import { FaRegSmile } from 'react-icons/fa';
import { FaArrowDown } from "react-icons/fa";

const Devices = () => {
  return (
    <div className="container mx-auto py-3">
         <h2 className="text-xl font-semibold text-gray-900 text-start mb-4">Devices</h2>
         <h4 className="text-lg text-gray-500 !text-start">Device allowance: 3</h4>
      <div className="bg-white rounded-lg shadow-md p-6">
     

        {/* Table Header */}
        <div className="w-full bg-gray-100 rounded-md text-gray-700 flex justify-around items-center px-4 py-2">
          <div className=" font-medium text-sm underline flex">Device name <FaArrowDown className='ms-2 mt-1'/></div>
          <div className=" text-right font-medium text-sm underline">Registration date</div>
        </div>

        {/* Empty State */}
        <div className="flex flex-col items-center justify-center py-16">
          <div className="flex items-center justify-center">
            {/* Replace the below with the cactus icon */}
            <FaRegSmile className="text-[70px] text-gray-400" />
          </div>
          <p className="text-sm text-gray-600 mt-4">This user hasn't connected any devices</p>
        </div>
      </div>
    </div>
  );
};

export default Devices;
