import React, { useState } from "react";
import { FiMoreVertical } from "react-icons/fi";
import { IoLink } from "react-icons/io5";
import { FaSearch } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import DownloadUserModalPage from "../downloadUserList";
import UserVisibilityModal from "../userVisibiltySettings";
import EditTimeZoneModal from "../editTimeZone";
import AddUserToGroupsModal from "../addUsersToGroupModal";
import AddUserToSitesModal from "../addUsersToSitesModal";
import { useAuth } from "../../../context/AuthContext";
import { useApi } from "../../../context/OrganizationContaxt";
import useHttp from "../../../../common/hooks/useHttp";
import { useNotification } from "../../../../common/hooks/useNotification";
const UserTable = () => {
  const [isDotOpen, setIsDotOpen] = useState();
  const [isTimeZoneOpen, setIsTimeZoneOpen] = useState(false);
  const [isGroupOpen, setIsGroupOpen] = useState(false);
  const [isVerticalOpen, setIsVerticalOpen] = useState(false);
  const [DownloadUserModal, setDownloadOpen] = useState(false);
  const [UservisibilityModal, setUserVisibilityOpen] = useState(false);
  const [UserAddModal, setUserAddOpen] = useState(false);
  const navigate = useNavigate();
  const { authData } = useAuth();
  const { users } = useApi();

  const { sendRequest } = useHttp();
  const notify = useNotification();
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const handleActiveClick = () => {
    navigate("/dashboard/profile");
  };
  const dotMenuOpen = () => {
    if (isDotOpen === false) {
      setIsDotOpen(true);
    } else {
      setIsDotOpen(false);
    }
  };
  const [showAdduser, setshoAdduser] = useState(false);
  const AddUser = async () => {
    try {
      const userData = {
        email,
        firstName,
        lastName,
        password,
      };
      await sendRequest(
        {
          url: "v1/organization/users/add",
          method: "POST",
          accessToken: authData.accessToken,
          headers: {
            userName: authData.userName,
            organizationId: authData.organizationId,
            deviceId: authData.deviceId,
          },
          payload: userData,
        },
        (response) => {
          if (response) {
            if (response.responseCode == "200") {
              notify(response.responseDesc, "success");
              setshoAdduser(false);
            } else {
              notify(response.responseDesc, "error");
            }
          }
        }
      );
    } catch (err) {
      console.log(err, " something wrong");
    }
  };

  return (
    <div className=" mx-auto overflow-visible">
      {DownloadUserModal && <DownloadUserModalPage />}
      {UservisibilityModal && <UserVisibilityModal />}
      {isTimeZoneOpen && (
        <EditTimeZoneModal setIsTimeZoneOpen={setIsTimeZoneOpen} />
      )}
      {isGroupOpen && <AddUserToGroupsModal setIsGroupOpen={setIsGroupOpen} />}
      {UserAddModal && <AddUserToSitesModal setUserAddOpen={setUserAddOpen} />}
      <div className="flex justify-between items-center py-4 ">
        <h1 className="text-2xl font-bold">Users</h1>
        <div className="flex items-center space-x-4">
          {/* Search */}
          <button
            className="bg-white flex hover:bg-[#e0e4ff] border border-[#bfc6d4] text-[#6559ff] px-4 py-2 rounded-lg"
            // onClick={() => navigate("/dashboard/site-matrix")}
          >
            <IoLink className="me-1 text-[#6559ff] mt-1" />
            Manage invites
          </button>
          {/* Add site */}
          <button
            onClick={() => setshoAdduser(true)}
            className="bg-[#6559ff] hover:bg-[#7470f5] text-white px-4 py-2 rounded-lg"
          >
            + Add Users
          </button>
          {/* More Options */}
          <button
            className="p-2 rounded-md border border-gray-300"
            onClick={() => dotMenuOpen()}
          >
            <FiMoreVertical />
          </button>
          <div className="relative">
            {isDotOpen && (
              <div className="absolute z-50 text-start space-y-3 ps-4 py-3 shadow-md cursor-pointer left-[-250px] right-0 mt-10 bg-white w-[270px]">
                <p onClick={() => navigate("/dashboard/bulk-upload-users")}>
                  Bulk Upload users (CSV)
                </p>
                <p
                  onClick={() => {
                    setDownloadOpen(true);
                    setIsDotOpen(false);
                  }}
                ></p>
                <p>Downlaod user list (CSV)</p>
                <p
                  onClick={() => {
                    setUserVisibilityOpen(true);
                    setIsDotOpen(false);
                  }}
                >
                  Manage user fields
                </p>
                {/* <p
                  onClick={() => {
                    // setSitePermissionOpen(true);
                    setIsDotOpen(false);
                  }}
                >
                  Update user details (CSV)
                </p>
                <p
                  onClick={() => {
                    // setSitePermissionOpen(true);
                    setIsDotOpen(false);
                  }}
                >
                  User visibility settings
                </p> */}
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="flex mb-5">
        <div className="flex items-center border border-gray-300 rounded-md px-2 py-1">
          <FaSearch className="text-gray-500" />
          <input
            type="text"
            placeholder="Search"
            className="ml-2 outline-none "
          />
        </div>
        {/* Filter */}
        <button className="text-[#4740d4]  hover:bg-[#ecedfe] ms-3 rounded-lg  px-4 py-2">
          + Add filter
        </button>
      </div>

      {/* Seats Card */}
      <div className=" mb-6">
        <div className="flex justify-between border p-4 rounded-lg bg-white shadow-sm w-[30%] ">
          <div className="text-start">
            <h2 className="text-lg font-medium">Seats</h2>
            <p className="text-xl font-semibold pt-3">9</p>
            <p className="text-sm text-gray-500">Available 10 total</p>
          </div>
          <div className="flex flex-col justify-center">
            <p className="text-sm text-gray-500 border-b">1 used</p>
            <p className="text-sm text-gray-500"> 10 total</p>
          </div>
        </div>
      </div>

      {/* Table */}
      <div className="overflow-x-auto overflow-visible">
        <div className="mt-4 border rounded-lg overflow-x-auto overflow-visible">
          <table className="w-full text-left">
            <thead>
              <tr className="bg-gray-100">
                <th className="p-3">
                  <input type="checkbox" />
                </th>
                <th className="p-3">Name</th>
                <th className="p-3">Permission set</th>
                <th className="p-3">Status</th>
                <th className="p-3">Last seen</th>
                <th className="p-3"></th>
              </tr>
            </thead>
            {users &&
              users.map((item, index) => (
                <tbody key={index}>
                  <tr className="border-t">
                    <td className="p-3">
                      <input type="checkbox" />
                    </td>
                    <td className="p-3">
                      <div className="flex items-center">
                        <div className="w-8 h-8 bg-blue-300 rounded-full flex items-center justify-center text-white">
                          {(
                            item.user.firstName.charAt(0) +
                            item.user.firstName.charAt(
                              item.user.firstName.length - 1
                            )
                          ).toUpperCase()}
                        </div>
                        <div className="ml-2">
                          <p className="font-semibold">{item.user.firstName}</p>
                          <p className="text-gray-500 text-sm">
                            {item.user.email}
                          </p>
                        </div>
                      </div>
                    </td>
                    <td className="p-3"> {item.role} </td>
                    <td className="p-4 border-b border-gray-200 text-start">
                      <span
                        className={`${
                          item.user.isActive === true
                            ? "text-green-600 border-green-600  cursor-pointer"
                            : "text-red-600 cursor-pointer border-red-600 "
                        } border  rounded-full text-sm px-3 py-1 `}
                        onClick={handleActiveClick} // Navigate to profile page
                      >
                        {item.user.isActive === true ? "Active" : "Non Active"}
                      </span>
                    </td>
                    <td
                      className={`
                        ${
                          item.user.lastSeen !== null
                            ? "text-green-600"
                            : "text-red-600"
                        }
                     p-4 border-b `}
                    >
                      <span className="flex items-center">
                        <span
                          className={`h-2 w-2 ${
                            item.user.lastSeen !== null
                              ? "bg-green-600"
                              : "bg-red-600"
                          }  rounded-full mr-1`}
                        ></span>
                        {item.user.lastSeen !== null ? "Online now" : "Offline"}
                      </span>
                    </td>

                    <td className="p-4 border-b border-gray-200 cursor-pointer">
                      <FiMoreVertical onClick={() => setIsVerticalOpen(true)} />
                    </td>
                  </tr>
                </tbody>
              ))}
          </table>
        </div>
      </div>
      <div className="relative overflow-visible">
        {isVerticalOpen && (
          <div className="absolute overflow- z-50 text-start space-y-3 ps-4 py-3 shadow-md cursor-pointer right-0  top-[-30px] bg-white w-[270px]">
            <p onClick={() => navigate("/dashboard/notifications-settings")}>
              View profile
            </p>

            <p onClick={() => navigate("/dashboard/notifications-settings")}>
              View Settings
            </p>
            <p
              onClick={() => {
                setIsTimeZoneOpen(true);
                setIsVerticalOpen(false);
              }}
            >
              Change time zone
            </p>
            {/* <p
              onClick={() => {
                setIsGroupOpen(true);
                setIsVerticalOpen(false);
              }}
            >
              Add to groups
            </p>
            <p
              onClick={() => {
                setUserAddOpen(true);
                setIsVerticalOpen(false);
              }}
            >
              Add to sites
            </p> */}
          </div>
        )}
        {showAdduser === true && (
          <div class="fixed inset-0 z-50 flex justify-center items-center bg-black bg-opacity-50 backdrop-blur-sm">
            <div class="relative p-4 w-full max-w-md max-h-full">
              {/* <!-- Modal content --> */}
              <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
                {/* <!-- Modal header --> */}
                <div class="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                  <h3 class="text-xl font-semibold text-gray-900 dark:text-white">
                    Add User
                  </h3>
                  <button
                    type="button"
                    onClick={() => {
                      setshoAdduser(false);
                    }}
                    class="end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                    data-modal-hide="authentication-modal"
                  >
                    <svg
                      class="w-3 h-3"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 14 14"
                    >
                      <path
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                      />
                    </svg>
                    <span class="sr-only">Close modal</span>
                  </button>
                </div>
                {/* <!-- Modal body --> */}
                <div class="p-4 md:p-5">
                  <div>
                    <label
                      for="email"
                      class="block  text-start mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      User email
                    </label>
                    <input
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                      placeholder="name@company.com"
                      required
                    />
                  </div>
                  <div>
                    <label
                      for="firstName"
                      class="block mb-2 text-start text-md font-medium font-bold text-gray-900 dark:text-white"
                    >
                      First Name
                    </label>
                    <input
                      type="text"
                      name="Firstname"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                      readOnly={false}
                      id="FirstName"
                      placeholder="First Name"
                      class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                      required
                    />
                  </div>

                  <div>
                    <label
                      for="lastname"
                      class="block mb-2 text-start text-md font-medium font-bold text-gray-900 dark:text-white"
                    >
                      Last Name
                    </label>
                    <input
                      type="text"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                      name="Last Name"
                      id="lastNAme"
                      placeholder="Last Name"
                      class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                      required
                    />
                  </div>

                  <div>
                    <label
                      for="firstName"
                      class="block mb-2 text-start text-md font-medium font-bold text-gray-900 dark:text-white"
                    >
                      Password
                    </label>
                    <input
                      type="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      name="password"
                      id="password"
                      placeholder="Password"
                      class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                      required
                    />
                  </div>

                  {/* <div class="flex justify-between">
                    <div class="flex items-start">
                      <div class="flex items-center h-5">
                        <input
                          id="remember"
                          type="checkbox"
                          value=""
                          class="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300 dark:bg-gray-600 dark:border-gray-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800"
                          required
                        />
                      </div>
                      <label
                        for="remember"
                        class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                      >
                        Remember me
                      </label>
                    </div>
                    <a
                      href="#"
                      class="text-sm text-blue-700 hover:underline dark:text-blue-500"
                    >
                      Lost Password?
                    </a>
                  </div> */}
                  <button
                    onClick={AddUser}
                    type="submit"
                    class="w-full mt-4 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                  >
                    Add User
                  </button>
                  {/* <div class="text-sm font-medium text-gray-500 dark:text-gray-300">
                    Not registered?{" "}
                    <a
                      href="#"
                      class="text-blue-700 hover:underline dark:text-blue-500"
                    >
                      Create account
                    </a>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {/* Pagination */}
      <div className="flex justify-between items-center mt-4">
        <p>1 - 1 of 1 result</p>
        <div className="flex space-x-2">
          <button className="px-4 py-2 border border-gray-300 rounded-lg text-gray-500 hover:bg-gray-100">
            &lt;
          </button>
          <span className="px-4 py-2">1 / 1</span>
          <button className="px-4 py-2 border border-gray-300 rounded-lg text-gray-500 hover:bg-gray-100">
            &gt;
          </button>
        </div>
      </div>
    </div>
  );
};

export default UserTable;
