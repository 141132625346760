import TextImagewFlex from "../../../shared/textImageFlex";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import TeamAssetsImage from "../../../assets/assets/TeamAssets.webp";
const TeamAssets = () => {
  const isArabic = i18next.language === "ar";
  const { t } = useTranslation();
  return (
    <div
      className={` ${isArabic ? "text-right" : "text-left"} ${
        isArabic ? "rtl" : ""
      }`}
    >
      <div className="py-24 bg-[#F3F6FB]">
        <TextImagewFlex
          img={TeamAssetsImage}
          heading={t("assetpage.makeassets")}
          widhtClass="85%"
          para={t("assetpage.makeassetspara")}
        />
      </div>
    </div>
  );
};
export default TeamAssets;
