import React, { useState } from "react";
import InspectionImage from "../../../../assets/platform/inspections-audits-reports.webp";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import TasksImage from "../../../../assets/platform/checklist.jpg";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
const Taskstab = () => {
  const { t } = useTranslation();
  const isArabic = i18next.language === "ar";
  const [openDropdown, setOpenDropdown] = useState(null);

  const toggleDropdown = (index) => {
    if (openDropdown === index) {
      setOpenDropdown(null);
    } else {
      setOpenDropdown(index);
    }
  };

  const dropdownData = [
    {
      Key: "complete",
    },
    {
      Key: "Manage work",
    },
    {
      Key: "traceability",
    },
  ];

  return (
    <div
      className={`py-4 ${isArabic ? "text-right" : "text-left"} ${
        isArabic ? "rtl" : ""
      }`}
    >
      <div className="w-full py-4 lg:py-14 px-6 md:px-12 lg:px-12 ">
        <div className="container mx-auto flex flex-col lg:flex-row items-start justify-between">
          <div className="lg:w-1/2 text-left me-0 lg:pe-[40px] text-start pt-10 lg:pt-0">
            <span className="inline-block border-t border-b border-r border-[3px] border-l-[.1875rem] border-[#293745] text-sm text-[#293745]  px-3 py-1 rounded-full mb-7">
              {t("platformpage.taskstitle")}
            </span>
            <h2 className="text-3xl sm:text-4xl lg:text-[2.4375rem] font-bold text-gray-900 mb-6">
              {t("platformpage.tasksheading")}
            </h2>
            <p className="text-[#293745] text-base  mb-6">
              {t("platformpage.taskspara")}
            </p>
            <div className="pt-2 pb-7">
              <div className="space-y-4">
                {dropdownData.map((item, index) => (
                  <div
                    key={index}
                    className="border-2 border-[#A2ACBD] rounded-lg "
                  >
                    {/* Dropdown Header */}
                    <div
                      className="flex justify-between items-center p-4 cursor-pointer"
                      onClick={() => toggleDropdown(index)}
                    >
                      <h3 className="text-[#293745] text-[1rem] font-semibold">
                        {item.title
                          ? item.title
                          : t(`platformpage.${item.Key}.title`)}
                      </h3>
                      {openDropdown === index ? (
                        <FaChevronUp className="text-[#293745]" />
                      ) : (
                        <FaChevronDown className="text-[#293745]" />
                      )}
                    </div>

                    {/* Dropdown Content */}
                    {openDropdown === index && (
                      <div className="px-4 pb-4">
                        <ul className="space-y-2 list-disc list-inside">
                          {(
                            t(`platformpage.${item.Key}.content`, {
                              returnObjects: true,
                            }) || []
                          ).map((contentItem, i) => (
                            <li key={i} className="text-[1rem] font-[400]">
                              {contentItem}
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="lg:w-1/2 mt-10 lg:mt-0 relative">
            <img
              src={TasksImage}
              alt="Operations"
              className="w-full rounded-lg"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Taskstab;
