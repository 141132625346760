import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaEdit, FaSearch } from "react-icons/fa";
import { useAuth } from "../../context/AuthContext";
const userdevice = require("../../../assets/userdevice/notconnect.jpg");
const UserGroup = () => {
  const { authData } = useAuth();
  const navigate = useNavigate();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false); // State for sidebar visibility
  const [isAssetDropdownOpen, setIsAssetDropdownOpen] = useState(false);
  const [assetSearchTerm, setAssetSearchTerm] = useState("");
  const [asset, setAsset] = useState("");

  const handleNavigation = (tab) => {
    const routes = {
      "User settings": "/dashboard/user-setting",
      Training: "/dashboard/user-trainning",
      Notifications: "/dashboard/user-notification",
      Devices: "/dashboard/user-device",
      Templates: "/dashboard/user-template",
      Groups: "/dashboard/user-group",
      Sites: "/dashboard/user-site",
      Credentials: "/dashboard/user-credential",
    };
    navigate(routes[tab]);
  };

  const handleSidebarToggle = () => {
    setIsSidebarOpen(!isSidebarOpen); // Toggle sidebar visibility
  };

  const tableHeaders = ["Group Name"];
  const assetOptions = ["Asset 1", "Asset 2", "Asset 3"]; // Placeholder asset options

  const filteredAssetOptions = assetOptions.filter((option) =>
    option.toLowerCase().includes(assetSearchTerm.toLowerCase())
  );
  const handleBack = () => {
    navigate(-1); // Moves back to the previous page
  };

  return (
    <div className="min-h-screen p-4 mr-10">
      <div className="text-left">
        <button onClick={handleBack} className="text-sm text-gray-500 mb-4">
          &larr; Back
        </button>
      </div>

      {/* Profile Section */}
      <div className="bg-gradient-to-r from-blue-200 to-purple-200 rounded-t-lg p-4 flex items-center relative">
        <div className="w-16 h-16 rounded-full bg-gray-300 flex items-center justify-center text-2xl text-white font-bold relative">
          {(
            authData.userName.charAt(0) +
            authData.userName.charAt(authData.userName.length - 1)
          ).toUpperCase()}
          <FaEdit
            onClick={handleSidebarToggle} // Opens sidebar
            className="absolute bottom-0 right-0 bg-white p-1 rounded-full text-blue-500 cursor-pointer text-lg"
          />
        </div>
        <div className="ml-4">
          <h2 className="text-xl font-semibold">{authData?.userName}</h2>
          <p className="text-gray-600">{authData?.userDetails?.email}</p>
        </div>
      </div>

      {/* Navigation Tabs */}
      <div className="bg-white border-b flex justify-start items-center space-x-4 px-4 py-2">
        {[
          "User settings",
          "Training",
          "Notifications",
          "Devices",
          "Templates",
          "Groups",
          "Sites",
          "Credentials",
        ].map((tab) => (
          <button
            key={tab}
            onClick={() => handleNavigation(tab)}
            className="text-sm text-gray-600 hover:text-blue-500 mx-4 my-2"
          >
            {tab}
          </button>
        ))}
      </div>

      {/* Search and Buttons Layout */}
      <div className="mt-6">
        {/* Groups heading and Add users to group button */}
        <div className="flex flex-col sm:flex-row sm:items-center space-y-4 sm:space-y-0 sm:space-x-4">
          <div className="flex items-center sm:w-1/2 justify-start">
            <h2 className="text-lg font-semibold">Groups</h2>
          </div>

          {/* Add users to group button */}
          <div className="flex items-center sm:w-1/2 justify-end">
            <button
              onClick={handleSidebarToggle} // Open sidebar
              className="bg-indigo-500 text-white px-4 py-2 rounded-md"
            >
              Add users to group
            </button>
          </div>
        </div>

        <div className="mt-4 flex items-left">
          <input
            type="text"
            placeholder="Search"
            className="border rounded-md p-2 w-full sm:w-64"
          />
        </div>
      </div>

      {/* Table Section */}
      <div className="mt-4 rounded-md overflow-x-auto">
        <table className="w-full min-w-max text-left border border-gray-300">
          <thead>
            <tr className="bg-indigo-50 text-gray-700 font-thin h-14">
              {tableHeaders.map((header) => (
                <th
                  key={header}
                  className="w-32 p-2 text-xs sm:text-sm md:text-base"
                >
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td colSpan="5" className="text-center p-4 sm:p-6">
                <div className="flex flex-col items-center">
                  <img
                    src={userdevice}
                    alt="No schedules"
                    className="mb-4 w-20 h-20 sm:w-32 sm:h-32 md:w-40 md:h-40"
                  />
                  <p className="text-gray-600 font-semibold text-sm sm:text-base md:text-lg">
                    This user hasn't connected any devices
                  </p>
                  <p className="text-gray-500 text-xs sm:text-sm">
                    You do not have any assigned courses or certificates.
                  </p>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      {/* Sidebar (Right side) */}
      {isSidebarOpen && (
        <div className="fixed top-0 right-0 w-1/3 bg-gray-100 h-full shadow-lg z-50 transition-transform transform translate-x-0">
          <div className="p-4">
            <h2 className="text-xl font-semibold">Add Users to Group</h2>
            {/* Sidebar content */}
            <div className="relative mt-12">
              <label className="block text-sm font-medium text-gray-700">
                Asset
              </label>
              <button
                onClick={() => setIsAssetDropdownOpen(!isAssetDropdownOpen)}
                className="block w-full border border-gray-300 rounded-md h-12 px-3 bg-white text-left focus:ring-0 focus:border-indigo-500 hover:border-indigo-700 transition"
              >
                {asset || "Select Asset"}
              </button>

              {isAssetDropdownOpen && (
                <div className="absolute mt-2 w-full bg-white border border-gray-300 rounded-lg shadow-lg z-10 p-4">
                  {/* Search Input with Icon */}
                  <div className="relative mb-4">
                    <input
                      type="text"
                      value={assetSearchTerm}
                      onChange={(e) => setAssetSearchTerm(e.target.value)}
                      placeholder="Search"
                      className="w-full h-12 pl-10 pr-3 border border-gray-300 rounded-md focus:outline-none focus:border-indigo-500"
                    />
                    <FaSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                  </div>

                  {/* Filtered Results */}
                  {filteredAssetOptions.length > 0 ? (
                    <ul className="max-h-48 overflow-y-auto mb-4">
                      {filteredAssetOptions.map((option, index) => (
                        <li
                          key={index}
                          onClick={() => {
                            setAsset(option);
                            setIsAssetDropdownOpen(false);
                            setAssetSearchTerm("");
                          }}
                          className="px-3 py-2 cursor-pointer hover:bg-indigo-500 hover:text-white transition rounded-md"
                        >
                          {option}
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <div className="text-center text-gray-500 mb-4">
                      <p className="text-lg font-semibold">No assets found</p>
                      <p>Try a different search term.</p>
                    </div>
                  )}

                  {/* Footer with Buttons */}
                  <div className="flex justify-between mt-4">
                    <button
                      onClick={() => {
                        setAsset("");
                        setAssetSearchTerm("");
                      }}
                      className="text-indigo-500 hover:underline"
                    >
                      Clear selections
                    </button>
                    <button
                      onClick={() => setIsAssetDropdownOpen(false)}
                      className="px-4 py-2 bg-indigo-500 text-white rounded-md"
                    >
                      Done
                    </button>
                  </div>
                </div>
              )}
            </div>
            <div className="mt-4">
              <button
                onClick={handleSidebarToggle}
                className="bg-gray-300 text-gray-700 rounded-md px-4 py-2"
              >
                Close Sidebar
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserGroup;
