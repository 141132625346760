import React from "react";
import Workflow from "../../../assets/integrations/workflow-builder-2.webp";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

const CardSection = () => {
  const { t } = useTranslation(); // Translation hook
  const isArabic = i18next.language === "ar";

  const cardsData = [
    {
      id: 1,
      text: t("integrationpage.id1"),
    },
    {
      id: 2,
      text: t("integrationpage.id2"),
    },
    {
      id: 3,
      text: t("integrationpage.id3"),
    },
  ];

  return (
    <section className="py-24">
      <div className="container mx-auto">
        <span className="inline-block border-t border-b border-r border-[3px] border-l-[.1875rem] border-[#293745] text-sm text-[#293745] px-3 py-1 rounded-full mb-8">
          {t("integrationpage.customizespan")}
        </span>
        <h2 className="text-2xl md:text-3xl lg:text-5xl font-black text-[#293745]">
          {t("integrationpage.customizework")}
        </h2>
        <p className="text-lg md:text-[1.25rem] text-[#293745] mt-7 px-3 md:px-36 leading-[34px]">
          {t("integrationpage.customizepara")}
        </p>
        <div
          className={` ${isArabic ? "text-right" : "text-left"} ${
            isArabic ? "rtl" : ""
          }`}
        >
          <div className="flex justify-center mb-10">
            <img
              src={Workflow}
              alt="Dotted lines"
              className="w-[90%] md:w-[56%] mx-auto"
            />
          </div>

          {/* Cards */}

          <div className="grid gap-6 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 w-full md:w-[80%] mx-auto">
            {cardsData.map((card) => (
              <div
                key={card.id}
                className="bg-blue-50 border-l-4 border-cyan-500 rounded-xl p-6 transition-all duration-300 ease-in-out transform hover:scale-105 hover:shadow-xl"
              >
                <p className="text-gray-700 text-sm text-start">{card.text}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default CardSection;
