import TextImagewFlex from "../../../shared/textImageFlex";
import ReportingImage from "../../../assets/training/Reporting-and-analytics.webp";
const Management = () => {
  return (
    <div >
      <TextImagewFlex
        span="Reporting & management"
        img={ReportingImage}
        heading="Engage your teams and pinpoint issues early"
        para="Get a clear view on your team’s knowledge gaps with analytics, so you can deliver and optimize training in real-time before it becomes an issue or a liability. Our learning management system makes it easy for you to enhance your training courses with engagement features."
        show
        reverse
      />
    </div>
  );
};
export default Management;
