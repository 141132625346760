import React from "react";

const Title = ({ title, setTitle }) => {
  return (
    <div className="mt-6">
      <label className="block text-sm font-medium text-gray-700">Title</label>
      <input
        type="text"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        placeholder="Enter title"
        className="mt-1 block w-full border border-gray-300 rounded-md h-12 px-3 bg-white focus:ring-0 focus:border-black hover:border-indigo-700 transition"
      />
    </div>
  );
};

export default Title;
