import { useState } from "react";
import { FaArrowDown } from "react-icons/fa";
const Templates = () => {
  const [activeTab, setActiveTab] = useState("Active");

  // Table data for the active tab
  const activeData = [
    {
      icon: "https://via.placeholder.com/40", // Use the appropriate icon URLs
      name: "Sample - Construction Daily Report Checklist",
      access: "Conduct, Edit, Delete",
    },
    {
      icon: "https://via.placeholder.com/40",
      name: "Sample - Daily Forklift Inspection Form",
      access: "Conduct, Edit, Delete",
    },
    {
      icon: "https://via.placeholder.com/40",
      name: "Untitled template",
      access: "Conduct, Edit, Delete",
    },
  ];

  // Table data for the archived tab (you can add more)
  const archivedData = [
    {
      icon: "https://via.placeholder.com/40",
      name: "Archived Template - Sample 1",
      access: "View, Restore",
    },
    {
      icon: "https://via.placeholder.com/40",
      name: "Archived Template - Sample 2",
      access: "View, Restore",
    },
  ];

  const renderTable = (data) => (
    <table className="min-w-full table-auto">
      <thead className="bg-gray-200">
        <tr>
          <th className="px-4 py-2 text-left text-sm underline flex">Template name <FaArrowDown className='ms-2 mt-1'/></th>
          <th className="px-4 py-2 text-center text-sm">Access level</th>
        </tr>
      </thead>
      <tbody>
        {data.map((row, index) => (
          <tr key={index} className="border-b">
            <td className="flex items-center px-4 py-2">
              <img
                src={row.icon}
                alt="Template Icon"
                className="w-10 h-10 rounded-full mr-3"
              />
              {row.name}
            </td>
            <td className="px-4 py-2">{row.access}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );

  return (
    <div className="p-4 sm:p-6 lg:py-1  mx-auto">
        <h1 className="text-start font-bold text-xl mb-4">Templates</h1>
      {/* Tabs */}
      <nav className="flex space-x-6 mb-4 border-b-2 ">
        {["Active", "Archived"].map((tab) => (
          <button
            key={tab}
            onClick={() => setActiveTab(tab)}
            className={`pb-2 ${
              activeTab === tab
                ? "border-b-4 border-blue-600 text-blue-600"
                : "text-gray-600"
            }`}
          >
            {tab}
          </button>
        ))}
      </nav>

      {/* Search Input */}
      <div className="mb-4">
        <input
          type="text"
          placeholder="Search"
          className="flex justify-start border border-gray-300 p-2 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600"
        />
      </div>

      {/* Table */}
      {activeTab === "Active" ? renderTable(activeData) : renderTable(archivedData)}

      {/* Floating Icon */}
      <button className="fixed bottom-8 right-8 bg-blue-600 text-white p-4 rounded-full shadow-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-600">
        <svg
          className="w-6 h-6"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M5 13l4 4L19 7"
          ></path>
        </svg>
      </button>
    </div>
  );
};

export default Templates;
