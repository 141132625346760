import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import RightImage from "../../../assets/home/Level-10.webp";
import Image2 from "../../../assets/home/level.svg";
import { FaArrowRight, FaArrowLeft } from "react-icons/fa";

const TabOneDetails = () => {
  const navigate = useNavigate();
  const isArabic = i18next.language === "ar"; // Check if the current language is Arabic
  const { t } = useTranslation(); // Translation hook for multi-language support

  return (
    <div
      className={`flex flex-col lg:flex-row items-center lg:items-start ps-7 md:ps-14 rounded-lg shadow-xl mx-3 md:mx-7 ${
        isArabic ? "text-right rtl" : "text-left"
      }`}
    >
      {/* Left Section with Text and Button */}
      <div className="lg:w-[50%] pt-[73px] pe-3 md:pe-12">
        {/* Top Icon/Image */}
        <img src={Image2} alt="Icon" className="w-[160px]" />

        {/* Statistics Section */}
        <div className="block sm:inline-block pt-3">
          <h1 className="me-1 text-start text-[3.8125rem] font-[700] text-[#293745] border-b-4 border-[#6559ff]">
            2.5M
          </h1>
          <p className="text-[1rem] text-start pt-3 text-[#293745]">
            {t("tabcardspage.tab2translation")}
          </p>
        </div>

        {/* Button Section */}
        <div className="h-[300px] flex items-center">
          <button
            className="flex mt-10 mb-10 lg:my-0 text-[14px] sm:text-[1rem] font-[600] text-[#4740d4] border border-[#d0d7e1] py-2 px-4 rounded-lg"
            onClick={() => navigate("/construction")}
          >
            {t("tabcardspage.explorebtn2")}
            {/* Dynamically Render Arrow Based on Language */}
            {isArabic ? (
              <FaArrowLeft className="me-1 mt-1" />
            ) : (
              <FaArrowRight className="ms-1 mt-1" />
            )}
          </button>
        </div>
      </div>

      {/* Right Section with Image */}
      <div className="lg:w-[50%] mt-6 lg:mt-0 flex justify-center lg:justify-end hidden sm:block">
        <div className="rounded-s-full overflow-hidden">
          <img
            src={RightImage}
            alt="Construction Image"
            className="object-cover w-full h-[570px]"
          />
        </div>
      </div>
    </div>
  );
};

export default TabOneDetails;
