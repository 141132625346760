import React, { useState } from "react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";

const faqData = [
  {
    question: "What are workplace training courses?",
    answer:
      "Workplace training courses are educational programs designed to equip employees with the skills and knowledge essential to their work. These courses can range from onboarding to specialized training for upskilling or compliance requirements.",
  },
  {
    question: "Why is workplace training important?",
    answer:
      "Workplace training is important for both employees and businesses as it helps maintain high standards of practice, ensures safety and compliance, and boosts efficiency and productivity. Investing in workplace training fosters continuous improvement, leading to many benefits that contribute to the growth and success of the organization.",
  },
  {
    question: "What types of workplace training courses are available?",
    answer:
      "Our course library offers a diverse range of topics and specific skills essential for various industries. These courses range from enhancing enterprise skills, administrative abilities, and ensuring compliance with industry and regulatory standards.",
  },
  {
    question: "How can SafetyCulture enhance workplace training?",
    answer:
      "SafetyCulture enhances workplace training by providing a platform for creating and delivering digital work instructions, operating procedures, and guides to ensure that every team member has the knowledge and skills they need to do their job. Training courses can be delivered and taken on mobile devices, making it easier for team members and external contractors to access – wherever they are. It comes with an in-built analytics tool so you can assess progress, completions, and certifications.",
  },
  {
    question: "Are all the courses in the training library free?",
    answer: "Yes, users can choose from over 1,000+ courses to customize or deliver right away to your team.",
  },
];

const FAQSection = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const toggleAccordion = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div className="bg-[#F3F6FB] py-16 px-4 sm:px-8 md:px-16 lg:px-24">
      <div className="container mx-auto max-w-5xl">
        <h2 className="text-3xl sm:text-4xl md:text-5xl font-bold text-[#293745] text-center mb-12">
          FAQs
        </h2>

        {faqData.map((faq, index) => (
          <div key={index} className="mb-6 sm:mb-8">
            <div
              className={`group rounded-xl border-2 border-[#A2ACBD] transition-all duration-300 ${
                openIndex === index ? "p-4" : "p-3"
              }`}
            >
              <button
                onClick={() => toggleAccordion(index)}
                className="flex justify-between items-center w-full bg-transparent text-left hover:text-[#4740d4] transition-colors duration-300"
                aria-expanded={openIndex === index}
                aria-controls={`faq-answer-${index}`} // Link to the answer section
              >
                <span className="text-md sm:text-lg font-semibold text-[#293745] group-hover:text-[#4740d4]">
                  {faq.question}
                </span>
                {openIndex === index ? (
                  <FaChevronUp className="text-gray-600 group-hover:text-[#4740d4]" />
                ) : (
                  <FaChevronDown className="text-gray-600 group-hover:text-[#4740d4]" />
                )}
              </button>
              {openIndex === index && (
                <div
                  id={`faq-answer-${index}`}
                  className="mt-4 sm:mt-6 text-start text-gray-700"
                >
                  <p>{faq.answer}</p>
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FAQSection;
