import FacilitiesHero from "../../../assets/facilities/Facilities-Management-1.webp";
import TextImageFlex from "../../../shared/textImageFlex";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
const EasyKnowledge = () => {
  const { t } = useTranslation();
  const isArabic = i18next.language === "ar";
  return (
    <div
      className={`py-14 ${isArabic ? "text-right" : "text-left"} ${
        isArabic ? "rtl" : ""
      }`}
    >
      <TextImageFlex
        heading={t("constructionpage.EasyKnowledgeheading")}
        para={t("constructionpage.EasyKnowledgepara")}
        img={FacilitiesHero}
        widhtClass="w-[98%]"
      />
    </div>
  );
};

export default EasyKnowledge;
