import CurveHeroFlex from "../../../shared/curveHero";
import HeroImage from "../../../assets/retail/RetailPillHero.jpg";
import Image1 from "../../../assets/retail/image1.svg";
import Image2 from "../../../assets/retail/image2.svg";
import Image3 from "../../../assets/retail/image3.svg";
import Image4 from "../../../assets/retail/image4.svg";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

const ImagesArray = [
  {
    id: 1,
    img: Image1,
  },
  {
    id: 2,
    img: Image2,
  },
  {
    id: 3,
    img: Image3,
  },
  {
    id: 4,
    img: Image4,
  },
];
const FacilitiesHero = () => {
  const { t } = useTranslation();
  const isArabic = i18next.language === "ar";
  return (
    <div
      className={` pt-4 pb-24 py-14 ${isArabic ? "text-right" : "text-left"} ${
        isArabic ? "rtl" : ""
      }`}
    >
      <CurveHeroFlex
        span={t("retailpage.retailhero")}
        img={HeroImage}
        heading={t("retailpage.retailheading")}
        para={t("retailpage.retailpara")}
        btn1={t("retailpage.retailbtn1")}
        btn2={t("retailpage.retailbtn2")}
        ImagesArray={ImagesArray}
      />
    </div>
  );
};
export default FacilitiesHero;
