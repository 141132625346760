import TextImagewFlex from "../../../shared/textImageFlex";
import TrainingCreatorImage from "../../../assets/training/Training-creator-tool.webp";
const CreatorTool = () => {
  return (
    <div className="py-24 ">
      <TextImagewFlex
        span="Creator tool"
        img={TrainingCreatorImage}
        heading="Build your own with drag-and-drop tools"
        para="Train up your team in real-time. Choose from 80+ templates and enhance your workplace training with video, quizzes, assessments, and more. Quickly turn dry safework documents and processes into engaging training courses."
        show
      />
    </div>
  );
};
export default CreatorTool;
