import TextImagewFlex from "../../../shared/textImageFlex";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import AssetOnePlaceImage from "../../../assets/assets/AssetsOnePlaceFA.webp";
const GetInsight = () => {
  const isArabic = i18next.language === "ar";
  const { t } = useTranslation();
  return (
    <div
      className={` ${isArabic ? "text-right" : "text-left"} ${
        isArabic ? "rtl" : ""
      }`}
    >
      <div className="py-24 ">
        <TextImagewFlex
          img={AssetOnePlaceImage}
          heading={t("assetpage.getinsightheading")}
          para={t("assetpage.getinsightpara")}
        />
      </div>
    </div>
  );
};
export default GetInsight;
