import { useTranslation } from "react-i18next";
import i18next from "i18next";
const Career = () => {
  const { t } = useTranslation();
  const isArabic = i18next.language === "ar";
  return (
    <div className=" py-16">
      <div className="relative w-full h-screen bg-black">
        {/* Image */}
        <img
          src={require("../../assets/aboutImages/about-team.jpeg")}
          alt="Background"
          className="w-full h-full object-cover"
        />

        {/* Overlay */}
        <div className={`${isArabic ? "text-right rtl" : "text-left"}`}>
          <div className="absolute inset-0 bg-black bg-opacity-50 ml-32 max-w-8xl mx-auto flex flex-col mt-36 space-y-8 px-4">
            {/* First Text */}
            <h1 className="text-white text-4xl sm:text-5xl md:text-6xl text-left max-w-3xl font-extrabold">
              <span>{t("careerpage.careerheading1")}</span>
            </h1>

            {/* Second Text */}
            <p className="text-white text-lg sm:text-xl md:text-2xl max-w-4xl text-left">
              {t("careerpage.careerpara1")}
            </p>
            <button className="bg-[#ffd700] text-black rounded-[0.5rem] px-6 py-2 mx-1 hover:bg-[#ffd700] min-h-[2.5rem] w-40 sm:w-32 text-sm border border-transparent">
              {t("careerpage.findbtn")}
            </button>
          </div>
        </div>
      </div>

      <div className="p-6 max-w-6xl mx-auto rounded-lg mt-14">
        {/* Title */}
        <div className="mb-4 text-center">
          <h2 className="text-2xl sm:text-4xl text-center font-bold text-gray-900">
            {t("careerpage.teamheading")}
          </h2>
          <p className="text-lg sm:text-xl text-black mt-10">
            {t("careerpage.teampara")}
          </p>
        </div>

        {/* Flex Container for Awards */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 mt-14">
          {/* Award Card 1 */}
          <div className="mb-4 p-4 text-center bg-white rounded-lg shadow-lg">
            <div className="text-center">
              <img
                src={require("../../assets/aboutImages/5.jpg")}
                alt="Award"
                className="w-16 sm:w-20 md:w-24 h-auto object-cover rounded-md mx-auto"
              />
              <h3 className="text-xl sm:text-lg font-semibold text-gray-800 mt-2">
                {t("careerpage.grow")}
              </h3>
              <p className="text-gray-600 text-sm sm:text-base">
                {t("careerpage.growpara")}
              </p>
              <div className="text-lg sm:text-sm text-gray-900 mt-4">
                {t("careerpage.growyear")}
              </div>
            </div>
          </div>

          {/* Award Card 2 */}
          <div className="mb-4 p-4 text-center bg-white rounded-lg shadow-lg">
            <div className="text-center">
              <img
                src={require("../../assets/aboutImages/5.jpg")}
                alt="Award"
                className="w-16 sm:w-20 md:w-24 h-auto object-cover rounded-md mx-auto"
              />
              <h3 className="text-xl sm:text-lg font-semibold text-gray-800 mt-2">
                {t("careerpage.build")}
              </h3>
              <p className="text-gray-600 text-sm sm:text-base">
                {t("careerpage.buildpara")}
              </p>
              <div className="text-lg sm:text-sm text-gray-900 mt-4">
                {" "}
                {t("careerpage.buildyear")}
              </div>
            </div>
          </div>

          {/* Award Card 3 */}
          <div className="mb-4 p-4 text-center bg-white rounded-lg shadow-lg">
            <div className="text-center">
              <img
                src={require("../../assets/aboutImages/6.jpg")}
                alt="Award"
                className="w-16 sm:w-20 md:w-24 h-auto object-cover rounded-md mx-auto"
              />
              <h3 className="text-xl sm:text-lg font-semibold text-gray-800 mt-2">
                {t("careerpage.start")}
              </h3>
              <p className="text-gray-600 text-sm sm:text-base">
                {t("careerpage.startpara")}
              </p>
              <div className="text-lg sm:text-sm text-gray-900 mt-4">
                {t("careerpage.startyear")}
              </div>
            </div>
          </div>

          {/* Award Card 4 */}
          <div className="mb-4 p-4 text-center bg-white rounded-lg shadow-lg">
            <div className="text-center">
              <img
                src={require("../../assets/aboutImages/7.jpg")}
                alt="Award"
                className="w-16 sm:w-20 md:w-24 h-auto object-cover rounded-md mx-auto"
              />
              <h3 className="text-xl sm:text-lg font-semibold text-gray-800 mt-2">
                {t("careerpage.empower")}
              </h3>
              <p className="text-gray-600 text-sm sm:text-base">
                {t("careerpage.empowerpara")}
              </p>
              <div className="text-lg sm:text-sm text-gray-900 mt-4">2024</div>
            </div>
            main
          </div>
        </div>
      </div>

      <div className="text-center py-12 bg-gray-50">
        {/* Title */}
        <h2 className="text-5xl text-center mt-10 font-bold mb-4 text-gray-900">
          {t("careerpage.globalheading")}
        </h2>

        {/* Offices Grid */}
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8 px-4 sm:px-8 md:px-12">
          {/* Amsterdam */}
          <div className="flex flex-col items-center">
            <img
              src={require("../../assets/careerImages/1.jpg")}
              alt="Amsterdam"
              className="w-32 h-32 sm:w-40 sm:h-40 md:w-48 md:h-48 rounded-full object-cover"
            />
            <h3 className="text-lg font-semibold mt-4 text-gray-900">
              {t("careerpage.globalname1")}
            </h3>
          </div>

          {/* Kansas City */}
          <div className="flex flex-col items-center">
            <img
              src={require("../../assets/careerImages/2.jpg")}
              alt="Kansas City"
              className="w-32 h-32 sm:w-40 sm:h-40 md:w-48 md:h-48 rounded-full object-cover"
            />
            <h3 className="text-lg font-semibold mt-4 text-gray-900">
              {t("careerpage.globalname2")}
            </h3>
          </div>

          {/* Manchester */}
          <div className="flex flex-col items-center">
            <img
              src={require("../../assets/careerImages/3.jpg")}
              alt="Manchester"
              className="w-32 h-32 sm:w-40 sm:h-40 md:w-48 md:h-48 rounded-full object-cover"
            />
            <h3 className="text-lg font-semibold mt-4 text-gray-900">
              {t("careerpage.globalname3")}
            </h3>
          </div>

          {/* Manila */}
          <div className="flex flex-col items-center">
            <img
              src={require("../../assets/careerImages/4.jpg")}
              alt="Manila"
              className="w-32 h-32 sm:w-40 sm:h-40 md:w-48 md:h-48 rounded-full object-cover"
            />
            <h3 className="text-lg font-semibold mt-4 text-gray-900">
              {t("careerpage.globalname4")}
            </h3>
          </div>

          {/* Sydney */}
          <div className="flex flex-col items-center">
            <img
              src={require("../../assets/careerImages/5.jpg")}
              alt="Sydney"
              className="w-32 h-32 sm:w-40 sm:h-40 md:w-48 md:h-48 rounded-full object-cover"
            />
            <h3 className="text-lg font-semibold mt-4 text-gray-900">
              {t("careerpage.globalname5")}
            </h3>
          </div>

          {/* Townsville */}
          <div className="flex flex-col items-center">
            <img
              src={require("../../assets/careerImages/6.jpg")}
              alt="Townsville"
              className="w-32 h-32 sm:w-40 sm:h-40 md:w-48 md:h-48 rounded-full object-cover"
            />
            <h3 className="text-lg font-semibold mt-4 text-gray-900">
              {t("careerpage.globalname6")}
            </h3>
          </div>
        </div>

        <div className="flex justify-center items-center mt-10">
          <iframe
            src="https://www.youtube.com/embed/pS7MYam4nrI"
            title="YouTube video player"
            className="h-60 w-full sm:w-[500px] md:w-[720px] lg:w-[960px] xl:w-[1200px] rounded-md"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </div>

      <div className="py-12">
        <div className="text-center">
          <h2 className="text-5xl text-center font-bold mb-2">
            {t("careerpage.careervaluehead")}
          </h2>
          <p className="text-lg text-gray-600 mb-8 mt-10">
            {t("careerpage.careervaluepara")}
          </p>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 max-w-7xl mx-auto gap-8 px-4 lg:px-12">
          {/* Value 1 */}
          <div className="text-center rounded-lg p-6 mx-2 md:mx-0">
            <img
              src={require("../../assets/aboutImages/1.jpg")}
              alt="Open Honest Always"
              className="w-full mb-4 rounded-lg"
            />
            <p className="text-gray-600 text-sm text-center">
              We embrace transparency, vulnerability and challenge with respect.
            </p>
          </div>

          {/* Value 2 */}
          <div className="text-center rounded-lg p-6 mx-2 md:mx-0">
            <img
              src={require("../../assets/aboutImages/2.jpg")}
              alt="Better As a Team"
              className="w-full mb-4 rounded-lg"
            />
            <p className="text-gray-600 text-sm text-center">
              Stronger, better, faster together. Our impact is greater as a
              collective.
            </p>
          </div>

          {/* Value 3 */}
          <div className="text-center rounded-lg p-6 mx-2 md:mx-0">
            <img
              src={require("../../assets/aboutImages/3.jpg")}
              alt="Be Bold Bring Action"
              className="w-full mb-4 rounded-lg"
            />
            <p className="text-gray-600 text-sm">
              Think better, bigger, and bolder. Act now, not tomorrow.
            </p>
          </div>

          {/* Value 4 */}
          <div className="text-center rounded-lg p-6 mx-2 md:mx-0">
            <img
              src={require("../../assets/aboutImages/4.jpg")}
              alt="Think Customer"
              className="w-full mb-4 rounded-lg"
            />
            <p className="text-gray-600 text-sm">
              We hold the customer at the heart of everything we do.
            </p>
          </div>
        </div>
      </div>

      <div className="bg-gray-50 py-10">
        <div className="text-center mt-10 font-bold">
          <h2 className="text-5xl text-center font-bold mb-2">
            More than just a job
          </h2>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 max-w-7xl mx-auto gap-6">
          {/* Story 1 */}
          <div className="rounded-lg shadow-md p-4 mt-5 shadow-gray-300">
            <img
              src={require("../../assets/careerImages/equity.jpg")}
              alt="SSP Food"
              className="w-full max-w-[500px] h-50 object-cover rounded-[20px] mb-4 mx-auto text-left"
            />
            <h4 className="text-base md:text-2xl font-bold text-left text-gray-900">
              Equity
            </h4>
            <p className="text-sm md:text-base text-gray-800 mt-6 font-medium mb-2 leading-5 text-left">
              Equity with high growth potential, and a competitive salary
            </p>
          </div>

          {/* Story 2 */}
          <div className="rounded-lg shadow-md p-4 mt-5 shadow-gray-300">
            <img
              src={require("../../assets/careerImages/flexibilty.jpg")}
              alt="Sodexo Food"
              className="w-full max-w-[500px] h-50 object-cover rounded-[20px] mb-4 mx-auto text-left"
            />
            <h4 className="text-base md:text-2xl font-bold text-left text-gray-900">
              Flexibility
            </h4>
            <p className="text-sm md:text-base text-gray-800 mt-6 font-medium mb-2 leading-5 text-left">
              Flexible working arrangements – we encourage you to create the
              best work blend while working from your home and the local
              SafetyCulture office
            </p>
          </div>

          {/* Story 3 */}
          <div className="rounded-lg shadow-md p-4 mt-5 shadow-gray-300">
            <img
              src={require("../../assets/careerImages/leave.jpg")}
              alt="MLSE Sports"
              className="w-full max-w-[500px] h-50 object-cover rounded-[20px] mb-4 mx-auto text-left"
            />
            <h4 className="text-base md:text-2xl font-bold text-left text-gray-900">
              Leave
            </h4>
            <p className="text-sm md:text-base text-gray-800 mt-6 font-medium mb-2 leading-5 text-left">
              Progressive leave policies designed to support our team through
              life’s more challenging moments
            </p>
          </div>

          {/* Story 4 */}
          <div className="rounded-lg shadow-md p-4 mt-5 shadow-gray-300">
            <img
              src={require("../../assets/careerImages/welbounding.jpg")}
              alt="MLSE Sports"
              className="w-full max-w-[500px] h-50 object-cover rounded-[20px] mb-4 mx-auto text-left"
            />
            <h4 className="text-base md:text-2xl font-bold text-left text-gray-900">
              Wellbeing
            </h4>
            <p className="text-sm md:text-base text-gray-800 mt-6 font-medium mb-2 leading-5 text-left">
              Wellbeing initiatives such as subsidised fitness programs and EAP
              services
            </p>
          </div>

          {/* Story 5 */}
          <div className="rounded-lg shadow-md p-4 mt-5 shadow-gray-300">
            <img
              src={require("../../assets/careerImages/development.jpg")}
              alt="MLSE Sports"
              className="w-full max-w-[500px] h-50 object-cover rounded-[20px] mb-4 mx-auto text-left"
            />
            <h4 className="text-base md:text-2xl font-bold text-left text-gray-900">
              Development
            </h4>
            <p className="text-sm md:text-base text-gray-800 mt-6 font-medium mb-2 leading-5 text-left">
              Access to professional and personal training and development
              opportunities (e.g., hackathons, workshops, lunch & learns)
            </p>
          </div>

          {/* Story 6 */}
          <div className="rounded-lg shadow-md p-4 mt-5 shadow-gray-300">
            <img
              src={require("../../assets/customerImages/6.png")}
              alt="MLSE Sports"
              className="w-full max-w-[500px] h-50 object-cover rounded-[20px] mb-4 mx-auto text-left"
            />
            <h4 className="text-base md:text-2xl font-bold text-left text-gray-900">
              Hackathons & ShipIt!
            </h4>
            <p className="text-sm md:text-base text-gray-800 mt-6 font-medium mb-2 leading-5 text-left">
              ShipIt! is our annual company offsite where we bring together all
              employees globally for a week to reflect on the successes of the
              past and our vision for the future of SafetyCulture
            </p>
          </div>
        </div>
      </div>

      <div className="px-4 py-16 bg-gray-100 text-center">
        {/* Heading Section */}
        <h2 className="text-4xl text-center font-bold text-gray-900 mb-4">
          {t("careerpage.joinfun")}
        </h2>
        <p className="text-gray-600 mb-8  w-8/12 text-base mx-auto">
          {t("careerpage.joinfunpara")}
        </p>

        {/* Video Section */}
        <div className="flex justify-center items-center w-full">
          <div className="flex justify-center items-center w-full max-w-7xl">
            <iframe
              src="https://www.youtube.com/embed/pS7MYam4nrI"
              title="YouTube video player"
              className="w-full h-[60vh] sm:w-[500px] md:w-[720px] lg:w-[960px] xl:w-[1200px] rounded-md"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        </div>

        {/* Thumbnail Section */}
        <div className="flex justify-center mt-6 space-x-4 overflow-x-auto">
          <img
            src={require("../../assets/customerImages/6.png")}
            alt="Thumbnail 1"
            className="w-24 h-16 rounded-lg object-cover"
          />
          <img
            src={require("../../assets/careerImages/2.jpg")}
            alt="Thumbnail 2"
            className="w-24 h-16 rounded-lg object-cover"
          />
          <img
            src={require("../../assets/careerImages/3.jpg")}
            alt="Thumbnail 3"
            className="w-24 h-16 rounded-lg object-cover"
          />
          <img
            src={require("../../assets/careerImages/4.jpg")}
            alt="Thumbnail 4"
            className="w-24 h-16 rounded-lg object-cover"
          />
        </div>
      </div>

      <div className="w-full bg-indigo-600 h-[50vh] flex flex-col justify-center items-center text-white mt-20 px-4 sm:px-8 md:px-16">
        <div>
          <h1 className="text-3xl sm:text-4xl md:text-5xl font-bold mb-4 text-center">
            {t("careerpage.readytopower")}
          </h1>
        </div>
        <div>
          <h3 className="text-sm sm:text-base md:text-lg mb-6 mt-2 text-center">
            {t("careerpage.readypara")}
          </h3>
        </div>
        <div className="flex justify-center">
          <button className="text-indigo-600 px-6 sm:px-8 md:px-10 py-2 mt-4 rounded-lg hover:bg-indigo-400 hover:text-white transition-colors duration-300">
            Button 1
          </button>
        </div>
      </div>
    </div>
  );
};
export default Career;
