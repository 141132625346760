import TextImagewFlex from "../../../shared/textImageFlex";
import { useTranslation } from "react-i18next";
import ManageAssetsImage from "../../../assets/assets/ManageAssets.webp";
import i18next from "i18next";

const ManageAssets = () => {
  const isArabic = i18next.language === "ar";
  const { t } = useTranslation();
  return (
    <div
      className={` ${isArabic ? "text-right" : "text-left"} ${
        isArabic ? "rtl" : ""
      }`}
    >
      <div className="py-24">
        <TextImagewFlex
          img={ManageAssetsImage}
          heading={t("assetpage.manageassetheading")}
          widhtClass="85%"
          para={t("assetpage.manageassetpara")}
          reverse
        />
      </div>
    </div>
  );
};
export default ManageAssets;
