import React, { useState } from "react";
import { FaSearch } from "react-icons/fa";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Assign from "./assign";
import Template from "./template";
import Site from "./site";
import Asset from "./asset";
import Frequency from "./frequency";
import Time from "./time";
import Title from "./title";

const ScheduleInspection = () => {
  const [template, setTemplate] = useState("");
  const [site, setSite] = useState("");
  const [asset, setAsset] = useState("");
  const [assignee, setAssignee] = useState("");
  const [frequency, setFrequency] = useState("Every day");
  const [startTime, setStartTime] = useState("09:00 AM");
  const [endTime, setEndTime] = useState("05:00 PM");
  const [allowLateSubmission, setAllowLateSubmission] = useState(true);
  const [title, setTitle] = useState("");

  const frequencyOptions = [
    "Every day",
    "Every week",
    "Every month",
    "Every quarter",
    "Every year",
  ];

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log({
      template,
      site,
      asset,
      assignee,
      frequency,
      startTime,
      endTime,
      allowLateSubmission,
      title,
    });
  };

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  return (
    <div className="flex justify-center items-center text-left p-4">
      <div className="w-full max-w-3xl bg-white border border-gray-300 rounded-lg shadow-lg p-8">
        <h2 className="text-3xl font-semibold mb-6 text-gray-800">
          Schedule Inspection
        </h2>
        <form onSubmit={handleSubmit} className="space-y-4">
          {/* Template Dropdown */}
          <Template template={template} setTemplate={setTemplate} />

          {/* Site Dropdown */}
          <Site site={site} setSite={setSite} siteOptions={[]} />

          {/* Asset Dropdown */}
          <Asset asset={asset} setAsset={setAsset} assetOptions={[]} />

          {/* Assignee Dropdown */}
          <Assign />

          {/* Frequency Dropdown */}
          <Frequency
            frequency={frequency}
            setFrequency={setFrequency}
            frequencyOptions={frequencyOptions}
          />

          {/* Time Range */}
          <Time
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
          />

          {/* Allow Late Submission */}
          <div className="flex items-center mt-6">
            <input
              type="checkbox"
              checked={allowLateSubmission}
              onChange={(e) => setAllowLateSubmission(e.target.checked)}
              className="h-4 w-4 text-indigo-700 border border-black rounded focus:ring-indigo-500"
            />
            <label className="ml-2 text-sm text-gray-700">
              Allow inspections to be submitted after the due date
            </label>
          </div>

          {/* Title Input */}
          <div>
            <Title title={title} setTitle={setTitle} />
          </div>

          {/* Buttons */}
          <div className="flex justify-end space-x-4 mt-6">
            <button
              type="submit"
              className="bg-indigo-500 text-white px-4 py-2 rounded-md hover:bg-indigo-600 transition"
            >
              Create
            </button>
            <button
              type="button"
              className="bg-gray-200 text-gray-700 px-4 py-2 rounded-md hover:bg-gray-300 transition"
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ScheduleInspection;
