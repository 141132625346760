import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import {
  FaSearch,
  FaClipboardList,
  FaClipboardCheck,
  FaCube,
  FaExclamationCircle,
  FaLightbulb,
  FaGlobe,
} from "react-icons/fa";

const Modal = () => {
  const [isOpen, setIsOpen] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredItems, setFilteredItems] = useState([]);

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    if (queryParams.get("isOpen") === "true") {
      setIsOpen(true);
    }
  }, [location.search]);

  useEffect(() => {
    if (searchQuery) {
      setFilteredItems(
        items.filter((item) =>
          item.name.toLowerCase().includes(searchQuery.toLowerCase())
        )
      );
    } else {
      setFilteredItems(items);
    }
  }, [searchQuery]);

  const closeModal = () => {
    setIsOpen(false);
    navigate(`${location.pathname}?isOpen=false`, { replace: true });
  };

  const items = [
    {
      name: "Templates",
      icon: <FaClipboardList className="w-6 h-6 text-[#6559ff]" />,
      path: "dashboard/templates",
    },
    {
      name: "Inspections",
      icon: <FaClipboardCheck className="w-6 h-6 text-[#6559ff]" />,
      path: "dashboard/inspection",
    },
    {
      name: "Actions",
      icon: <FaExclamationCircle className="w-6 h-6 text-[#6559ff]" />,
      path: "dashboard/actions",
    },
    {
      name: "Assets",
      icon: <FaCube className="w-6 h-6 text-[#6559ff]" />,
      path: "dashboard/assets",
    },
    {
      name: "Issues",
      icon: <FaExclamationCircle className="w-6 h-6 text-[#6559ff]" />,
      path: "dashboard/start-inspection",
    },
    {
      name: "Heads Up",
      icon: <FaLightbulb className="w-6 h-6 text-[#6559ff]" />,
      path: "dashboard/headsup",
    },
    {
      name: "Sites",
      icon: <FaGlobe className="w-6 h-6 text-[#6559ff]" />,
      path: "dashboard/user-site",
    },
  ];

  const handleItemClick = (path) => {
    navigate(`/${path}`);
  };

  return (
    <div>
      {/* Modal */}
      {isOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white w-full max-w-2xl mx-auto rounded-lg shadow-lg p-6 relative">
            {/* Search Box */}
            <div className="flex items-center bg-gray-100 rounded-lg border border-purple-500 px-4 py-2 mb-6">
              <FaSearch className="w-5 h-5 text-gray-500" />
              <input
                type="text"
                placeholder="Start typing to search"
                className="ml-2 w-full bg-transparent outline-none text-gray-700"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>

            {/* Jump to Section */}
            <div className="text-gray-500 mb-4 text-start">Jump to</div>

            {/* List of Items */}
            <ul className="space-y-4">
              {filteredItems.map((item, index) => (
                <li
                  key={index}
                  onClick={() => handleItemClick(item.path)}
                  className="flex justify-between items-center cursor-pointer hover:bg-gray-50 p-2 rounded-lg"
                >
                  <div className="flex items-center space-x-4">
                    {item.icon}
                    <span className="text-gray-700 font-medium">
                      {item.name}
                    </span>
                  </div>
                  <span className="text-gray-400">{">"}</span>
                </li>
              ))}
            </ul>

            {/* Close Button at the bottom */}
            <button
              onClick={closeModal}
              className="mt-6 w-full bg-red-500 text-white py-2 rounded-lg hover:bg-red-600"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Modal;
