import ConstructionHero from "../../../assets/mining/Mining-2-1.webp";
import TextImageFlex from "../../../shared/textImageFlex";
const KeepTrack = () => {
  return (
    <div className="py-10">
      <TextImageFlex
        heading="Know where your machinery is at all times"
        para="Always know where your valuable assets are with telematics that track the GPS location of your machinery. Improve business operations and prevent loss or theft."
        img={ConstructionHero}
        widhtClass="w-full"
        reverse
      />
    </div>
  );
};

export default KeepTrack;
