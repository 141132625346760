import IconCards from "../../../shared/iconCards";
import Icon from "../../../assets/construction/Feature-Stack-Icon.svg";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
const IndustryCards = () => {
  const { t } = useTranslation();
  const isArabic = i18next.language === "ar";
  const CardsData = [
    {
      img: Icon,
      title: t("retailindustryCards.retailindustrycardtitle1"),
      description: t("retailindustryCards.retailindustrycarddescription1"),
      linkText: "View this checklist",
    },
    {
      img: Icon,
      title: t("retailindustryCards.retailindustrycardtitle2"),
      description: t("retailindustryCards.retailindustrycarddescription2"),
      linkText: "View this checklist",
    },
    {
      img: Icon,
      title: t("retailindustryCards.retailindustrycardtitle3"),
      description: t("retailindustryCards.retailindustrycarddescription3"),
      linkText: "View this checklist",
    },
    {
      img: Icon,
      title: t("retailindustryCards.retailindustrycardtitle4"),
      description: t("retailindustryCards.retailindustrycarddescription4"),
      linkText: "View this checklist",
    },
    {
      img: Icon,
      title: t("retailindustryCards.retailindustrycardtitle5"),
      description: t("retailindustryCards.retailindustrycarddescription5"),
      linkText: "View this checklist",
    },
    {
      img: Icon,
      title: t("retailindustryCards.retailindustrycardtitle6"),
      description: t("retailindustryCards.retailindustrycarddescription6"),
      linkText: "View this checklist",
    },
 
  ];
  return (
    <div className="py-10"> 
      <IconCards
        heading="Get started with industry-leading templates"
        CardsData={CardsData}
      />
    </div>
  );
};
export default IndustryCards;
