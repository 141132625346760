import React, { useState } from 'react';

const DownloadUserModal = ({ isOpen, onClose }) => {
  const [selectedOption, setSelectedOption] = useState('filtered');

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white rounded-lg shadow-lg max-w-md w-full p-6 mx-4">
        {/* Header */}
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-semibold">Keep applied filters?</h2>
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700 focus:outline-none"
          >
            &times;
          </button>
        </div>

        {/* Options */}
        <div className="space-y-3 mb-6">
          <label className="flex items-center cursor-pointer">
            <input
              type="radio"
              name="downloadOption"
              value="filtered"
              checked={selectedOption === 'filtered'}
              onChange={() => setSelectedOption('filtered')}
              className="form-radio h-4 w-4 text-[#6559ff]"
            />
            <span className="ml-3 text-gray-700">Download user list with 1 filter applied</span>
          </label>
          <label className="flex items-center cursor-pointer">
            <input
              type="radio"
              name="downloadOption"
              value="all"
              checked={selectedOption === 'all'}
              onChange={() => setSelectedOption('all')}
              className="form-radio h-4 w-4 text-[#6559ff]"
            />
            <span className="ml-3 text-gray-700">Download all users</span>
          </label>
        </div>

        {/* Buttons */}
        <div className="flex justify-end space-x-3">
          <button
            onClick={onClose}
            className="px-4 py-2 text-[#4740d4] rounded-lg border border-[#bfc6d4] hover:bg-[#e9edf6] focus:outline-none"
          >
            Cancel
          </button>
          <button
            className="px-4 py-2 bg-[#6559ff] text-white rounded-lg hover:bg-[#4740d4] focus:outline-none"
          >
            Download
          </button>
        </div>
      </div>
    </div>
  );
};

export default DownloadUserModal;
