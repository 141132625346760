import FeaturesCards from "../../../shared/featuresCards";
const FeaturesSection = () => {
  const features = [
    {
      title: "Image and video capture",
      description:
        "Provide rich evidence in a few clicks and add even more context with photo annotations.",
    },
    {
      title: "Offline mode",
      description:
        "Have confidence working in any environment with auto-save and offline syncing.",
    },
    {
      title: "Signature capture",
      description: "Drive accountability with convenient e-signatures.",
    },
    {
      title: "Voice-to-text",
      description: "Add notes and context quickly with speech recognition.",
    },
    {
      title: "Calculations",
      description:
        "Add Excel-like formulas to templates that automatically calculate results in real time.",
    },
    {
      title: "Barcode scanning",
      description:
        "Reduce errors by directly scanning numbers into your inspection.",
    },
    {
      title: "Complete audit trail",
      description:
        "Keep a record of everything with date, time stamping, GPS location, and user attribution.",
    },
    {
      title: "Task assignment",
      description:
        "Action required? Assign follow-up tasks to the right people right away.",
    },
  ];
  // return (
  //   <FeaturesCards
  //     features={features}
  //     heading=" Complete checks quickly with no detail left behind"
  //     para="Through smart data capture and dynamic responses, our mobile inspection software ensures that the right information is recorded every time."
  //   />
  // );
};
export default FeaturesSection;
