import React, { useState } from "react";

function AddUserToSitesModal({ setUserAddOpen, onClose }) {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 px-4 sm:px-0">
      <div className="bg-white rounded-lg w-full max-w-md p-6 md:p-8">
        <h2 className="text-lg text-start font-semibold text-gray-800 mb-4">
          Add user to sites
        </h2>

        {/* Dropdown */}
        <div className="relative mb-6">
          <button
            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
            className="w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 flex justify-between items-center"
          >
            <span>Select ...</span>
            <svg
              className={`transform transition-transform ${
                isDropdownOpen ? "rotate-180" : "rotate-0"
              }`}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              width="20"
              height="20"
            >
              <path
                fillRule="evenodd"
                d="M10 3a1 1 0 01.707.293l5 5a1 1 0 01-1.414 1.414L10 5.414l-4.293 4.293a1 1 0 01-1.414-1.414l5-5A1 1 0 0110 3z"
                clipRule="evenodd"
              />
            </svg>
          </button>

          {/* Dropdown Menu */}
          {isDropdownOpen && (
            <div className="absolute z-10 mt-2 w-full bg-white border border-gray-300 rounded-md shadow-lg p-4">
              <input
                type="text"
                placeholder="Search"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="w-full border border-gray-300 rounded-md py-2 px-3 mb-2 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 text-gray-700"
              />

              <div className="text-center text-gray-500">No results</div>
              <div className="flex justify-end">
                <button
                  onClick={onClose}
                  className="bg-[#6559ff] text-white text-sm font-medium py-2 px-4 rounded-lg  transition"
                >
                  Done
                </button>
              </div>
            </div>
          )}
        </div>

        {/* Buttons */}
        <div className="flex justify-end mt-6 space-x-2">
          <button
            type="button"
            className="text-sm font-medium text-gray-500 border border-gray-300 rounded-md px-4 py-3 hover:bg-gray-100 focus:outline-none"
            onClick={() => setUserAddOpen(false)}
          >
            Cancel
          </button>
          <button
            type="button"
            className="text-sm font-medium text-white bg-[#6559ff] rounded-md px-4 py-3  focus:outline-none"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
}

export default AddUserToSitesModal;
