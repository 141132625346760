import React from "react";
import oldman from "../../assets/home/decision.jpg";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

const MakeDecisions = () => {
  const { t } = useTranslation();
  const isArabic = i18next.language === "ar";

  return (
    <section
      className={`w-full py-12 lg:py-20 px-4 md:px-8 lg:px-16 ${
        isArabic ? "rtl text-right" : "text-left"
      }`}
    >
      <div className="container mx-auto flex flex-col lg:flex-row items-center gap-10">
        {/* Right Image Section */}
        <div className="lg:w-1/2">
          <img
            src={oldman}
            alt="Make Decisions"
            className="w-full rounded-lg shadow-lg"
          />
        </div>

        {/* Left Text Section */}
        <div className="lg:w-1/2">
          <h2 className="text-2xl md:text-4xl lg:text-5xl font-bold text-gray-900 mb-6">
            {t("homepage.makedecisiontitle")}
          </h2>
          <p className="text-lg md:text-xl text-gray-600">
            {t("homepage.makedecisiondescr")}
          </p>
        </div>
      </div>
    </section>
  );
};

export default MakeDecisions;
