const Insurance = () => {
  return (
    <div className="bg-[#F3F6FB] py-20 mt-20">
      <div className="container mx-auto px-4 text-center">
        <h2 className="text-[#293745] text-3xl md:text-[39px] font-bold ">
          A proactive approach to insurance
        </h2>
        <p className="text-center py-8 text-[#293745]">
          We help you take action to reduce risk, every day. Powered by
          technology to bring you a proactive, risk-minded approach to insurance
        </p>
        <div>
          <a
            href="#"
            className="bg-[#6559FF] text-white text-[1rem] font-medium py-3 px-4 rounded-xl  hover:bg-[#4740d4] transition-colors inline-flex items-center"
          >
            Visit SecureSafetyCulture Care
            <span className="ml-1">
              <svg
                clip-rule="evenodd"
                fill="none"
                fill-rule="evenodd"
                height="16"
                viewBox="0 0 24 24"
                width="16"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.79293 16.2071C7.4024 15.8166 7.4024 15.1834 7.79292 14.7929L13.0856 9.50015H10C9.44772 9.50015 9 9.05244 9 8.50015C9 7.94787 9.44772 7.50015 10 7.50015L15.5 7.50015C16.0523 7.50015 16.5 7.94787 16.5 8.50015V14.0002C16.5 14.5524 16.0523 15.0002 15.5 15.0002C14.9477 15.0002 14.5 14.5524 14.5 14.0002L14.5 10.9142L9.20714 16.2071C8.81662 16.5976 8.18345 16.5976 7.79293 16.2071Z"
                  fill="currentColor"
                ></path>
                <path
                  d="M20 1C21.6569 1 23 2.34315 23 4L23 20C23 21.6569 21.6569 23 20 23H4C2.34315 23 1 21.6569 1 20L1 4C1 2.34315 2.34315 1 4 1L20 1ZM21 4C21 3.44772 20.5523 3 20 3L4 3C3.44772 3 3 3.44772 3 4L3 20C3 20.5523 3.44772 21 4 21L20 21C20.5523 21 21 20.5523 21 20L21 4Z"
                  fill="currentColor"
                ></path>
              </svg>
            </span>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Insurance;
