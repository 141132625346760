import React from 'react';

function UserVisibilityModal({ isOpen, onClose }) {

  return (
    <div className="fixed inset-0 z-50 bg-black bg-opacity-50 flex items-center justify-center px-4 sm:px-0">
      <div className="bg-white rounded-lg w-full text-start md:w-[55%] p-6 md:p-8">
        <h2 className="text-lg font-semibold text-gray-800 mb-2">User visibility setting</h2>
        <p className="text-sm text-gray-600 mb-4">
          Choose which users and groups are visible when someone selects assignees, applies filters, or gives access across the platform. 
          <a href="#" className="text-[#6559ff] underline"> Learn more</a>
        </p>
        
        <div className="space-y-3 mb-6">
          <label className="flex items-center space-x-2">
            <input type="radio" name="visibility" className="text-blue-600" defaultChecked />
            <span className="text-sm text-gray-800">Users can select everyone in the organization (default)</span>
          </label>
          <label className="flex items-center space-x-2">
            <input type="radio" name="visibility" className="text-blue-600" />
            <span className="text-sm text-gray-800">Users can only select members of sites and groups they're in</span>
          </label>
          <label className="flex items-center space-x-2">
            <input type="radio" name="visibility" className="text-blue-600" />
            <span className="text-sm text-gray-800">Users can only select members of their sites</span>
          </label>
        </div>

        <div className="bg-blue-50 border-l-4 border-blue-400 p-4 rounded-md flex justify-between items-center mb-6">
          <div className='pe-10'>
            <p className="text-sm text-gray-700">Upgrade your plan</p>
            <p className="text-xs text-gray-500">
              To change this setting and unlock even more features, consider upgrading to the Premium Plan.
            </p>
          </div>
          <button className="bg-[#6559ff] text-white text-sm font-medium py-2 px-4 rounded-lg">Upgrade</button>
        </div>

        <div className="flex justify-end space-x-3">
          <button
            onClick={onClose}
            className="px-4 py-2 text-[#4740d4] rounded-lg border border-[#bfc6d4] hover:bg-[#e9edf6] focus:outline-none"
          >
            Cancel
          </button>
          <button
            className="px-4 py-2 bg-[#6559ff] text-white rounded-lg hover:bg-[#4740d4] focus:outline-none"
          >
            Save and apply
          </button>
        </div>
      </div>
    </div>
  );
}

export default UserVisibilityModal;
