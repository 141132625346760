import React from "react";
import { useTranslation } from "react-i18next";
import {
  FaMobileAlt,
  FaChartLine,
  FaChartBar,
  FaBolt,
  FaShieldAlt,
} from "react-icons/fa";
import { SiAppstore, SiGoogleplay } from "react-icons/si";

const ImprovementSection = () => {
  const { t } = useTranslation();
  return (
    <div className="bg-[#6559FF] py-20">
      <div className="container mx-auto px-4 text-center">
        {/* Main heading */}
        <h2 className="text-white text-3xl md:text-[39px] font-bold mb-10">
          {/* Start your improvement movement */}
          {t("improvementSection.improvementplatform")}
        </h2>

        {/* Icon and text grid */}
        <div className="flex flex-wrap justify-center gap-6 mb-8">
          <div className="flex items-center space-x-2 text-white">
            <FaMobileAlt className="text-2xl" />
            <span className="text-md">{t("improvementSection.span1")}</span>
          </div>

          <div className="flex items-center space-x-2 text-white">
            <FaChartLine className="text-2xl" />
            <span className="text-md">{t("improvementSection.span2")}</span>
          </div>

          <div className="flex items-center space-x-2 text-white">
            <FaChartBar className="text-2xl" />
            <span className="text-md">{t("improvementSection.span3")}</span>
          </div>

          <div className="flex items-center space-x-2 text-white">
            <FaBolt className="text-2xl" />
            <span className="text-md">{t("improvementSection.span4")}</span>
          </div>

          <div className="flex items-center space-x-2 text-white">
            <FaShieldAlt className="text-2xl" />
            <span className="text-md">{t("improvementSection.span5")}</span>
          </div>
        </div>

        {/* Try for free button */}
        <button className="bg-[#faca00] text-black font-normal py-2 px-6 rounded-lg mb-8 hover:bg-[#d29b00] transition-colors duration-300">
        {t("improvementSection.improvementbtnfree")}
        </button>

        {/* App store and Google Play buttons */}
        <div className="flex justify-center space-x-4">
          {/* <img
            src="https://developer.apple.com/assets/elements/badges/download-on-the-app-store.svg"
            alt="App Store"
            className="w-28 md:w-32"
          />
          <img
            src="https://upload.wikimedia.org/wikipedia/commons/7/78/Google_Play_Store_badge_EN.svg"
            alt="Google Play"
            className="w-32 md:w-36"
          /> */}
        </div>
      </div>
    </div>
  );
};

export default ImprovementSection;
