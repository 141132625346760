import CurveHeroFlex from "../../../shared/curveHero";
import HeroImage from "../../../assets/facilities/FacilitiesManagementHero (1).webp"
import Image1 from "../../../assets/facilities/image1.svg";
import Image2 from "../../../assets/facilities/image2.svg";
import Image3 from "../../../assets/facilities/image3.svg";
import Image4 from "../../../assets/facilities/image4.svg";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
const ImagesArray = [
    {
      id: 1,
      img: Image1,
    },
    {
      id: 2,
      img: Image2,
    },
    {
      id: 3,
      img: Image3,
    },
    {
      id: 4,
      img: Image4,
    },
   
  ];
const FacilitiesHero = () => {
  const { t } = useTranslation();
  const isArabic = i18next.language === "ar";
  return (
    <div
      className={`py-4 ${isArabic ? "text-right" : "text-left"} ${
        isArabic ? "rtl" : ""
      }`}
    >
      <CurveHeroFlex
        span={t("facilitypage.facilitiesspan")}
        img={HeroImage}
        heading={t("facilitypage.facilitiesheading")}
        para={t("facilitypage.facilitiespara")}
        btn1={t("facilitypage.facilitiesbtn1")}
        btn2={t("facilitypage.facilitiesbtn2")}
        ImagesArray={ImagesArray}
      />
    </div>
  );
};
export default FacilitiesHero;
