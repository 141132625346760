import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import RightImage from "../../../assets/home/Brentford-Football-Club.webp";
import Image1 from "../../../assets/home/brentford.svg";
import { FaArrowRight, FaArrowLeft } from "react-icons/fa";
const TabFiveDetails = () => {
  const isArabic = i18next.language === "ar";
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div
      className={`flex flex-col lg:flex-row items-center lg:items-start ps-7 md:ps-14 rounded-lg shadow-xl mx-3 md:mx-7 ${
        isArabic ? "text-right rtl" : "text-left"
      }`}
    >
      <div className="lg:w-[50%]  lg:text-left pt-[73px] pe-3 md:pe-12">
        <img src={Image1} alt="" className="w-[60px]" />

        {/* <p className="text-[#293745] text-start italic mb-4 text-[1.25rem] pt-10">
          “It takes a mental load off me and my team knowing that every
          important step is digitized and we all have visibility.”
        </p>
        <p className="text-[#293745] text-start font-semibold text-sm">Jess Morris,</p>
        <p className="text-[#293745] text-sm pt-1 text-start">
          Operations Manager, Brentford Football Club
        </p> */}
        <div className="h-[300px] flex items-center">
          <button
            className="flex text-start mt-11 mb-10 lg:mb-0 text-[13px] text-[1rem] font-[600] text-[#4740d4] border border-[#d0d7e1] py-2 px-1 sm:px-4 rounded-lg"
            onClick={() => navigate("/facilities-management/")}
          >
            {t("tabcardspage.explorebtn5")}
            {isArabic ? (
              <FaArrowLeft className="me-1 mt-1" />
            ) : (
              <FaArrowRight className="ms-1 mt-1" />
            )}
          </button>
        </div>
      </div>
      <div className="lg:w-[50%] mt-6 lg:mt-0 flex justify-center lg:justify-end hidden lg:block">
        <div className=" rounded-s-full overflow-hidden">
          <img
            src={RightImage}
            alt="Image"
            className="object-cover w-full h-[460px]"
          />
        </div>
      </div>
    </div>
  );
};
export default TabFiveDetails;
