import { useState } from "react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import Logo from "../../assets/home/logoSSC.jpg";
import { useNavigate } from "react-router-dom";
import { useNotification } from "../../common/hooks/useNotification";
import useHttp from "../../common/hooks/useHttp";

const ForgotPassword = () => {
  const { t } = useTranslation();
  const isArabic = i18next.language === "ar";
  const notify = useNotification();
  const { sendRequest } = useHttp();

  const [email, setEmail] = useState("");
  const navigate = useNavigate();

  const handlePasswordReset = async (e) => {
    e.preventDefault();

    if (!email) {
      notify("Please enter your email address", "error");
      return;
    }

    try {
      await sendRequest(
        {
          url: "v1/auth/request-reset-password",
          method: "POST",
          payload: { email },
        },
        (response) => {
          if (response) {
            if (response.responseCode === "200") {
              notify(response.responseDesc, "success");

              // Clear the email field
              setEmail("");

              // Optionally redirect to login
              navigate("/login");
            } else {
              notify(response.responseDesc, "error");
            }
          }
        }
      );
    } catch (error) {
      console.error("Error requesting password reset:", error);
      notify("Something went wrong. Please try again later.", "error");
    }
  };

  return (
    <div
      className="flex flex-col items-center min-h-screen bg-[#E9EEF6]"
      dir={isArabic ? "rtl" : "ltr"}
    >
      <div className="flex justify-center w-full my-6">
        <img src={Logo} alt="SafetyCulture Logo" className="h-14 w-auto" />
      </div>

      <div className="w-[90%] max-w-[500px] shadow-lg rounded-lg p-6">
        <h1
          className={`text-xl font-semibold text-gray-800 mb-6 ${
            isArabic ? "text-right" : "text-left"
          }`}
        >
          {t("resetpage.resetheading")}
        </h1>

        <p
          className={`text-sm text-gray-600 mb-4 ${
            isArabic ? "text-right" : "text-left"
          }`}
        >
          {t("resetpage.resetpara")}
        </p>

        <form onSubmit={handlePasswordReset}>
          <label
            htmlFor="email"
            className={`block text-[#1f2533] mb-2 font-medium ${
              isArabic ? "text-right" : "text-left"
            }`}
          >
            {t("resetpage.Email")}
          </label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="w-full border border-gray-300 rounded-md p-2 mb-4 focus:outline-none focus:border-indigo-500"
            placeholder={t("resetpage.Emailplaceholder")}
            required
          />

          <button
            type="submit"
            className="w-full bg-[#675df4] text-white font-semibold py-2.5 rounded-md hover:bg-[#5048c8] focus:outline-none"
          >
            {t("resetpage.sendrestbtn")}
          </button>
        </form>

        <p
          className={`mt-5 text-sm text-gray-600 text-center ${
            isArabic ? "text-right" : "text-left"
          }`}
        >
          {t("resetpage.rememberpara")}{" "}
          <span
            className="text-[#605cf8] font-semibold hover:underline cursor-pointer"
            onClick={() => navigate("/login")}
          >
            {t("resetpage.rememberpara1")}
          </span>
        </p>
      </div>
    </div>
  );
};

export default ForgotPassword;
