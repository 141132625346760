import React from "react";
import ImprovementSection from "../../../shared/improvementSection";
import { useTranslation } from "react-i18next";

const Improvement = () => {
  const { t } = useTranslation();

  return (
    <ImprovementSection
      heading={t("improvement.heading")}
      btn1={t("improvement.btn1")}
      btn2={t("improvement.btn2")}
      btn1Navigate="/signup"
    />
  );
};

export default Improvement;
