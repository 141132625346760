import ImprovementSection from "../../../shared/improvementSection";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
const Improvement = () => {
  const isArabic = i18next.language === "ar";
  const { t } = useTranslation();
  return (
    <ImprovementSection
      heading={t("retailguest.retailguesthead")}
      btn1={t("retailguest.retailguestbtn")}
      btn2={t("retaiguest.retailguestbtn1")}

    />
  );
};
export default Improvement;