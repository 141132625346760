import React from "react";
import { useTranslation } from "react-i18next";
import InspectionTemplate from "../../../assets/inspection/Inspections-1.webp";
import { FaRegCheckCircle } from "react-icons/fa";
import ContentWithChecked from "../../../shared/contentWithChecked";

const EverythingClickAway = () => {
  const { t } = useTranslation();
  return (
    <ContentWithChecked
      img={InspectionTemplate}
      heading={t("inspectionpage.everythingheading")}
      strong1={t("inspectionpage.strong1")}
      icon={<FaRegCheckCircle className="text-[#6559ff] me-3" size={29} />}
      strong2={t("inspectionpage.strong2")}
      para1={t("inspectionpage.everythingpara1")}
      para2={t("inspectionpage.everythingpara2")}
      strong3={t("inspectionpage.strong3")}
      para3={t("inspectionpage.everythingpara3")}
    />
  );
};

export default EverythingClickAway;
