import { useState, useCallback } from "react";
import axios from "axios";
import { appConfig } from "../../config/appConfig";
import { useAuth } from "../../components/context/AuthContext";

const ContentTypes = {
  APPLICATION_JSON: "application/json",
  MULTIPART_FORM_DATA: "multipart/form-data",
  FORM_URLENCODED: "application/x-www-form-urlencoded",
};

const useHttp = () => {
  const { updateAccessToken, logout, authData } = useAuth();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const sendRequest = useCallback(
    async (config, applyData) => {
      const {
        url,
        method = "GET",
        accessToken,
        headers = {},
        payload = null,
        responseType = "json",
        isExternal = false,
      } = config;

      const requestHeaders = {
        ...(accessToken && { Authorization: `Bearer ${accessToken}` }),
        ...(headers || {}),
      };

      if (headers["Content-Type"] === ContentTypes.MULTIPART_FORM_DATA) {
        requestHeaders["Content-Type"] = ContentTypes.MULTIPART_FORM_DATA;
      } else {
        requestHeaders["Content-Type"] = ContentTypes.APPLICATION_JSON; // Default to JSON
      }

      let requestData = payload;

      if (
        typeof payload === "object" &&
        payload !== null &&
        !Array.isArray(payload) &&
        requestHeaders["Content-Type"] === ContentTypes.FORM_URLENCODED
      ) {
        requestData = new URLSearchParams(...payload).toString();
      }

      const fullUrl = isExternal ? url : `${appConfig.serviceUrl}/${url}`;

      setLoading(true);
      setError(null);

      try {
        const response = await axios({
          url: fullUrl,
          method,
          headers: requestHeaders,
          data: requestData,
          responseType,
          withCredentials: true,
        });

        if (
          response.data.responseCode === "4001" &&
          response.data.responseDesc === "JWT Token has expired"
        ) {
          console.warn("JWT Token expired. Attempting to refresh token.");

          try {
            const refreshTokenResponse = await await axios({
              url: `${appConfig.serviceUrl}/v1/auth/refresh-token`,
              method: "GET",
              headers: {
                "Content-Type": ContentTypes.APPLICATION_JSON,
                ...(authData.refreshToken && {
                  Authorization: `Bearer ${authData.refreshToken}`,
                }),
                userName: authData.userName,
                deviceId: authData.deviceId,
              },
              responseType,
              withCredentials: true,
            });

            const responseData = refreshTokenResponse.data?.data;
            const newAccessToken = responseData?.token?.accessToken;
            const newRefreshToken = responseData?.token?.refreshToken;
            console.log(refreshTokenResponse, "refresh token respoone");
            if (newAccessToken && newRefreshToken) {
              updateAccessToken(newAccessToken, newRefreshToken);
            } else {
              console.log("Token refresh failed. Logging out.");
            }
          } catch (refreshError) {
            console.log(refreshError, "error refreshing ");
          }
        }

        applyData && applyData(response.data);
        setLoading(false);
        return response.data;
      } catch (err) {
        const errorMessage =
          err.response?.data?.message || "Unknown error, please try later";
        setError(errorMessage);
        setLoading(false);
        throw err;
      }
    },
    [updateAccessToken, logout]
  );

  return { sendRequest, loading, error };
};

export default useHttp;
