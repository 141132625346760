import ImprovementSection from "../../../shared/improvementSection";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
const ImprovementTwo = () => {
  const isArabic = i18next.language === "ar";
  const { t } = useTranslation();
  return (
    <div
            className={` ${isArabic ? "text-right" : "text-left"} ${
              isArabic ? "rtl" : ""
            }`}
          >
    <ImprovementSection
    heading={t("manufacturingimprovement.manufacturingimprovementhead")}
    btn1={t("manufacturingimprovement.manufacturingimprovementbtn")}
    btn2={t("manufacturingimprovement.manufacturingimprovementbtn1")}
    />
  </div>
  );
};
export default ImprovementTwo;
