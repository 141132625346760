import TextImagewFlex from "../../../shared/textImageFlex";
import TrainingImage from "../../../assets/training/Training-qualifications.webp";
const License = () => {
  return (
    <div className="py-24 ">
      <TextImagewFlex
        span="Licenses & Credentials"
        img={TrainingImage}
        heading="Keep track of your team’s qualifications"
        para="Safety and compliance has never been easier. Save all of your team’s licenses and certificates in one place. Keep track of which ones are valid, expired, or expiring soon, so you know who needs to renew or update their qualifications, and so does each individual team member."
        show
      />
    </div>
  );
};
export default License;
