import React from "react";

const IntegrationSection = () => {
  return (
    <div className="px-6 py-8 bg-gray-50 min-h-screen">
      {/* Main Heading */}
      <h1 className="text-3xl font-semibold text-gray-800 mb-6">
        Integrations
      </h1>

      {/* Access Banner */}
      <div className="flex justify-between items-center p-4 bg-indigo-50 rounded-lg mb-6">
        <div>
          <h2 className="text-xl font-semibold text-gray-800 text-left">
            Get access to Integrations
          </h2>
          <p className="text-gray-600">
            Seamlessly connect your teams' favorite tools and streamline your
            every day.
          </p>
        </div>
        <button className="px-4 py-2 bg-indigo-500 text-white font-semibold rounded-lg shadow hover:bg-indigo-600">
          🚀 Try Premium for free
        </button>
      </div>

      {/* Partner Integrations Note */}
      <div className="flex items-start gap-2 bg-gray-100 p-4 rounded-lg mb-6">
        <span className="text-gray-500 text-lg">ℹ️</span>
        <p className="text-gray-600 text-left">
          Partner integrations are not managed by SafetyCulture. If you require
          support, please contact the owner of each integration for assistance.
          You can find the contact details in each integration's details window.
        </p>
      </div>

      {/* Partner Integrations List */}
      <div className="space-y-4">
        {/* Integration Card */}
        <div className="flex justify-between items-center p-4 border rounded-lg bg-white">
          <div>
            <h3 className="text-lg font-semibold text-gray-800 text-left">
              Perspio
            </h3>
            <p className="text-gray-600">
              Seamlessly integrate any real-time IoT data from sensors and
              telematics into SafetyCulture.
            </p>
          </div>
          <a href="#" className="text-indigo-500 font-semibold hover:underline">
            Get started
          </a>
        </div>

        {/* Integration Card */}
        <div className="flex justify-between items-center p-4 border rounded-lg bg-white">
          <div>
            <h3 className="text-lg font-semibold text-gray-800 text-left">
              SafetyInsights
            </h3>
            <p className="text-gray-600">
              Simplify data analytics with user-friendly dashboards for
              SafetyCulture users. Free trial available.
            </p>
          </div>
          <a href="#" className="text-indigo-500 font-semibold hover:underline">
            Get started
          </a>
        </div>

        {/* Integration Card */}
        <div className="flex justify-between items-center p-4 border rounded-lg bg-white">
          <div>
            <h3 className="text-lg font-semibold text-gray-800 text-left">
              Simpro by SyncEzy
            </h3>
            <p className="text-gray-600">
              Automatically sync Simpro job data to SafetyCulture and completed
              inspections back to Simpro.
            </p>
          </div>
          <a href="#" className="text-indigo-500 font-semibold hover:underline">
            Get started
          </a>
        </div>
      </div>
    </div>
  );
};

export default IntegrationSection;
