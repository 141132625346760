import Car from "../../../assets/assets/m-sport_card.webp";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import Image1 from "../../../assets/assets/m-sport-logo.webp";
import { FaArrowRight } from "react-icons/fa";

const TeamPrincipal = () => {
  const { t } = useTranslation();
  const isArabic = i18next.language === "ar";
  return (
    <div
      className={` ${isArabic ? "text-right" : "text-left"} ${
        isArabic ? "rtl" : ""
      }`}
    >
      <div className="container bg-[#F3F6FB] flex flex-col lg:flex-row items-center  lg:items-start  ps-7 md:ps-14 rounded-2xl mx-auto w-[93%] my-28">
        <div className="lg:w-[50%] text-center lg:text-left pt-[38px] pe-3 md:pe-12">
          <img src={Image1} alt="" className="w-[90px] h-[90px]" />

          <p className="text-[#293745] text-start italic mb-4 text-[1.25rem] pt-7">
            {t("assetpage.teamprincipalheading")}
          </p>
          <p className="text-[#293745] text-start font-semibold text-sm pt-2">
            {t("assetpage.teamprincipalpara")}
          </p>
          <p className="text-[#293745]  text-sm pt-1 text-start">
            {t("assetpage.teamprincipalpara1")}
          </p>
        </div>
        <div className=" hidden lg:block  lg:w-[50%] mt-6 lg:mt-0 flex justify-center lg:justify-end  ">
          <div className=" rounded-s-full overflow-hidden">
            <img
              src={Car}
              alt="Image"
              className="object-cover w-full h-[430px]"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeamPrincipal;
