import React from "react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import InpectionHero from "../../../assets/inspection/Inspections_hero.webp";
import HeroFlex from "../../../shared/heroFlex";
const HeroSection = () => {
  const isArabic = i18next.language === "ar";
  const { t } = useTranslation();
  return (
    <div
      className={` ${isArabic ? "text-right" : "text-left"} ${
        isArabic ? "rtl" : ""
      }`}
    >
      <HeroFlex
        heading={t("inspectionpage.inspectionheading")}
        para={t("inspectionpage.headingpara1")}
        btn1={t("inspectionpage.headingbtn1")}
        btn2={t("inspectionpage.headingbtn2")}
        img={InpectionHero}
        btn1Navigate="/signup"
        btn2Navigate="/login"
      />
    </div>
  );
};

export default HeroSection;
