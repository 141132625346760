import React from "react";
import FAQSection from "../../../shared/faqs";
import { useTranslation } from "react-i18next";

const Faqs = () => {
  const { t } = useTranslation();

  const faqData = [
    {
      question: t("faqs.questions.benefits"),
      answer: t("faqs.answers.benefits"),
    },
    {
      question: t("faqs.questions.smallBusinesses"),
      answer: t("faqs.answers.smallBusinesses"),
    },
    {
      question: t("faqs.questions.qualityManagement"),
      answer: t("faqs.answers.qualityManagement"),
    },
    {
      question: t("faqs.questions.remoteAccess"),
      answer: t("faqs.answers.remoteAccess"),
    },
    {
      question: t("faqs.questions.continuousImprovement"),
      answer: t("faqs.answers.continuousImprovement"),
    },
  ];

  return <FAQSection faqData={faqData} heading={t("faqs.heading")} />;
};

export default Faqs;
