import { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation, Autoplay } from "swiper/modules";
import { LuBellRing } from "react-icons/lu";
import { IoCubeOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

// Image Imports
import Image1 from "../../assets/home/home1.PNG";
import Image2 from "../../assets/home/home22.PNG";
import Image3 from "../../assets/home/home3.PNG";
import Image4 from "../../assets/home/home4.PNG";
import Image5 from "../../assets/home/home5.PNG";
import Image6 from "../../assets/home/home6.PNG";
import Image7 from "../../assets/home/home7.PNG";
import Carousel1 from "../../assets/home/hero1.jpg";
import Carousel2 from "../../assets/home/hero2.jpg";
import Carousel3 from "../../assets/home/hero3.jpg";
import LiveAlert from "../../assets/home/live-alert.webp";
import Message from "../../assets/home/message.svg";
import Inspection from "../../assets/home/inspections.svg";
import Startinspection from "../../assets/home/start-inspection.webp";
import Manageassets from "../../assets/home/manage-assets.webp";
import Action from "../../assets/home/action-trainin.webp";

const HeroSection = () => {
  const isArabic = i18next.language === "ar";
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [animateClass, setAnimateClass] = useState("");
  const [activeSlide, setActiveSlide] = useState(0);

  useEffect(() => {
    // Animation on slide change
    setAnimateClass("");
    const timer = setTimeout(() => {
      setAnimateClass("slider-bottom-to-top");
    }, 50);
    return () => clearTimeout(timer);
  }, [activeSlide]);

  const slides = [
    { id: 1, img: Carousel1 },
    { id: 2, img: Carousel2 },
    { id: 3, img: Carousel3 },
  ];

  const imagesArray = [Image1, Image2, Image3, Image4, Image5, Image6, Image7];

  return (
    <div className="">
      {/* First Content: Main Hero Section */}
      <div
        className={` ${isArabic ? "text-right" : "text-left"} ${
          isArabic ? "rtl" : ""
        }`}
      >
        <div className="lg:absolute md:relative top-[110px] flex justify-center w-full z-10">
          <div className="w-[90%] md:w-[85%] lg:w-[85%] xl:w-[78%] bg-white  rounded-[200px] text-white text-center pt-8 md:pt-12 lg:pt-16 pb-8 md:pb-12 lg:pb-16 px-4 lg:px-16">
            <span className="inline-block border-t border-b border-r border-[3px] border-l-[.1875rem] border-[#6559ff] text-sm text-[#293745]  px-3 py-1 rounded-full mb-5 md:mb-8">
              {t("homepage.herospan")}
            </span>
            <h1 className="text-2xl lg:text-5xl font-black text-[#293745] mb-6">
              {t("homepage.herotitle")}
            </h1>
            <p className="text-[1.1rem] md:text-[1.24rem] lg:text-[1.26rem] xl:text-[1.34rem] mb-8 text-[#293745] pt-2 px-2 md:px-10 lg:px-16 mx-0 md:mx-8 lg:mx-10">
              {t("homepage.herodescription")}
            </p>
            <div className="flex flex-col sm:flex-row justify-center gap-4">
              <button
                className="bg-[#6559ff] text-white px-6 py-3 rounded-lg text-base"
                onClick={() => navigate("/signup")}
              >
                {t("homepage.herobtn1")}
              </button>
              <button
                className="text-[#6559ff] px-6 py-3 rounded-lg border border-gray-300 hover:bg-gray-100"
                onClick={() => navigate("/book-demo")}
              >
                {t("homepage.herobtn2")}
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Swiper Section */}
      <div className="mt-8 lg:mt-16 px-4">
        <Swiper
          modules={[Navigation, Autoplay]}
          spaceBetween={50}
          slidesPerView={1}
          navigation
          pagination={{ clickable: true }}
          autoplay={{ delay: 3000, disableOnInteraction: false }}
          onSlideChange={(swiper) => setActiveSlide(swiper.activeIndex)}
          Autoplay={{ delay: 3000, disableOnInteraction: false }}
        >
          {slides.map((slide) => (
            <SwiperSlide key={slide.id}>
              <div className="relative h-[300px] md:h-[500px] lg:h-[680px]">
                <img
                  src={slide.img}
                  alt={`Slide ${slide.id}`}
                  className="w-full h-full object-cover rounded-xl mt-52"
                />
                <div className="absolute inset-0 flex items-center justify-center mt-20">
                  {/* Slide Content */}
                  {slide.id === 1 && (
                    <div
                      className={`flex flex-col md:flex-row gap-4 ${animateClass}`}
                    >
                      <div className="text-center">
                        <button className="bg-[#6559ff] text-white px-4 py-2 rounded-lg">
                          {t("homepage.imgtext1")}
                        </button>
                        <img
                          src={Startinspection}
                          alt="Inspection"
                          className="mt-4"
                        />
                      </div>
                      <div className=" pt-8 md:pt-4 lg:pt-4 xl:pt-12 py-4">
                        <button className="bg-[#6559ff] py-3 px-5 rounded-full flex">
                          <IoCubeOutline className="text-white me-1.5 mt-1 font-bold text-base" />
                          {t("homepage.imgtext2")}
                        </button>
                        <img
                          src={Manageassets}
                          alt=""
                          className="w-[120px] md:w-[240px] mt-2 md:mt-5"
                        />
                      </div>
                    </div>
                  )}

                  {slide.id === 2 && (
                    <div
                      className={`flex flex-col items-end w-full mt-0 md:mt-20 px-4 md:px-20 lg:px-32 mx-6 ${animateClass}`}
                    >
                      <div>
                        <button
                          type="button"
                          className="bg-[#6559ff] py-3 px-5 rounded-full flex"
                        >
                          {t("homepage.imgtext3")}
                          <span className="inline-flex items-center justify-center w-4 h-4 ms-2 text-xs font-semibold text-[#6559ff] mt-1  rounded-full">
                            2
                          </span>
                        </button>
                        <img
                          src={Action}
                          alt=""
                          className="w-[120px] md:w-[240px] mt-2 md:mt-5"
                        />
                      </div>
                    </div>
                  )}

                  {slide.id === 3 && (
                    <div
                      className={`flex md:flex-row items-end md:items-start flex-col justify-end md:justify-between w-full mt-0 md:mt-5 px-4 md:px-20 lg:px-32 mx-6 ${animateClass}`}
                    >
                      <div>
                        <button className="bg-[#6559ff] py-3 px-5 rounded-full flex">
                          <LuBellRing className="text-white me-2 mt-1.5 text-[15px]" />
                          {t("homepage.imgtext4")}
                        </button>
                        <img
                          src={LiveAlert}
                          alt=""
                          className="w-[120px] md:w-[240px] mt-2 md:mt-5"
                        />
                      </div>
                      <div className="flex flex-col items-center md:items-start mt-4 md:mt-0">
                        <img src={Message} alt="" />
                        <button className="bg-[#6559ff] py-3 px-5 rounded-full flex mt-2 md:mt-5">
                          {t("homepage.imgtext5")}
                        </button>
                        <img src={Manageassets} alt="Manage" className="mt-4" />
                      </div>
                    </div>
                  )}
                  {/* Other Slide Content */}
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>

      {/* Brand Logos */}
      <div className="flex justify-center flex-wrap gap-8 mt-8 lg:mt-16">
        {imagesArray.map((img, index) => (
          <img
            key={index}
            src={img}
            alt={`Brand ${index}`}
            className="w-20 md:w-28 h-24 object-contain"
          />
        ))}
      </div>
    </div>
  );
};

export default HeroSection;
