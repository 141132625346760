import IconCards from "../../../shared/iconCards";
import Icon from "../../../assets/construction/Feature-Stack-Icon.svg";
const IndustryCards = () => {
  const CardsData = [
    {
      img: Icon,
      title: "Facility Maintenance Checklist",
      description:
        "Perform efficient facility maintenance checks with a digital checklist to improve operations.",
      linkText: "View this checklist",
    },
    {
      img: Icon,
      title: "Facility Condition Assessment Template",
      description:
        "Triage and prioritize building areas for maintenance & investment. Perform Facility Condition Assessments using a Mobile App.",
      linkText: "View this checklist",
    },
    {
      img: Icon,
      title: "Facility Security Assessment Checklist",
      description:
        "Capture minor security loopholes before they evolve into bigger risks.",
      linkText: "View this checklist",
    },
    {
      img: Icon,
      title: "HVAC Preventive Maintenance Checklist",
      description:
        "Used for preventive maintenance inspections and includes crucial maintenance checkpoints such as motors, controls, and 107 other items.",
      linkText: "View this checklist",
    },
    {
      img: Icon,
      title: "Fire Risk Assessment Template",
      description:
        "Effectively identify and address fire risks using free fire risk assessment templates.",
      linkText: "View this checklist",
    },
    {
      img: Icon,
      title: "Facility Emergency Response Plan",
      description:
        "Effectively create emergency response plans for your organization’s spot-on health and safety measures during unprecedented times using emergency response plan templates.",
      linkText: "View this checklist",
    },
 
  ];
  return (
    <div className="py-10">
      <IconCards
        heading="Get started with industry-leading templates"
        CardsData={CardsData}
      />
    </div>
  );
};
export default IndustryCards;
