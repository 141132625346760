import { FaDownload } from "react-icons/fa6";
import { FaCloudDownloadAlt } from "react-icons/fa";
const CsvUploadTab = () => {
  return (
    <div>
      <h1 className="text-gray-800 font-semibold text-start text-xl md:text-2xl">Upload CSV</h1>
      <p className="text-gray-800 pt-2 text-sm text-start mb-6">
        Before you start, you’ll need to have a CSV spreadsheet that’s formatted
        based on what you’re trying to do.
      </p>
      <h2 className="text-xl font-semibold text-gray-800 text-start mb-3">
            Step 1. Prepare your CSV
          </h2>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
        {/* Step 1 - Left Section */}
        <div className="bg-white p-6 rounded-lg shadow-md border border-gray-200">
      
          <p className="text-gray-600 mt-2 text-start">What are you trying to do?</p>
          <div className="mt-4 space-y-4">
            {/* Option 1 */}
            <div className="border border-gray-300 p-4 rounded-md bg-gray-50 text-start">
              <h3 className="font-semibold text-gray-700">
                I am adding new sites
              </h3>
              <p className="text-gray-600 text-sm mt-1">
                Fill out our recommended template with your sites.
              </p>
              <a
                href="#"
                className="text-[#4740d4] text-sm mt-2 inline-flex items-center"
              >
                <FaDownload className="me-1"/>
                Download sites template
              </a>
            </div>

            {/* Option 2 */}
            <div className="border border-gray-300 p-4 rounded-md bg-gray-50 text-start">
              <h3 className="font-semibold text-gray-700">
                I am making changes to existing sites
              </h3>
              <p className="text-gray-600 text-sm mt-1">
                Edit names and add new members to existing sites.
              </p>
              <a
                href="#"
                className="text-[#4740d4] text-sm mt-2 inline-flex items-center"
              >
                <FaDownload className="me-1"/>
                Download existing site list
              </a>
            </div>
          </div>
          <p className="text-gray-600 text-sm mt-4 text-start">
            Or you can continue with your own CSV spreadsheet.{" "}
            <a href="#" className="text-[#4740d4]">
              Learn more about CSV upload
            </a>
          </p>
        </div>

        {/* Step 2 - Right Section */}
        <div>
        <h2 className="text-xl font-semibold text-gray-800 text-start mb-3 mt-[-40px]">
            Step 2. Upload your CSV
          </h2>
        <div className="bg-gray-50 h-full border-dashed border-2 border-gray-300 rounded-lg flex items-center justify-center p-10">
      
          <div className="text-center">
            <div className="flex justify-center">
            <FaCloudDownloadAlt className="text-[90px] text-gray-600 "/>
            </div>
      
            <p className="text-gray-700 mt-2">Drag your CSV file here</p>
            <p className="text-gray-600 text-sm">
              or{" "}
              <button
                onClick={() => document.getElementById("fileInput").click()}
                className="text-[#4740d4]"
              >
                browse
              </button>
            </p>
            <input type="file" id="fileInput" className="hidden" />
          </div>
        </div>
        </div>
      
      </div>
    </div>
  );
};
export default CsvUploadTab;
