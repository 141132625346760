import React, { useState } from "react";
import { MdSpeakerNotes } from "react-icons/md";
import { MdPermMedia } from "react-icons/md";
import { MdOutlinePendingActions } from "react-icons/md";
import { useNavigate } from "react-router-dom";
const RightSideModal = ({ onClose }) => {
  const navigate=useNavigate()
  const [pageNumber, setPageNumber] = useState(1);
  const [isOpen, setIsOpen] = useState(false);
  const [noteOpen, setNoteOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const data = ["Option 1", "Option 2", "Option 3", "Option 4"];
  const filteredOptions = data.filter((option) =>
    option.toLowerCase().includes(searchQuery.toLowerCase())
  );
  const openModal = () => {
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };
  const setPageHandler = () => {
    if (pageNumber === 1) {
      setPageNumber(2);
    } else {
      setPageNumber(1);
    }
  };
  return (
    <div className="fixed inset-0 z-50 flex justify-end bg-black bg-opacity-50">
      <div className="bg-white w-full max-w-md h-full  overflow-y-auto">
        <div className="flex justify-between items-center px-6 pt-6 ">
          <h1 className="text-lg font-bold">Preview</h1>
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700"
          >
            X
          </button>
        </div>

        <div className="text-start  px-6 py-3">
          <h3 className="text-lg font-semibold">Skanska PAS Audit</h3>
          <p className="text-gray-500 text-sm">Field Audit</p>
        </div>

        <div className="flex justify-around mb-3 space-x-3 mx-6">
          <button className="bg-[#6559ff]  text-white py-2 rounded-lg hover:bg-[#7470f5] w-full" onClick={()=>navigate("/dashboard/start-inspection")}>
            Start this inspection
          </button>
          <button className="border border-[#bfc6d4] text-[#6559ff]  py-2 rounded-lg w-full hover:bg-[#e0e4ff]">
            Edit this template
          </button>
        </div>
        {pageNumber === 1 ? (
          <div className="space-y-4 bg-[#f8f9fc] px-2 ">
            <div className="bg-white py-6 px-4 ">
              <label className="block text-gray-800 text-start font-medium pb-1">
                <span className="text-red-500">*</span> Site conducted
              </label>
              <div className="mb-4 relative bg-white px-4 pb-4">
                <div className="relative border border-gray-300 rounded-lg">
                  {/* Search Bar Inside Select */}
                  <button
                    onClick={() => setIsOpen(!isOpen)}
                    className="inline-flex justify-between w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none"
                  >
                    Search
                    <svg
                      className="ml-2 -mr-1 h-5 w-5"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>

                  {/* Dropdown panel */}
                  {isOpen && (
                    <div className="origin-top-right absolute mt-2 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10">
                      <div className="p-4">
                        {/* Search Input with Search Icon */}
                        <div className="relative mb-4">
                          <svg
                            className="absolute right-3 top-3 h-5 w-5 text-gray-500"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              fillRule="evenodd"
                              d="M8 4a6 6 0 100 12A6 6 0 008 4zm4.707 10.293a1 1 0 00-1.414-1.414L9 10.586V9a1 1 0 10-2 0v2a1 1 0 00.293.707l2.586 2.586a1 1 0 001.414 0z"
                              clipRule="evenodd"
                            />
                          </svg>
                          <input
                            type="text"
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            placeholder="Search..."
                            className="w-full border border-gray-300 rounded-md px-4 py-2 pr-10 focus:outline-none focus:ring-2 focus:ring-blue-600"
                          />
                        </div>

                        {/* List of options */}
                        <ul className="max-h-40 overflow-auto text-start">
                          <>
                            <li className="cursor-pointer px-2 py-1 hover:bg-blue-100 text-base text-gray-500">
                              All
                            </li>
                            <li className="cursor-pointer px-2 py-1 hover:bg-blue-100 text-base text-gray-500">
                              www.google.com
                            </li>
                            <li className="cursor-pointer px-2 py-1 hover:bg-blue-100 text-base text-gray-500">
                              My site not listed here
                            </li>
                          </>
                        </ul>

                        <div className="flex justify-end mt-3 border-t-2 pt-2">
                          <button className="px-4 py-2 mr-2 text-[#6559ff]  rounded-lg hover:bg-gray-100 mr-2">
                            Clear Selection
                          </button>
                          <button className="px-4 py-2 bg-[#6559ff] text-white rounded-lg hover:bg-[#7470f5]">
                            Done
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="bg-white py-6 px-4">
              <label className="text-start block font-medium pb-1">
                Audit Title (Job Number - YYYYMMDD)
              </label>
              <textarea
                className="w-full p-2 border rounded "
                rows="2"
                placeholder="Type your notes..."
              ></textarea>
              {noteOpen && (
                <>
                  <textarea
                    className="w-full p-2 border rounded "
                    rows="1"
                    placeholder="Type your notes..."
                  ></textarea>
                  <div className="flex space-x-3 mt-4">
                    <button className="bg-[#6559ff] text-white px-5 py-2 rounded-lg hover:bg-[#4740d4]">
                      Save
                    </button>
                    <button
                      className="border border-gray-300 text-[#6559ff] px-4 py-2 rounded-lg hover:bg-[#e9edf6]"
                      onClick={() => setNoteOpen(false)}
                    >
                      Cancel
                    </button>
                  </div>
                </>
              )}
              <div className="flex justify-end space-x-2 pt-1">
                <p
                  className="flex text-[#4740d4] hover:bg-[#f8f9fc] rounded-lg p-2"
                  onClick={() => setNoteOpen(true)}
                >
                  <MdSpeakerNotes className="me-1 mt-1" /> Note
                </p>
                <p className="flex text-[#4740d4] hover:bg-[#f8f9fc] rounded-lg p-2">
                  <MdPermMedia className="me-1 mt-1" /> Media
                </p>
                <p className="flex text-[#4740d4] hover:bg-[#f8f9fc] rounded-lg p-2">
                  <MdOutlinePendingActions className="me-1 mt-1" /> Action
                </p>
              </div>
            </div>

            <div className="bg-white py-6 px-4">
              <label className="text-start block font-medium pb-1">
                Project Name
              </label>
              <textarea
                className="w-full p-2 border rounded "
                rows="2"
                placeholder="Type your notes..."
              ></textarea>
              {noteOpen && (
                <>
                  <textarea
                    className="w-full p-2 border rounded "
                    rows="1"
                    placeholder="Type your notes..."
                  ></textarea>
                  <div className="flex space-x-3 mt-4">
                    <button className="bg-[#6559ff] text-white px-5 py-2 rounded-lg hover:bg-[#4740d4]">
                      Save
                    </button>
                    <button
                      className="border border-gray-300 text-[#6559ff] px-4 py-2 rounded-lg hover:bg-[#e9edf6]"
                      onClick={() => setNoteOpen(false)}
                    >
                      Cancel
                    </button>
                  </div>
                </>
              )}
              <div className="flex justify-end space-x-2 pt-1">
                <p
                  className="flex text-[#4740d4] hover:bg-[#f8f9fc] rounded-lg p-2"
                  onClick={() => setNoteOpen(true)}
                >
                  <MdSpeakerNotes className="me-1 mt-1" /> Note
                </p>
                <p className="flex text-[#4740d4] hover:bg-[#f8f9fc] rounded-lg p-2">
                  <MdPermMedia className="me-1 mt-1" /> Media
                </p>
                <p className="flex text-[#4740d4] hover:bg-[#f8f9fc] rounded-lg p-2">
                  <MdOutlinePendingActions className="me-1 mt-1" /> Action
                </p>
              </div>
            </div>

            <div className="bg-white py-6 px-4">
              <label className="text-start block font-medium pb-1">
                Conducted on
              </label>
              <input
                className="w-full p-2 border rounded "
                placeholder="Type your notes..."
              />
              {noteOpen && (
                <>
                  <textarea
                    className="w-full p-2 border rounded "
                    rows="1"
                    placeholder="Type your notes..."
                  ></textarea>
                  <div className="flex space-x-3 mt-4">
                    <button className="bg-[#6559ff] text-white px-5 py-2 rounded-lg hover:bg-[#4740d4]">
                      Save
                    </button>
                    <button
                      className="border border-gray-300 text-[#6559ff] px-4 py-2 rounded-lg hover:bg-[#e9edf6]"
                      onClick={() => setNoteOpen(false)}
                    >
                      Cancel
                    </button>
                  </div>
                </>
              )}
              <div className="flex justify-end space-x-2 pt-1">
                <p
                  className="flex text-[#4740d4] hover:bg-[#f8f9fc] rounded-lg p-2"
                  onClick={() => setNoteOpen(true)}
                >
                  <MdSpeakerNotes className="me-1 mt-1" /> Note
                </p>
                <p className="flex text-[#4740d4] hover:bg-[#f8f9fc] rounded-lg p-2">
                  <MdPermMedia className="me-1 mt-1" /> Media
                </p>
                <p className="flex text-[#4740d4] hover:bg-[#f8f9fc] rounded-lg p-2">
                  <MdOutlinePendingActions className="me-1 mt-1" /> Action
                </p>
              </div>
            </div>

            <div className="bg-white py-6 px-4">
              <label className="text-start block font-medium pb-1">
                Prepared by
              </label>
              <input
                className="w-full p-2 border rounded "
                placeholder="Type your notes..."
              />
              {noteOpen && (
                <>
                  <input
                    className="w-full p-2 border rounded "
                    placeholder=""
                  ></input>
                  <div className="flex space-x-3 mt-4">
                    <button className="bg-[#6559ff] text-white px-5 py-2 rounded-lg hover:bg-[#4740d4]">
                      Save
                    </button>
                    <button
                      className="border border-gray-300 text-[#6559ff] px-4 py-2 rounded-lg hover:bg-[#e9edf6]"
                      onClick={() => setNoteOpen(false)}
                    >
                      Cancel
                    </button>
                  </div>
                </>
              )}
              <div className="flex justify-end space-x-2 pt-1">
                <p
                  className="flex text-[#4740d4] hover:bg-[#f8f9fc] rounded-lg p-2"
                  onClick={() => setNoteOpen(true)}
                >
                  <MdSpeakerNotes className="me-1 mt-1" /> Note
                </p>
                <p className="flex text-[#4740d4] hover:bg-[#f8f9fc] rounded-lg p-2">
                  <MdPermMedia className="me-1 mt-1" /> Media
                </p>
                <p className="flex text-[#4740d4] hover:bg-[#f8f9fc] rounded-lg p-2">
                  <MdOutlinePendingActions className="me-1 mt-1" /> Action
                </p>
              </div>
            </div>
          </div>
        ) : (
          <div className="space-y-4 bg-[#f8f9fc] px-2 ">
            <div className="bg-white py-6 px-4 ">
              <label className="block text-gray-800 text-start font-medium pb-1">
                <span className="text-red-500">*</span> Site conducted
              </label>
              <div className="mb-4 relative bg-white px-4 pb-4">
                <div className="relative border border-gray-300 rounded-lg">
                  {/* Search Bar Inside Select */}
                  <button
                    onClick={() => setIsOpen(!isOpen)}
                    className="inline-flex justify-between w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none"
                  >
                    Search
                    <svg
                      className="ml-2 -mr-1 h-5 w-5"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>

                  {/* Dropdown panel */}
                  {isOpen && (
                    <div className="origin-top-right absolute mt-2 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10">
                      <div className="p-4">
                        {/* Search Input with Search Icon */}
                        <div className="relative mb-4">
                          <svg
                            className="absolute right-3 top-3 h-5 w-5 text-gray-500"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              fillRule="evenodd"
                              d="M8 4a6 6 0 100 12A6 6 0 008 4zm4.707 10.293a1 1 0 00-1.414-1.414L9 10.586V9a1 1 0 10-2 0v2a1 1 0 00.293.707l2.586 2.586a1 1 0 001.414 0z"
                              clipRule="evenodd"
                            />
                          </svg>
                          <input
                            type="text"
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            placeholder="Search..."
                            className="w-full border border-gray-300 rounded-md px-4 py-2 pr-10 focus:outline-none focus:ring-2 focus:ring-blue-600"
                          />
                        </div>

                        {/* List of options */}
                        <ul className="max-h-40 overflow-auto text-start">
                          <>
                            <li className="cursor-pointer px-2 py-1 hover:bg-blue-100 text-base text-gray-500">
                              All
                            </li>
                            <li className="cursor-pointer px-2 py-1 hover:bg-blue-100 text-base text-gray-500">
                              www.google.com
                            </li>
                            <li className="cursor-pointer px-2 py-1 hover:bg-blue-100 text-base text-gray-500">
                              My site not listed here
                            </li>
                          </>
                        </ul>

                        <div className="flex justify-end mt-3 border-t-2 pt-2">
                          <button className="px-4 py-2 mr-2 text-[#6559ff]  rounded-lg hover:bg-gray-100 mr-2">
                            Clear Selection
                          </button>
                          <button className="px-4 py-2 bg-[#6559ff] text-white rounded-lg hover:bg-[#7470f5]">
                            Done
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="flex justify-start ms-8 items-center mt-5 mb-3">
          <button
            className="bg-white border border-[#bfc6d4] text-[#4740d4] px-4 py-2 rounded-lg"
            onClick={() => setPageHandler()}
          >
            {pageNumber === 1 ? "Next Page" : "Previous Page"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default RightSideModal;
