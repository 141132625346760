import Dashboardtraining from "../../../components/adminPortal/dashboard/training";
import MultiTemplates from "../../../components/adminPortal/dashboard/sampleTemplates";
import SummaryProgress from "../../../components/adminPortal/dashboard/summaryProgress"
import Agenda from "../../../components/adminPortal/dashboard/agenda";
const DashboardPage = () => {
  return (
    <div className="bg-[#E9EDF6]">
      <div className="container mx-auto w-[93%] ">
        <Dashboardtraining />
        <MultiTemplates />
        <SummaryProgress/>
        <Agenda/>
      </div>
    </div>
  );
};
export default DashboardPage;
