import FeaturesCards from "../../../shared/featuresCards";
import Card1 from "../../../assets/training/training1.webp";
import Card2 from "../../../assets/training/training2.webp";
import Card3 from "../../../assets/training/training3.webp";
import Card4 from "../../../assets/training/training4.webp";
const FeaturesSection = () => {
  const features = [
    {
      img: Card1,
      title: "Onboard new team members",
    },
    {
      img: Card2,
      title: "Take them through digital training",
    },
    {
      img: Card3,
      title: "Assess knowledge on the job",
    },
    {
      img: Card4,
      title: "Track performance",
    },
  ];
  return (
    <FeaturesCards
      features={features}
      heading="Take the burden out of compliance: Track every training interaction"
      para="Assess and track your team’s knowledge in person and in-app to pinpoint areas for improvement and ensure they perform tasks correctly and safely for easy risk management."
    />
  );
};
export default FeaturesSection;
