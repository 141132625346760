import FacilitiesHero from "../../components/solutions/hospitality/hero";
import States from "../../components/solutions/hospitality/states";
import BoostProductivity from "../../components/solutions/hospitality/boostProductivity";
import PreventIncidents from "../../components/solutions/hospitality/preventIncidents";
import GainVisibilty from "../../components/solutions/hospitality/gainvisibility";
import DeliverEffective from "../../components/solutions/hospitality/deliverEffective";
import ImproveUptime from "../../components/solutions/hospitality/improveUptime";
import ListDataSection from "../../components/solutions/hospitality/listData";
import Improvement from "../../components/solutions/hospitality/improvement";
import CustomizableCardsSection from "../../components/solutions/hospitality/customizableCards";
import IndustryCards from "../../components/solutions/hospitality/industryCards";
import ImprovementTwo from "../../components/solutions/hospitality/improvementTwo";
import Faqs from "../../components/solutions/hospitality/faqs";
const Hospatility = () => {
  return (
    <div>
      <FacilitiesHero />
      <States />
      <BoostProductivity />
      <PreventIncidents/>
      <GainVisibilty/>
      <DeliverEffective/>
      <ImproveUptime/>
      <ListDataSection/>
      <Improvement/>
      <CustomizableCardsSection/>
      <IndustryCards/>
      <ImprovementTwo/>
      <Faqs/>
    </div>
  );
};
export default Hospatility;
