import StatsSection from "../../../shared/stateSection";
import { useTranslation } from "react-i18next";

const States = () => {
  const { t } = useTranslation();

  // Data for the stats section with translation
  const data = [
    {
      value: "60%",
      description: t("statesection.description1"), // Translation applied here
      borderColor: "border-purple-500",
    },
    {
      value: "36%",
      description: t("statesection.description2"),
      borderColor: "border-cyan-500",
    },
    {
      value: "49%",
      description: t("statesection.description3"),
      borderColor: "border-yellow-500",
    },
  ];

  // Return the StatsSection component with data prop
  return <StatsSection data={data} />;
};

export default States;
