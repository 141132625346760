import AnalyticsHero from "../../../assets/transport/Logistics.png";
import TextImageFlex from "../../../shared/textImageFlex";
import { useTranslation } from "react-i18next";
const ImproveUptime = () => {
  const { t } = useTranslation();
  return (
    <div className="py-10">
      <TextImageFlex
        heading={t("transportpage.Improveheading")}
        para={t("transportpage.Improvepara")}
        img={AnalyticsHero}
        widhtClass="w-full"
        reverse
      />
    </div>
  );
};

export default ImproveUptime;
