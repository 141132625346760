import React from "react";
import ListData from "../../../shared/listData";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
const ListDataSection = () => {
  const { t } = useTranslation();
  const isArabic = i18next.language === "ar";
const categoriesData = [
  
  {
    title: t("retaillistdata.retaillistdata1.title"),
    items: t("retaillistdata.retaillistdata1.items", { returnObjects: true }),
  },
  {
    title: t("retaillistdata.retaillistdata2.title"),
    items: t("retaillistdata.retaillistdata2.items", { returnObjects: true }),
  },
  {
    title: t("retaillistdata.retaillistdata3.title"),
    items: t("retaillistdata.retaillistdata3.items", { returnObjects: true }),
  },
];

  return (
    <div>
      <ListData
        title={t("retaillistdata.retaillistdataheading")}
        categories={categoriesData}
      />
    </div>
  );
};
export default ListDataSection;
