const ActionTab = () => {

    return (
      <div className="text-center bg-white text-start p-6 rounded-md border border-gray-300 mt-4">
        <p className="text-lg font-semibold text-gray-700">
        Actions that don't have the following selected are listed here: site
        </p>
        <p className="text-sm text-gray-500">
        The actions you see will also depend on the access and permissions you have.
        </p>
      </div>
    );

}
export default ActionTab;