import TextImagewFlex from "../../../shared/textImageFlex";
import TeamWorkImage from "../../../assets/training/M-sport-microlearning-edapp.webp";
const TeamWorks = () => {
  return (
    <div className="py-24 ">
      <TextImagewFlex
        img={TeamWorkImage}
        heading="Built for the way your team works"
        para="Deliver bite-sized workplace training that fits into the flow of your team’s work – not disrupt it. With training accessible on any device, it’s easy to put employee training software in the hands of every team member and external contractor – no matter where they are."
        reverse
      />
    </div>
  );
};
export default TeamWorks;
