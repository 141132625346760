import React, { useState } from "react";
import { FaUser, FaEye } from "react-icons/fa";
import { useNavigate } from "react-router-dom"; // Import the useNavigate hook
import { useApi } from "../../context/OrganizationContaxt";
const AdministratorPage = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const navigate = useNavigate(); // Initialize the navigate function
  const { users } = useApi();
  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };
  const administrators = users?.filter(
    (item) => item?.role?.toLowerCase() === "administrator"
  );
  // Function to handle back button click
  const handleBackClick = () => {
    navigate(-1); // Navigates back to the previous page
  };
  const handleRowClick = () => {
    navigate("/dashboard/profile"); // Navigate to the profile page when the row is clicked
  };
  const calculateMinutesAgo = (lastSeen) => {
    const now = new Date();
    const lastSeenDate = new Date(lastSeen);
    const diffInMs = now - lastSeenDate;
    const diffInMinutes = Math.floor(diffInMs / (1000 * 60));
    if (diffInMinutes >= 60) {
      const diffInHours = Math.floor(diffInMinutes / 60);
      return `${diffInHours} hour(s) ago`;
    }

    return `${diffInMinutes} minute(s) ago`;
  };
  return (
    <div className="container p-20">
      <button
        onClick={handleBackClick} // Call the handleBackClick function when back button is clicked
        className="text-indigo-500 hover:text-indigo-600 text-lg flex items-left mb-2"
      >
        ← Back
      </button>
      <header className="flex justify-between items-center mb-6">
        <div className="text-left">
          <h2 className="text-2xl font-semibold">Administrator</h2>
          <p className="text-gray-600">Full seat</p>
        </div>
        <div className="flex gap-4">
          <button
            onClick={toggleSidebar}
            className="flex items-center text-indigo-500 py-2 px-4 rounded-md hover:bg-indigo-100"
          >
            <FaEye className="mr-2" /> View permissions
          </button>
          <button className="text-indigo-300 py-2 px-4 rounded-md hover:bg-indigo-50">
            Create a copy
          </button>
        </div>
      </header>

      <div className="mb-6 text-left bg-indigo-50 p-6 rounded">
        <p className="font-semibold">Description</p>
        <p className="text-gray-700">
          Full control of the organization, including users, groups, sites, and
          permissions.
        </p>
      </div>

      <section className="users-section text-left">
        <h3 className="text-xl font-semibold mb-4">Users</h3>
        <div className="flex justify-between items-center mb-4">
          <input
            type="text"
            placeholder="Search"
            className="border p-2 rounded-md w-58"
          />
          <button className="bg-indigo-500 text-white py-2 px-4 rounded-md hover:bg-ind">
            + Add filter
          </button>
        </div>

        <table className="table-auto w-full border-collapse border border-gray-200">
          <thead>
            <tr className="bg-indigo-50">
              <th className="p-2 border-b border-gray-200">
                <input type="checkbox" />
              </th>
              <th className="p-2 border-b border-gray-200 text-left">Name</th>
              <th className="p-2 border-b border-gray-200 text-left">Email</th>
              <th className="p-2 border-b border-gray-200 text-left">
                Last seen
              </th>
              <th className="p-2 border-b border-gray-200"></th>
            </tr>
          </thead>
          <tbody>
            {administrators &&
              administrators.map((item, index) => (
                <>
                  <tr
                    key={index}
                    onClick={handleRowClick}
                    className="cursor-pointer hover:bg-gray-100"
                  >
                    <td className="p-2 border-b border-gray-200">
                      <input type="checkbox" />
                    </td>
                    <td className="p-2 border-b border-gray-200 flex items-center gap-2">
                      <div className="w-8 h-8 rounded-full bg-gray-300 flex items-center justify-center">
                        <FaUser className="text-white" />
                      </div>
                      <span>{item.user.firstName}</span>
                    </td>
                    <td className="p-2 border-b border-gray-200">
                      {item.user.email}
                    </td>
                    <td className="p-2 border-b border-gray-200">
                      {item.user.lastSeen
                        ? `${calculateMinutesAgo(item.user.lastSeen)} `
                        : "No data"}
                    </td>
                    <td className="p-2 border-b border-gray-200">
                      <button className="text-gray-600 hover:text-black">
                        •••
                      </button>
                    </td>
                  </tr>
                </>
              ))}
          </tbody>
        </table>

        <div className="flex justify-end mt-4">
          <button className="bg-gray-200 text-black py-2 px-4 rounded-md hover:bg-gray-300">
            {"<"}
          </button>
          <span className="mx-4 text-gray-600">1 / 1</span>
          <button className="bg-gray-200 text-black py-2 px-4 rounded-md hover:bg-gray-300">
            {">"}
          </button>
        </div>
      </section>

      {/* Sidebar */}
      {sidebarOpen && (
        <div className="fixed top-0 right-0 w-1/3 bg-white shadow-lg p-6 h-full z-50">
          <div className="flex justify-between items-center mb-4 bg-indigo-50 p-4 rounded">
            <h3 className="text-xl font-semibold ">View Permissions Set</h3>
            <button
              onClick={toggleSidebar}
              className="text-gray-600 hover:text-black text-lg"
            >
              ✖
            </button>
          </div>
          <p className="text-gray-700">
            Here you can manage the permissions for the administrator. These
            permissions grant full access to the organization, including user
            management, site configurations, and permissions control.
          </p>
        </div>
      )}
    </div>
  );
};

export default AdministratorPage;
