import React, { useState, useEffect, useRef } from "react";
import useHttp from "../../../common/hooks/useHttp";
import { useNotification } from "../../../common/hooks/useNotification";
import { useAuth } from "../../context/AuthContext";
import { useNavigate, useParams } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";

const AssetField = () => {
  const { authData } = useAuth();
  const { sendRequest } = useHttp();
  const notify = useNotification();
  const navigate = useNavigate();

  const { id } = useParams();

  // State to manage the dropdown for each item
  const [openDropdown, setOpenDropdown] = useState(null);
  const [isVisible, setIsVisible] = useState(true);

  const [isFieldPopupOpen, setIsFieldPopupOpen] = useState(false);
  const [isTypePopupOpen, setIsTypePopupOpen] = useState(false);
  const [currentField, setCurrentField] = useState(null); // For field renaming
  const [currentType, setCurrentType] = useState(null); // For type renaming
  const [assetTypesFields, setAssetTypeFields] = useState([]);

  // const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [assetFields, setAssetFields] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  // Reference to dropdown menu for detecting clicks outside
  const dropdownRef = useRef([]);

  const [isModalOpen, setIsModalOpen] = useState(false);

  // States for form inputs
  const [fieldName, setFieldName] = useState("");
  const [valueType, setValueType] = useState("STRING");

  // Function to handle form submission
  const handleRenameField = (fieldId, fieldName) => {
    setCurrentField({ id: fieldId, name: fieldName }); // Set the field to be renamed
    setIsFieldPopupOpen(true); // Open the rename popup
  };
  // const handleRenameType = (typeId, typwName) => {
  //   setCurrentType({ id: typeId, name: typwName }); // Set the field to be renamed
  //   setIsFieldPopupOpen(true); // Open the rename popup
  // };

  const handleSaveRename = () => {
    if (currentField && currentField.name) {
      // Call API to rename the field
      // setIsPopupOpen(false); // Close the popup after renaming
    }
  };

  const getAssetTypeFields = async () => {
    try {
      await sendRequest(
        {
          url: `v1/asset/type/fields?assetTypeId=${id}`,
          accessToken: authData.accessToken,
          headers: {
            userName: authData.userName,
            organizationId: authData.organizationId,
            deviceId: authData.deviceId,
          },
        },
        (response) => {
          console.log(response);

          setAssetTypeFields(response.data.assetTypeFields);

          const assetType = response.data.assetType;
          setCurrentType({ id: assetType.id, name: assetType.name });
        }
      );
    } catch (error) {
      console.error("Error creating asset type:", error);
    }
  };
  useEffect(() => {
    getAssetTypeFields();
  }, [authData, id]);

  const handleSaveFieldRename = async () => {
    setIsFieldPopupOpen(false);

    try {
      await sendRequest(
        {
          url: "v1/asset/field/update",
          method: "PUT",
          accessToken: authData.accessToken,
          headers: {
            userName: authData.userName,
            organizationId: authData.organizationId,
            deviceId: authData.deviceId,
          },
          payload: {
            assetFieldId: currentField.id,
            fieldName: currentField.name,
          },
        },
        (response) => {
          if (response) {
            if (response.responseCode == "200") {
              setIsFieldPopupOpen(false); // Close the popup after renaming
              setCurrentField(null);
              getAssetFields();
              notify(response.responseDesc, "success");
            } else {
              notify(response.responseDesc, "error");
            }
          }
        }
      );
    } catch (error) {
      console.error("Error getting:", error.response);
    }
    if (currentField && currentField.name) {
      // Call API to rename the field
      setIsFieldPopupOpen(false); // Close the popup after renaming
    }
  };

  const handleSaveTypeRename = async () => {
    if (currentType && currentType.name) {
      try {
        await sendRequest(
          {
            url: "v1/asset/type/update",
            method: "PUT",
            accessToken: authData.accessToken,
            headers: {
              userName: authData.userName,
              organizationId: authData.organizationId,
              deviceId: authData.deviceId,
            },
            payload: {
              assetTypeId: currentType.id,
              assetTypeName: currentType.name,
            },
          },
          (response) => {
            if (response) {
              if (response.responseCode == "200") {
                getAssetTypeFields();
                setIsTypePopupOpen(false);
                notify(response.responseDesc, "success");
              } else {
                notify(response.responseDesc, "error");
              }
            }
          }
        );
      } catch (error) {
        console.error("Error getting:", error.response);
      }
    }
  };

  const handleDeleteField = async (id) => {
    try {
      await sendRequest(
        {
          url: `v1/asset/field/delete?assetFieldId=${id}`,
          method: "DELETE",
          accessToken: authData.accessToken,
          headers: {
            userName: authData.userName,
            organizationId: authData.organizationId,
            deviceId: authData.deviceId,
          },
        },
        (response) => {
          if (response) {
            if (response.responseCode == "200") {
              getAssetFields();
              notify(response.responseDesc, "success");
            } else {
              notify(response.responseDesc, "error");
            }
          }
        }
      );
    } catch (error) {
      console.error("Error getting:", error.response);
    }
  };
  const handleAddFieldToType = async (assetFieldId, removing = false) => {
    try {
      await sendRequest(
        {
          url: `v1/asset/type/field/update`,
          method: "POST",
          accessToken: authData.accessToken,
          headers: {
            userName: authData.userName,
            organizationId: authData.organizationId,
            deviceId: authData.deviceId,
          },
          payload: {
            assetTypeId: id,
            assetFieldId: assetFieldId,
            isDelete: removing,
          },
        },
        (response) => {
          if (response) {
            if (response.responseCode == "200") {
              getAssetFields();
              getAssetTypeFields();
              notify(response.responseDesc, "success");
            } else {
              notify(response.responseDesc, "error");
            }
          }
        }
      );
    } catch (error) {
      console.error("Error getting:", error.response);
    }
  };
  const handleCreate = async () => {
    try {
      await sendRequest(
        {
          url: "v1/asset/field/create",
          method: "POST",
          accessToken: authData.accessToken,
          headers: {
            userName: authData.userName,
            organizationId: authData.organizationId,
            deviceId: authData.deviceId,
          },
          payload: {
            fieldName,
            valueType,
          },
        },
        (response) => {
          if (response) {
            if (response.responseCode == "200") {
              setFieldName("");
              setIsModalOpen(false);
              getAssetFields();
              notify(response.responseDesc, "success");
            } else {
              notify(response.responseDesc, "error");
            }
          }
        }
      );
    } catch (error) {
      console.error("Error getting:", error.response);
    }
  };

  const getAssetFields = async () => {
    try {
      await sendRequest(
        {
          url: "v1/asset/fields",
          accessToken: authData.accessToken,
          headers: {
            userName: authData.userName,
            organizationId: authData.organizationId,
            deviceId: authData.deviceId,
          },
        },
        (response) => {
          console.log(response);

          setAssetFields(response.data.fields);
        }
      );
    } catch (error) {
      console.error("Error creating asset type:", error);
    }
  };
  const toggleDropdown = (index) => {
    setOpenDropdown(openDropdown === index ? null : index);
  };

  useEffect(() => {
    getAssetFields();
  }, [authData]);
  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      // Close the dropdown if clicking outside of it
      if (
        dropdownRef.current &&
        !dropdownRef.current.some((ref) => ref && ref.contains(event.target))
      ) {
        setOpenDropdown(null);
      }
    };

    // Add event listener for clicks
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup the event listener
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const filteredAssetFields = assetFields.filter((field) =>
    field.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="flex flex-col lg:flex-row bg-gray-50 min-h-screen p-8 relative">
      {/* Main Content */}
      <div className="flex-1 bg-white shadow-md rounded-lg p-6 space-y-6">
        <div className="flex items-center mb-4">
          <FaArrowLeft
            className="text-black text-2xl cursor-pointer mr-2"
            onClick={() => navigate(-1)}
          />
          <h3 className="font-bold text-2xl text-left">Manage types</h3>
        </div>
        {/* Header */}
        <div className="flex items-center space-x-2">
          <h1 className="text-lg font-semibold text-gray-800">
            {currentType?.name}
          </h1>
          <button
            className="text-gray-500 hover:text-gray-800"
            onClick={() => setIsTypePopupOpen(true)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="2"
              stroke="currentColor"
              className="w-5 h-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 4v16m8-8H4"
              />
            </svg>
          </button>
        </div>

        {/* Summary Fields */}
        <div>
          <h2 className="text-gray-700 font-medium mb-2">Summary Fields</h2>
          <div className="space-y-4">
            {/* Unique ID */}
            <div className="flex items-center justify-between p-4 border rounded-md">
              <div>
                <p className="font-semibold text-gray-800">Unique ID</p>
                <span className="text-xs text-blue-500">Required</span>
              </div>
              <p className="text-sm text-gray-500">Text</p>
            </div>
            {/* Site */}
            <div className="flex items-center justify-between p-4 border rounded-md">
              <p className="font-semibold text-gray-800">Site</p>
              <p className="text-sm text-gray-500">Site</p>
            </div>
          </div>
        </div>

        {/* Detail Fields */}
        <div>
          <h2 className="text-gray-700 font-medium mb-2">Detail Fields</h2>
          {assetTypesFields.length > 0 ? (
            assetTypesFields.map(
              (
                field,
                index // Fixed variable name from `feilds` to `field`
              ) => (
                <div
                  key={index}
                  className="p-4 border rounded-md flex items-center justify-between"
                >
                  <p className="font-semibold text-gray-800">{field.name}</p>
                  <div className="flex items-center space-x-4">
                    <p className="text-sm text-gray-500">{field.valueType}</p>
                    {/* Cross Icon */}
                    <button
                      className="text-gray-500 hover:text-red-500"
                      onClick={() => {
                        handleAddFieldToType(field.fieldId, true);
                      }} // Make sure setIsVisible is properly defined
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="2"
                        stroke="currentColor"
                        className="w-5 h-5"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              )
            )
          ) : (
            <p className="text-gray-500">No fields available</p> // Added a fallback message if no fields are present
          )}
        </div>
      </div>

      {/* Sidebar */}
      <div className="w-full lg:w-1/3 bg-white shadow-md rounded-lg p-6 ml-0 lg:ml-6 overflow-y-auto h-[calc(100vh-64px)] mt-6 lg:mt-0">
        <h2 className="text-gray-800 font-medium mb-4">
          Add an existing field
        </h2>
        <p className="text-sm text-gray-500 mb-4">
          Reuse existing fields that have been added to other types to keep your
          asset details consistent.
        </p>
        <input
          type="text"
          placeholder="Search"
          className="w-full p-2 border rounded-md mb-4"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <ul className="space-y-4">
          {filteredAssetFields.map((field, index) => (
            <li
              key={index}
              className="relative flex items-center justify-between p-4 border rounded-md"
            >
              <span className="font-medium text-gray-800">{field.name}</span>
              <div className="flex items-center space-x-4">
                <span className="text-gray-500 text-sm">{field.valueType}</span>

                <button
                  className="text-gray-500 hover:text-gray-800"
                  onClick={() => {
                    handleAddFieldToType(field.id); // Correctly pass field.id
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="2"
                    stroke="currentColor"
                    className="w-5 h-5"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M12 4v16m8-8H4"
                    />
                  </svg>
                </button>

                {/* Conditionally render if fieldType is not PREDEFINED */}
                {field.fieldType !== "PREDEFINED" && (
                  <>
                    {/* Three Dots Menu */}

                    <button
                      className="text-gray-500 hover:text-gray-800 relative"
                      onClick={() => toggleDropdown(index)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="2"
                        stroke="currentColor"
                        className="w-5 h-5"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M12 6h.01M12 12h.01M12 18h.01"
                        />
                      </svg>
                    </button>

                    {/* Dropdown */}
                    {openDropdown === index && (
                      <div
                        ref={(el) => (dropdownRef.current[index] = el)} // Set ref for the dropdown
                        className="absolute right-0 mt-2 w-48 bg-white border border-gray-300 shadow-lg rounded-md z-10"
                      >
                        <ul className="py-1 text-sm text-gray-700">
                          <li
                            className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                            onClick={() =>
                              handleRenameField(field.id, field.name)
                            }
                          >
                            Rename Field
                          </li>
                          <li
                            className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                            onClick={() => handleDeleteField(field.id)}
                          >
                            Delete field
                          </li>
                        </ul>
                      </div>
                    )}
                  </>
                )}
              </div>
            </li>
          ))}
        </ul>
      </div>
      <button
        className="fixed bottom-6 right-[24rem] bg-blue-500 text-white rounded-full px-4 py-2 shadow-lg hover:bg-blue-600 focus:outline-none"
        onClick={() => setIsModalOpen(true)}
      >
        Create a new field
      </button>

      {isFieldPopupOpen && (
        <div className="fixed inset-0 bg-gray-500 bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg w-full sm:w-96">
            <h3 className="text-lg font-semibold text-gray-800 mb-4">
              Rename Field
            </h3>
            <input
              type="text"
              value={currentField?.name || ""}
              onChange={(e) =>
                setCurrentField({ ...currentField, name: e.target.value })
              }
              className="w-full p-2 border rounded-md mb-4"
              placeholder="Enter new field name"
            />
            <div className="flex justify-end space-x-4">
              <button
                className="px-4 py-2 text-white bg-gray-500 rounded-md hover:bg-gray-600"
                onClick={() => setIsFieldPopupOpen(false)}
              >
                Cancel
              </button>
              <button
                className="px-4 py-2 text-white bg-blue-500 rounded-md hover:bg-blue-600"
                onClick={handleSaveFieldRename} // Save the renamed field
              >
                Save
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Popup */}
      {isTypePopupOpen && (
        <div className="fixed inset-0 bg-gray-500 bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg w-full sm:w-96">
            <h3 className="text-lg font-semibold text-gray-800 mb-4">
              Rename Type
            </h3>
            <input
              type="text"
              value={currentType?.name || ""}
              onChange={(e) =>
                setCurrentType({ ...currentType, name: e.target.value })
              }
              className="w-full p-2 border rounded-md mb-4"
              placeholder="Enter new type name"
            />
            <div className="flex justify-end space-x-4">
              <button
                className="px-4 py-2 text-white bg-gray-500 rounded-md hover:bg-gray-600"
                onClick={() => setIsTypePopupOpen(false)}
              >
                Cancel
              </button>
              <button
                className="px-4 py-2 text-white bg-blue-500 rounded-md hover:bg-blue-600"
                onClick={handleSaveTypeRename} // Save the renamed type
              >
                Save
              </button>
            </div>
          </div>
        </div>
      )}
      {isModalOpen && (
        <div className="fixed inset-0 bg-gray-500 bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-md">
            <h3 className="text-lg font-semibold text-gray-800 mb-4">
              Create New Field
            </h3>

            {/* Name Input and Value Type Dropdown in Flex */}
            <div className="flex space-x-4 mb-4">
              {/* Field Name Input */}
              <div className="flex-1">
                <label
                  htmlFor="fieldName"
                  className="block text-sm font-medium text-gray-700"
                >
                  Field Name
                </label>
                <input
                  id="fieldName"
                  type="text"
                  value={fieldName}
                  onChange={(e) => setFieldName(e.target.value)}
                  placeholder="Enter field name"
                  className="w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              </div>

              {/* Value Type Dropdown */}
              <div className="flex-1">
                <label
                  htmlFor="valueType"
                  className="block text-sm font-medium text-gray-700"
                >
                  Value Type
                </label>
                <select
                  id="valueType"
                  value={valueType}
                  onChange={(e) => setValueType(e.target.value)}
                  className="w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                >
                  <option value="STRING">String</option>
                  <option value="MONEY">Money</option>
                  <option value="TIMESTAMP">Timestamp</option>
                </select>
              </div>
            </div>

            {/* Buttons */}
            <div className="flex justify-end space-x-4">
              <button
                className="px-4 py-2 text-white bg-gray-500 rounded-md hover:bg-gray-600"
                onClick={() => {
                  setIsModalOpen(false);
                  setFieldName("");
                }}
              >
                Cancel
              </button>
              <button
                className={`px-4 py-2 text-white rounded-md ${
                  fieldName.trim()
                    ? "bg-blue-500 hover:bg-blue-600"
                    : "bg-blue-300 cursor-not-allowed"
                }`}
                disabled={!fieldName.trim()}
                onClick={handleCreate}
              >
                Create
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AssetField;
