import React from "react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

const Cards = ({ scrollToSection }) => {
  const { t } = useTranslation();
  const isArabic = i18next.language === "ar";

  const cardsData = [
    {
      key: "Health & Safety",
      link: "/health-safety",
    },
    {
      key: "Risk Management & Compliance",
    },
    {
      key: "Operational Excellence",
    },
    {
      key: "Environment & Sustainability",
    },
  ];

  return (
    <div className={`${isArabic ? "text-right rtl" : "text-left"}`}>
      <div className="container mx-auto py-14 px-4">
        {/* Section Title */}
        <h2 className="text-center text-[30px] sm:text-[33px] md:text-[43px] lg:text-[53px] text-[#293745] font-bold mb-2 leading-snug">
          {t("homepage.getuptitle")}
        </h2>

        {/* Section Description */}
        <p className="text-center text-[1.25rem] text-[#293745] py-4 mx-8 sm:mx-16 mb-8">
          {t("homepage.getupdescription")}
        </p>

        {/* Cards Grid */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
          {cardsData.map((card, index) => (
            <div
              key={index}
              className="group flex flex-col items-start border border-gray-300 rounded-lg p-5 hover:shadow-xl transition-shadow"
            >
              {/* Badge */}
              <span
                className="border border-[#ffd700] text-sm text-[#293745] px-3 py-1 rounded-full"
                aria-label="Quality Badge"
              >
                {" "}
                {t(`homepage.${card.key}.span`)}
              </span>

              {/* Card Title */}
              <h3 className="text-2xl font-extrabold my-6 text-start group-hover:text-[#4740d4] group-hover:underline transition-all">
                {t(`homepage.${card.key}.title`)}
              </h3>

              {/* Card Description */}
              <p className="text-gray-600 mb-4 text-start">
                {t(`homepage.${card.key}.description`)}
              </p>

              {/* Card Link */}
              {/* <a
                href="#"
                className="text-[#4740d4] font-semibold group-hover:underline transition-all"
                aria-label={`Learn more about ${card.key}`}
              >
                {t(`homepage.${card.key}.linkText`)}
              </a> */}
            </div>
          ))}
        </div>

        {/* Footer Text */}
        <p
          className="text-[#4740d4] font-semibold text-center pt-16 cursor-pointer"
          onClick={() => scrollToSection("tabContentSection")}
        >
          {t("homepage.exploreIndustries")}
        </p>
      </div>
    </div>
  );
};

export default Cards;
