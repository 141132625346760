import React from "react";
import LonWorkerHero from "../../../assets/loneWorker/Lone-Worker-Hero.webp";
import HeroFlex from "../../../shared/heroFlex";
const HeroSection = () => {
  return (
    <HeroFlex
      heading="Move from reactive to proactive lone worker safety"
      para="Stay connected to remote teams with SHEQSY by SafetyCulture, a lone worker safety solution that protects workers in real-time so they never have to feel alone."
      btn1=" Talk to a product specialist"
      btn2="Take a tour"
      img={LonWorkerHero}
    />
  );
};

export default HeroSection;
