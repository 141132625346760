import React from "react";
import { useNavigate } from "react-router-dom";
import { IoMdSettings } from "react-icons/io";
import { FiRefreshCcw } from "react-icons/fi";
import NotificationImage from "../../../assets/notification/notify.png";
export default function Notifications() {
  const navigate = useNavigate();
  return (
    <div>
      <div className="min-h-screen flex flex-col  justify-between ">
        {/* Top section with text and buttons */}
        <div className="w-full py-8  flex justify-between items-center  ">
          <h1 className="text-2xl text-start font-bold text-[#1f2533]">
            {" "}
            Notifications
          </h1>
          <div className="flex space-x-4">
            <button
              type="submit"
              class="inline-flex items-center py-3 px-5 ms-2 text-base font-medium text-[#4740d4] bg-transparent   "
            >
              <FiRefreshCcw className="me-1" />
              Refresh
            </button>
            <button
              type="submit"
              class="inline-flex items-center py-3 px-5 ms-2 text-sm font-medium text-[#4740d4] bg-white rounded-lg border border-[#bfc6d4] focus:ring-4 hover:bg-[#e0e4ff]"
            >
              <IoMdSettings className="me-1" />
              Settings
            </button>
          </div>
        </div>

        {/* Center section with image, text, and button */}
        <div className="flex flex-col justify-center items-center flex-grow">
          <div className="flex flex-col items-center">
            <img
              src={NotificationImage}
              alt="No Notifications"
              className="w-40 h-40 mb-6"
            />
            <p className="text-xl text-gray-600 mb-2">
              You don't have any notifications
            </p>
            <p className="text-sm text-gray-500 mb-6">
              Use notifications settings page to enable more notification types.
            </p>

            <button
              onClick={() => navigate("/dashboard/notifications-settings")}
              type="submit"
              class="inline-flex items-center py-3 px-5 ms-2 text-sm font-medium text-[#4740d4] bg-white rounded-lg border border-[#bfc6d4] focus:ring-4 hover:bg-[#e0e4ff]"
            >
              <IoMdSettings className="me-1" />
              Notification settings
            </button>
          </div>
        </div>

        {/* Footer or other content if needed */}
      </div>
    </div>
  );
}
