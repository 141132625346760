import React from "react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import AITemplate from "../../../assets/inspection/AI-templates.webp";
import { FaArrowRight } from "react-icons/fa6";

const CreateTemplate = () => {
  const isArabic = i18next.language === "ar"; // Check if the language is Arabic
  const { t } = useTranslation();

  return (
    <section className="px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto flex flex-col-reverse lg:flex-row items-center justify-between">
        {/* Left Side (Image) */}
        <div className="lg:w-1/2 mb-12 lg:mb-0">
          <div className="relative flex justify-center md:justify-start">
            <img
              src={AITemplate}
              alt={t("inspectionpage.alttext")} // Use translation for alt text
              className="rounded-xl w-[91%] h-full"
            />
          </div>
        </div>

        {/* Right Side (Text and Buttons) */}
        <div
          dir={isArabic ? "rtl" : "ltr"} // Dynamically set direction
          className={`lg:w-1/2 space-y-6 ${
            isArabic ? "text-right rtl" : "text-left"
          }`} // Adjust alignment
        >
          <h1 className="text-[2.2rem] md:text-[3.2rem] lg:text-[2.65rem] font-bold text-[#293745] md:leading-[53px]">
            {t("inspectionpage.createtemplateheading")}
          </h1>
          <p className="text-md md:text-[1.1rem] text-[#293745]">
            {t("inspectionpage.createtemplatepara")}
          </p>
          {/* Button Section */}
          <button className="bg-transparent flex font-[500] text-[1rem] text-[#4740d4] hover:underline pt-3 ps-3">
            {t("inspectionpage.createtemplatebtn")}{" "}
            <FaArrowRight
              className={`mt-[3px] ms-2 ${isArabic ? "rotate-180" : ""}`} // Rotate arrow for Arabic
            />
          </button>
        </div>
      </div>
    </section>
  );
};

export default CreateTemplate;
