import React from "react";
import ListData from "../../../shared/listData";
import { useTranslation } from "react-i18next";

const ListDataSection = () => {
  const { t } = useTranslation();

  const categoriesData = [
    {
      title: t("listData.streamlineOperations.title"),
      items: [
        t("listData.streamlineOperations.items.propertyInspections"),
        t("listData.streamlineOperations.items.safetyAudits"),
        t("listData.streamlineOperations.items.issueActionManagement"),
        t("listData.streamlineOperations.items.sensorMonitoring"),
      ],
    },
    {
      title: t("listData.highStandards.title"),
      items: [
        t("listData.highStandards.items.inspectionsAudits"),
        t("listData.highStandards.items.policiesProcedures"),
        t("listData.highStandards.items.paintStorage"),
        t("listData.highStandards.items.poolInspection"),
      ],
    },
    {
      title: t("listData.preventIncidents.title"),
      items: [
        t("listData.preventIncidents.items.incidentReporting"),
        t("listData.preventIncidents.items.riskAssessments"),
      ],
    },
    {
      title: t("listData.safeFacility.title"),
      items: [
        t("listData.safeFacility.items.fireSafetyAssessment"),
        t("listData.safeFacility.items.gasSafetyMonitoring"),
        t("listData.safeFacility.items.elevatorChecks"),
        t("listData.safeFacility.items.hvacMaintenance"),
        t("listData.safeFacility.items.legionellaAssessment"),
        t("listData.safeFacility.items.asbestosInspection"),
      ],
    },
  ];

  return (
    <div>
      <ListData
        title={t("listData.driveImprovementTitle")}
        categories={categoriesData}
      />
    </div>
  );
};

export default ListDataSection;
