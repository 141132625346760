import React, { useState } from "react";
import { FaEdit, FaChevronDown, FaChevronUp } from "react-icons/fa";
import { IoMdArrowBack } from "react-icons/io";
import { FaUsers, FaBuilding, FaLock } from "react-icons/fa";
function ActionSettings({  onClose }) {
  const [isCreateTypeModalOpen, setCreateTypeModalOpen] = useState(false);
  const [isEditStatusModalOpen, setEditStatusModalOpen] = useState(false);
  const [isEditStatusDetailsModalOpen, setEditStatusDetailsModalOpen] = useState(false);
  
  const [selectedOption, setSelectedOption] = useState("Collaborators");
  const openCreateTypeModal = () => setCreateTypeModalOpen(true);
  const closeCreateTypeModal = () => setCreateTypeModalOpen(false);

  const openEditStatusModal = () => setEditStatusModalOpen(true);
  const openEditStatusDetailsModal = () => setEditStatusDetailsModalOpen(true);
  const closeEditStatusModal = () => setEditStatusModalOpen(false);
  const [editLow, setEditLow] = useState(false);
  const [editMedium, setEditMedium] = useState(false);
  const [editHigh, setEditHigh] = useState(false);
  const [dueDate, setDueDate] = useState({ low: 7, medium: 7, high: 7 });
  const [unit, setUnit] = useState("Day");

  const toggleEdit = (priority) => {
    if (priority === "low") setEditLow(!editLow);
    if (priority === "medium") setEditMedium(!editMedium);
    if (priority === "high") setEditHigh(!editHigh);
  };

  return (
    <div className="p-6 ">
      {/* Header */}
      <div className="flex items-center space-x-2 text-2xl  text-gray-800 mb-7">
        <IoMdArrowBack className="font-[400]" />
        <span>Action settings</span>
      </div>

      {/* Types Table */}
      <div className="bg-white border border-[#bfc6d4] text-start rounded-xl p-6 mb-7">
        <div className="flex justify-between items-center mb-4">
          <div>
            <h2 className="text-lg font-semibold">Types</h2>
            <p className="text-base text-gray-500">
              Manage custom types and fields
            </p>
          </div>
          <span className="text-indigo-500 font-semibold text-sm bg-indigo-100 rounded-full px-2 py-1">
            NEW
          </span>
        </div>
        <table className="w-full text-left text-sm">
          <thead className="bg-[#f8f9fc] !py-2">
            <tr className="py-2">
              <th className="border-b pb-2">Type name</th>
              <th className="border-b pb-2">Active actions</th>
              <th className="border-b pb-2">Active schedules</th>
              <th className="border-b pb-2">Number of fields</th>
              <th className="border-b pb-2"></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="py-2">Action</td>
              <td className="py-2">0</td>
              <td className="py-2">0</td>
              <td className="py-2">0</td>
              <td className="py-2 text-indigo-500 cursor-pointer">Edit type</td>
            </tr>
          </tbody>
        </table>
        <div className="flex justify-end pt-3">
          <button
            onClick={openCreateTypeModal}
            className="mt-4 px-4 py-2 bg-indigo-500 text-white rounded-md hover:bg-indigo-600 focus:outline-none"
          >
            + Create type
          </button>
        </div>
      </div>

      {/* Action Status Table */}
      <div className="bg-white border border-[#bfc6d4] rounded-lg p-6 text-start mb-7">
        <h2 className="text-lg font-semibold mb-2">Action status</h2>
        <p className="text-base text-gray-500 mb-4">
          Customize status names, manage who can move actions to each status,
          and make evidence required for each action.
        </p>
        <table className="w-full text-left text-sm">
          <thead>
            <tr>
              <th className="border-b pb-2">Status name</th>
              <th className="border-b pb-2">Move to status</th>
              <th className="border-b pb-2">Require evidence</th>
              <th className="border-b pb-2"></th>
            </tr>
          </thead>
          <tbody className="space-y-3">
            <tr className=" bg-[#f8f9fc]">
              <th className="border-b pb-2 font-normal">Open statuses</th>
            </tr>

            {["To do", "In progress"].map((status) => (
              <tr key={status}>
                <td className="py-2">{status}</td>
                <td className="py-2">
                  <span className="px-3 py-1 bg-indigo-100 text-indigo-500 rounded-md">
                    All users
                  </span>
                </td>
                <td className="py-2">
                  <span className="px-3 py-1 bg-gray-100 text-gray-500 rounded-md">
                    No evidence required
                  </span>
                </td>
                <td
                  className="py-2 text-gray-500 cursor-pointer"
                  onClick={openEditStatusModal}
                >
                  <FaEdit />
                </td>
              </tr>
            ))}

            <tr className="bg-[#f8f9fc]">
              <th className="border-b pb-2 font-normal">Closed statuses</th>
            </tr>
            {["Complete", "Can't do"].map((status) => (
              <tr key={status}>
                <td className="py-2">{status}</td>
                <td className="py-2">
                  <span className="px-3 py-1 bg-indigo-100 text-indigo-500 rounded-md">
                    All users
                  </span>
                </td>
                <td className="py-2">
                  <span className="px-3 py-1 bg-gray-100 text-gray-500 rounded-md">
                    No evidence required
                  </span>
                </td>
                <td
                  className="py-2 text-gray-500 cursor-pointer"
                  onClick={openEditStatusModal}
                >
                  <FaEdit />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Create Type Modal */}
      {isCreateTypeModalOpen && (
        <div className="fixed inset-0 top-0 bg-black bg-opacity-50 flex justify-center z-50 items-center">
          <div className="bg-white p-7 text-start rounded-lg max-w-sm w-full space-y-4">
            <div className="flex justify-between items-center">
              <h3 className="text-lg font-semibold">Create type</h3>
              <button
                onClick={closeCreateTypeModal}
                className="text-gray-500 font-[600]"
              >
                X
              </button>
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Type name
              </label>
              <input
                type="text"
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500"
              />
            </div>
            <div className="flex justify-end space-x-2">
              <button
                onClick={closeCreateTypeModal}
                className="px-4 py-2 bg-white border border-[#bfc6d4] text-[#4740d4] rounded-lg"
              >
                Cancel
              </button>
              <button className="px-4 py-2 bg-indigo-500 text-white rounded-lg hover:bg-indigo-600">
                Create type
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Edit Status Modal */}
      {isEditStatusModalOpen && (
        <div className="fixed inset-0 z-50 flex justify-end text-start">
          {/* Overlay */}
          <div
            className="absolute inset-0 bg-black opacity-50"
            onClick={onClose}
          >X</div>

          {/* Modal */}
          <div className="relative bg-white w-full max-w-md h-full p-6 shadow-lg overflow-y-auto">
            <h2 className="text-lg font-semibold mb-4">Edit status</h2>

            {/* Name Input */}
            <div className="mb-6">
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Name
              </label>
              <input
                type="text"
                defaultValue="To do"
                className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:border-purple-500"
              />
              <p className="text-xs text-gray-500 mt-1">
                This is the default status for new actions.
              </p>
            </div>

            {/* Move to Status Section */}
            <div className="mb-6">
              <h3 className="text-sm font-semibold mb-2">Move to status</h3>
              <p className="text-xs text-gray-500 mb-3">
                Manage who can move actions to this status.
              </p>
              <div className="space-y-2">
                <label className="flex items-center">
                  <input
                    type="radio"
                    name="moveToStatus"
                    defaultChecked
                    className="mr-2 text-purple-600 focus:ring-purple-500"
                  />
                  All users with edit access
                </label>
                <label className="flex items-center">
                  <input
                    type="radio"
                    name="moveToStatus"
                    className="mr-2 text-purple-600 focus:ring-purple-500"
                  />
                  Restricted
                </label>
              </div>
            </div>

            {/* Require Evidence Section */}
            <div className="mb-6">
              <h3 className="text-sm font-semibold mb-2">Require evidence</h3>
              <div className="space-y-2">
                <label className="flex items-center">
                  <input
                    type="radio"
                    name="requireEvidence"
                    defaultChecked
                    className="mr-2 text-purple-600 focus:ring-purple-500"
                  />
                  No evidence required
                </label>
                <label className="flex items-center">
                  <input
                    type="radio"
                    name="requireEvidence"
                    className="mr-2 text-purple-600 focus:ring-purple-500"
                  />
                  Only notes
                </label>
                <label className="flex items-center">
                  <input
                    type="radio"
                    name="requireEvidence"
                    className="mr-2 text-purple-600 focus:ring-purple-500"
                  />
                  Only media
                </label>
                <label className="flex items-center">
                  <input
                    type="radio"
                    name="requireEvidence"
                    className="mr-2 text-purple-600 focus:ring-purple-500"
                  />
                  Notes and media
                </label>
              </div>
            </div>

            {/* Buttons */}
            <div className="flex justify-start space-x-2">
               <button className="px-4 py-2 bg-[#6559ff] text-white rounded-md hover:bg-purple-700">
                Save and apply
              </button>
              <button
                onClick={()=>setEditStatusModalOpen(false)}
                className="px-4 py-2 border border-gray-300 rounded-md text-gray-600 hover:bg-gray-100"
              >
                Cancel
              </button>
             
            </div>
          </div>
        </div>
      )}

{isEditStatusDetailsModalOpen && (
        <div className="fixed inset-0 z-50 flex justify-end text-start">
          {/* Overlay */}
          <div
            className="absolute inset-0 bg-black opacity-50"
            onClick={onClose}
          >X</div>

          {/* Modal */}
          <div className="relative bg-white w-full max-w-md h-full p-6 shadow-lg overflow-y-auto">
            <h2 className="text-lg font-semibold mb-4">Edit action details</h2>

        

            {/* Move to Status Section */}
            <div className="mb-6">
              <h3 className="text-sm font-semibold mb-2">  Edit detail fields</h3>
              <p className="text-xs text-gray-500 mb-3">
              Manage who can edit the detail fields for each action.
              </p>
              <div className="space-y-2">
                <label className="flex items-center">
                  <input
                    type="radio"
                    name="moveToStatus"
                    defaultChecked
                    className="mr-2 text-purple-600 focus:ring-purple-500"
                  />
                  All users with edit access
                </label>
                <label className="flex items-center">
                  <input
                    type="radio"
                    name="moveToStatus"
                    className="mr-2 text-purple-600 focus:ring-purple-500"
                  />
                  Restricted
                </label>
              </div>
            </div>

            {/* Buttons */}
            <div className="flex justify-start space-x-2">
               <button className="px-4 py-2 bg-[#6559ff] text-white rounded-md hover:bg-purple-700">
                Save and apply
              </button>
              <button
                onClick={()=>setEditStatusDetailsModalOpen(false)}
                className="px-4 py-2 border border-gray-300 rounded-md text-gray-600 hover:bg-gray-100"
              >
                Cancel
              </button>
             
            </div>
          </div>
        </div>
      )}

      {/* Detail Fields Section */}
      <div className="bg-white border border-[#bfc6d4] rounded-lg p-6 text-start mb-7">
        <h2 className="text-lg font-semibold mb-2">Detail fields</h2>
        <p className="text-sm text-gray-500 mb-4">
          Manage who can edit the detail fields for each action.
        </p>
        <table className="w-full text-left text-sm">
          <thead>
            <tr>
              <th className="border-b pb-2">Fields</th>
              <th className="border-b pb-2">Edit detail fields</th>
              <th className="border-b pb-2"></th>
            </tr>
          </thead>
          <tbody onClick={openEditStatusDetailsModal} className="hover:bg-[#ecedfe] cursor-pointer">
            <tr>
              <td className="py-2">
                title, description, site, assignee, priority, due date, label,
                recurrence, asset, and custom fields
              </td>
              <td className="py-2">
                <span className="px-3 py-1 bg-indigo-100 text-indigo-500 rounded-md">
                  All users
                </span>
              </td>
              <td className="py-2 text-gray-500 cursor-pointer">
                <FaEdit />
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      {/* Priority-based Due Dates Section */}
      <div className="bg-white border border-[#bfc6d4] rounded-lg p-6 text-start mb-7">
        <h2 className="text-lg font-semibold mb-2">Priority-based due dates</h2>
        <p className="text-sm text-gray-500 mb-4">
          Configure the default due dates based on priority.
        </p>

        <div className="space-y-4">
          {/* Low Priority */}
          <div className="flex items-center space-x-4">
            <button onClick={() => toggleEdit("low")} className="text-gray-500">
              {editLow ? <FaChevronUp /> : <FaChevronDown />}
            </button>
            <span className="text-gray-700">Low</span>
            {editLow ? (
              <div className="flex items-center space-x-2">
                <input
                  type="number"
                  value={dueDate.low}
                  onChange={(e) =>
                    setDueDate({ ...dueDate, low: e.target.value })
                  }
                  className="w-16 px-2 py-1 border border-gray-300 rounded-md"
                />
                <select
                  value={unit}
                  onChange={(e) => setUnit(e.target.value)}
                  className="px-2 py-1 border border-gray-300 rounded-md"
                >
                  <option>Day</option>
                  <option>Week</option>
                </select>
                <button
                  onClick={() => toggleEdit("low")}
                  className="px-4 py-2 text-gray-500 bg-gray-100 rounded-md"
                >
                  Cancel
                </button>
                <button className="px-4 py-2 text-white bg-indigo-500 rounded-md hover:bg-indigo-600">
                  Save
                </button>
              </div>
            ) : (
              <span className="text-gray-700">
                {dueDate.low} {unit}
              </span>
            )}
            <button onClick={() => toggleEdit("low")} className="text-gray-500">
              <FaEdit />
            </button>
          </div>

          {/* Medium Priority */}
          <div className="flex items-center space-x-4">
            <button
              onClick={() => toggleEdit("medium")}
              className="text-gray-500"
            >
              {editMedium ? <FaChevronUp /> : <FaChevronDown />}
            </button>
            <span className="text-gray-700">Medium</span>
            {editMedium ? (
              <div className="flex items-center space-x-2">
                <input
                  type="number"
                  value={dueDate.medium}
                  onChange={(e) =>
                    setDueDate({ ...dueDate, medium: e.target.value })
                  }
                  className="w-16 px-2 py-1 border border-gray-300 rounded-md"
                />
                <select
                  value={unit}
                  onChange={(e) => setUnit(e.target.value)}
                  className="px-2 py-1 border border-gray-300 rounded-md"
                >
                  <option>Day</option>
                  <option>Week</option>
                </select>
                <button
                  onClick={() => toggleEdit("medium")}
                  className="px-4 py-2 text-gray-500 bg-gray-100 rounded-md"
                >
                  Cancel
                </button>
                <button className="px-4 py-2 text-white bg-indigo-500 rounded-md hover:bg-indigo-600">
                  Save
                </button>
              </div>
            ) : (
              <span className="text-gray-700">{dueDate.medium} Days</span>
            )}
            <button
              onClick={() => toggleEdit("medium")}
              className="text-gray-500"
            >
              <FaEdit />
            </button>
          </div>

          {/* High Priority */}
          <div className="flex items-center space-x-4">
            <button
              onClick={() => toggleEdit("high")}
              className="text-gray-500"
            >
              {editHigh ? <FaChevronUp /> : <FaChevronDown />}
            </button>
            <span className="text-gray-700">High</span>
            {editHigh ? (
              <div className="flex items-center space-x-2">
                <input
                  type="number"
                  value={dueDate.high}
                  onChange={(e) =>
                    setDueDate({ ...dueDate, high: e.target.value })
                  }
                  className="w-16 px-2 py-1 border border-gray-300 rounded-md"
                />
                <select
                  value={unit}
                  onChange={(e) => setUnit(e.target.value)}
                  className="px-2 py-1 border border-gray-300 rounded-md"
                >
                  <option>Day</option>
                  <option>Week</option>
                </select>
                <button
                  onClick={() => toggleEdit("high")}
                  className="px-4 py-2 text-gray-500 bg-gray-100 rounded-md"
                >
                  Cancel
                </button>
                <button className="px-4 py-2 text-white bg-indigo-500 rounded-md hover:bg-indigo-600">
                  Save
                </button>
              </div>
            ) : (
              <span className="text-gray-700">{dueDate.high} Days</span>
            )}
            <button
              onClick={() => toggleEdit("high")}
              className="text-gray-500"
            >
              <FaEdit />
            </button>
          </div>
        </div>
      </div>

      <div className="bg-white text-start border border-[#bfc6d4 rounded-lg p-6 mb-7">
        <h2 className="text-lg font-semibold mb-2">Access</h2>
        <p className="text-sm text-gray-500 mb-4">
          The following access rules only apply to{" "}
          <a href="#" className="text-indigo-600 underline">
            standalone actions
          </a>
          .
        </p>

        <div className="space-y-4">
          <div
            className="flex items-center space-x-3 cursor-pointer"
            onClick={() => setSelectedOption("All users")}
          >
            <FaUsers className="text-gray-500" />
            <span className="text-gray-700">All users</span>
            <span className="text-sm text-gray-500">
              (Anyone in my organization)
            </span>
            <input
              type="radio"
              name="access"
              checked={selectedOption === "All users"}
              onChange={() => setSelectedOption("All users")}
              className="ml-auto form-radio text-indigo-600"
            />
          </div>

          <div
            className="flex items-center space-x-3 cursor-pointer"
            onClick={() => setSelectedOption("Site")}
          >
            <FaBuilding className="text-gray-500" />
            <span className="text-gray-700">Site</span>
            <span className="text-sm text-gray-500">
              <a href="#" className="text-indigo-600 underline">
                Learn more
              </a>
            </span>
            <input
              type="radio"
              name="access"
              checked={selectedOption === "Site"}
              onChange={() => setSelectedOption("Site")}
              className="ml-auto form-radio text-indigo-600"
            />
          </div>

          <div
            className="flex items-center space-x-3 cursor-pointer"
            onClick={() => setSelectedOption("Collaborators")}
          >
            <FaLock className="text-gray-500" />
            <span className="text-gray-700">Collaborators</span>
            <span className="text-sm text-gray-500">
              (Creator and assignee)
            </span>
            <input
              type="radio"
              name="access"
              checked={selectedOption === "Collaborators"}
              onChange={() => setSelectedOption("Collaborators")}
              className="ml-auto form-radio text-indigo-600"
            />
          </div>
        </div>

        <div className="mt-4 p-3 bg-blue-50 text-sm text-gray-700 border-l-4 border-blue-400 rounded-md">
          <p>
            <span className="text-indigo-600">ℹ</span> If you create an action
            in an inspection, the action will follow that{" "}
            <a href="#" className="text-indigo-600 underline">
              inspection's access rules
            </a>
            .
          </p>
        </div>
      </div>

      {/* Labels Section */}
      <div className="bg-white text-start border border-[#bfc6d4 rounded-lg p-6 mb-7">
        <h2 className="text-lg font-semibold mb-2">Labels</h2>
        <p className="text-sm text-gray-500 mb-4">
          Organize your actions further with customizable labels.
        </p>

        <div className="border rounded-lg p-4 text-gray-500 text-start">
          No Labels
        </div>

        <button className="mt-4 flex items-center px-4 py-2 bg-indigo-50 text-indigo-600 rounded-md hover:bg-indigo-100">
          <span className="text-lg">+</span>
          <span className="ml-2">Create label</span>
        </button>
      </div>
    </div>
  );
}

export default ActionSettings;
