import ManufacturingHero from "../../../assets/manufacturing/ManufacturingAssets.webp";
import TextImageFlex from "../../../shared/textImageFlex";
const GainVisibilty = () => {
  return (
    <div className="py-12 md:py-24 bg-[#F3F6FB]">
      <TextImageFlex
        heading="Improve uptime on critical equipment"
        para="Go beyond reactive repairs and keep things running smoothly with scheduled maintenance tasks that match the frequency you need. Gain a detailed overview of your assets, including history and upcoming activities. Set up automated reminders so teams are alerted when maintenance checks are due to be completed."
        img={ManufacturingHero}
        widhtClass="w-full"
        reverse
      />
    </div>
  );
};

export default GainVisibilty;
