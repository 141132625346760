import DemoForm from "../../../shared/form";
const Form = () => {
  return (
    <DemoForm
      heading="Interested in learning more?"
      para="Fill out the form to chat with our team"
    />
  );
};

export default Form;
