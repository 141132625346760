import React from "react";
import { FaStar } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

const reviews = [
  {
    id: 1,
    rating: 5,
    titleKey: "reviews.integrationreviewid1", // Use keys for titles
    descriptionKey:"reviews.integrationreviewid1des",
    author: "Pete C13",
    location: "United Kingdom",
  },
  {
    id: 2,
    rating: 5,
    titleKey: "reviews.integrationreviewid2", // Another key
    descriptionKey:"reviews.integrationreviewid2des",
    author: "dcconole",
    location: "New Zealand",
  },
  {
    id: 3,
    rating: 5,
    titleKey: "reviews.integrationreviewid3", // Another key
    descriptionKey:"reviews.integrationreviewid3des",
    author: "Alonso Heredia",
  },
];

const ReviewCard = () => {
  const isArabic = i18next.language === "ar";
  const { t } = useTranslation(); // Hook for translations

  return (
    <div className="bg-[#f3f6fb] py-20">
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {reviews.map((review) => (
            <div
              key={review.id}
              className="px-6 py-6 rounded-xl shadow-sm border border-gray-200 hover:shadow-md transition-shadow duration-300"
            >
              
              <div className="flex items-center mb-4">
                {Array(review.rating)
                  .fill()
                  .map((_, i) => (
                    <FaStar key={i} className="text-[#FFD700] mr-1" size={20} />
                  ))}
              </div>
              <h3 className="text-2xl text-start font-semibold text-gray-800 mb-3">
                {t(review.titleKey)} {/* Dynamically translate the title */}
              </h3>
              <p className="text-gray-600 mb-4 text-start italic">
              "{t(review.descriptionKey)}"
              </p>
              <div className="text-gray-800 text-start font-semibold">
                By {review.author}
                {review.location && (
                  <span className="text-gray-500 text-start font-normal">
                    | {review.location}
                  </span>
                )}
              </div>
            </div>
          ))}
        </div>
        <div className="text-center mt-6 text-gray-500 text-sm">
          {t("reviews.integrationallreviews")} {/* Footer translation */}
        </div>
      </div>
    </div>
  );
};

export default ReviewCard;
