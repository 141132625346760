import React from "react";
import IconCards from "../../../shared/iconCards";
import Icon from "../../../assets/construction/Feature-Stack-Icon.svg";
import { useTranslation } from "react-i18next";

const IndustryCards = () => {
  const { t } = useTranslation();

  const CardsData = [
    {
      img: Icon,
      title: t("industryCards.cards.facilityMaintenance.title"),
      description: t("industryCards.cards.facilityMaintenance.description"),
      linkText: t("industryCards.cards.facilityMaintenance.linkText"),
    },
    {
      img: Icon,
      title: t("industryCards.cards.conditionAssessment.title"),
      description: t("industryCards.cards.conditionAssessment.description"),
      linkText: t("industryCards.cards.conditionAssessment.linkText"),
    },
    {
      img: Icon,
      title: t("industryCards.cards.securityAssessment.title"),
      description: t("industryCards.cards.securityAssessment.description"),
      linkText: t("industryCards.cards.securityAssessment.linkText"),
    },
    {
      img: Icon,
      title: t("industryCards.cards.hvacMaintenance.title"),
      description: t("industryCards.cards.hvacMaintenance.description"),
      linkText: t("industryCards.cards.hvacMaintenance.linkText"),
    },
    {
      img: Icon,
      title: t("industryCards.cards.fireRisk.title"),
      description: t("industryCards.cards.fireRisk.description"),
      linkText: t("industryCards.cards.fireRisk.linkText"),
    },
    {
      img: Icon,
      title: t("industryCards.cards.emergencyResponse.title"),
      description: t("industryCards.cards.emergencyResponse.description"),
      linkText: t("industryCards.cards.emergencyResponse.linkText"),
    },
  ];

  return (
    <div className="py-10">
      <IconCards heading={t("industryCards.heading")} CardsData={CardsData} />
    </div>
  );
};

export default IndustryCards;
