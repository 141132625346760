import React from "react";
import ImprovementSection from "../../../shared/improvementSection";
import { useTranslation } from "react-i18next";

const ImprovementTwo = () => {
  const { t } = useTranslation();

  return (
    <ImprovementSection
      heading={t("improvementSection.headingTwo")}
      btn1={t("improvementSection.btn1")}
      btn2={t("improvementSection.btn2")}
      btn1Navigate="/signup"
    />
  );
};

export default ImprovementTwo;
