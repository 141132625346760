import React from "react";

function EditTimeZoneModal({ isOpen, setIsTimeZoneOpen }) {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center px-4 z-50 sm:px-0">
      <div className="bg-white rounded-lg w-full text-start max-w-md p-6 md:p-8">
        <h2 className="text-lg font-semibold text-gray-800 mb-4">
          Edit time zone
        </h2>

        <label className="block text-sm font-medium text-gray-700 mb-2">
          Time zone
        </label>
        <div className="relative mb-6">
          <select className="block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500">
            <option>Select...</option>
            {/* Add more time zone options here */}
          </select>
        </div>

        <div className="flex justify-end mt-6 space-x-2">
          <button
            type="button"
            className="text-sm font-medium text-gray-500 border border-gray-300 rounded-md px-4 py-3 hover:bg-gray-100 focus:outline-none"
            onClick={() => setIsTimeZoneOpen(false)}
          >
            Cancel
          </button>
          <button
            type="button"
            className="text-sm font-medium text-white bg-[#6559ff] rounded-md px-4 py-3  focus:outline-none"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
}

export default EditTimeZoneModal;
