import React from "react";
import { useTranslation } from "react-i18next";
// const locations = [
//   {
//     city: "Amsterdam",
//     phone: "+31208096660",
//     address: "Nieuwezijds Voorburgwal 162, 1012 SJ Amsterdam, The Netherlands",
//   },
//   {
//     city: "Kansas City",
//     phone: "+1 816 787 1343",
//     address: "6th Floor, 2114 Central St Kansas City, MO 64108 USA",
//   },
//   {
//     city: "Manchester",
//     phone: "+44 161 768 1124",
//     address: "Suite 3.01 Bond, 38 – 42 Mosley St, Manchester M2 3AZ UK",
//   },
//   {
//     city: "Sydney",
//     phone: "+61 1300 984 245",
//     address: "72 Foveaux St, Surry Hills NSW 2010, Australia",
//   },
//   {
//     city: "Townsville",
//     phone: "+61 1300 984 245",
//     address: "PO Box 578, Townsville QLD 4810, Australia",
//   },
// ];

const GlobalSupport = () => {
  const { t } = useTranslation();
  return (
    <div className="bg-[#F3F6FB] text-center py-24 px-4 sm:px-6 lg:px-8">
      <h1 className="text-5xl font-bold text-[#293745] mb-8 mx-4 md:mx-40">
        {t("supportpage.globalsupport")}
      </h1>
      <p className="text-[#293745] mb-16 text-xl">
        {t("supportpage.globalsupport1")}
      </p>
      {/* <div className="container mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3  gap-32 w-[98%] md:w-[87%] ">
        {locations.map((location, index) => (
          <div key={index} className="text-center">
            <h2 className="text-2xl font-[500] text-[#4740D4] mb-2">
              {location.city}
            </h2>
            <p className="text-[#293745] pt-3">{location.phone}</p>
            <p className="text-[#293745]">{location.address}</p>
          </div>
        ))}
      </div> */}
    </div>
  );
};

export default GlobalSupport;
