import DemoForm from "../../../shared/form";
const Form = () => {
  return (
    <DemoForm
      heading=" Book a demo"
      para="Tell us a little about yourself and we’ll connect you with a training
          specialist to take you through the platform tour and chat through your
          training needs."
    />
  );
};

export default Form;
