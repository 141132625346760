import React from "react";
import { useNavigate } from "react-router-dom";

const AddAssetsInBulk = () => {
  const navigate = useNavigate();

  const handleSkipToNext = () => {
    navigate("/dashboard/uploadcsv");
  };

  return (
    <div className="bg-gray-50 p-8 flex justify-center">
      <div className="w-full max-w-4xl">
        {/* Tabs */}
        <div className="flex justify-center space-x-8 border-b pb-4">
          {/* Prepare CSV Button */}
          <button
            onClick={() => navigate("/dashboard/asset-bulk")}
            className="text-blue-600 font-medium border-b-2 border-blue-600"
          >
            Prepare CSV
          </button>

          {/* Upload CSV Button */}
          <button
            onClick={() => navigate("/dashboard/uploadcsv")}
            className="text-gray-500 font-medium"
          >
            Upload CSV
          </button>

          {/* Create Assets Button */}
          <button className="text-gray-500 font-medium">Create assets</button>
        </div>

        {/* Header */}
        <div className="text-center mt-6">
          <h1 className="text-2xl font-semibold text-left">
            Add assets in bulk
          </h1>
        </div>

        {/* Steps Section */}
        <div className="bg-white shadow rounded-lg p-6 mt-6 flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-6">
          <div className="flex-1 flex items-center space-x-4">
            <div className="text-blue-600 text-3xl">📥</div>
            <div>
              <h2 className="font-medium">1. Download</h2>
              <p className="text-gray-500 text-sm">
                Download CSV templates for each type.
              </p>
            </div>
          </div>
          <div className="flex-1 flex items-center space-x-4">
            <div className="text-yellow-600 text-3xl">✏️</div>
            <div>
              <h2 className="font-medium">2. Add details</h2>
              <p className="text-gray-500 text-sm">
                Add asset details to type fields.
              </p>
            </div>
          </div>
          <div className="flex-1 flex items-center space-x-4">
            <div className="text-blue-400 text-3xl">📤</div>
            <div>
              <h2 className="font-medium">3. Upload</h2>
              <p className="text-gray-500 text-sm">
                Save your file as a CSV and upload it in the next step.
              </p>
            </div>
          </div>
        </div>

        {/* Important Section */}
        <div className="mt-8 text-left">
          <h2 className="text-lg font-semibold text-gray-800">Important!</h2>
          <ul className="list-disc pl-6 text-gray-600 space-y-2 mt-4">
            <li>Use the provided template to enter asset details.</li>
            <li>Don't add new columns or edit column headers.</li>
            <li>Each CSV file should only contain assets of one type.</li>
            <li>Each asset must have a type and unique ID specified.</li>
            <li>
              The date must be entered in the date format set in your{" "}
              <a
                href="#"
                className="text-blue-600 underline hover:text-blue-800"
              >
                profile settings
              </a>
              , such as "27 Mar 2023".
            </li>
            <li>
              Currency values must be specified in{" "}
              <a
                href="#"
                className="text-blue-600 underline hover:text-blue-800"
              >
                ISO 4217 code
              </a>{" "}
              without comma dividers or special characters, such as "1234.00
              AUD".
            </li>
            <li>Only CSV files will be accepted.</li>
          </ul>
          <a
            href="#"
            className="text-blue-600 underline hover:text-blue-800 mt-4 inline-block"
          >
            Manage types and fields
          </a>
        </div>

        {/* Action Buttons */}
        <div className="flex flex-col sm:flex-row justify-end items-center mt-8 space-y-4 sm:space-y-0">
          <button className="bg-white border border-blue-600 text-blue-600 font-medium px-4 py-2 rounded-lg hover:bg-blue-50 w-full sm:w-auto">
            Download CSV template
          </button>
          <button
            onClick={handleSkipToNext}
            className="bg-blue-600 text-white font-medium px-4 py-2 rounded-lg hover:bg-blue-700 w-full sm:w-auto sm:ml-4"
          >
            Skip to next step →
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddAssetsInBulk;
