import React from "react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

import Icon1 from "../../../assets/integrations/icon1.svg";
import Icon2 from "../../../assets/integrations/icon2.svg";
import Icon3 from "../../../assets/integrations/icon3.svg";
import Icon4 from "../../../assets/integrations/icon4.svg";

const cardsData = [
  {
    id: 1,
    title: "Visualize and compare your data",
    description:
      "Extract decision-making insights by connecting directly to Power BI or your organization’s data warehouse.",
    image: Icon1,
  },
  {
    id: 2,
    title: "Automate manual tasks",
    description:
      "Streamline workflows by accessing thousands of plug-and-play integrators through Zapier and Microsoft Power Automate.",
    image: Icon2,
  },
  {
    id: 3,
    title: "Provide seamless and secured access",
    description:
      "Use Single Sign-On (SSO) authentication and manage users and groups through your company’s identity provider (such as Active Directory).",
    image: Icon3,
  },
  {
    id: 4,
    title: "Create customized solutions",
    description:
      "Use our Integration Builder or SafetyCulture API to meet your team’s unique needs with completely customizable connections.",
    image: Icon4,
  },
];

const ConnectToolsSection = () => {
  const { t } = useTranslation();
  const isArabic = i18next.language === "ar";

  const cardsData = [
    {
      id: 1,
      title: t("integrationpage.id1title"),
      description: t("integrationpage.id1description"),
      image: Icon1,
    },
    {
      id: 2,
      title: t("integrationpage.id2title"),
      description: t("integrationpage.id2description"),
      image: Icon2,
    },
    {
      id: 3,
      title: t("integrationpage.id3title"),
      description: t("integrationpage.id3discription"),
      image: Icon3,
    },
    {
      id: 4,
      title: t("integrationpage.id4title"),
      description: t("integrationpage.id4discription"),
      image: Icon4,
    },
  ];

  return (
    <section className="py-24">
      <div className="container mx-auto text-center">
        <h2 className="text-2xl md:text-3xl lg:text-5xl font-black text-[#293745]">
          {t("integrationpage.connecttoolh2")}
        </h2>
        <p className="text-lg md:text-[1.25rem] text-[#293745] mt-7 px-3 md:px-44">
          {t("integrationpage.connecttoolpara")}
        </p>

        <div
          className={`grid gap-8 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-4 mt-12 px-14 ${
            isArabic ? "rtl" : ""
          }`}
        >
          {cardsData.map((card) => (
            <div
              key={card.id}
              className="border border-gray-200 rounded-2xl px-6 pt-7 pb-3"
            >
              <div className="flex justify-start">
                <img src={card.image} alt={card.title} className="w-12 h-12" />
              </div>
              <h3 className="text-lg md:text-2xl font-bold text-[#293745] py-6 text-start">
                {card.title}
              </h3>
              <p className="text-[#293745] text-start">{card.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ConnectToolsSection;
