import React from "react";
import { Link } from "react-router-dom";
import { FaCube, FaPen, FaClipboard } from "react-icons/fa";

const Popup = ({ onClose }) => {
  return (
    <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-4xl">
        <h1 className="text-2xl text-left font-bold">Add Assets</h1>
        {/* Flex container for sections */}
        <div className="flex flex-row justify-between mt-10">
          {/* Section 1 */}
          <Link to="/dashboard/asset-prompt">
            <div className="p-4 bg-white rounded-md flex flex-col items-center border border-black mx-2">
              <FaCube className="text-purple-600 text-3xl mb-2 mt-2" />
              <h3 className="font-semibold text-center">Add a single assets</h3>
              <p className="text-center text-gray-500 text-sm mt-4">
                Multiple add your assets in one-by-one using our assets creator.
              </p>
            </div>
          </Link>

          {/* Section 2 */}
          <Link to="/dashboard/no-asset-types">
            <div className="p-4 bg-white rounded-md flex flex-col items-center border border-black mx-2">
              <FaPen className="text-yellow-500 text-3xl mb-2 mt-2" />
              <h3 className="font-semibold text-center">
                Upload multiple assets
              </h3>
              <p className="text-center text-gray-500 text-sm mt-4">
                Import all your assets at once by uploadng a CSV file.
              </p>
            </div>
          </Link>

          <Link to="/dashboard/integrationsection">
            <div className="p-4 bg-white rounded-md flex flex-col items-center border border-black mx-2 cursor-pointer">
              <FaClipboard className="text-blue-500 text-3xl mb-2 mt-2" />
              <h3 className="font-semibold text-center">Add via integration</h3>
              <p className="text-center text-gray-500 text-sm mt-4">
                Sync your assets from an asset management system by setting.
              </p>
            </div>
          </Link>
        </div>

        {/* Close Button */}
        <button
          onClick={onClose}
          className="mt-6 bg-purple-600 text-white px-4 py-2 rounded-md w-48"
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default Popup;
