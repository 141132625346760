import FlexContent from "../../../shared/flexContent";
import InspectionImage from "../../../assets/hospatility/HospitalityInspections.webp";
import { useTranslation } from "react-i18next";
const BoostProductivity = () => {
  const { t } = useTranslation();
  return (
    <div className="bg-[#F3F6FB] py-24">
      <FlexContent
        heading={t("hospitalitypage.BoostProdheading")}
        para={t("hospitalitypage.BoostProdpara")}
        reverse
        italicText={t("hospitalitypage.italicText")}
        name={t("hospitalitypage.name")}
        position={t("hospitalitypage.position")}
        img={InspectionImage}
      />
    </div>
  );
};
export default BoostProductivity;
