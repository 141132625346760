import Hero from "../../components/CustomerPage/Customer";
import Customer from "../../components/CustomerPage/CustomerDetailPage";
const Customers=()=>{
    return(
        <div>
            <Hero/>
            <Customer/>
        </div>
    )
}
export default Customers;