import React, { useState } from "react";
import { FaSearch } from "react-icons/fa";
import { CiNoWaitingSign } from "react-icons/ci";
import { useNavigate } from "react-router-dom";
import { momentLocalizer } from "react-big-calendar";
import moment from "moment";

const localizer = momentLocalizer(moment);

const ManageHeadsUp = () => {
  const navigate = useNavigate();
  const [CreatedropdownOpen, setCreateDropdownOpen] = useState(false);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("Due date");
  const options = ["Completed", "Published date", "Title"];

  const toggleFilterDropdown = () => {
    setIsFilterOpen(!isFilterOpen);
  };
  return (
    <div className="flex flex-col items-center pe-8 ">
      <div className="flex justify-between items-center mb-1 w-full pt-3">
        <h1 className="text-2xl font-bold text-[#1f2533]">Heads Up</h1>
        <div>
          <button
            className="bg-[#6559ff] text-white px-5 py-3 rounded-lg hover:bg-[#7470f5] shadow"
            onClick={() => navigate("/dashboard/create-headsup")}
          >
            + Create Heads Up
          </button>
        </div>
      </div>

      <div className="flex justify-between w-full items-center mb-6 mt-5">
        {/* Search and filter */}
        <div className="flex items-center space-x-4">
          {/* Search box */}
          <div className="flex items-center border border-gray-300 rounded-md px-2 py-2">
            <FaSearch className="text-gray-500" />
            <input
              type="text"
              placeholder="Search users"
              className="ml-2 outline-none bg-transparent"
            />
          </div>

          {/* User status and filter */}
          <div className="flex items-center">
            <div className="relative">
              <button
                onClick={toggleFilterDropdown}
                className="text-[#6559ff] hover:text-blue-600"
              >
                + Add filter
              </button>
              {isFilterOpen && (
                <div className="absolute mt-2 w-48 rounded-md shadow-lg bg-white">
                  <ul className="py-1 text-gray-700">
                    <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer">
                      Filter 1
                    </li>
                    <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer">
                      Filter 2
                    </li>
                    <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer">
                      Filter 3
                    </li>
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Create group and download */}
        <div className="flex items-center space-x-4">
          <p className=" text-gray-600 text-xs">No result</p>
        </div>
      </div>
      <div className="w-full text-center py-20">
        <div className="flex flex-col items-center justify-center h-full pt-4">
          <CiNoWaitingSign className="text-gray-500 mb-4 text-7xl" />

          <p className="text-gray-800">
            Send important information to your team
          </p>
          <p className=" pt-2 text-gray-800 mx-2 md:mx-36">
            Create a Heads Up with text, video or images to quickly and
            effectively get important information to those who need it
          </p>
        </div>
      </div>
    </div>
  );
};

export default ManageHeadsUp;
