import { useState } from "react";
import UserSettingsTab from "./tabs/tab1";
import NotificationSettings from "./tabs/tab2";
import Devices from "./tabs/tab3";
import Templates from "./tabs/tab4"
import Groups from "./tabs/tab5"
import Sites from "./tabs/tab6";
import Credentials from "./tabs/tab7";
const NotificationTab = () => {
  const [activeTab, setActiveTab] = useState("User Settings");

  const renderContent = () => {
    switch (activeTab) {
      case "User Settings":
        return <UserSettingsTab/>;
      case "Notifications":
        return <NotificationSettings/>;
      case "Devices":
        return <Devices/>;
      case "Templates":
        return <Templates/>;
      case "Groups":
        return <Groups/>;
      case "Sites":
        return <Sites/>;
        case "Credentials":
          return <Credentials/>;
      default:
        return null;
    }
  };

  return (
    <div className="p-4 sm:p-6 lg:p-5  mx-auto">
      {/* Back Button */}
      <button className="flex items-center text-blue-600 hover:text-blue-800 mb-4">
        <svg
          className="w-5 h-5 mr-2"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M15 19l-7-7 7-7"
          ></path>
        </svg>
        Back
      </button>

      {/* Profile Section */}
      <div className="bg-gradient-to-r from-purple-200 via-blue-200 to-green-200 py-8 px-6 rounded-xl shadow-md">
        <div className="flex items-center space-x-4">
          <div className="relative">
            <img
              className="w-24 h-24 rounded-full"
              src="https://via.placeholder.com/150"
              alt="User Avatar"
            />
            <button className="absolute bottom-0 right-0 bg-white p-1 rounded-full shadow-md">
              <svg
                className="w-4 h-4 text-blue-600"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M15.232 5.232l3.536 3.536M9 13.536l2.121-2.121m0 0l2.829 2.828m0 0L17.949 9.707m-2.828 2.829L9 17.707m0 0L5.707 21m-2.828 0a1 1 0 01-1.414-1.414l3.536-3.536M9 17.707l7.778-7.778m0 0L15 5.232m2.121 2.121L21 3"
                ></path>
              </svg>
            </button>
          </div>
          <div>
            <h2 className="text-lg font-semibold text-start pt-1">Uzair Khan</h2>
            <p className="text-gray-500">uzairkhan55465@gmail.com</p>
          </div>
        </div>
      </div>

      {/* Tabs Section */}
      <div className="mt-6">
        <nav className="flex space-x-7 border-b border-gray-300">
          {["User Settings", "Notifications", "Devices", "Templates","Groups", "Sites", "Credentials"].map(
            (tab) => (
              <button
                key={tab}
                onClick={() => setActiveTab(tab)}
                className={`pb-2 text-gray-600 ${
                  activeTab === tab ? "border-b-2 border-blue-600 text-blue-600" : ""
                }`}
              >
                {tab}
              </button>
            )
          )}
        </nav>

        {/* Tab Content */}
        <div className="mt-4">{renderContent()}</div>
      </div>
    </div>
  );
};

export default NotificationTab;
