import React, { useState } from "react";

const SitePermissionsModal = ({ isOpen, onClose, setSitePermissionOpen }) => {
  const [selectedOption, setSelectedOption] = useState("allSites");


  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-30">
      <div className="bg-white text-start rounded-lg shadow-lg  w-full md:w-[50%]  mx-4 p-6">
        <h2 className="text-xl font-semibold text-gray-800">
          What sites can team members view and select from?
        </h2>
        <p className="text-sm text-gray-500 mt-1">
          Control which sites will be available for your users to view in the
          site list, and select from in inspections, schedules, actions, issues, and when filtering.
        </p>

        <form className="mt-4 space-y-4">
          <div className="flex items-center">
            <input
              type="radio"
              id="allSites"
              name="siteAccess"
              value="allSites"
              checked={selectedOption === "allSites"}
              onChange={() => setSelectedOption("allSites")}
              className="w-4 h-4 text-[#6559ff] focus:ring-purple-500"
            />
            <label htmlFor="allSites" className="ml-3 text-gray-700">
              Users can view and select from all sites
            </label>
          </div>

          <div className="flex items-center">
            <input
              type="radio"
              id="memberSites"
              name="siteAccess"
              value="memberSites"
              checked={selectedOption === "memberSites"}
              onChange={() => setSelectedOption("memberSites")}
              className="w-4 h-4 text-[#6559ff] focus:ring-purple-500"
            />
            <label htmlFor="memberSites" className="ml-3 text-gray-700">
              Users can only view and select sites they're a member of
            </label>
          </div>

          <div className="flex items-start mt-4">
            <span className="w-5 h-5 flex items-center justify-center text-gray-400">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-4 h-4"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth="2"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
            </span>
            <p className="ml-2 text-sm text-gray-500">
              Anyone with the 'Platform management: Sites' permission will be
              able to select all sites.
            </p>
          </div>

          <a
            href="#"
            className="text-[#6559ff] text-sm hover:underline mt-2 inline-block text-start"
          >
            Learn more
          </a>
        </form>

        <div className="flex justify-end mt-6 space-x-2">
          <button
            type="button"
            className="text-sm font-medium text-gray-500 border border-gray-300 rounded-md px-4 py-3 hover:bg-gray-100 focus:outline-none"
            onClick={()=>setSitePermissionOpen(false)}
          >
            Cancel
          </button>
          <button
            type="button"
            className="text-sm font-medium text-white bg-[#6559ff] rounded-md px-4 py-3  focus:outline-none"
          >
            Save and apply
          </button>
        </div>
      </div>
    </div>
  );
};

export default SitePermissionsModal;
