import React from "react";
import { FaArrowRight } from "react-icons/fa";
import i18next from "i18next"; // Assuming i18next is imported for language detection

const IconCards = ({ heading, CardsData }) => {
  const isArabic = i18next.language === "ar"; // Detect the language

  return (
    <div className={`container mx-auto py-14 px-4 ${isArabic ? "text-right rtl" : ""}`}>
      <h2
        className={`text-[30px] sm:text-[33px] md:text-[43px] lg:text-[53px] text-[#293745] font-bold mb-10 md:leading-[66px] ${
          isArabic ? "text-right" : ""
        }`}
      >
        {heading}
      </h2>
      <div
        className={`grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 ${isArabic ? "rtl-grid" : ""}`}
      >
        {CardsData.map((card, index) => (
          <div
            key={index}
            className="group flex flex-col items-start border border-gray-300 rounded-2xl p-5 transition-shadow"
          >
            <img src={card.img} alt={card.title} />
            <h3
              className={`text-2xl font-[800] my-6 ${isArabic ? "text-right" : "text-start"} transition-all`}
            >
              {card.title}
            </h3>
            <p className={`text-gray-600 mb-4 ${isArabic ? "text-right" : "text-start"}`}>{card.description}</p>

            {/* Ensure the button stays at the bottom */}
            <div className="flex-grow" /> {/* Empty div to push button to the bottom */}
            
            {/* <div className="border border-[#D0D7E1] w-full rounded-lg py-3 mt-7 hover:bg-[#f0f0ff]">
              <a
                href="#"
                className="text-[#4740d4] font-semibold transition-all block text-center"
              >
                {card.linkText}
              </a>
            </div> */}
          </div>
        ))}
      </div>
    </div>
  );
};

export default IconCards;
