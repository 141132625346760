import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const savedLanguage = localStorage.getItem("i18nextLng") || "ar"; // Retrieve saved language or default to "ar"

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: require("../locales/en.json"),
    },
    ar: {
      translation: require("../locales/ar.json"),
    },
  },
  lng: savedLanguage, // Use saved language
  fallbackLng: "ar",
  interpolation: {
    escapeValue: false, // React already escapes content
  },
  detection: {
    // Enable detection and caching
    order: ["localStorage", "navigator"],
    caches: ["localStorage"],
  },
});

// Save language change to localStorage
i18n.on("languageChanged", (lng) => {
  localStorage.setItem("i18nextLng", lng);
});

export default i18n;
