import React, { useState } from "react";
import Filter from "./filter";
const ScheduleImage1 = require("../../../../assets/schedule/3.jpg");
const ScheduleImage2 = require("../../../../assets/schedule/4.jpg");

const Missed = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const toggleFilterDropdown = () => {
    setIsFilterOpen(!isFilterOpen);
  };

  const handleDropdownClick = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const options = [
    { value: "", label: "Select period" },
    { value: "30", label: "Last 30 days" },
    { value: "60", label: "Last 60 days" },
    { value: "90", label: "Last 90 days" },
  ];

  const tableHeaders = ["Title", "Status", "Template", "Assignees", "Due on"];

  return (
    <div className="px-2 sm:px-4 md:px-8 lg:px-12">
      <div className="mt-6 flex flex-col sm:flex-row items-center space-y-4 sm:space-y-0 sm:space-x-4">
        <input
          type="text"
          placeholder="Search"
          className="border rounded-md p-2 w-full sm:w-64"
        />

        <div className="relative w-full sm:w-auto">
          <select
            onClick={handleDropdownClick}
            onBlur={() => setIsDropdownOpen(false)}
            onChange={handleOptionChange}
            className="border rounded-md bg-transparent hover:bg-indigo-50 p-2 pr-8 w-full sm:w-auto appearance-none"
          >
            {options.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>

          {/* Arrow Icon */}
          <span
            className={`absolute right-3 top-3 transform ${
              isDropdownOpen ? "rotate-180" : "rotate-0"
            } transition-transform duration-200`}
          >
            ▼
          </span>
        </div>

        <div>
          <Filter
            isFilterOpen={isFilterOpen}
            toggleFilterDropdown={toggleFilterDropdown}
          />
        </div>
      </div>

      <div className="mt-4 rounded-md overflow-x-auto">
        <table className="w-full min-w-max text-left border border-gray-300">
          <thead>
            <tr className="bg-indigo-50 text-gray-700 font-thin h-14">
              {tableHeaders.map((header) => (
                <th
                  key={header}
                  className="p-2 text-xs sm:text-sm md:text-base"
                >
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {selectedOption ? (
              <tr>
                <td colSpan="5" className="text-center p-4 sm:p-6">
                  <div className="flex flex-col items-center">
                    <img
                      src={ScheduleImage1}
                      alt="No schedules"
                      className="mb-4 w-20 h-20 sm:w-32 sm:h-32 md:w-40 md:h-40"
                    />
                    <p className="text-gray-600 font-semibold text-sm sm:text-base">
                      No results
                    </p>
                    <p className="text-gray-500 text-xs sm:text-sm">
                      Try again with different search terms or filters.
                    </p>
                  </div>
                </td>
              </tr>
            ) : (
              <tr>
                <td colSpan="5" className="text-center p-4 sm:p-6">
                  <div className="flex flex-col items-center">
                    <img
                      src={ScheduleImage2}
                      alt="No schedules"
                      className="mb-4 w-20 h-20 sm:w-32 sm:h-32 md:w-40 md:h-40"
                    />
                    <p className="text-gray-600 font-semibold text-sm sm:text-base md:text-lg">
                      No missed schedules
                    </p>
                    <p className="text-gray-500 text-xs sm:text-sm">
                      Yay! No scheduled inspections have been missed.
                    </p>
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Missed;
