import React, { useState } from "react";
import {  FaThList, FaSearch } from "react-icons/fa";
import { CiNoWaitingSign } from "react-icons/ci";
import { IoIosSettings } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { Calendar, momentLocalizer } from "react-big-calendar";
import dayjs from "dayjs";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import moment from "moment";
import DatePicker from "react-datepicker";
import { FaTasks } from "react-icons/fa";
const localizer = momentLocalizer(moment);

const Actions = ({ onClose }) => {
  const navigate = useNavigate();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [CreatedropdownOpen, setCreateDropdownOpen] = useState(false);
  const [activeTab, setActiveTab] = useState("list");
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("Due date");
  const options = ["Creation date", "Due date", "Modified date", "Priority"];
  const [view, setView] = useState("month");
  const [date, setDate] = useState(new Date());
  const [priority, setPriority] = useState("Low");
  const [dueDate, setDueDate] = useState(new Date());
  const [repeat, setRepeat] = useState("Does not repeat");
  const [showSiteModal, setShowSiteModal] = useState(false);
  const [showAssetModal, setShowAssetModal] = useState(false);
  const [showLabelModal, setShowLabelModal] = useState(false);
  // Move to today’s date
  const goToToday = () => setDate(new Date());

  // Change view (Month/Week)
  const changeView = (newView) => setView(newView);

  // Navigation functions
  const goToNext = () =>
    setDate(
      dayjs(date)
        .add(1, view === "month" ? "month" : "week")
        .toDate()
    );
  const goToPrevious = () =>
    setDate(
      dayjs(date)
        .subtract(1, view === "month" ? "month" : "week")
        .toDate()
    );

  const toggleDropdown2 = () => setIsOpen(!isOpen);
  const toggleDropdown3 = () => {
    if (CreatedropdownOpen === false) {
      setCreateDropdownOpen(true);
    } else {
      setCreateDropdownOpen(false);
    }
  };
  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
  };

  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);
  const toggleFilterDropdown = () => {
    setIsFilterOpen(!isFilterOpen);
  };
  return (
    <div className="flex flex-col items-center p-4 ">
      <div className="flex justify-between items-center mb-8 w-full pt-3">
        <h1 className="text-2xl font-bold text-[#1f2533]">Actions</h1>
        <div className="flex gap-2">
          <button
            type="submit"
            class="inline-flex items-center py-3 px-5 ms-2 text-sm font-medium text-[#4740d4] bg-white rounded-lg border border-[#bfc6d4] focus:ring-4 hover:bg-[#e0e4ff]"
            onClick={() => navigate("/dashboard/action-settings")}
          >
            <IoIosSettings className="text-[#6559ff] me-1" />
            Settings
          </button>
          <button
            className="bg-[#6559ff] text-white px-5 py-3 rounded-lg hover:bg-[#7470f5] shadow"
            onClick={toggleDropdown3}
          >
            + Create actions
          </button>
        </div>
      </div>

      <div className="flex justify-between w-full items-center mb-6 mt-3">
        {/* Search and filter */}
        <div className="flex items-center space-x-4">
          {/* Search box */}
          <div className="flex items-center border border-gray-300 rounded-md px-2 py-2">
            <FaSearch className="text-gray-500" />
            <input
              type="text"
              placeholder="Search users"
              className="ml-2 outline-none bg-transparent"
            />
          </div>
          <div className="flex">
            <p className="border border-[#bfc6d4] py-2 px-3  rounded-l-lg text-[#4740d4]">
              assignee: arab khan
            </p>
            <span className="border border-[#bfc6d4] py-2 px-3 rounded-r-lg text-[#4740d4]">
              X
            </span>
          </div>
          <div className="flex">
            <p className="border border-[#bfc6d4] py-2 px-3  rounded-l-lg text-[#4740d4]">
              Due date: past date{" "}
            </p>
            <span className="border border-[#bfc6d4] py-2 px-3 rounded-r-lg text-[#4740d4]">
              X
            </span>
          </div>
          {/* User status and filter */}
          <div className="flex items-center">
            <div className="relative">
              <button
                onClick={toggleFilterDropdown}
                className="text-[#6559ff] hover:text-blue-600"
              >
                + Add filter
              </button>
              {isFilterOpen && (
                <div className="absolute mt-2 w-48 rounded-md shadow-lg bg-white">
                  <ul className="py-1 text-gray-700">
                    <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer">
                      Filter 1
                    </li>
                    <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer">
                      Filter 2
                    </li>
                    <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer">
                      Filter 3
                    </li>
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Create group and download */}

        <label class="inline-flex items-center cursor-pointer">
          <span class="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300 me-3">
            Hide closed
          </span>
          <input type="checkbox" value="" class="sr-only peer" />
          <div class="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
        </label>
      </div>

      <div className="w-full flex flex-col md:flex-row md:items-center md:justify-between mb-4">
        {/* Checkbox with text */}
        <label className="flex items-center text-gray-500 text-sm md:text-base">
          <input type="checkbox" className="mr-2" />
          Select:
        </label>

        {/* Dropdown */}
        <div className="relative">
          <button
            onClick={toggleDropdown2}
            className="flex items-center px-4 py-2 bg-white  text-[#6559ff] hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-indigo-500"
          >
            <span>{selectedOption}</span>
            <svg
              className={`ml-2 w-4 h-4 transform transition-transform ${
                isOpen ? "rotate-180" : "rotate-0"
              }`}
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M19 9l-7 7-7-7"
              ></path>
            </svg>
          </button>

          {/* Dropdown menu */}
          {isOpen && (
            <ul className="absolute mt-2 w-full bg-white border border-gray-300 rounded-md shadow-lg z-10 text-gray-700">
              {options.map((option) => (
                <li
                  key={option}
                  onClick={() => handleOptionClick(option)}
                  className={`px-4 py-2 cursor-pointer hover:bg-indigo-100 ${
                    selectedOption === option ? "bg-indigo-100 " : ""
                  }`}
                >
                  {option}
                </li>
              ))}
            </ul>
          )}
        </div>

        <div className="flex items-center space-x-4 mt-4 md:mt-0">
          <div className="relative">
            <button
              onClick={toggleDropdown}
              className="bg-gray-100 px-3 py-2 rounded-lg flex items-center space-x-2"
            >
              <FaThList />
              <span>
                {activeTab === "list"
                  ? "List"
                  : activeTab === "board"
                  ? "Board"
                  : "Calendar"}
              </span>
            </button>
            {dropdownOpen && (
              <div className="absolute right-0 mt-2 w-32 bg-white border rounded-lg shadow-lg">
                <button
                  onClick={() => {
                    setActiveTab("list");
                    toggleDropdown();
                  }}
                  className="block px-4 py-2 text-left w-full hover:bg-gray-100"
                >
                  List
                </button>
                <button
                  onClick={() => {
                    setActiveTab("board");
                    toggleDropdown();
                  }}
                  className="block px-4 py-2 text-left w-full hover:bg-gray-100"
                >
                  Board
                </button>
                <button
                  onClick={() => {
                    setActiveTab("calendar");
                    toggleDropdown();
                  }}
                  className="block px-4 py-2 text-left w-full hover:bg-gray-100"
                >
                  Calendar
                </button>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="w-full text-center py-16">
        {activeTab === "list" && (
          <div className="flex flex-col items-center justify-center h-full pt-4">
            <CiNoWaitingSign className="text-gray-500 mb-4 text-7xl" />
            <p className="text-gray-500">No results</p>
            <p className="text-gray-400">
              There might be results under different filters. Please try again.
            </p>
            <p className="text-[#6559ff] pt-1 hover:underline">
              Remove all filters
            </p>
          </div>
        )}
        {activeTab === "board" && (
          <div className="flex flex-wrap justify-between gap-1">
            {["To do", "In progress", "Complete", "Can't do"].map(
              (title, index) => (
                <div
                  key={index}
                  className="flex flex-col w-full sm:w-1/2 lg:w-[23%]  rounded-lg  h-80 overflow-y-auto "
                >
                  <h3 className="text-start text-lg pt-1.5 text-gray-700 mb-2">
                    {title}
                  </h3>
                  <div className="flex-grow bg-[#e9edf6] text-start rounded-lg p-2 overflow-y-auto">
                    <div className="bg-white w-full h-24 rounded-lg mb-3"></div>
                    <div className="bg-white w-full h-24 rounded-lg mb-3"></div>
                  </div>
                </div>
              )
            )}
          </div>
        )}
        {activeTab === "calendar" && (
          <div>
            {/* Header with navigation and controls */}
            <div className="flex justify-between mt-[-40px] items-center mb-4">
              <div className="flex items-center space-x-2">
                <button onClick={goToPrevious} className="text-gray-600">
                  <FaChevronLeft />
                </button>
                <button
                  onClick={goToToday}
                  className="px-3 py-1 bg-blue-100 text-blue-600 rounded-lg"
                >
                  Today
                </button>
                <button onClick={goToNext} className="text-gray-600">
                  <FaChevronRight />
                </button>
              </div>

              <h2 className="text-lg pt-1.5 text-gray-700">
                {dayjs(date).format("MMMM YYYY")}
              </h2>

              <div className="flex space-x-2">
                <button
                  onClick={() => changeView("month")}
                  className={`px-3 py-1 border rounded-lg ${
                    view === "month"
                      ? "bg-blue-100 text-blue-600"
                      : "border-gray-300"
                  }`}
                >
                  Month
                </button>
                <button
                  onClick={() => changeView("week")}
                  className={`px-3 py-1 border rounded-lg ${
                    view === "week"
                      ? "bg-blue-100 text-blue-600"
                      : "border-gray-300"
                  }`}
                >
                  Week
                </button>
              </div>
            </div>

            {/* Calendar component */}
            <Calendar
              localizer={localizer}
              events={[]} // Add your events here
              startAccessor="start"
              endAccessor="end"
              date={date}
              view={view}
              onNavigate={setDate}
              onView={setView}
              style={{ height: "75vh" }}
              className="bg-white border rounded-lg shadow-sm"
            />
          </div>
        )}
      </div>
      {CreatedropdownOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 right-0 flex z-50 
           justify-end items-center"
        >
          <div className="bg-white w-full sm:w-1/2 lg:w-1/3 h-screen p-5 rounded-lg overflow-y-auto relative">
            {/* Close Button */}
            <button
              onClick={() => setCreateDropdownOpen(false)}
              className="absolute top-4 right-4 text-gray-500 hover:text-gray-800 mb-2"
            >
              ✕
            </button>

            {/* Action Dropdown */}
            <div className="mb-4">
              <select className="block  p-2 border border-[#bfc6d4] rounded mt-1 text-[#4740d4] ">
                <option>
                  <FaTasks /> Action
                </option>
              </select>
            </div>

            {/* Title and Description */}
            <div className="mb-4">
              <input
                type="text"
                placeholder="Add title..."
                className="block w-full p-2 border border-gray-300 rounded mb-2"
              />
              <textarea
                placeholder="Add description..."
                className="block w-full p-2 border border-gray-300 rounded"
              ></textarea>
            </div>

            {/* Priority Dropdown */}
            <div className="mb-4 mt-2 flex justify-between">
              <label className="pt-1.5 text-gray-700">Priority</label>
              <select
                value={priority}
                onChange={(e) => setPriority(e.target.value)}
                className="block text-[#0d75b5] flex justify-end w-[180px] py-2 border-transparent rounded focus:outline-transparent"
              >
                <option value="Low">Low</option>
                <option value="Medium">Medium</option>
                <option value="High">High</option>
                <button
                  onClick={() => setPriority("")}
                  className="mt-1 text-blue-500"
                >
                  Clear
                </button>
              </select>
            </div>

            {/* Due Date Picker */}
            <div className="mb-4 flex justify-between">
              <label className="pt-1.5 text-gray-700">Due Date</label>
              <DatePicker
                selected={dueDate}
                onChange={(date) => setDueDate(date)}
                className="block flex justify-end w-[180px] py-2 border-transparent rounded focus:border-transparent"
                showTimeSelect
                dateFormat="Pp"
              />
            </div>

            {/* Repeat Dropdown */}
            <div className="mb-4 flex justify-between">
              <label className="pt-1.5 text-gray-700">Repeat</label>
              <select
                value={repeat}
                onChange={(e) => setRepeat(e.target.value)}
                className="block flex text-[#bfc6d4] justify-end w-[180px] py-2 border-transparent rounded  focus:border-transparent"
              >
                <option>Does not repeat</option>
                <option>Daily</option>
                <option>Weekly on Tuesday</option>
                <option>Annually on November 12</option>
                <option>Every weekday (Monday to Friday)</option>
                <option>Custom</option>
              </select>
            </div>

            {/* Assignees */}
            <div className="mb-4 flex justify-between">
              <label className="pt-1.5 text-gray-700">Assignees</label>
              <select className="block flex justify-end w-[180px] py-2 border-transparent rounded focus:border-transparent">
                <option>arab khan</option>
              </select>
            </div>

            {/* Site Field (opens overlay modal) */}
            <div className="mb-4 flex justify-between">
              <label className="pt-1.5 text-gray-700">Site</label>
              <button
                onClick={() => setShowSiteModal(true)}
                className="block flex  text-[#bfc6d4] justify-end w-[180px] py-2 border-transparent rounded focus:border-transparent"
              >
                Add site
              </button>
            </div>

            {/* Asset Field (opens small modal) */}
            <div className="mb-4 flex justify-between">
              <label className="pt-1.5 text-gray-700">Asset</label>
              <button
                onClick={() => setShowAssetModal(true)}
                className="block  text-[#bfc6d4] flex justify-end w-[180px] py-2 border-transparent rounded focus:border-transparent"
              >
                Add asset
              </button>
            </div>

            {/* Labels Field (opens similar modal as asset) */}
            <div className="mb-4 flex justify-between">
              <label className="pt-1.5 text-gray-700">Labels</label>
              <button
                onClick={() => setShowLabelModal(true)}
                className="block flex  text-[#bfc6d4] justify-end w-[180px] py-2 border-transparent rounded focus:border-transparent"
              >
                Add labels
              </button>
            </div>

            {/* Create and Cancel Buttons */}
            <div className="flex justify-end mt-5">
              <button
                onClick={() => setCreateDropdownOpen(false)}
                className="px-4 py-2 mr-2 text-[#6559ff] border border-[#bfc6d4] rounded-lg hover:bg-gray-100"
              >
                Cancel
              </button>
              <button className="px-4 py-2 bg-[#6559ff] text-white rounded-lg hover:bg-[#7470f5]">
                Create
              </button>
            </div>
          </div>

          {/* Site Modal Overlay */}
          {showSiteModal && (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
              <div className="bg-white p-5 w-80 rounded-lg">
                <div className="flex items-center border border-gray-300 rounded-md px-2 py-1">
                  <FaSearch className="text-gray-500" />
                  <input
                    type="text"
                    placeholder="Search users"
                    className="ml-2 py-1 outline-none bg-transparent"
                  />
                </div>
                <p className="font-semibold text-center mt-8">No sites</p>
                <p className="text-gray-600 text-center text-sm">
                  You are not member of any site,
                </p>
                <div className="flex justify-end mt-8">
                  <button
                    onClick={() => setShowSiteModal(false)}
                    className="px-4 py-2 mr-2 text-[#6559ff] border border-[#bfc6d4] rounded-lg hover:bg-gray-100 mr-2"
                  >
                    Clear Selection
                  </button>
                  <button
                    onClick={() => setShowSiteModal(false)}
                    className="px-4 py-2 bg-[#6559ff] text-white rounded-lg hover:bg-[#7470f5]"
                  >
                    Done
                  </button>
                </div>
              </div>
            </div>
          )}

          {/* Asset Modal Overlay */}
          {showAssetModal && (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
              <div className="bg-white p-5 w-80 rounded-lg">
                <p></p>
                <p
                  className="text-end mb-2 cursor-pointer"
                  onClick={() => setShowAssetModal(false)}
                >
                  X
                </p>
                <div className="flex items-center border border-gray-300 rounded-md px-2 py-1">
                  <FaSearch className="text-gray-500" />
                  <input
                    type="text"
                    placeholder="Search users"
                    className="ml-2 py-1 outline-none bg-transparent"
                  />
                </div>
                <p className="font-semibold text-center mt-7">
                  No assets avalaible
                </p>
                <p className="text-gray-600 text-center text-sm">
                  There are no assets in you organization
                </p>
                <p className="text-[#6559ff] text-center text-sm">Learn more</p>
              </div>
            </div>
          )}

          {/* Labels Modal Overlay */}
          {showLabelModal && (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
              <div className="bg-white p-5 w-80 rounded-lg">
                <div className="flex items-center border border-gray-300 rounded-md px-2 py-1">
                  <FaSearch className="text-gray-500" />
                  <input
                    type="text"
                    placeholder="Search users"
                    className="ml-2 py-1 outline-none bg-transparent"
                  />
                </div>
                <p className=" text-start mt-7">
                  There are no action labels. Organize your actions further with
                  customizable labels.
                </p>
                <p
                  className="text-[#6559ff] text-center text-sm mt-2 cursor-pointer"
                  onClick={() => navigate("/dashboard/action-settings")}
                >
                  Add action labels
                </p>
                <div className="flex justify-end mt-6">
                  <button
                    onClick={() => setShowLabelModal(false)}
                    className="px-4 py-2 mr-2 text-[#6559ff]  rounded-lg hover:bg-gray-100 mr-2"
                  >
                    Clear Selection
                  </button>
                  <button
                    onClick={() => setShowLabelModal(false)}
                    className="px-4 py-2 bg-[#6559ff] text-white rounded-lg hover:bg-[#7470f5]"
                  >
                    Done
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Actions;
