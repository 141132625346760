import FlexContent from "../../../shared/flexContent";
import SensorsImage from "../../../assets/manufacturing/Sensors_monitoring_mobile.webp";
import { useTranslation } from "react-i18next";
const Keepfoodfresh = () => {
  const { t } = useTranslation();
  return (
    <div className=" py-24">
      <FlexContent
        heading={t("manufacturingpage.Keepfoodfreshheading")}
        para={t("manufacturingpage.Keepfoodfreshpara")}
        reverse
        italicText={t("manufacturingpage.Keepfoodfreshitalic")}
        name={t("manufacturingpage.Keepfoodfreshname")}
        position={t("manufacturingpage.Keepfoodfreshposition")}
        img={SensorsImage}
      />
    </div>
  );
};
export default Keepfoodfresh;
