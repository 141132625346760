import React, { useState } from "react";

const CustomizeLabelsModal = ({ setCustomizelabel, onClose }) => {
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [selectedRegion, setSelectedRegion] = useState("");
  const [selectedArea, setSelectedArea] = useState("");
  const [selectedSite, setSelectedSite] = useState("");



  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-30">
      <div className="bg-white rounded-lg shadow-lg w-full md:w-[50%]  mx-4 p-6">
        <h2 className="text-xl font-semibold text-gray-800 text-start">Customize site labels</h2>
        <p className="text-sm text-gray-500 mt-1 text-start">
          Use labels that match the terminology used in your organization. They’ll be updated everywhere across SafetyCulture.
        </p>

        <form className="mt-4 space-y-4 text-start">
          <div>
            <label className="text-gray-600 text-sm font-medium">Country</label>
            <select
              className="mt-1 block w-full border border-gray-300 rounded-lg py-2 px-3 text-gray-700 focus:outline-none focus:ring focus:ring-purple-500 focus:border-purple-500"
              value={selectedCountry}
              onChange={(e) => setSelectedCountry(e.target.value)}
            >
              <option value="">Select Country</option>
              <option value="USA">USA</option>
              <option value="Canada">Canada</option>
            </select>
          </div>

          <div>
            <label className="text-gray-600 text-sm font-medium">State</label>
            <select
              className="mt-1 block w-full border border-gray-300 rounded-lg py-2 px-3 text-gray-700 focus:outline-none focus:ring focus:ring-purple-500 focus:border-purple-500"
              value={selectedState}
              onChange={(e) => setSelectedState(e.target.value)}
            >
              <option value="">Select State</option>
              <option value="California">California</option>
              <option value="Texas">Texas</option>
            </select>
          </div>

          <div>
            <label className="text-gray-600 text-sm font-medium">Region</label>
            <select
              className="mt-1 block w-full border border-gray-300 rounded-lg py-2 px-3 text-gray-700 focus:outline-none focus:ring focus:ring-purple-500 focus:border-purple-500"
              value={selectedRegion}
              onChange={(e) => setSelectedRegion(e.target.value)}
            >
              <option value="">Select Region</option>
              <option value="North">North</option>
              <option value="South">South</option>
            </select>
          </div>

          <div>
            <label className="text-gray-600 text-sm font-medium">Area</label>
            <select
              className="mt-1 block w-full border border-gray-300 rounded-lg py-2 px-3 text-gray-700 focus:outline-none focus:ring focus:ring-purple-500 focus:border-purple-500"
              value={selectedArea}
              onChange={(e) => setSelectedArea(e.target.value)}
            >
              <option value="">Select Area</option>
              <option value="Urban">Urban</option>
              <option value="Rural">Rural</option>
            </select>
          </div>

          <div>
            <label className="text-gray-600 text-sm font-medium">Site</label>
            <input
              type="text"
              className="mt-1 block w-full border border-gray-300 rounded-lg py-2 px-3 text-gray-700 focus:outline-none focus:ring focus:ring-purple-500 focus:border-purple-500"
              placeholder="Site"
              value={selectedSite}
              onChange={(e) => setSelectedSite(e.target.value)}
            />
          </div>
        </form>

        <div className="flex justify-between mt-6">
          <button
            type="button"
            className="text-sm text-gray-400 hover:underline focus:outline-none"
            onClick={() => {
              setSelectedCountry("");
              setSelectedState("");
              setSelectedRegion("");
              setSelectedArea("");
              setSelectedSite("");
            }}
          >
            Reset to default
          </button>
          <div className="flex space-x-2">
            <button
              type="button"
              className="text-sm font-medium text-gray-500 border border-gray-300 rounded-md px-4 py-2 hover:bg-gray-100 focus:outline-none"
              onClick={()=>setCustomizelabel(false)}
            >
              Cancel
            </button>
            <button
              type="button"
              className="text-sm font-medium text-white bg-[#6559ff] rounded-md px-4 py-2 hover:bg-[#4740d4] focus:outline-none"
            >
              Update labels
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomizeLabelsModal;
