import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import InspectionTab from "./inspectionTab";
import Taskstab from "./tasksTab";
import AssetsTab from "./assetsTab";
import TrainingTab from "./trainingtab";
import IssuesTab from "./issues";
import CommunicationTab from "./communicationTab";
import AnalyticTab from "./analyticsTabs";
import IntegrationTab from "./integrations";

const TabsSection = () => {
  const { t } = useTranslation();
  const isArabic = i18next.language === "ar";
  const [activeTab, setActiveTab] = useState("inspections");
  const [sticky, setSticky] = useState(false);
  const tabRef = useRef(null);

  const tabs = [
    { name: t("platformpage.btnname1"), id: "inspections" },
    { name: t("platformpage.btnname2"), id: "tasks" },
    { name: t("platformpage.btnname3"), id: "assets" },
    { name: t("platformpage.btnname4"), id: "issues" },
    { name: t("platformpage.btnname5"), id: "headsup" },
    { name: t("platformpage.btnname6"), id: "analytics" },
    { name: t("platformpage.btnname7"), id: "integrations" },
  ];

  const handleScroll = () => {
    const tabTop = tabRef.current?.offsetTop;
    if (window.scrollY > tabTop) {
      setSticky(true);
    } else {
      setSticky(false);
    }

    const scrollPosition = window.scrollY;
    tabs.forEach((tab) => {
      const section = document.getElementById(tab.id);
      if (
        section &&
        section.offsetTop <= scrollPosition + 100 &&
        section.offsetTop + section.offsetHeight > scrollPosition + 100
      ) {
        setActiveTab(tab.id);
      }
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      const section = document.querySelector(hash);
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, []);

  const handleTabClick = (id) => {
    setActiveTab(id);
    const tab = tabs.find((tab) => tab.id === id);
    document.getElementById(tab.id).scrollIntoView({
      behavior: "smooth",
    });
  };

  return (
    <>
      {/* Tab Header */}
      <div
        ref={tabRef}
        className={`mt-16 mb-10 ${
          sticky
            ? "fixed top-0 left-0 z-20 w-full shadow-sm mt-24"
            : "mx-auto rounded-xl w-fit px-2"
        } bg-[#f3f6fb]`}
      >
        <div className="container mx-auto overflow-auto scrollbar-hide">
          <div className={`${isArabic ? "text-right rtl" : "text-left"}`}>
            <div className="flex items-center justify-center px-2 py-4 space-x-4 flex-wrap">
              {tabs.map((tab) => (
                <button
                  key={tab.id}
                  onClick={() => handleTabClick(tab.id)}
                  className={`px-4 py-2 rounded-xl whitespace-nowrap text-[.875rem] font-[600] 
                  ${
                    activeTab === tab.id
                      ? "bg-[#6559ff] text-white hover:bg-[#6559ff] hover:text-white"
                      : "text-[#6559ff] border border-purple-200"
                  }
                  transition-colors duration-300 hover:bg-[#f0f0ff] hover:text-[#6559ff]`}
                >
                  {tab.name}
                </button>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* Tab Sections */}
      <section id="inspections" className="my-16">
        <InspectionTab />
      </section>
      <section id="tasks" className="my-16">
        <Taskstab />
      </section>
      <section id="assets" className="my-16">
        <AssetsTab />
      </section>
      <section id="issues" className="my-16">
        <IssuesTab />
      </section>
      <section id="headsup" className="my-16">
        <CommunicationTab />
      </section>
      <section id="analytics" className="my-16">
        <AnalyticTab />
      </section>
      <section id="integrations" className="my-16">
        <IntegrationTab />
      </section>
    </>
  );
};

export default TabsSection;
