import React from "react";
import Icon1 from "../../../assets/assets/icon1.webp";
import Icon2 from "../../../assets/assets/icon2.webp";
import Icon3 from "../../../assets/assets/icon3.webp";
import Icon4 from "../../../assets/assets/icon4.webp";
import Icon5 from "../../../assets/assets/icon5.webp";
import Icon6 from "../../../assets/assets/icon6.webp";

const featuresCards = [
  {
    title: "Sharing information",
    description:
      "Connect your frontline to the information they need to do their work.",
    image: Icon1,
    description:
      "Connect your frontline to the information they need to do their work.",
    image: Icon1,
  },
  {
    title: "Preventative Maintenance",
    description:
      "Stay on top of maintenance activities and avoid any unplanned downtime.",
    description:
      "Stay on top of maintenance activities and avoid any unplanned downtime.",
    image: Icon2,
  },
  {
    title: "Safety checks",
    description:
      "Empower teams to perform in-depth, safety and pre-start inspections on assets.",
    description:
      "Empower teams to perform in-depth, safety and pre-start inspections on assets.",
    image: Icon3,
  },
  {
    title: "Quality control",
    description:
      "Capture quality control data to ensure the reliability and performance of your assets.",
    description:
      "Capture quality control data to ensure the reliability and performance of your assets.",
    image: Icon4,
  },
  {
    title: "Maintaining compliance",
    description:
      "View a secure, up-to-date audit trail of all inspections and tasks completed on your assets.",
    description:
      "View a secure, up-to-date audit trail of all inspections and tasks completed on your assets.",
    image: Icon5,
  },
  {
    title: "Resource planning",
    description:
      "Know where your assets are, how often they are inspected, and what areas need attention.",
    description:
      "Know where your assets are, how often they are inspected, and what areas need attention.",
    image: Icon6,
  },
];

const Features = () => {
  // return (
  //   <div className=" px-4 md:px-16 py-28 bg-[#F3F6FB]">
  //     <h2 className="text-center text-xl md:text-3xl lg:text-5xl font-bold text-gray-800 mb-12">
  //       Use SafetyCulture Asset Management Software for
  //     </h2>
  //     <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">
  //       {featuresCards.map((feature, index) => (
  //         <div key={index} className="text-center">
  //           <div className=' p-1 rounded-xl inline-block'>
  //           <img
  //             src={feature.image}
  //             alt={feature.title}
  //             className="w-16 h-16 mx-auto  "
  //           />
  //           </div>
  //           <h3 className="text-xl md:text-2xl font-bold text-[#293745] mb-2">
  //             {feature.title}
  //           </h3>
  //           <p className="text-gray-600 pt-5">{feature.description}</p>
  //         </div>
  //       ))}
  //     </div>
  //   </div>
  // );
};

export default Features;
