import StatsSection from "../../../shared/stateSection";
const States=()=>{
    const data = [
        {
          value: "50%",
          description: "improved efficiency in training*",
          borderColor: "border-[#6559FF]",
        },
        {
          value: "60%",
          description: "increased audit efficiency*",
          borderColor: "border-cyan-500",
        },
        {
          value: "49%",
          description: "savings from safety & compliance improvements*",
          borderColor: "border-yellow-500",
        },
      ];
    return(
        <StatsSection data={data}/>
    )
}
export default States;