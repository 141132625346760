import React, { useState } from "react";
import { FiSearch, FiTrash2, FiPlus, FiMoreVertical } from "react-icons/fi";
import { FaEdit } from "react-icons/fa";
import { MdFileUpload } from "react-icons/md";
const GlobalresponseModal = ({ setIsGlobalOpen, onClose }) => {
  const [showResponseInput, setShowResponseInput] = useState(false);
  const [showSettings, setShowSettings] = useState(false);
  const SettingsHandler = () => {
    if (showSettings === false) {
      setShowSettings(true);
    } else {
      setShowSettings(false);
    }
  };
  return (
    <div className="fixed inset-0 z-50 flex justify-end">
      {/* Overlay */}
      <div className="absolute inset-0 right-[-1300px] z-50 mt-5 ">
        {" "}
        <button onClick={() => SettingsHandler()}>
          <FiMoreVertical className=" text-gray-600 text-2xl" />
        </button>
      </div>

      {/* Modal Content */}
      <div className="relative bg-white w-full max-w-md h-full shadow-lg flex flex-col px-6 py-14 mt-10">
        {/* Header */}
        <div className="flex items-center justify-between mb-4">
          <h2 className="text-xl font-semibold">Untitled response set</h2>
          <button onClick={onClose}>
            <FaEdit className="text-gray-600" />
          </button>
        </div>

        {/* Search Input */}
        <div className="relative mb-4">
          <FiSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
          <input
            type="text"
            placeholder="Search"
            className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>

        {/* New Response Input (conditionally rendered) */}
        {showResponseInput && (
          <div className="relative mb-4 flex items-center">
            <input
              type="text"
              placeholder="New response"
              className="w-full px-3 py-2 border border-blue-500 rounded-md focus:outline-none"
            />
            <button onClick={() => setShowResponseInput(false)}>
              <FiTrash2 className="text-gray-500 ml-2 hover:text-red-600" />
            </button>
          </div>
        )}
        {showSettings && (
          <div className="relative mb-4 ">
            <div className="abolute mt-[-130px] right-5  bg-white p-4 shadow-md w-fit">
              <p className="flex text-gray-800 pb-1">
                <MdFileUpload className="me-2 " />
                Upload response as CSV
              </p>
              <p className="flex text-gray-800">
                <FiTrash2 className="me-2" />
                Delete
              </p>
            </div>
          </div>
        )}

        {/* Footer Buttons */}
        <div className="flex flex-col justify-start space-y-2 mt-auto">
          {/* Add Response Button */}
          <button
            onClick={() => setShowResponseInput(true)}
            className="text-[#6559ff] font-medium text-sm flex items-center mb-4 border-t-2 border-b-2 py-3"
          >
            <FiPlus className="mr-1 text-[#6559ff]" /> Add response
          </button>
          <div className="flex">
            <button
              onClick={() => setIsGlobalOpen(false)}
              type="button"
              className="text-sm me-3 font-medium cursor-pointer text-gray-500 border border-gray-300 rounded-md px-4 py-3 hover:bg-gray-100 focus:outline-none"
            >
              Cancel
            </button>
            <button
              type="button"
              className="text-sm font-medium text-white cursor-pointer bg-[#6559ff] rounded-md px-4 py-3  focus:outline-none"
            >
              Save and apply
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GlobalresponseModal;
