import React from "react";
import { RiPuzzleFill, RiStarFill } from "react-icons/ri";
import { FiArrowRight, FiArrowLeft } from "react-icons/fi";
import { MdOutlineSensors } from "react-icons/md";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { useNavigate } from "react-router-dom";

const MoreFeatures = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isArabic = i18next.language === "ar";

  const featuresData = [
    {
      icon: <MdOutlineSensors size={26} />,
      key: "features1",
      link: "/inspection",
    },
    {
      icon: <RiPuzzleFill size={22} />,
      key: "features2",
      link: "/integrations",
    },
    {
      icon: <RiStarFill size={22} />,
      key: "features6",
      link: "/assets",
    },
    {
      key: "features3",
      link: "/manufacturing",
    },
    {
      key: "features4",
      link: "/lone-worker",
    },
    {
      key: "features5",
      link: "/facilities-management",
    },
  ];

  return (
    <div className={isArabic ? "text-right rtl" : "text-left"}>
      <div className="container mx-auto py-8 px-4">
        {/* Title */}
        <h2 className="text-center text-[#293745] text-3xl font-bold mb-10">
          {t("homepage.moreFeaturesTitle")}
        </h2>

        {/* Features Grid */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {featuresData.map((feature, index) => (
            <div
              key={index}
              className="border-2 border-gray-300 rounded-lg p-4 flex justify-between items-center hover:border-[#6559ff] transition-all duration-300"
            >
              {/* Feature Icon and Title */}
              <div className="flex items-center space-x-3">
                {feature.icon && (
                  <div
                    className="text-[#293745]"
                    aria-label={t(`homepage.${feature.key}.iconDescription`)}
                  >
                    {feature.icon}
                  </div>
                )}
                <h3
                  className="text-lg font-bold text-[#293745] cursor-pointer"
                  onClick={() => navigate(feature.link)}
                >
                  {t(`homepage.${feature.key}.title`)}
                </h3>
              </div>

              {/* Directional Arrow */}
              <span className="font-semibold text-[#6559ff]">
                {isArabic ? <FiArrowLeft /> : <FiArrowRight />}
              </span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MoreFeatures;
