import React from "react";
import AustilianOpenImg from "../../assets/home/Digital.jpg";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

const HeroSection = () => {
  const isArabic = i18next.language === "ar";
  const { t } = useTranslation();

  return (
    <div className="relative w-full h-auto">
      {/* Background Image */}
      <div className="absolute inset-0">
        <img
          className="w-full h-full object-cover"
          src={AustilianOpenImg}
          alt={t("homepage.herosectionalt")}
          aria-label={t("homepage.herosectionalt")}
        />
        <div
          className="absolute inset-0 bg-black opacity-40"
          role="img"
          aria-label={t("homepage.herosectionoverlay")}
        ></div>
      </div>

      {/* Content */}
      <div
        className={` ${isArabic ? "text-right" : "text-left"} ${
          isArabic ? "rtl" : ""
        }`}
      >
        <div className="relative z-10 flex items-center justify-start px-6 py-12 lg:py-20">
          <div className="text-white max-w-2xl lg:max-w-4xl xl:max-w-6xl">
            {/* Quote Icon */}
            <div className="text-yellow-400 text-4xl md:text-5xl lg:text-6xl">
              <span className="inline-block">&ldquo;</span>
            </div>

            {/* Hero Description */}
            <blockquote className="text-lg md:text-xl lg:text-2xl font-semibold mt-4">
              {t("homepage.herosectiondescr1")}
            </blockquote>

            {/* Author Details */}
            <p className="mt-6 text-sm md:text-base font-light">
              <strong>{t("homepage.section1name")}</strong>
            </p>
            <p className="mt-2 text-sm md:text-base font-light">
              {t("homepage.section1designation")}
            </p>

            {/* Call-to-Action Button */}
            {/* <button className="mt-8 bg-indigo-500 text-xs md:text-base hover:bg-indigo-600 text-white font-medium py-2 px-4 rounded-lg transition-all duration-300">
              {t("homepage.section1button")}
            </button> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
