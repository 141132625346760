const CustomerDetailPage = () => {
  return (
    <div className="flex flex-col items-center  py-8 mt-10">
      {/* Logo and Title Section */}
      <div className="flex flex-col items-center text-center">
        <img
          src={require("../../assets/customerImages/3.png")}
          alt="Thermosash Logo"
          className="w-72 h-32 max-w-[400px] mx-auto rounded-[20px] shadow-xl p-5 mb-5  object-contain"
        />
        <h2 className="text-4xl font-extrabold text-gray-800 mb-4 mt-8">
          Sodexo
        </h2>
        <p className="text-xl text-center text-gray-600 max-w-5xl mx-auto">
          Sodexo is a global leader in food and facilities management, working
          with 33,000 organizations in 45 countries. Here’s how they achieve
          operational excellence by digitizing inspections, training, and
          temperature monitoring using SafetyCulture.
        </p>
      </div>

      {/* Statistics Section */}
      <div className="flex flex-col sm:flex-row items-center justify-center gap-8 mt-20 text-center max-w-5xl">
        <div className="flex items-center mt-4">
          {" "}
          {/* Added mt-4 for top margin */}
          <span className="text-blue-600 text-lg">✔️</span>
          <p className="text-gray-700 text-base">
            <span className="font-bold ">8,000+ hours saved</span> across the
            organization
          </p>
        </div>
        <div className="flex items-center mt-4">
          {" "}
          {/* Added mt-4 for top margin */}
          <span className="text-blue-600 text-lg ">✔️</span>
          <p className="text-gray-700 text-base">
            <span className="font-bold">30+ HACCP logs digitized</span>, saving
            resources like paper and storage
          </p>
        </div>
        <div className="flex items-center mt-4">
          {" "}
          {/* Added mt-4 for top margin */}
          <span className="text-blue-600 text-lg">✔️</span>
          <p className="text-gray-700 text-base">
            <span className="font-bold">83,000+ pages</span> of paper saved
          </p>
        </div>
      </div>

      {/* Image Section */}
      <img
        src={require("../../assets/customerImages/8.png")}
        alt="Thermosash Logo"
        className=" h-96 rounded-[200px] mx-3 shadow-xl mb-5  object-contain mt-20"
      />

      {/* Tags Section */}

      <div className="flex flex-col items-center mt-6 max-w-5xl mx-auto">
        {/* Heading with responsive text size */}
        <h2 className="text-lg sm:text-xl lg:text-2xl font-semibold mb-4 text-center">
          We use SafetyCulture for
        </h2>

        {/* Tags section with responsive spacing */}
        <div className="flex flex-wrap justify-center gap-3 sm:gap-4 lg:gap-6 mt-4">
          {[
            "Actions",
            "Analytics",
            "Assets",
            "Heads Up",
            "Inspections",
            "Issues",
            "SafetyCulture Platform",
          ].map((tag, index) => (
            <a
              href="#"
              key={index}
              className="px-3 py-1 sm:px-4 sm:py-2 bg-gray-100 rounded-full text-gray-700 font-medium text-sm sm:text-base"
            >
              {tag}
            </a>
          ))}
        </div>
      </div>
      <div className="flex flex-col items-center max-w-5xl mx-auto p-4 mt-20">
        {/* Text Section */}
        <div className="text-center mb-4">
          <p className="text-lg text-left sm:text-sm text-gray-800">
            As a global leader in food and facilities management, Sodexo works
            with 33,000 organizations in 45 countries. They directly interact
            with over 80 million consumers every day. If you’ve ever visited the
            Eiffel Tower, a major stadium, a daycare, a hospital, a university,
            or a retirement home—you’ve probably been served by a Sodexo
            operator in some way.
          </p>
        </div>

        {/* Video/Image Section */}
        <div className="relative w-full max-w-4xl mb-4">
          <img
            src="path_to_your_image_or_video_thumbnail" // Replace with the image/video thumbnail source
            alt="Sodexo Market"
            className="w-full rounded-lg"
          />
          <div className="absolute inset-0 flex items-center justify-center">
            <button className="bg-blue-600 text-white rounded-full p-4 focus:outline-none">
              <span className="text-2xl">▶</span> {/* Play Icon */}
            </button>
          </div>
        </div>

        {/* Additional Text Section */}
        <div className="text-center">
          <p className="text-lg text-left sm:text-sm text-gray-800">
            The Sodexo mission runs deeper than safely providing food services
            and facilities management. They’re committed to real improvements,
            day after day, for millions of people, which can have a big impact
            on individuals, society, and the planet.
          </p>
        </div>
      </div>
      <div className="max-w-5xl mx-auto p-4">
        <h1 className="text-5xl font-bold mb-4 mt-4 text-left">
          Struggling with consistency
        </h1>
        <p className="mb-4 text-base text-left text-black mt-8">
          Sodexo’s journey with SafetyCulture started with a golden question:
          How do you ensure consistency across thousands of sites? Sodexo needed
          a digital system that could help deliver a more consistent experience
          for its workers and customers across the globe.
        </p>
        <p className="mb-4 text-base text-left text-black mt-8">
          Sarah Porter, Vice President, Corporate Services Operational
          Excellence at Sodexo, rose to the challenge. She pulled together a
          group that could help drive digitization changes in US food service
          sites. The team included Catherine Cuticelli, District Manager,
          Allison Pietrucha, Director of Retail Implementation at Sodexo, and
          Mark Price, Senior Vice President at Sodexo.
        </p>
        <p className="mb-4 text-base text-left text-black mt-8">
          With a need for good structure, good resources, and good systems, they
          looked to the SafetyCulture platform to help them achieve operational
          excellence—implementing inspections, heads-up, assets, analytics,
          training, sensors, and more.
        </p>
        <p className="mb-4 text-base text-left text-black mt-8">
          Until 2018, Sodexo relied on in-person or Facetime visits, and staff
          members completed inspections and checks via spreadsheets and (often)
          outdated documents. There were frequent new or brand rollouts across
          hundreds of sites, but there was little room for feedback from the
          teams on the frontline. Consistency was a struggle due to a heavy load
          on manual processes and a lack of oversight.
        </p>
        <p className="mb-4 text-base text-left text-black mt-8">
          A few things stood in the way of achieving the operational excellence
          they strove for. They simplified their goals to three simple measures
          of success: making the lives of unit managers easier and more
          effective, improving competitiveness in the market, and maximizing
          commercial results.
        </p>
        <blockquote className="border-l-4 border-blue-500 pl-4 italic my-4">
          <p className="mb-4 text-base text-center text-black mt-8">
            You really have to rely on good structure, good resources, and good
            systems to make it work successfully.
          </p>
        </blockquote>
        <div className=" p-4 mb-4">
          <h2 className="text-lg text-center font-semibold">
            Catherine Cuticelli
          </h2>
          <p className="text-base mt-5 text-center text-black">
            District Manager at Sodexo
          </p>
        </div>
      </div>
      <div className="max-w-5xl mx-auto p-4">
        <h1 className="text-5xl font-bold mb-4 mt-4 text-left">
          Streamlining food safety regulation records
        </h1>
        <p className="mb-4 text-base text-left text-black mt-8">
          As a food provider, some regulations have to be met at the federal,
          state, and government levels. HACCP (hazard analysis and critical
          control points) is one of them. This essential food safety program in
          the United States ensures that food is safely handled at every step,
          from procurement to plate, allowing providers to serve their guests
          with confidence.
        </p>
        <p className="mb-4 text-base text-left text-black mt-8">
          Pre-SafetyCulture, it was common practice for Sodexo workers to fill
          out HACCP logs on paper, which would be stuck to the fridges, and
          collect coffee rings or sauce splatters throughout the month. By
          reporting time, they were nearly unreadable. Once filed away, leaders
          like Sarah couldn’t track potential trends and had restricted
          oversight of site compliance.
        </p>
        <p className="mb-4 text-base text-left text-black mt-8">
          Sodexo used Inspections to digitize the HACCP process. Frontline
          workers such as chefs, supervisors, and general managers could now
          investigate food safety issues, pinpoint the cause instantly, and
          proactively address potential incidents.
        </p>
        <p className="mb-4 text-base text-left text-black mt-8">
          Sodexo has now digitized over 30 HACCP logs in 40% of its sites and
          saved over 83,000 paper pages in the process. Having this information
          readily available for regulators also alleviates potential downtime
          and closes the gap between frontline workers and management, making
          the lives of unit managers easier.
        </p>
        <p className="mb-4 text-base text-left text-black mt-8">
          Inspections are also used for allergen signage, delivery receiving
          logs, and many other checks. They all contribute to the 250,000+
          inspections Sodexo workers have done since starting with
          SafetyCulture. Now, every worker is responsible for consistency.
        </p>
      </div>
      <div className="max-w-5xl mx-auto p-4">
        <h1 className="text-5xl font-bold mb-4 mt-4 text-left">
          Going above and beyond by automating and streamlining
        </h1>
        <p className="mb-4 text-base text-left text-black mt-8">
          “We go above and beyond what’s required to ensure guest safety and the
          best experience every day” – Sarah Porter, Vice President, Corporate
          Services Operational Excellence at Sodexo.
        </p>
        <p className="mb-4 text-base text-left text-black mt-8">
          Management saw immediate benefits, like real-time site visibility,
          after implementing the SafetyCulture platform. They wanted to take it
          to the next level. Sarah and her team knew that automating and
          streamlining manual tasks would help drastically reduce the
          administrative load.
        </p>
        <p className="mb-4 text-base text-left text-black mt-8">
          If a fridge or freezer failed or was left open accidentally, it could
          cause a significant drop in temperature without notice, and food would
          go to waste. Sensors were introduced to automate temperature
          monitoring of their fridges and freezers.
        </p>
        <p className="mb-4 text-base text-left text-black mt-8">
          Fridges, freezers, and other kitchen or retail equipment can be set up
          as Assets. From there, managers can scan a QR code and complete an
          Inspection, take the Sensor temperature recording, and answer specific
          questions to check that the equipment is running correctly.
        </p>
        <p className="mb-4 text-base text-left text-black mt-8">
          Over 950 Sensors and 6,216 Assets have been deployed at 306 Sodexo
          sites. They’re helping reduce stock loss to nearly zero at every
          deployed site.
        </p>
        <p className="mb-4 text-base text-left text-black mt-8">
          Using the SafetyCulture platform, Sarah has real-time on-the-ground
          monitoring and visibility from Sodexo HQ. She can take the
          administrative load and determine what needs to be done to maintain
          workers’ and guests’ health and safety so that the frontline workers
          can focus on their core.
        </p>
        <blockquote className="border-l-4 border-blue-500 pl-4 italic my-4">
          <p className="mb-4 text-base text-center text-black mt-8">
            Our operators are the heartbeat of what we do. They’re meeting
            different demands, and their needs vary, but there is one consistent
            theme. They’re time-starved. We can give them the gift of time
            through SafetyCulture.
          </p>
        </blockquote>
        <div className=" p-4 mb-4">
          <h2 className="text-lg text-center font-semibold">
            Allison Pietrucha
          </h2>
          <p className="text-base mt-5 text-center text-black">
            Director of Retail Implementation at Sodexo
          </p>
        </div>
      </div>
      <div className="max-w-5xl mx-auto p-4">
        <h1 className="text-5xl font-bold mb-4 mt-4 text-left">
          Standardizing brand rollout through Training
        </h1>
        <p className="mb-4 text-base text-left text-black mt-8">
          Without a unified training tool, Sodexo found it difficult to monitor
          who had completed training on brand rollouts, menu offerings, and
          guest experiences. Because they worked across both East Coast and West
          Coast time zones, a single training session that captured everyone at
          once wasn’t feasible or accessible. Once again, Sodexo was up against
          the golden question.
        </p>
        <p className="mb-4 text-base text-left text-black mt-8">
          With thousands of sites to reach and many more staff to train, they
          introduced SafetyCulture’s Training feature to centralize all training
          resources. Allison broke down this lofty goal into a three-stage
          milestone approach. “It’s a way to make big tasks feel less
          overwhelming and more achievable,” she says.
        </p>
        <p className="mb-4 text-base text-left text-black mt-8">
          This approach empowered workers on the frontline to take training,
          digest the learnings, and provide feedback. It propelled Allison,
          Sarah, and senior management into a continuous improvement mindset
          cycle.
        </p>
        <p className="mb-4 text-base text-left text-black mt-8">
          With training in the SafetyCulture mobile app in every worker’s hands,
          over 6,700 courses have been completed, and managers have quick access
          to training and operational data. It’s given Allison and Sarah
          confidence that each brand is up to the Sodexo standard. The
          experience would be the same if you walked into a site in California,
          Connecticut, or Florida.
        </p>
        <p className="mb-4 text-base text-left text-black mt-8">
          And for the most important messages, Sarah credits the Heads Up
          feature to reach everyone she needs as fast as possible. “Heads Up is
          a power I take very seriously. When I send a Heads Up, everyone knows
          they need to lift their heads and look at the message,” Sarah says.
          “If we have a global outage, we can quickly reach our people with a
          solution, and they’ll need to acknowledge it—even the President. And
          you know if the President acknowledges these messages, it sets the
          standard for everyone else,” she adds.
        </p>
        <p className="mb-4 text-base text-left text-black mt-8">
          Using the SafetyCulture platform has saved Sodexo over 8,000
          management hours by smoothing the training system, improving internal
          communications, and making the platform accessible to everyone.
        </p>
        <p className="mb-4 text-base text-left text-black mt-8">
          “Our operators are the heartbeat of what we do. They’re meeting
          different demands, and their needs vary, but there is one consistent
          theme. They’re time-starved. We can give them the gift of time through
          SafetyCulture,” Allison said.
        </p>
        <blockquote className="border-l-4 border-blue-500 pl-4 italic my-4">
          <p className="mb-4 text-base text-center text-black mt-8">
            Now I can look into sites in California, Connecticut, and Florida
            while I’m having my morning coffee.
          </p>
        </blockquote>
        <div className=" p-4 mb-4">
          <h2 className="text-lg text-center font-semibold">Sarah Porter</h2>
          <p className="text-base mt-5 text-center text-black">
            Vice President, Corporate Services Operational Excellence at Sodexo
          </p>
        </div>
      </div>
      <div className="max-w-5xl mx-auto p-4">
        <h1 className="text-5xl font-bold mb-4 mt-4 text-left">
          A recipe for success
        </h1>
        <p className="mb-4 text-base text-left text-black mt-8">
          Sodexo is looking forward to cooking up more workplace operations
          improvements using the SafetyCulture platform. Although there are
          still more business units, customers, employees, and communities to
          serve globally, the future looks peachy.
        </p>
        <p className="mb-4 text-base text-left text-black mt-8">
          For Sarah, using SafetyCulture to gain visibility over her 1,000+
          sites across the US has made the scale of Sodexo’s operations feel
          much less daunting. “We’re reaching more sites than ever before.
          They’re getting the support and resources they would never have had if
          we hadn’t utilized SafetyCulture. That’s all we’re trying to do. It’s
          not going to change everything, but if we can take away some process
          they have to do every single day, we’re helping make their days just a
          little bit better.” she concludes.
        </p>
        <blockquote className="border-l-4 border-blue-500 pl-4 italic my-4">
          <p className="mb-4 text-base text-center text-black mt-8">
            The results mentioned were captured at the time of this case study
            (May 2024).
          </p>
        </blockquote>
      </div>
      <div className="container max-w-7xl mx-auto px-4 mt-20">
        <h2 className="text-lg md:text-5xl font-bold text-gray-800 text-left mb-6 mt-10">
          More customer stories
        </h2>

        {/* Cards Section */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 mt-10">
          {/* Card 1 */}
          <div className=" p-4 sm:p-6 rounded-lg shadow-md shadow-black ">
            <img
              src={require("../../assets/customerImages/1.png")}
              alt="Thermosash Logo"
              className="w-full h-32 max-w-[400px] mx-auto rounded-[20px] shadow-xl p-5 mb-5  object-contain"
            />

            <img
              src={require("../../assets/customerImages/2.png")}
              alt="Woods Glass"
              className="w-full max-w-[400px] h-50 object-cover rounded-[20px] mb-4 mx-auto"
            />
            <h4 className="text-base md:text-2xl font-bold text-gray-900">
              Woods Glass (Thermosash Group)
            </h4>
            <p className="text-sm md:text-base text-gray-800 mt-6 font-medium mb-2 leading-5 text-left">
              Quality, consistency, and safety are non-negotiable in the
              construction industry. Here's how SafetyCulture helps Thermosash
              Group build a solid foundation.
            </p>
            <a href="#" className="text-blue-600 hover:underline">
              Read story →
            </a>
          </div>

          {/* Card 2 */}
          <div className=" p-4 sm:p-6 rounded-lg shadow-md shadow-black">
            <img
              src={require("../../assets/customerImages/3.png")}
              alt="Thermosash Logo"
              className="w-full h-32 max-w-[400px] mx-auto rounded-[20px] shadow-xl p-5 mb-5  object-contain"
            />
            <img
              src={require("../../assets/customerImages/4.png")}
              alt="Thermosash Logo"
              className="w-full max-w-[400px] h-50 object-cover rounded-[20px] mb-4 mx-auto text-left"
            />
            <h4 className="text-base md:text-2xl font-bold text-gray-900">
              Sodexo
            </h4>
            <p className="text-sm md:text-base text-gray-800 mt-6 font-medium mb-2 leading-5 text-left">
              Sodexo is a global leader in food and facilities management,
              working with 33,000 organizations in 45 countries. Here's how they
              achieve operational excellence by digitizing inspections,
              training, and temperature monitoring using SafetyCulture.
            </p>
            <a href="#" className="text-blue-600 hover:underline">
              Read story →
            </a>
          </div>

          {/* Card 3 */}
          <div className=" p-4 sm:p-6 rounded-lg shadow-md shadow-black">
            <img
              src={require("../../assets/customerImages/5.png")}
              alt="Thermosash Logo"
              className="w-full h-32 max-w-[400px] mx-auto rounded-[20px] shadow-xl p-5 mb-5  object-contain"
            />
            <img
              src={require("../../assets/customerImages/6.png")}
              alt="Thermosash Logo"
              className="w-full max-w-[400px] h-50 object-cover rounded-[20px] mb-4 mx-auto text-left"
            />
            <h4 className="text-base md:text-2xl font-bold text-gray-900">
              DB Schenker
            </h4>
            <p className="text-sm md:text-base text-gray-800 mt-6 font-medium mb-2 leading-5 text-left">
              How DB Schenker rewrote the playbook on their frontline health,
              safety, and quality communications strategy with Heads Up in
              SafetyCulture.
            </p>
            <a href="#" className="text-blue-600 hover:underline">
              Read story →
            </a>
          </div>
        </div>
        <div className="text-white p-4 w-full text-center">
          <h3 className="font-bold">Premium</h3>
          <button className="mt-4 w-full md:w-72 py-2 border border-slate-300 text-indigo-600 rounded-xl hover:bg-indigo-400 hover:text-white transition-colors duration-300 mb-6 md:w-80 h-12 font-medium rounded-lg">
            Start your 30-day free trial
          </button>
        </div>
      </div>
      <div className="w-full bg-indigo-600 h-[40vh] flex flex-col justify-center items-center text-white mt-10">
        <div>
          <h1 className="text-3xl font-bold mb-2">
            Looking for more inspiration
          </h1>
        </div>
        <div>
          <h3 className="text-xl mb-4 mt-2">
            Explore more stories, expert guides, and thought leadership from
            SafetyCulture
          </h3>
        </div>
        <div className="flex space-x-4">
          <button className=" text-indigo-600 px-4 py-2 rounded-lg hover:bg-indigo-400 hover:text-white transition-colors duration-300">
            Button 1
          </button>
          <button className=" text-indigo-600 px-4 py-2 rounded-lg hover:bg-indigo-400 hover:text-white transition-colors duration-300">
            Button 2
          </button>
        </div>
      </div>
    </div>
  );
};
export default CustomerDetailPage;
