import React, { useState } from "react";
import { FiSearch, FiChevronDown, FiMoreVertical } from "react-icons/fi";
import GlobalresponseModal from "./globalResponse";

const GlobalResponseSets = () => {
  const [isGlobalOpen, setIsGlobalOpen] = useState(false);
  return (
    <div className="p-6">
      {isGlobalOpen && <GlobalresponseModal setIsGlobalOpen={setIsGlobalOpen}/>}
      <div className="flex justify-between">
        <div>
          <h1 className="text-2xl  mb-6">Global Response Sets</h1>
        </div>
        <div>
          <button
            className="px-4 py-2 bg-[#6559ff] text-white rounded-lg hover:bg-[#7470f5] "
            onClick={() => setIsGlobalOpen(true)}
          >
            + Create Global Response Set
          </button>
        </div>
      </div>

      <div className="flex items-center justify-between mb-4">
        {/* Search Input */}
        <div className="relative w-full max-w-xs">
          <FiSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
          <input
            type="text"
            placeholder="Search"
            className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>
        <div className="flex">
          <p className="text-xs text-gray-500 me-2">1 - 2 of 2 results</p>
          <FiMoreVertical className="text-gray-500 hover:text-gray-700 cursor-pointer" />
        </div>
      </div>

      {/* Table */}
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white shadow-md rounded-md overflow-hidden">
          <thead>
            <tr className="bg-gray-100">
              <th className="px-6 py-3 text-left text-sm font-semibold text-gray-600">
                Name
              </th>
              <th className="px-6 py-3 text-left text-sm font-semibold text-gray-600">
                Last modified{" "}
                <FiChevronDown className="inline ml-1 text-gray-400" />
              </th>
              <th className="px-6 py-3 text-left text-sm font-semibold text-gray-600">
                Responses
              </th>
              <th className="px-6 py-3"></th>
            </tr>
          </thead>
          <tbody>
            {[1, 2].map((_, index) => (
              <tr key={index} className="border-b text-start">
                <td className="px-6 py-4 text-sm text-gray-800">
                  Untitled response set
                </td>
                <td className="px-6 py-4 text-sm text-gray-800">7 Nov 2024</td>
                <td className="px-6 py-4 text-sm text-gray-800">0</td>
                <td className="px-6 py-4 text-right">
                  <FiMoreVertical className="text-gray-500 hover:text-gray-700 cursor-pointer" />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Pagination */}
      <div className="flex items-center justify-end mt-4 space-x-2">
        <span className="text-sm text-gray-600">1 - 2 of 2 results</span>
        <div className="flex items-center space-x-2">
          <button className="px-2 py-1 border border-gray-300 rounded-md text-gray-500 hover:bg-gray-100">
            &lt;
          </button>
          <span className="px-2 py-1 border border-gray-300 bg-gray-100 rounded-md">
            1
          </span>
          <button className="px-2 py-1 border border-gray-300 rounded-md text-gray-500 hover:bg-gray-100">
            &gt;
          </button>
        </div>
      </div>
    </div>
  );
};

export default GlobalResponseSets;
