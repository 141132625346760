import FlexContent from "../../../shared/flexContent";
import FacilitiesImage from "../../../assets/facilities/Incident-Management-Software-img-03.webp";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
const SafeWorkSpaces = () => {
  const { t } = useTranslation();
  const isArabic = i18next.language === "ar";
  return (
    <div
      className={`bg-[#F3F6FB] pt-4 pb-24 py-4 ${
        isArabic ? "text-right" : "text-left"
      } ${isArabic ? "rtl" : ""}`}
    >
      <FlexContent
        heading={t("constructionpage.SafeWorkSpacesheading")}
        para={t("constructionpage.SafeWorkSpacespara")}
        img={FacilitiesImage}
        italicText={t("constructionpage.SafeWorkSpacesitalic")}
        name={t("constructionpage.SafeWorkSpacesname")}
        position={t("constructionpage.SafeWorkSpacesposition")}
      />
    </div>
  );
};
export default SafeWorkSpaces;
