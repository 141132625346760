import CustomizableCards from "../../../shared/customizableCards";
const CustomizableCardsSection = () => {
  const CardsData = [
    {
      badge: (
        <span className="border-t border-b border-r border-[3px] border-l-[.1875rem] border-[#293745] text-sm text-[#293745]  px-3 py-1 rounded-full">
          EHS
        </span>
      ),
      title: "Health & Safety Management",
      description:
        "Keep teams safe on the job while promoting a safety culture across your organization",
      linkText: "Learn more",
    },
    {
      badge: (
        <span className="border-t border-b border-r border-[3px] border-l-[.1875rem] border-[#293745] text-sm text-[#293745]  px-3 py-1 rounded-full">
          GRC
        </span>
      ),
      title: "Risk & Compliance",
      description:
        "Identify, evaluate and mitigate risks to your teams and assets through greater visibility and a data-driven approach.",
      linkText: "Learn more",
    },
    {
      badge: (
        <span className="border-t border-b border-r border-[3px] border-l-[.1875rem] border-[#293745] text-sm text-[#293745]  px-3 py-1 rounded-full">
          Quality
        </span>
      ),
      title: "Operational Excellence",
      description:
        "Optimize processes and drive productivity, greater efficiencies and quality products or services at scale.",
      linkText: "Learn more",
    },
    {
      badge: (
        <span className="border-t border-b border-r border-[3px] border-l-[.1875rem] border-[#293745] text-sm text-[#293745]  px-3 py-1 rounded-full">
          ESG
        </span>
      ),
      title: "Environment & Sustainability",
      description:
        "Digitize SOP’s, reduce waste and adapt sustainability best practices the moment conditions change.",
      linkText: "Learn more",
    },
  ];
  return (
    <div className="bg-[#F3F6FB] py-10">
      <CustomizableCards
        heading="Explore customizable solutions ready to meet your business needs"
        CardsData={CardsData}
      />
    </div>
  );
};
export default CustomizableCardsSection;
