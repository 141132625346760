import FlexContent from "../../../shared/flexContent";
import InspectionImage from "../../../assets/retail/consistent.webp";
import { useTranslation } from "react-i18next";
const BoostProductivity = () => {
  const { t } = useTranslation();
  return (
    <div className="bg-[#F3F6FB] py-24">
      <FlexContent
        heading={t("retailpage.BoostProductivityheading")}
        para={t("retailpage.BoostProductivitypara")}
        reverse
        italicText={t("retailpage.BoostProductivityitalic")}
        name={t("retailpage.BoostProductivityname")}
        position={t("retailpage.BoostProductivityposition")}
        img={InspectionImage}
      />
    </div>
  );
};
export default BoostProductivity;
