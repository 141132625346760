import React, { useState } from "react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

const faqData = [
  
  // {
  //   question: t("faqs.integrationfaqs1"),
  //   answer: t("inspectionpage.ans1"),
  // },

  {
    question:
      "How can operations management software help organizations improve?",
    answer:
      "SafetyCulture as an operations management software helps organizations improve by streamlining processes through digitized forms and automated workflows, enhancing compliance with safety regulations and internal policies, providing real-time monitoring for proactive decision-making, facilitating risk management through regular inspections and audits, centralizing data for easy access and analysis, fostering employee engagement by involving frontline workers in safety processes, and enabling continuous improvement through data analytics and insights. The use of operations management software such as SafetyCulture can help organizations create safer workplaces, reduce operational inefficiencies, and improve overall performance.",
  },
  {
    question:
      "What are the benefits of using an operations management software for workplace operations?",
    answer:
      "Using operations management software for workplace operations can increase efficiency through automation and streamlined workflows, improve communication and collaboration among team members, enhance visibility for better decision-making, reduce costs through optimized processes, and improve customer satisfaction through faster delivery of products or services. Operations management software helps ensure compliance with regulations, manage risks effectively, and provides scalability and adaptability to support organizational growth. The use of operations management software boosts productivity and efficiency, so it’s a win-win for organizations.",
  },
  {
    question: "How secure is the data stored in SafetyCulture?",
    answer:
      "SafetyCulture delivers world-leading security and compliance, ensuring your organization and customer information is always protected. Adhering to privacy and regulatory standards is effortlessly achieved through enterprise-level data protection measures. With a 99.9% uptime service level agreement (SLA), an annual SOC 2 Type 2 audit, hosting on an ISO27001 certified platform,multi-region data centers, SAML v2 single sign-on (SSO), and GDPR compliance SafetyCulture ensures robust security protocols.",
  },
  {
    question: "What level of support does SafetyCulture offer?",
    answer: [
      "All our plans have 24/5 support and include:",
      "Email support: You can contact our support team via email to get help with their account or any other issues you might be having.",
      "In-app support: You can access the support center directly within the app",
      "Phone support: You can also call support to get help with your account or other issues you might be experiencing.",
      "Live chat: We also have a Live chat support option",
      "Online help center: We also have online documentation and tutorials, which can help you learn how to use the software and troubleshoot common problems.",
      "Enterprise packages offer more options like a dedicated customer success manager, personalized onboarding and training, prioritized customer support, customization and integrations.",
    ],
  },
];

const FAQSection = () => {
  const [openIndex, setOpenIndex] = useState(null);
  const isArabic = i18next.language === "ar";
  const { t } = useTranslation();


  const toggleAccordion = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div className="bg-[#F3F6FB] py-24 px-4">
      <div className="container mx-auto max-w-4xl">
        <h2 className="text-3xl md:text-5xl font-bold text-[#293745] text-center mb-14">
        {t("faqs.integrationfaqsheading")}
        </h2>
        {faqData.map((faq, index) => (
          <div key={index} className="mb-4">
            <div
              className={`group rounded-xl border-2 border-[#A2ACBD] transition-all duration-300 ${
                openIndex === index ? "p-4" : "p-3"
              }`}
            >
              <button
                onClick={() => toggleAccordion(index)}
                className="flex justify-between items-center w-full bg-transparent text-left hover:text-[#4740d4] transition-colors duration-300"
              >
                <span className="text-md font-semibold text-[#293745] group-hover:text-[#4740d4]">
                  {faq.question}
                </span>
                {openIndex === index ? (
                  <FaChevronUp className="text-gray-600 group-hover:text-[#4740d4]" />
                ) : (
                  <FaChevronDown className="text-gray-600 group-hover:text-[#4740d4]" />
                )}
              </button>
              {openIndex === index && (
                <div className="mt-4 text-start text-gray-700">
                  {Array.isArray(faq.answer) ? (
                    <ul className="list-disc pl-6">
                      {faq.answer.map((item, i) => (
                        <li key={i} className="mb-2">
                          {item}
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <p>{faq.answer}</p>
                  )}
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FAQSection;
