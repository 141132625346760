import AssetPlatform from "../../../assets/assets/AssetPlatform.webp";
import { useTranslation } from "react-i18next";
const FrontLine = () => {
  const { t } = useTranslation();
  return (
    <div className="py-20 px-4 md:px-12 lg:px-20 text-center">
      {/* Heading 1 */}
      <h2 className="text-[2.2rem] md:text-[3.2rem] lg:text-[3.1rem] font-bold text-[#293745] mb-4">
        {t("assetpage.frontlineheading")}
      </h2>
      <p className="text-md md:text-[1.3rem] text-[#293745] mb-8 w-[98%] md:w-[80%] lg:w-[70%] mx-auto">
        {t("assetpage.frontlinepara")}
      </p>

      {/* Image */}
      <div className="flex justify-center pt-4">
        <img
          src={AssetPlatform}
          alt="Asset Platform"
          className="w-full max-w-[800px] mx-auto" // Ensuring the image is responsive
        />
      </div>

      {/* Heading 2 */}
      <h2 className="text-[2.2rem] md:text-[3.2rem] lg:text-[3.1rem] font-bold text-[#293745] mb-4 mt-7">
        {t("assetpage.improveupheading")}
      </h2>
      <p className="text-md md:text-[1.3rem] text-[#293745] mb-8 w-[98%] md:w-[80%] lg:w-[70%] mx-auto">
        {t("assetpage.improvepara")}
      </p>
    </div>
  );
};

export default FrontLine;
