import React, { useState } from "react";
import { IoIosArrowBack } from "react-icons/io";
import InspectionTab from "./itemsWithoutSitesTabs/inspection";
import ActionTab from "./itemsWithoutSitesTabs/actions";
import IssuesTab from "./itemsWithoutSitesTabs/issues";
const ItemsWithoutSites = () => {
  const [activeTab, setActiveTab] = useState("Inspections");

  const renderContent = () => {
    switch (activeTab) {
      case "Inspections":
        return <InspectionTab />;
      case "Actions":
        return <ActionTab />;
      case "Issues":
        return <IssuesTab />;
      default:
        return null;
    }
  };

  return (
    <div className="px-4 py-10 md:px-3 mx-auto">
      {/* Back Button */}
      <button className="text-gray-600 font-semibold text-sm mb-4 flex items-center">
        <IoIosArrowBack />
        <span className="ml-2">Back to Sites list</span>
      </button>

      {/* Heading */}
      <h1 className="text-start text-2xl md:text-3xl font-medium pt-3 text-gray-800">
        Items without sites
      </h1>
      <p className="text-gray-600 text-start mt-2">
        Review items you have access to, such as inspections and actions, that
        don't have the following selected: Site.
      </p>

      {/* Tabs */}
      <div className="flex mt-6 border-b border-gray-300">
        {["Inspections", "Actions", "Issues"].map((tab) => (
          <button
            key={tab}
            onClick={() => setActiveTab(tab)}
            className={`pb-2 px-4 font-medium text-gray-700 ${
              activeTab === tab
                ? "border-b-2 border-[#6559ff] !text-[#6559ff]"
                : ""
            }`}
          >
            {tab}
          </button>
        ))}
      </div>

      {/* Tab Content */}
      <div className="mt-4">{renderContent()}</div>
    </div>
  );
};

export default ItemsWithoutSites;
