import React from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";

const UserProfile = () => {
  const navigate = useNavigate();

  const goToSettings = () => {
    navigate("/dashboard/user-setting"); // Replace with the actual route for your user settings page
  };
  const { authData } = useAuth();
  console.log(authData);

  return (
    <div className="p-6 bg-gray-100 min-h-screen">
      <div className="container mx-auto">
        {/* Back Button */}
        <div className="mb-4 text-left">
          <button className="text-blue-500">&larr; Back</button>
        </div>

        {/* Profile Header */}
        <div className="bg-gradient-to-r from-blue-200 to-green-200 p-6 rounded-lg relative flex justify-between items-center">
          <div className="flex items-center">
            <div className="bg-blue-300 w-24 h-24 rounded-full flex items-center justify-center text-3xl text-white font-semibold">
              {(
                authData.userName.charAt(0) +
                authData.userName.charAt(authData.userName.length - 1)
              ).toUpperCase()}
            </div>
            <div className="ml-4">
              <h2 className="text-2xl font-semibold">{authData.userName}</h2>
            </div>
          </div>
          <button
            onClick={goToSettings}
            className="bg-white p-2 rounded-md shadow text-blue-500"
          >
            ⚙️ Settings
          </button>
        </div>

        {/* Main Content */}
        <div className="mt-6 grid grid-cols-1 md:grid-cols-3 gap-6">
          {/* About Section */}
          <div className="col-span-1 bg-white p-6 rounded-lg shadow text-left">
            <h3 className="font-semibold text-lg mb-4">About</h3>
            <ul className="text-gray-700">
              <li>
                <strong>Email:</strong>
                <br /> {authData.userDetails?.email || "Add email"}
              </li>
              <li>
                <strong>Phone:</strong>
                <br /> {authData.userDetails?.phoneNumber || "add number"}
              </li>
              <li>
                <strong>Time Zone:</strong>
                <br /> Asia/Karachi
              </li>
              <li>
                <strong>Permissions:</strong>
                <br /> Administrator
              </li>
            </ul>
          </div>

          {/* Qualifications Section */}
          <div className="col-span-1 md:col-span-2 bg-white p-6 rounded-lg shadow">
            <h3 className="font-semibold text-lg mb-4 text-left">
              Qualifications
            </h3>
            <div className="flex justify-between mb-4">
              <div className="flex space-x-2">
                <button className="bg-purple-200 text-purple-600 py-1 px-3 rounded">
                  Credentials
                </button>
                <button className="bg-purple-50 text-purple-600 py-1 px-3 rounded">
                  Training
                </button>
              </div>
              <button className="bg-purple-500 text-white py-1 px-3 rounded">
                + Add credential
              </button>
            </div>
            <div className="mb-4">
              <input
                type="text"
                placeholder="Search"
                className="w-full p-2 border border-gray-300 rounded"
              />
            </div>
            <div className="text-center text-gray-500">
              <img
                src="path/to/your/no-credentials-image.png"
                alt="No Credentials"
                className="mx-auto mb-4"
              />
              <p className="font-semibold">No credentials</p>
              <p>This user doesn’t have any credentials added.</p>
            </div>
          </div>

          {/* Membership Section */}
          <div className="col-span-1 bg-white p-6 rounded-lg shadow">
            <h3 className="font-semibold text-lg mb-4 text-left">
              Memberships
            </h3>
            <div className="flex space-x-2 mb-4">
              <button className="bg-purple-200 text-purple-600 py-1 px-3 rounded">
                Groups
              </button>
              <button className="bg-purple-50 text-purple-600 py-1 px-3 rounded">
                Sites
              </button>
            </div>
            <div className="text-center text-gray-500">
              <img
                src="path/to/your/no-groups-image.png"
                alt="No Groups"
                className="mx-auto mb-4"
              />
              <p className="font-semibold">No groups</p>
              <p>This user is not a member of any groups.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserProfile;
