import MiningHero from "../../components/solutions/mining/hero";
import States from "../../components/solutions/mining/states";
import BoostProductivity from "../../components/solutions/mining/boostProductivity";
import PreventIncidents from "../../components/solutions/mining/preventIncidents";
import GainVisibilty from "../../components/solutions/mining/gainvisibility";
import DeliverEffective from "../../components/solutions/mining/deliverEffective";
import ImproveUptime from "../../components/solutions/mining/improveUptime";
import EasyKnowledge from "../../components/solutions/mining/easyknowledge";
import KeepTrack from "../../components/solutions/mining/keepTrack";
import ListDataSection from "../../components/solutions/mining/listData";
import Improvement from "../../components/solutions/mining/improvement";
import CustomizableCardsSection from "../../components/solutions/mining/customizableCards";
import IndustryCards from "../../components/solutions/mining/industryCards";
import ImprovementTwo from "../../components/solutions/mining/improvementTwo";
import Faqs from "../../components/solutions/mining/faqs";
const Mining = () => {
  return (
    <div>
      <MiningHero />
      <States />
      <BoostProductivity />
      <PreventIncidents/>
      <GainVisibilty/>
      <DeliverEffective/>
      <ImproveUptime/>
      <EasyKnowledge/>
      <KeepTrack/>
      <ListDataSection/>
      <Improvement/>
      <CustomizableCardsSection/>
      <IndustryCards/>
      <ImprovementTwo/>
      <Faqs/>
    </div>
  );
};
export default Mining;
