import React from "react";
import { FaTasks, FaRegCheckCircle } from "react-icons/fa";
import { MdOutlineAssignment, MdOutlineArrowRightAlt } from "react-icons/md";
import { FcInspection } from "react-icons/fc";
import { FiBox } from "react-icons/fi";
import { VscReport } from "react-icons/vsc";
import { HiSpeakerphone } from "react-icons/hi";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { useNavigate } from "react-router-dom";

const Cards = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const isArabic = i18next.language === "ar";

  const cardData = [
    {
      icon: <FcInspection size={30} />,
      key: "inspections",
      link: "/platform#inspections",
    },
    {
      icon: <MdOutlineAssignment size={30} />,
      key: "training",
      link: "/platform#training",
    },
    {
      icon: <FiBox size={30} />,
      key: "assets",
      link: "/platform#assets",
    },
    {
      icon: <VscReport size={30} />,
      key: "issueReporting",
      link: "/platform#issues",
    },
    {
      icon: <FaRegCheckCircle size={30} />,
      key: "tasks",
      link: "/platform#tasks",
    },
    {
      icon: <HiSpeakerphone size={30} />,
      key: "communications",
      link: "/platform#headsup",
    },
  ];

  return (
    <div className={isArabic ? "text-right rtl" : "text-left"}>
      <div className="container mx-auto py-12 px-4">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {cardData.map((card, index) => (
            <div
              key={index}
              className="group border-2 border-[#d0d7e1] rounded-lg px-6 py-7 hover:border-[#6559ff] transition-colors duration-300"
            >
              {/* Card Header */}
              <div className="flex items-start mb-4">
                <div className="flex-shrink-0">{card.icon}</div>
                <h3 className="text-xl font-semibold ms-4 group-hover:text-[#6559ff]">
                  {t(`homepage.${card.key}.title`)}
                </h3>
              </div>

              {/* Card Description */}
              <p className="text-gray-600 my-4">
                {t(`homepage.${card.key}.description`)}
              </p>

              {/* Card Link */}
              <a
                href="#"
                className="text-gray-800 font-medium group-hover:text-[#6559ff] flex items-center"
                aria-label={t(`homepage.${card.key}.linkText`)}
                onClick={() => navigate(card.link)} // Navigate with hash to the specific section
              >
                {t(`homepage.${card.key}.linkText`)}
                <span className="ml-1">
                  <MdOutlineArrowRightAlt />
                </span>
              </a>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Cards;
