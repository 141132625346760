import EquipmentHero from "../../../assets/construction/ConstructionAnalyticsGraphs.webp";
import TextImageFlex from "../../../shared/textImageFlex";
import { useTranslation } from "react-i18next";
const GainVisibilty = () => {
  const { t } = useTranslation();
  return (
    <div className="py-12 md:py-24">
      <TextImageFlex
        heading={t("constructionpage.GainVisibiltyheading")}
        para={t("constructionpage.GainVisibiltypara")}
        img={EquipmentHero}
        widhtClass="w-full"
        reverse
      />
    </div>
  );
};

export default GainVisibilty;
