import FAQSection from "../../../shared/faqs";
const faqData = [
  {
    question:
      "What are the benefits of using construction management software?",
    answer:
      "Benefits of using a construction management software include faster completion of tasks, reduce extra work and time spent on manual and repetitive tasks, real-time tracking and monitoring of projects, streamlined document management and version control, visibility and transparency into projects, and more. This platform is designed to simplify construction processes by automating various aspects of the operation. Tools and features of the software can include the ability to track and monitor the progress of tasks, conduct assessments, schedule tasks, and documentation.",
  },
  {
    question:
      "Are construction management software suitable for small businesses?",
    answer:
      "Yes, they can be beneficial for small businesses to take advantage of construction management software. The benefits of using one still apply regardless of the size of the organization. However, it is best to consider specific business needs and budget constraints when choosing a software solution.",
  },
  {
    question:
      "How can the SafetyCulture platform help my business with quality management in construction?",
    answer:
      "The SafetyCulture platform can help businesses with quality management in construction projects by standardizing inspections. Teams can conduct inspections and audits with their mobile devices and be able to identify quality issues, maintain consistency, and track non-conformance issues to ensure quality standards are met across various sites.",
  },
  {
    question: "Can the SafetyCulture construction management platform be accessed remotely or via mobile devices?",
    answer:
      "Yes, construction management software like SafetyCulture can be accessed either via desktop or mobile devices. This allows contractors to track the progress of tasks or the overall project and facilitates real-time communication among team members, even when they aren’t physically present at the job site.",
  },
  {
    question:
      "How can SafetyCulture help my business drive continuous improvement?",
    answer:
      "SafetyCulture allows organizations to identify potential risks and hazards, track corrective actions, and monitor progress toward continuous improvement goals. With real-time data and insights, you’ll be able to make informed decisions, allocate resources effectively, and drive continuous improvement in your operations.",
  },
];
const Faqs = () => {
  return <FAQSection faqData={faqData} heading="Frequently Asked Questions" />;
};
export default Faqs;
