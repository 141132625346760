import "./Customer.css";

const stories = [
  {
    id: 1,
    logoUrl: require("../../assets/customerImages/1.png"),
    logoAlt: "SSP Logo",
    imageUrl: require("../../assets/customerImages/2.png"),
    imageAlt: "SSP Food",
    title: "SSP",
    description:
      "As the largest food and beverage provider in Australian travel locations, SSP is delivering a world-class experience to airport patrons in major cities and regional locations. Here’s how they use SafetyCulture to propel their food safety and compliance during a rapid period of growth.",
    link: "/customerdetail",
  },
  {
    id: 2,
    logoUrl: require("../../assets/customerImages/3.png"),
    logoAlt: "Sodexo Logo",
    imageUrl: require("../../assets/customerImages/4.png"),
    imageAlt: "Sodexo Food",
    title: "Sodexo",
    description:
      "Sodexo is a global leader in food and facilities management, working with 33,000 organizations in 45 countries. Here’s how they achieve operational excellence by digitizing inspections, training, and temperature monitoring using SafetyCulture.",
    link: "/customerdetail",
  },
  {
    id: 3,
    logoUrl: require("../../assets/customerImages/5.png"),
    logoAlt: "MLSE Logo",
    imageUrl: require("../../assets/customerImages/6.png"),
    imageAlt: "MLSE Sports",
    title: "Sports & Entertainment",
    description:
      "Coordinating over 1,400 staff members to deliver high-quality food experiences across Maple Leaf Sports & Entertainment’s numerous venues is no easy undertaking. The solution? Detailed reports, careful monitoring, and centralized communication in SafetyCulture.",
    link: "#",
  },
];

const Customer = () => {
  return (
    <div>
      {/* Section Header */}
      <div className="text-center mb-8 px-4 py-8 md:py-12 sm:py-6">
        <h2 className="text-6xl font-bold text-gray-800 max-w-7xl mx-auto text-center font-sans">
          Trusted by established companies and their teams
        </h2>
      </div>

      {/* Featured Stories */}
      <div className="container max-w-7xl mx-auto px-4">
        <h2 className="text-lg md:text-5xl font-bold text-gray-800 text-center mb-6">
          Featured stories
        </h2>

        {/* Cards Section */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {stories.map((story) => (
            <div
              key={story.id}
              className="p-4 sm:p-6 rounded-lg shadow-md shadow-black"
            >
              <img
                src={story.logoUrl}
                alt={story.logoAlt}
                className="w-full h-32 max-w-[400px] mx-auto rounded-[20px] shadow-xl p-5 mb-5 object-contain"
              />
              <img
                src={story.imageUrl}
                alt={story.imageAlt}
                className="w-full max-w-[400px] h-50 object-cover rounded-[20px] mb-4 mx-auto"
              />
              <h4 className="text-base md:text-2xl font-bold text-gray-900">
                {story.title}
              </h4>
              <p className="text-sm md:text-base text-gray-800 mt-6 font-medium mb-2 leading-5 text-left">
                {story.description}
              </p>
              <a href={story.link} className="text-blue-600 hover:underline">
                Read story →
              </a>
            </div>
          ))}
        </div>
      </div>

      <div className="mt-20 mb-20 p-5 max-w-7xl mx-auto rounded-lg">
        {/* Filter Bar */}
        <div className="filter-bar mb-6">
          <h2 className="text-5xl font-bold mb-10 mt-16">
            Explore customer stories
          </h2>
          <div className="my-2 text-left ml-5 mb-4 border-b border-gray-500">
            <span>
              <a href="#" className="text-black text-2xl hover:underline">
                By companies
              </a>{" "}
              |{" "}
              <a href="#" className="text-black text-2xl hover:underline">
                By frontline workers
              </a>
            </span>
          </div>
          <p className="text-gray-900 text-base text-left">
            Discover how SafetyCulture helps customers like you gain the
            knowledge, tools, and processes needed to work safely, meet higher
            standards, and improve every day. For a better way of working.
          </p>
        </div>

        {/* Filter Options */}
        <div className="flex flex-wrap gap-4 mb-6">
          <div className="filter flex-1 min-w-[200px]">
            <label htmlFor="search" className="block mb-1 text-left">
              Search
            </label>
            <div className="search-container flex items-center border rounded p-1">
              <i className="fas fa-search text-gray-500"></i>
              <input
                type="text"
                id="search"
                placeholder="Search..."
                className="flex-1 border-none outline-none p-1"
              />
            </div>
          </div>

          <div className="filter flex-1 min-w-[200px]">
            <label htmlFor="industry" className="block mb-1 text-left">
              Industry
            </label>
            <select id="industry" className="border rounded p-2 w-full">
              <option value="all">All</option>
              <option value="industry1">Industry 1</option>
              <option value="industry2">Industry 2</option>
              <option value="industry3">Industry 3</option>
              <option value="industry4">Industry 4</option>
            </select>
          </div>

          <div className="filter flex-1 min-w-[200px]">
            <label htmlFor="industry2" className="block mb-1 text-left">
              Industry
            </label>
            <select id="industry2" className="border rounded p-2 w-full">
              <option value="all">All</option>
              <option value="industry1">Industry 1</option>
              <option value="industry2">Industry 2</option>
              <option value="industry3">Industry 3</option>
              <option value="industry4">Industry 4</option>
            </select>
          </div>

          <div className="filter flex-1 min-w-[200px]">
            <label htmlFor="industry3" className="block mb-1 text-left">
              Industry
            </label>
            <select id="industry3" className="border rounded p-2 w-full">
              <option value="all">All</option>
              <option value="industry1">Industry 1</option>
              <option value="industry2">Industry 2</option>
              <option value="industry3">Industry 3</option>
              <option value="industry4">Industry 4</option>
            </select>
          </div>

          {/* Reset Filters Button */}
          <div className="col-span-full text-center mt-4 w-full">
            <button className="reset-filters bg-blue-600 text-white rounded px-4 py-2 hover:bg-blue-700">
              Reset filters
            </button>
          </div>
        </div>

        {/* Customer Stories */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {stories.map((story) => (
            <div
              key={story.id}
              className="rounded-lg shadow-md p-4 mt-5 shadow-black"
            >
              <a href={story.link} className="card-logo mb-2">
                <img
                  src={story.logoUrl}
                  alt={story.logoAlt}
                  className="company-logo mx-auto h-20 w-auto"
                />
                <img
                  src={story.imageUrl}
                  alt={story.imageAlt}
                  className="w-full max-w-[500px] h-50 object-cover rounded-[20px] mb-4 mx-auto text-left"
                />
              </a>
              <h4 className="text-base md:text-2xl font-bold text-gray-900">
                {story.title}
              </h4>
              <p className="text-sm md:text-base text-gray-800 mt-2 font-medium mb-2 leading-5 text-left">
                {story.description}
              </p>
              <a href={story.link} className="text-blue-600 hover:underline">
                Read story →
              </a>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Customer;
