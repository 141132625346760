import React from "react";
import { FaArrowRight } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import i18next from "i18next"; // Assuming i18next is imported for language detection

const CustomizableCards = ({
  heading,
  span1,
  span2,
  span3,
  span4,
  CardsData,
}) => {
  const location = useLocation();
  const isArabic = i18next.language === "ar";

  return (
    <div
      className={`container mx-auto py-14 px-4 ${
        isArabic ? "text-right rtl" : ""
      }`}
    >
      <h2
        className={`text-[30px] sm:text-[33px] md:text-[43px] lg:text-[53px] text-[#293745] font-bold mb-10 md:leading-[66px] ${
          isArabic ? "text-right" : ""
        }`}
      >
        {heading}
      </h2>
      <div
        className={`grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8 ${
          isArabic ? "rtl-grid" : ""
        }`}
      >
        {CardsData.map((card, index) => (
          <div
            key={index}
            className="group flex flex-col items-start border border-gray-300 rounded-2xl p-5 transition-shadow"
          >
            <span>{card.badge}</span>
            <h3
              className={`text-2xl font-[800] my-6 ${
                isArabic ? "text-right" : "text-start"
              } transition-all`}
            >
              {card.title}
            </h3>
            <p
              className={`text-gray-600 mb-4 ${
                isArabic ? "text-right" : "text-start"
              }`}
            >
              {card.description}
            </p>
            {/* <div className="border border-[#D0D7E1] w-full rounded-lg py-3 mt-3 hover:bg-[#f0f0ff]">
              <a
                href="#"
                className="text-[#4740d4] font-semibold transition-all"
              >
                {card.linkText}
              </a>
            </div> */}
          </div>
        ))}
      </div>
      {location.pathname === "/construction" ? (
        <div
          className={`flex flex-wrap justify-center md:justify-between pt-24 pb-16 ${
            isArabic ? "text-right" : ""
          }`}
        >
          <h2
            className={`text-2xl font-bold text-[#293745] mb-4 ${
              isArabic ? "text-right" : ""
            }`}
          >
            Additional resources
          </h2>
          <div
            className={`flex flex-wrap space-y-4 md:space-y-0 md:flex-nowrap md:gap-10 ${
              isArabic ? "text-right" : ""
            }`}
          >
            <div className="space-y-4">
              <div className="flex items-center space-x-2 text-[#4C40D4] hover:underline">
                <span>{span1}</span>
                <FaArrowRight className="text-[#4C40D4]" />
              </div>
              <div className="flex items-center space-x-2 text-[#4C40D4] hover:underline">
                <span>{span2}</span>
                <FaArrowRight className="text-[#4C40D4]" />
              </div>
            </div>

            <div className="space-y-4">
              <div className="flex items-center space-x-2 text-[#4C40D4] hover:underline">
                <span>{span3}</span>
                <FaArrowRight className="text-[#4C40D4]" />
              </div>
            </div>

            <div className="space-y-4">
              <div className="flex items-center space-x-2 text-[#4C40D4] hover:underline">
                <span>{span4}</span>
                <FaArrowRight className="text-[#4C40D4]" />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default CustomizableCards;
