import AnalyticsHero from "../../../assets/facilities/AnalyticsReportingGraphs.webp";
import TextImageFlex from "../../../shared/textImageFlex";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

const ImproveUptime = () => {
  const { t } = useTranslation();
  const isArabic = i18next.language === "ar";

  return (
    <div className={`py-10`}>
      <TextImageFlex
        heading={t("healthsafety.Improveheading")}
        para={t("healthsafety.Improvepara")}
        img={AnalyticsHero}
        widhtClass="w-full"
        reverse
      />
    </div>
  );
};

export default ImproveUptime;
