import Platform from "../../../assets/home/platform-app-hero-FA.webp";
import Hero from "../../../shared/hero";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

const Platformhero = () => {
  const { t } = useTranslation();
  const isArabic = i18next.language === "ar";
  return (
    <div
      className={` ${isArabic ? "text-right" : "text-left"} ${
        isArabic ? "rtl" : ""
      }`}
    >
      <Hero
        heading={t("platformpage.Platformheading")}
        para={t("platformpage.Platformpara")}
        btn1={t("platformpage.Platformbtn1")}
        btn2={t("platformpage.Platformbtn2")}
        img={Platform}
        imgWidth="75%"
      />
    </div>
  );
};

export default Platformhero;
