import FAQSection from "../../../shared/faqs";
import { useTranslation } from "react-i18next";

const Faqs = () => {
  // Call the useTranslation hook
  const { t } = useTranslation();

  // Define the FAQ data using `t()` for translations
  const faqData = [
    {
      question: t("retailfaqs.retailfaqsquestion0"),
      answer: t("retailfaqs.retailfaqsanswer0"),
    },
    {
      question: t("retailfaqs.retailfaqsquestion1"),
      answer: t("retailfaqs.retailfaqsanswer1"),
    },
    {
      question: t("retailfaqs.retailfaqsquestion2"),
      answer:
      t("retailfaqs.retailfaqsanswer2"),
    },
    {
      question: t("retailfaqs.retailfaqsquestion3"),
      answer:
      t("retails.retailfaqsanswer3"),
    },
    {
      question: t("retailfaqs.retailfaqsquestion4"),
      answer:
      t("retailfaqs.retailfaqsanswer4"),
    },
  ];

  // Return the FAQ section with translated data
  return <FAQSection faqData={faqData} heading={t("retailfaqs.retailfaqsheading")} />;
};

export default Faqs;
