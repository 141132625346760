import { IoMdArrowRoundBack } from "react-icons/io";
import React, { useState } from "react";
import { MdSpeakerNotes } from "react-icons/md";
import { MdPermMedia } from "react-icons/md";
import { MdOutlinePendingActions } from "react-icons/md";
import { useNavigate } from "react-router-dom";
const StartTheInspection = ({ onClose }) => {
  const navigate = useNavigate();
  const [pageNumber, setPageNumber] = useState(1);
  const [isOpen, setIsOpen] = useState(false);
  const [noteOpen, setNoteOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const data = ["Option 1", "Option 2", "Option 3", "Option 4"];
  const filteredOptions = data.filter((option) =>
    option.toLowerCase().includes(searchQuery.toLowerCase())
  );
  const openModal = () => {
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };
  const setPageHandler = () => {
    if (pageNumber === 1) {
      setPageNumber(2);
    } else {
      setPageNumber(1);
    }
  };
  return (
    <div>
      <div className="bg-white fixed mb-16 top-0 w-full flex py-4 px-4">
        <div>
          <h1>
            <IoMdArrowRoundBack className="text-gray-800 " size={25} />
          </h1>
        </div>
        <div className="ps-6">
          <h1 className="text-gray-800 text-lg font-semibold text-start">
            Skanska PAS Audit
          </h1>
          <p className="hover:underline text-gray-800 text-sm text-start">
            Last edited by uzair khan at 12 Nov 2024 9:42 PM
          </p>
        </div>
      </div>
      <div className="bg-[#f8f9fc]">
        <div className="container mx-auto w-[95%] md:w-[60%]">
          <div>
            <div className="mt-20">
              <div className="flex justify-between items-center px-6 pt-6 ">
                <h1 className="text-lg font-bold">Preview</h1>
                <button
                  onClick={onClose}
                  className="text-gray-500 hover:text-gray-700"
                >
                  X
                </button>
              </div>

              <div className="text-start  px-6 py-3">
                <h3 className="text-lg font-semibold">Skanska PAS Audit</h3>
                <p className="text-gray-500 text-sm">Field Audit</p>
              </div>

              {pageNumber === 1 ? (
                <div className="space-y-4 bg-[#f8f9fc] px-2 ">
                  <div className="bg-white py-6 px-4">
                    <label className="text-start block font-medium pb-1">
                      Audit Title (Job Number - YYYYMMDD)
                    </label>
                    <textarea
                      className="w-full p-2 border rounded "
                      rows="2"
                      placeholder="Type your notes..."
                    ></textarea>
                    {noteOpen && (
                      <>
                        <textarea
                          className="w-full p-2 border rounded "
                          rows="1"
                          placeholder="Type your notes..."
                        ></textarea>
                        <div className="flex space-x-3 mt-4">
                          <button className="bg-[#6559ff] text-white px-5 py-2 rounded-lg hover:bg-[#4740d4]">
                            Save
                          </button>
                          <button
                            className="border border-gray-300 text-[#6559ff] px-4 py-2 rounded-lg hover:bg-[#e9edf6]"
                            onClick={() => setNoteOpen(false)}
                          >
                            Cancel
                          </button>
                        </div>
                      </>
                    )}
                    <div className="flex justify-end space-x-2 pt-1">
                      <p
                        className="flex text-[#4740d4] hover:bg-[#f8f9fc] rounded-lg p-2"
                        onClick={() => setNoteOpen(true)}
                      >
                        <MdSpeakerNotes className="me-1 mt-1" /> Note
                      </p>
                      <p className="flex text-[#4740d4] hover:bg-[#f8f9fc] rounded-lg p-2">
                        <MdPermMedia className="me-1 mt-1" /> Media
                      </p>
                      <p className="flex text-[#4740d4] hover:bg-[#f8f9fc] rounded-lg p-2">
                        <MdOutlinePendingActions className="me-1 mt-1" /> Action
                      </p>
                    </div>
                  </div>

                  <div className="bg-white py-6 px-4">
                    <label className="text-start block font-medium pb-1">
                      Project Name
                    </label>
                    <textarea
                      className="w-full p-2 border rounded "
                      rows="2"
                      placeholder="Type your notes..."
                    ></textarea>
                    {noteOpen && (
                      <>
                        <textarea
                          className="w-full p-2 border rounded "
                          rows="1"
                          placeholder="Type your notes..."
                        ></textarea>
                        <div className="flex space-x-3 mt-4">
                          <button className="bg-[#6559ff] text-white px-5 py-2 rounded-lg hover:bg-[#4740d4]">
                            Save
                          </button>
                          <button
                            className="border border-gray-300 text-[#6559ff] px-4 py-2 rounded-lg hover:bg-[#e9edf6]"
                            onClick={() => setNoteOpen(false)}
                          >
                            Cancel
                          </button>
                        </div>
                      </>
                    )}
                    <div className="flex justify-end space-x-2 pt-1">
                      <p
                        className="flex text-[#4740d4] hover:bg-[#f8f9fc] rounded-lg p-2"
                        onClick={() => setNoteOpen(true)}
                      >
                        <MdSpeakerNotes className="me-1 mt-1" /> Note
                      </p>
                      <p className="flex text-[#4740d4] hover:bg-[#f8f9fc] rounded-lg p-2">
                        <MdPermMedia className="me-1 mt-1" /> Media
                      </p>
                      <p className="flex text-[#4740d4] hover:bg-[#f8f9fc] rounded-lg p-2">
                        <MdOutlinePendingActions className="me-1 mt-1" /> Action
                      </p>
                    </div>
                  </div>

                  <div className="bg-white py-6 px-4">
                    <label className="text-start block font-medium pb-1">
                      Conducted on
                    </label>
                    <input
                      className="w-full p-2 border rounded "
                      placeholder="Type your notes..."
                    />
                    {noteOpen && (
                      <>
                        <textarea
                          className="w-full p-2 border rounded "
                          rows="1"
                          placeholder="Type your notes..."
                        ></textarea>
                        <div className="flex space-x-3 mt-4">
                          <button className="bg-[#6559ff] text-white px-5 py-2 rounded-lg hover:bg-[#4740d4]">
                            Save
                          </button>
                          <button
                            className="border border-gray-300 text-[#6559ff] px-4 py-2 rounded-lg hover:bg-[#e9edf6]"
                            onClick={() => setNoteOpen(false)}
                          >
                            Cancel
                          </button>
                        </div>
                      </>
                    )}
                    <div className="flex justify-end space-x-2 pt-1">
                      <p
                        className="flex text-[#4740d4] hover:bg-[#f8f9fc] rounded-lg p-2"
                        onClick={() => setNoteOpen(true)}
                      >
                        <MdSpeakerNotes className="me-1 mt-1" /> Note
                      </p>
                      <p className="flex text-[#4740d4] hover:bg-[#f8f9fc] rounded-lg p-2">
                        <MdPermMedia className="me-1 mt-1" /> Media
                      </p>
                      <p className="flex text-[#4740d4] hover:bg-[#f8f9fc] rounded-lg p-2">
                        <MdOutlinePendingActions className="me-1 mt-1" /> Action
                      </p>
                    </div>
                  </div>

                  <div className="bg-white py-6 px-4">
                    <label className="text-start block font-medium pb-1">
                      Prepared by
                    </label>
                    <input
                      className="w-full p-2 border rounded "
                      placeholder="Type your notes..."
                    />
                    {noteOpen && (
                      <>
                        <input
                          className="w-full p-2 border rounded "
                          placeholder=""
                        ></input>
                        <div className="flex space-x-3 mt-4">
                          <button className="bg-[#6559ff] text-white px-5 py-2 rounded-lg hover:bg-[#4740d4]">
                            Save
                          </button>
                          <button
                            className="border border-gray-300 text-[#6559ff] px-4 py-2 rounded-lg hover:bg-[#e9edf6]"
                            onClick={() => setNoteOpen(false)}
                          >
                            Cancel
                          </button>
                        </div>
                      </>
                    )}
                    <div className="flex justify-end space-x-2 pt-1">
                      <p
                        className="flex text-[#4740d4] hover:bg-[#f8f9fc] rounded-lg p-2"
                        onClick={() => setNoteOpen(true)}
                      >
                        <MdSpeakerNotes className="me-1 mt-1" /> Note
                      </p>
                      <p className="flex text-[#4740d4] hover:bg-[#f8f9fc] rounded-lg p-2">
                        <MdPermMedia className="me-1 mt-1" /> Media
                      </p>
                      <p className="flex text-[#4740d4] hover:bg-[#f8f9fc] rounded-lg p-2">
                        <MdOutlinePendingActions className="me-1 mt-1" /> Action
                      </p>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="space-y-4 bg-[#f8f9fc] px-2 ">
                  <div className="bg-white py-6 px-4">
                    <label className="text-start block font-medium pb-1">
                      Audit Title (Job Number - YYYYMMDD)
                    </label>
                    <textarea
                      className="w-full p-2 border rounded "
                      rows="2"
                      placeholder="Type your notes..."
                    ></textarea>
                    {noteOpen && (
                      <>
                        <textarea
                          className="w-full p-2 border rounded "
                          rows="1"
                          placeholder="Type your notes..."
                        ></textarea>
                        <div className="flex space-x-3 mt-4">
                          <button className="bg-[#6559ff] text-white px-5 py-2 rounded-lg hover:bg-[#4740d4]">
                            Save
                          </button>
                          <button
                            className="border border-gray-300 text-[#6559ff] px-4 py-2 rounded-lg hover:bg-[#e9edf6]"
                            onClick={() => setNoteOpen(false)}
                          >
                            Cancel
                          </button>
                        </div>
                      </>
                    )}
                    <div className="flex justify-end space-x-2 pt-1">
                      <p
                        className="flex text-[#4740d4] hover:bg-[#f8f9fc] rounded-lg p-2"
                        onClick={() => setNoteOpen(true)}
                      >
                        <MdSpeakerNotes className="me-1 mt-1" /> Note
                      </p>
                      <p className="flex text-[#4740d4] hover:bg-[#f8f9fc] rounded-lg p-2">
                        <MdPermMedia className="me-1 mt-1" /> Media
                      </p>
                      <p className="flex text-[#4740d4] hover:bg-[#f8f9fc] rounded-lg p-2">
                        <MdOutlinePendingActions className="me-1 mt-1" /> Action
                      </p>
                    </div>
                  </div>

                  <div className="bg-white py-6 px-4">
                    <label className="text-start block font-medium pb-1">
                      Project Name
                    </label>
                    <textarea
                      className="w-full p-2 border rounded "
                      rows="2"
                      placeholder="Type your notes..."
                    ></textarea>
                    {noteOpen && (
                      <>
                        <textarea
                          className="w-full p-2 border rounded "
                          rows="1"
                          placeholder="Type your notes..."
                        ></textarea>
                        <div className="flex space-x-3 mt-4">
                          <button className="bg-[#6559ff] text-white px-5 py-2 rounded-lg hover:bg-[#4740d4]">
                            Save
                          </button>
                          <button
                            className="border border-gray-300 text-[#6559ff] px-4 py-2 rounded-lg hover:bg-[#e9edf6]"
                            onClick={() => setNoteOpen(false)}
                          >
                            Cancel
                          </button>
                        </div>
                      </>
                    )}
                    <div className="flex justify-end space-x-2 pt-1">
                      <p
                        className="flex text-[#4740d4] hover:bg-[#f8f9fc] rounded-lg p-2"
                        onClick={() => setNoteOpen(true)}
                      >
                        <MdSpeakerNotes className="me-1 mt-1" /> Note
                      </p>
                      <p className="flex text-[#4740d4] hover:bg-[#f8f9fc] rounded-lg p-2">
                        <MdPermMedia className="me-1 mt-1" /> Media
                      </p>
                      <p className="flex text-[#4740d4] hover:bg-[#f8f9fc] rounded-lg p-2">
                        <MdOutlinePendingActions className="me-1 mt-1" /> Action
                      </p>
                    </div>
                  </div>

                  <div className="bg-white py-6 px-4">
                    <label className="text-start block font-medium pb-1">
                      Conducted on
                    </label>
                    <input
                      className="w-full p-2 border rounded "
                      placeholder="Type your notes..."
                    />
                    {noteOpen && (
                      <>
                        <textarea
                          className="w-full p-2 border rounded "
                          rows="1"
                          placeholder="Type your notes..."
                        ></textarea>
                        <div className="flex space-x-3 mt-4">
                          <button className="bg-[#6559ff] text-white px-5 py-2 rounded-lg hover:bg-[#4740d4]">
                            Save
                          </button>
                          <button
                            className="border border-gray-300 text-[#6559ff] px-4 py-2 rounded-lg hover:bg-[#e9edf6]"
                            onClick={() => setNoteOpen(false)}
                          >
                            Cancel
                          </button>
                        </div>
                      </>
                    )}
                    <div className="flex justify-end space-x-2 pt-1">
                      <p
                        className="flex text-[#4740d4] hover:bg-[#f8f9fc] rounded-lg p-2"
                        onClick={() => setNoteOpen(true)}
                      >
                        <MdSpeakerNotes className="me-1 mt-1" /> Note
                      </p>
                      <p className="flex text-[#4740d4] hover:bg-[#f8f9fc] rounded-lg p-2">
                        <MdPermMedia className="me-1 mt-1" /> Media
                      </p>
                      <p className="flex text-[#4740d4] hover:bg-[#f8f9fc] rounded-lg p-2">
                        <MdOutlinePendingActions className="me-1 mt-1" /> Action
                      </p>
                    </div>
                  </div>

                  <div className="bg-white py-6 px-4">
                    <label className="text-start block font-medium pb-1">
                      Prepared by
                    </label>
                    <input
                      className="w-full p-2 border rounded "
                      placeholder="Type your notes..."
                    />
                    {noteOpen && (
                      <>
                        <input
                          className="w-full p-2 border rounded "
                          placeholder=""
                        ></input>
                        <div className="flex space-x-3 mt-4">
                          <button className="bg-[#6559ff] text-white px-5 py-2 rounded-lg hover:bg-[#4740d4]">
                            Save
                          </button>
                          <button
                            className="border border-gray-300 text-[#6559ff] px-4 py-2 rounded-lg hover:bg-[#e9edf6]"
                            onClick={() => setNoteOpen(false)}
                          >
                            Cancel
                          </button>
                        </div>
                      </>
                    )}
                    <div className="flex justify-end space-x-2 pt-1">
                      <p
                        className="flex text-[#4740d4] hover:bg-[#f8f9fc] rounded-lg p-2"
                        onClick={() => setNoteOpen(true)}
                      >
                        <MdSpeakerNotes className="me-1 mt-1" /> Note
                      </p>
                      <p className="flex text-[#4740d4] hover:bg-[#f8f9fc] rounded-lg p-2">
                        <MdPermMedia className="me-1 mt-1" /> Media
                      </p>
                      <p className="flex text-[#4740d4] hover:bg-[#f8f9fc] rounded-lg p-2">
                        <MdOutlinePendingActions className="me-1 mt-1" /> Action
                      </p>
                    </div>
                  </div>
                </div>
              )}

              <div className="flex justify-start ms-8 items-center mt-5 mb-3">
                <button
                  className="bg-white border border-[#bfc6d4] text-[#4740d4] px-4 py-2 rounded-lg"
                  onClick={() => setPageHandler()}
                >
                  {pageNumber === 1 ? "Next Page" : "Previous Page"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default StartTheInspection;
