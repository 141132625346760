import StatsSection from "../../../shared/stateSection";

const States = () => {
  const data = [
    {
      value: "60%",
      description: "increased audit & document management efficiency*",
      borderColor: "border-purple-500",
    },
    {
      value: "49%",
      description: "savings from safety & compliance improvements*",
      borderColor: "border-cyan-500",
    },
    {
      value: "60%",
      description: "reduction in reporting time*",
      borderColor: "border-yellow-500",
    },
  ];

  return <StatsSection data={data} />;
};

export default States;
