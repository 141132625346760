import React from "react";
import { FaInfoCircle } from "react-icons/fa";
const AssignFields = () => {
  return (
    <div className="p-4 md:p-3 mx-auto">
      {/* Title and Description */}
      <h1 className="text-2xl font-semibold text-gray-800 text-start">
        Assign fields
      </h1>
      <p className="text-gray-600 mt-2 text-start">
        Select a header from your CSV to map to the label in SafetyCulture.
        Fields left blank won’t be mapped.
      </p>

      {/* Form Fields */}
      <div className="mt-6 bg-white p-6 rounded-lg shadow-md border border-gray-200 space-y-6">
        {[
          { label: "country", value: "country" },
          { label: "state", value: "state" },
          { label: "region", value: "region" },
          { label: "area", value: "area" },
          { label: "location", value: "site" },
          { label: "members", value: "members" },
          { label: "Site ID (optional)", value: "id" },
        ].map((field, index) => (
          <div key={index} className="flex items-center justify-start">
            <label className="w-[20%] text-start text-gray-700 font-medium">
              {field.label}
            </label>
            <select
              className="w-2/3 border border-gray-300 bg-white p-2 rounded-md text-gray-700 focus:outline-none focus:border-purple-500"
              defaultValue={field.value}
            >
              <option value={field.value}>{field.value}</option>
            </select>
          </div>
        ))}
        {/* Info Text */}
        <p className="text-sm text-gray-500 mt-4 text-start flex ms-44 ps-4">
          <FaInfoCircle className="me-2 mt-1"/>
          If left blank, you will be adding new sites.
        </p>
        {/* Footer Buttons */}
        <div className="flex justify-end mt-8">
          <button className="text-[#4740d4] border me-3 bg-[#fffff] border-[#bfc6d4] rounded-md py-2 px-4 hover:bg-[#e9edf6]">
            Back
          </button>
          <button className="bg-[#4740d4] text-white rounded-md py-2 px-4 hover:bg-[#4740d4]">
            Review data
          </button>
        </div>
      </div>
    </div>
  );
};

export default AssignFields;
