import React, { createContext, useContext, useState, useEffect } from "react";

import useHttp from "../../common/hooks/useHttp";
import { useAuth } from "./AuthContext";
const ApiContext = createContext();

export const ApiProvider = ({ children }) => {
  const { authData } = useAuth();
  const { sendRequest, loading, error } = useHttp();
  const [getTamplateList, setGetTemplate] = useState([]);
  const [assetTypes, setAssetTypes] = useState([]);
  const [users, setUsers] = useState(null);
  const [assetFields, setAssetFields] = useState();
  const [getSiteLists, setGetSitlist] = useState([]);

  const getOrganizationUsers = async () => {
    try {
      await sendRequest(
        {
          url: "v1/organization/users",
          accessToken: authData.accessToken,
          headers: {
            userName: authData.userName,
            organizationId: authData.organizationId,
            deviceId: authData.deviceId,
          },
        },
        (response) => {
          setUsers(response.data.organizationUsers);
        }
      );
    } catch (error) {
      console.error("Error fetching organization users:", error);
    }
  };
  const getAssetTypes = async () => {
    try {
      await sendRequest(
        {
          url: "v1/asset/types/details",
          accessToken: authData.accessToken,
          headers: {
            userName: authData.userName,
            organizationId: authData.organizationId,
            deviceId: authData.deviceId,
          },
        },
        (response) => {
          setAssetTypes(response?.data?.assetTypesDetails);
        }
      );
    } catch (error) {
      console.error("Error creating asset type:", error);
    }
  };
  const getAssetFields = async () => {
    try {
      await sendRequest(
        {
          url: "v1/asset/fields",
          accessToken: authData.accessToken,
          headers: {
            userName: authData.userName,
            organizationId: authData.organizationId,
            deviceId: authData.deviceId,
          },
        },
        (response) => {
          setAssetFields(response.data.fields);
        }
      );
    } catch (error) {
      console.error("Error creating asset type:", error);
    }
  };
  const getSiteList = async () => {
    try {
      await sendRequest(
        {
          url: "v1/site/list",
          accessToken: authData.accessToken,
          headers: {
            userName: authData.userName,
            organizationId: authData.organizationId,
            deviceId: authData.deviceId,
          },
        },
        (response) => {
          setGetSitlist(response.data?.sites);
        }
      );
    } catch (err) {
      console.log(err);
    }
  };

  const getTamplate = async () => {
    try {
      await sendRequest(
        {
          url: "v1/template/list",
          accessToken: authData.accessToken,
          headers: {
            userName: authData.userName,
            organizationId: authData.organizationId,
            deviceId: authData.deviceId,
          },
        },
        (response) => {
          setGetTemplate(response);
        }
      );
    } catch (error) {
      console.log(error);
    }
  };
  const TamplateById = async () => {
    try {
      await sendRequest(
        {
          url: "v1/template/list",
          accessToken: authData.accessToken,
          headers: {
            userName: authData.userName,
            organizationId: authData.organizationId,
            deviceId: authData.deviceId,
          },
        },
        (response) => {
          setGetTemplate(response);
        }
      );
    } catch (error) {}
  };
  useEffect(() => {
    if (authData && authData.accessToken) {
      try {
        getTamplate();
      } catch (err) {
        console.log(err.message); // Handle error
      }
    }
  }, [authData]);

  useEffect(() => {
    if (authData && authData.accessToken) {
      try {
        getSiteList();
      } catch (err) {
        console.log(err.message); // Handle error
      }
    }
  }, [authData]);
  useEffect(() => {
    if (authData && authData.accessToken) {
      try {
        getAssetTypes();
      } catch (err) {
        console.log(err.message); // Handle error
      }
    }
  }, [authData]);
  useEffect(() => {
    if (authData && authData.accessToken) {
      try {
        getOrganizationUsers();
      } catch (err) {
        console.log(err.message); // Handle error
      }
    }
  }, [authData]);
  // Run
  useEffect(() => {
    if (authData && authData.accessToken) {
      try {
        getAssetFields();
      } catch (err) {
        console.log(err.message); // Handle error
      }
    }
  }, [authData]);
  return (
    <ApiContext.Provider
      value={{
        loading,
        error,
        users,
        assetTypes,
        getSiteLists,
        getTamplateList,
        assetFields,
        getOrganizationUsers,
        // getAssetTypes,
      }}
    >
      {children}
    </ApiContext.Provider>
  );
};

export const useApi = () => {
  const context = useContext(ApiContext);
  if (!context) {
    throw new Error("useApi must be used within an ApiProvider");
  }
  return context;
};
