import TextImagewFlex from "../../../shared/textImageFlex";
import { useTranslation } from "react-i18next";
import AssetsCodeImage from "../../../assets/assets/Assets_Code.webp";
const Accessinformation = () => {
  const { t } = useTranslation();
  return (
    <div className="py-24 bg-[#F3F6FB]">
      <TextImagewFlex
        img={AssetsCodeImage}
        heading={t("assetpage.accessinfoheading")}
        para={t("assetpage.accessinfopara")}
        reverse
      />
    </div>
  );
};
export default Accessinformation;
