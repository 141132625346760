const Video = () => {
  return (
    <div className="container mx-auto px-4 sm:px-6 md:px-12">
      {/* Heading */}
      <h1 className="text-center font-black leading-tight text-[#293745] text-xl md:text-3xl lg:text-[3.0625rem] mb-8">
        Powered by the SafetyCulture platform, Davies Chocolates aren't just
        insuring their business – they're improving it.
      </h1>

      {/* Video */}
      <div className="w-full max-w-4xl mx-auto h-[520px] sm:h-[400px] lg:h-[520px] pt-10">
        <iframe
          id="player"
          width="100%"
          height="100%"
          src="https://www.youtube.com/embed/cc995971-77c4-4be0-9fd5-5740d0c0340c"
          title="YouTube video player"
          className="rounded-xl"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>
      </div>
    </div>
  );
};

export default Video;
