import React from "react";
import ColesLogo from "../../../assets/integrations/logoColes.svg";
import { useTranslation } from "react-i18next";

const YouTubeEmbed = () => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col items-center justify-center bg-gray-100 py-24">
      <h1 className="text-2xl md:text-3xl lg:text-5xl font-black text-[#293745] mb-12 text-center">
        {t("integrationpage.learnmore")}
      </h1>

      {/* Video Container */}
      <div className="w-full max-w-4xl h-[520px] mb-12">
        <iframe
          id="player"
          width="100%"
          height="100%"
          src="https://www.youtube.com/embed/6anSwCLRpt8"
          title="YouTube video player"
          frameBorder="0"
          className="rounded-xl"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>
      </div>

      <div className="container rounded-xl py-6 px-10 mt-12 mx-auto w-[98%] md:w-[92%] border border-[#D0D7E1]">
        <div className="flex justify-between mb-6">
          <div className="text-gray-200 text-5xl">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="w-12 h-12"
            >
              <path d="M7.5 2.25C5.43 2.25 3.75 3.93 3.75 6v4.5a.75.75 0 0 0 .75.75h4.5a.75.75 0 0 0 .75-.75v-3a.75.75 0 0 0-.75-.75H6.75A.75.75 0 0 1 6 6c0-.69.56-1.25 1.25-1.25h.375a.375.375 0 0 0 .375-.375v-1.5A.375.375 0 0 0 7.625 2.5H7.5zm9 0C14.43 2.25 12.75 3.93 12.75 6v4.5a.75.75 0 0 0 .75.75h4.5a.75.75 0 0 0 .75-.75v-3a.75.75 0 0 0-.75-.75h-1.5a.75.75 0 0 1-.75-.75c0-.69.56-1.25 1.25-1.25h.375a.375.375 0 0 0 .375-.375v-1.5a.375.375 0 0 0-.375-.375H16.5z" />
            </svg>
          </div>
          <div className="text-gray-200 text-5xl">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="w-12 h-12"
            >
              <path d="M7.5 2.25C5.43 2.25 3.75 3.93 3.75 6v4.5a.75.75 0 0 0 .75.75h4.5a.75.75 0 0 0 .75-.75v-3a.75.75 0 0 0-.75-.75H6.75A.75.75 0 0 1 6 6c0-.69.56-1.25 1.25-1.25h.375a.375.375 0 0 0 .375-.375v-1.5A.375.375 0 0 0 7.625 2.5H7.5zm9 0C14.43 2.25 12.75 3.93 12.75 6v4.5a.75.75 0 0 0 .75.75h4.5a.75.75 0 0 0 .75-.75v-3a.75.75 0 0 0-.75-.75h-1.5a.75.75 0 0 1-.75-.75c0-.69.56-1.25 1.25-1.25h.375a.375.375 0 0 0 .375-.375v-1.5a.375.375 0 0 0-.375-.375H16.5z" />
            </svg>
          </div>
        </div>

        {/* Author details */}
        <div className="text-center">
          <h5 className="font-bold text-[#293745] text-[1rem]">
            {t("integrationpage.name")}
          </h5>
          <p className="text-[#293745] text-[1rem]">
            {t("integrationpage.para")}
          </p>

          <div className="mt-7">
            <img src={ColesLogo} alt="Coles" className="mx-auto h-16 w-auto" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default YouTubeEmbed;
