import ImprovementSection from "../../../shared/improvementSection";
const ImprovementTwo = () => {
  return (
    <ImprovementSection
      heading="See how SafetyCulture can help your facility management business"
      btn1="Get started for free"
      btn2=" Book a demo"
    />
  );
};
export default ImprovementTwo;
