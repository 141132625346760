import React, { useState } from "react";
import CsvUploadTab from "./bulkUploadSitesTabs/uplaodCSVTab";
import AssignFields from "./bulkUploadSitesTabs/assignFields";
import ReviewData from "./bulkUploadSitesTabs/reviewData";
const BulkUploadSites = () => {
  const [activeTab, setActiveTab] = useState("Upload CSV");
  const renderContent = () => {
    switch (activeTab) {
      case "Upload CSV":
        return <CsvUploadTab />;
      case "Assign fields":
        return <AssignFields />;
      case "Review data":
        return <ReviewData />;
      //   case "Templates":
      //     return <RegionTab/>;
      default:
        return null;
    }
  };
  return (
    <div className="p-4 md:p-8  mx-auto">
      <div className="flex justify-between w-full md:w-[53%] mb-7">
      <button className="font-bold text-[#4740d4] text-center border border-[#bfc6d4] py-3 px-4 rounded-lg">Cancel and exit</button>
        <h1 className="font-bold text-gray-800 text-center text-xl pt-2">Bulk upload sites</h1>
      </div>
      {/* Tabs */}
      <div className="flex space-x-8 border-b border-gray-300">
        {["Upload CSV", "Assign fields", "Review data", "Upload sites"].map(
          (tab) => (
            <button
              key={tab}
              onClick={() => setActiveTab(tab)}
              className={`pb-2 px-4 font-medium ${
                activeTab === tab
                  ? "border-b-2 border-purple-600 text-purple-600"
                  : "text-gray-600"
              }`}
            >
              {tab}
            </button>
          )
        )}
      </div>

      {/* Main Content */}
      <div className="mt-8">{renderContent()}</div>
    </div>
  );
};

export default BulkUploadSites;
