import CurveHeroFlex from "../../../shared/curveHero";
import HeroImage from "../../../assets/construction/constructhero.webp"
import Image1 from "../../../assets/construction/image1.svg";
import Image2 from "../../../assets/construction/image2.svg";
import Image3 from "../../../assets/construction/image3.svg";
import Image4 from "../../../assets/construction/image4.svg";
import Image5 from "../../../assets/construction/image5.svg";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
const ImagesArray = [
    {
      id: 1,
      img: Image1,
    },
    {
      id: 2,
      img: Image2,
    },
    {
      id: 3,
      img: Image3,
    },
    {
      id: 4,
      img: Image4,
    },
    {
      id: 5,
      img: Image5,
    },
  ];
const ConstructionHero = () => {
  const { t } = useTranslation();
  const isArabic = i18next.language === "ar";
  return (
    <div
      className={` ${isArabic ? "text-right" : "text-left"} ${
        isArabic ? "rtl" : ""
      }`}
    >
      <CurveHeroFlex
        span={t("constructionpage.constructionHero")}
        img={HeroImage}
        heading={t("constructionpage.constructionheading")}
        para={t("constructionpage.constructionpara")}
        btn1={t("constructionpage.constructionbtn1")}
        btn2={t("constructionpage.constructionbtn2")}
        ImagesArray={ImagesArray}
      />
    </div>
  );
};
export default ConstructionHero;
