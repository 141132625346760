import React from "react";
import { useNavigate } from "react-router-dom";

const CurveHeroFlex = ({
  span,
  heading,
  para,
  btn1,
  btn2,
  img,
  ImagesArray,
}) => {
  const navigate = useNavigate();

  return (
    <section className="py-1 px-4 sm:px-6 lg:px-0 lg:ps-6">
      <div className="max-w-7xl mx-auto flex flex-col-reverse lg:flex-row items-center justify-between">
        {/* Left Side (Text and Buttons) */}
        <div className="w-full lg:w-[58%] text-start space-y-6">
          <span className="inline-block border-t border-b border-r border-[3px] border-l-[.1875rem] border-[#293745] text-sm text-[#293745] px-3 py-1 rounded-full">
            {span}
          </span>

          <h1 className="text-[2.2rem] md:text-[3.2rem] lg:text-[3.8125rem] font-bold text-[#293745] md:leading-[77px]">
            {heading}
          </h1>
          <p className="text-md md:text-[1.25rem] text-[#293745]">{para}</p>
          <div className="flex space-x-4 mb-2">
            <button
              className="bg-[#6559FF] text-white py-3 px-2 md:px-6 rounded-lg hover:bg-blue-700"
              onClick={() => {
                navigate("/signup");
              }}
            >
              {btn1}
            </button>

            <button
              className="border border-[#d0d7e1] font-medium text-[#6559FF] py-3 px-6 rounded-lg hover:bg-[#f1ebff]"
              onClick={() => {
                navigate("/book-demo");
              }}
            >
              {btn2}
            </button>
          </div>
        </div>

        {/* Right Side (Image) */}
        <div className="w-full lg:w-[42%] mb-12 lg:mb-0">
          <div className="relative flex justify-center md:justify-end">
            <img
              src={img} // Make sure this is correct
              alt="Inspecting"
              className="border-s-4 border-[#293745] rounded-s-full w-full h-auto md:h-[490px] object-contain"
            />
          </div>
        </div>
      </div>
      <div className="flex justify-center flex-wrap space-x-3 md:space-x-24 pt-12 pb-12 md:pt-24 md:pb-14">
        {ImagesArray?.map((images, index) => (
          <img key={index} src={images.img} alt="Additional Image" />
        ))}
      </div>
    </section>
  );
};

export default CurveHeroFlex;
