import AnalyticsHero from "../../../assets/mining/ConstructionTraining-2.webp";
import TextImageFlex from "../../../shared/textImageFlex";
const ImproveUptime = () => {
  return (
    <div className="py-10">
      <TextImageFlex
        heading="Onboard and upskill teams quickly"
        para="Mining projects require a complex and diverse group of partners to ensure operations run on time. Help contractors get up to speed quickly with process checklists, digital work instructions, SOPs,  and induction training. SafetyCulture makes it easy to assimilate the vital information needed to get the job done."
        img={AnalyticsHero}
        widhtClass="w-full"
        reverse
      />
    </div>
  );
};

export default ImproveUptime;
