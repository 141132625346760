import FAQSection from "../../../shared/faqs";
import { useTranslation } from "react-i18next";

const Faqs = () => {
  // Call the useTranslation hook
  const { t } = useTranslation();

  // Define the FAQ data using `t()` for translations
  const faqData = [
    {
      question: t("manufacturingfaqs.manufacturingfaqsquestion0"),
      answer: t("manufacturingfaqs.manufacturingfaqsanswer0"),
    },
    {
      question: t("manufacturingfaqs.manufacturingfaqsquestion1"),
      answer: t("manufacturingfaqs.manufacturingfaqsanswer1"),
    },
    {
      question: t("manufacturingfaqs.manufacturingfaqsquestion2"),
      answer:
      t("manufacturingfaqs.manufacturingfaqsanswer2"),
    },
    {
      question: t("manufacturingfaqs.manufacturingfaqsquestion3"),
      answer:
      t("manufacturingfaqs.manufacturingfaqsanswer3"),
    },
    {
      question: t("manufacturingfaqs.manufacturingfaqsquestion4"),
      answer:
      t("manufacturingfaqs.manufacturingfaqsanswer4"),
    },
  ];

  // Return the FAQ section with translated data
  return <FAQSection faqData={faqData} heading={t("manufacturingfaqs.manufacturingfaqsheading")} />;
};

export default Faqs;






// <>
      //   A manufacturing inspection software like the SafetyCulture platform can
      //   support various types of inspections across different stages of the
      //   manufacturing process. Which are:
      //   <ul className="list-disc ps-4 pt-3">
      //     <li className="text-[#4740D6]">Incoming inspection</li>
      //     <li className="text-[#4740D6]">In-process inspection</li>
      //     <li className="text-[#4740D6]">Quality Control Inspections</li>
      //     <li>Safety Inspections; and</li>
      //     <li>Process Audits.</li>
      //   </ul>
      // </>,