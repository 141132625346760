import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import Logo from "../../assets/home/logoSSC.jpg";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import useHttp from "../../common/hooks/useHttp";
import Loader from "../../common/loader/Loader";

const SignUpPage = () => {
  const { t } = useTranslation();
  const isArabic = i18next.language === "ar";
  const navigate = useNavigate();
  const { sendRequest, loading } = useHttp();

  // State to manage form input values
  const [formData, setFormData] = useState({
    email: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    password: "",
    consent: false,
    organizationName: "",
    organizationPhoneNumber: "",
    organizationWebsite: "",
  });

  // Function to handle form input changes
  const handleInputChange = (e) => {
    const { id, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [id]: type === "checkbox" ? checked : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await sendRequest(
        {
          url: "v1/auth/register",
          method: "POST",
          payload: {
            email: formData.email,
            firstName: formData.firstName,
            lastName: formData.lastName,
            phoneNumber: formData.phoneNumber,
            password: formData.password,
            consent: formData.consent,
            organizationName: formData.organizationName,
            organizationPhoneNumber: formData.organizationPhoneNumber,
            organizationWebsite: formData.organizationWebsite,
          },
          headers: {
            userName: formData.email,
          },
        },
        (response) => {
          if (response) {
            if (response.responseCode == "200") {
              Swal.fire({
                title: response.responseDesc,
                text: "Your account has been created. You will now be redirected to the login page.",
                icon: "success",
                timer: 2000, // 2 seconds timer
                showConfirmButton: false,
              }).then(() => {
                navigate("/login");
              });
            } else {
              Swal.fire({
                title: "Error",
                text:
                  response.responseDesc ||
                  "An error occurred. Please try again.",
                icon: "error",
                confirmButtonText: "OK",
              });
            }
          }
          // Show success message using SweetAlert
        }
      );
    } catch (error) {
      // Show error message using SweetAlert
      Swal.fire({
        title: "Error",
        text: error || "An error occurred. Please try again.",
        icon: "error",
        confirmButtonText: "OK",
      });

      console.error("Error occurred:", error.message);
    }
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div
          className="flex flex-col items-center bg-[#edf0f7] min-h-screen"
          dir={isArabic ? "rtl" : "ltr"} // Apply RTL or LTR direction
        >
          <div className="flex justify-center w-full my-6">
            <img src={Logo} alt="SafetyCulture Logo" className="h-14 w-auto" />
          </div>

          <div className="shadow-lg rounded-lg p-8 w-[90%] max-w-[500px] ">
            <h1 className="text-start text-2xl font-bold text-[#3f495a] mb-2">
              {t("signuppage.createheading")}
            </h1>
            <p className="text-start text-sm text-[#545f70] mb-6">
              {t("signuppage.createpara")}
            </p>

            <form onSubmit={handleSubmit}>
              <label
                className="text-start block text-sm font-semibold text-[#3f495a] mb-1"
                htmlFor="email"
              >
                {t("signuppage.emaillabel")}
              </label>
              <input
                type="email"
                id="email"
                value={formData.email}
                onChange={handleInputChange}
                className="w-full border border-gray-300 rounded-md p-2 mb-4 focus:outline-none focus:border-indigo-500"
                placeholder={t("signuppage.emailplaceholder")}
                required
              />

              <div className="flex flex-col sm:flex-row sm:space-x-2 mb-4">
                <div className="w-full sm:w-1/2 mb-4 sm:mb-0">
                  <label
                    className="text-start block text-sm font-semibold text-[#3f495a] mb-1"
                    htmlFor="firstName"
                  >
                    {t("signuppage.firstnamelabel")}
                  </label>
                  <input
                    type="text"
                    id="firstName"
                    value={formData.firstName}
                    onChange={handleInputChange}
                    className="w-full border border-gray-300 rounded-md p-2 focus:outline-none focus:border-indigo-500"
                    placeholder={t("signuppage.firstnameplaceholder")}
                    required
                  />
                </div>

                <div className="w-full sm:w-1/2">
                  <label
                    className="text-start block text-sm font-semibold text-[#3f495a] mb-1"
                    htmlFor="lastName"
                  >
                    {t("signuppage.lastnamelabel")}
                  </label>
                  <input
                    type="text"
                    id="lastName"
                    value={formData.lastName}
                    onChange={handleInputChange}
                    className="w-full border border-gray-300 rounded-md p-2 focus:outline-none focus:border-indigo-500"
                    placeholder={t("signuppage.lastnameplaceholder")}
                    required
                  />
                </div>
              </div>

              <label
                className="text-start block text-sm font-semibold text-[#3f495a] mb-1"
                htmlFor="phoneNumber"
              >
                {t("signuppage.phone")}
              </label>
              <input
                type="tel"
                id="phoneNumber"
                value={formData.phoneNumber}
                onChange={handleInputChange}
                className="w-full border border-gray-300 rounded-md p-2 mb-4 focus:outline-none focus:border-indigo-500"
                placeholder={t("signuppage.phoneplaceholder")}
              />

              <label
                className="text-start block text-sm font-semibold text-[#3f495a] mb-1"
                htmlFor="password"
              >
                {t("signuppage.password")}
              </label>
              <div className="relative mb-4">
                <input
                  type="password"
                  id="password"
                  value={formData.password}
                  onChange={handleInputChange}
                  className="w-full border border-gray-300 rounded-md p-2 focus:outline-none focus:border-indigo-500"
                  placeholder={t("signuppage.passwordplaceholder")}
                  required
                />
              </div>

              <label
                className="text-start block text-sm font-semibold text-[#3f495a] mb-1"
                htmlFor="organizationName"
              >
                {t("signuppage.organization")}
              </label>
              <input
                type="text"
                id="organizationName"
                value={formData.organizationName}
                onChange={handleInputChange}
                className="w-full border border-gray-300 rounded-md p-2 mb-4 focus:outline-none focus:border-indigo-500"
                placeholder={t("signuppage.organizationplaceholder")}
              />

              <label
                className="text-start block text-sm font-semibold text-[#3f495a] mb-1"
                htmlFor="organizationPhoneNumber"
              >
                {t("signuppage.organizationphone")}
              </label>
              <input
                type="tel"
                id="organizationPhoneNumber"
                value={formData.organizationPhoneNumber}
                onChange={handleInputChange}
                className="w-full border border-gray-300 rounded-md p-2 mb-4 focus:outline-none focus:border-indigo-500"
                placeholder={t("signuppage.organizationphoneplaceholder")}
              />

              <label
                className="text-start block text-sm font-semibold text-[#3f495a] mb-1"
                htmlFor="organizationWebsite"
              >
                {t("signuppage.website")}
              </label>
              <input
                type="url"
                id="organizationWebsite"
                value={formData.organizationWebsite}
                onChange={handleInputChange}
                className="w-full border border-gray-300 rounded-md p-2 mb-4 focus:outline-none focus:border-indigo-500"
                placeholder={t("signuppage.websiteplaceholder")}
              />

              <div className="flex items-start mb-6">
                <input
                  type="checkbox"
                  id="consent"
                  checked={formData.consent}
                  onChange={handleInputChange}
                  className="h-4 w-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
                />
                <label
                  htmlFor="consent"
                  className="text-start ml-2 text-sm text-gray-600"
                >
                  {t("signuppage.labelpara")}
                </label>
              </div>

              <button
                type="submit"
                className="w-full py-2 px-4 bg-[#6559FF] text-white rounded-md hover:bg-[#4e4bcd] focus:outline-none"
              >
                {t("signuppage.createcountbtn")}
              </button>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default SignUpPage;
