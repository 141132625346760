import HeroSection from "../../components/products/safetyDocs/hero";
import TrustedResource from "../../components/products/safetyDocs/trustedresource";
import Testimonial from "../../components/products/safetyDocs/testimonial";
import ServicesSection from "../../components/products/safetyDocs/serviceSection";
const SafetyDocs = () => {
  return (
    <div>
      <HeroSection />
      <TrustedResource/>
      <Testimonial/>
      <ServicesSection/>
    </div>
  );
};
export default SafetyDocs;
