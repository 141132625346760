import React from "react";
const construction = require("../../../assets/industrySection/constructionicon.png");
const facility = require("../../../assets/industrySection/facility.png");
const hospatility = require("../../../assets/industrySection/hospitality.png");
const manufacturing = require("../../../assets/industrySection/manufacturing.png");
const retail = require("../../../assets/industrySection/retail.jpg");
const transportation = require("../../../assets/industrySection/transportation.png");
const energy = require("../../../assets/industrySection/energy.jpg");
const other = require("../../../assets/industrySection/other.png");
const logo = require("../../../assets/ssclogo/ssclogo.png");

function IndustrySelection() {
  return (
    <div className="flex flex-col items-center p-4 sm:p-8 min-h-screen mt-6">
      <div className="max-w-5xl w-full mx-auto">
        {/* Header */}
        <header className="flex flex-col items-left text-left mb-8">
          <img src={logo} alt="SafetyCulture Logo" className="w-32 mb-4" />
          <h2 className="text-gray-500 text-sm font-medium">Step 1 of 2</h2>
          <h1 className="text-2xl font-bold mt-2">
            Which industry best describes your business?
          </h1>
          <p className="text-gray-600 mt-2">
            We’ll use this to recommend industry templates to help you get
            started.
          </p>
        </header>

        {/* Industry Options */}
        <div className="mt-8 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
          {/* Sample buttons for each industry. Replace with appropriate icons */}
          <button className="w-full h-20 flex items-center justify-center p-4 border rounded-lg bg-white hover:shadow-md transition">
            <img
              src={construction}
              alt="Construction Icon"
              className="w-8 h-8 mr-2"
            />
            <span className="text-gray-700 font-medium">Construction</span>
          </button>
          <button className="w-full h-20 flex items-center justify-center p-4 border rounded-lg bg-white hover:shadow-md transition">
            <img src={facility} alt="Facilities Icon" className="w-8 h-8" />
            <span className="text-gray-700 font-medium">
              Facilities Management
            </span>
          </button>
          <button className="w-full h-20 flex items-center justify-center p-4 border rounded-lg bg-white hover:shadow-md transition">
            <img
              src={hospatility}
              alt="Hospitality Icon"
              className="w-8 h-8 mr-2"
            />
            <span className="text-gray-700 font-medium">Hospitality</span>
          </button>
          <button className="w-full h-20 flex items-center justify-center p-4 border rounded-lg bg-white hover:shadow-md transition">
            <img
              src={manufacturing}
              alt="Manufacturing Icon"
              className="w-8 h-8 mr-2"
            />
            <span className="text-gray-700 font-medium">Manufacturing</span>
          </button>
          <button className="w-full h-20 flex items-center justify-center p-4 border rounded-lg bg-white hover:shadow-md transition">
            <img src={retail} alt="Retail Icon" className="w-8 h-8 mr-2" />
            <span className="text-gray-700 font-medium">Retail</span>
          </button>
          <button className="w-full h-20 flex items-center justify-center p-4 border rounded-lg bg-white hover:shadow-md transition">
            <img
              src={transportation}
              alt="Transportation Icon"
              className="w-8 h-8 mr-2"
            />
            <span className="text-gray-700 font-medium">
              Transportation and logistics
            </span>
          </button>
          <button className="w-full h-20 flex items-center justify-center p-4 border rounded-lg bg-white hover:shadow-md transition">
            <img src={energy} alt="Energy Icon" className="w-8 h-8 mr-3" />
            <span className="text-gray-700 font-medium">
              Energy and utilities
            </span>
          </button>
          <button className="w-full h-20 flex items-center justify-center p-4 border rounded-lg bg-white hover:shadow-md transition">
            <img src={other} alt="Other Icon" className="w-8 h-8 mr-3" />
            <span className="text-gray-700 font-medium">Other</span>
          </button>
        </div>

        {/* Next Button */}
        <div className="flex justify-start mt-8">
          <button className="px-6 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition">
            Next
          </button>
        </div>
      </div>
    </div>
  );
}

export default IndustrySelection;
