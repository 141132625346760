import React from "react";
import { useNavigate } from "react-router-dom";

const UploadCSV = () => {
  const navigate = useNavigate();

  return (
    <div className="bg-gray-50 p-4 sm:p-8 h-screen flex flex-col items-center">
      <div className="w-full max-w-4xl">
        {/* Navigation Tabs */}
        <div className="flex flex-wrap justify-center gap-4 sm:gap-8 border-b pb-4">
          <button
            onClick={() => navigate("/dashboard/asset-bulk")}
            className="text-blue-600 font-medium border-b-2 border-blue-600 px-2 sm:px-4"
          >
            Prepare CSV
          </button>
          <button
            onClick={() => navigate("/dashboard/uploadcsv")}
            className="text-gray-500 font-medium px-2 sm:px-4"
          >
            Upload CSV
          </button>
          <button
            onClick={() => navigate("/dashboard/assets/types")}
            className="text-gray-500 font-medium px-2 sm:px-4"
          >
            Create assets
          </button>
        </div>

        {/* Header */}
        <h1 className="text-lg sm:text-xl font-semibold text-gray-800 mb-6 mt-6 text-center sm:text-left">
          Select CSV file to upload
        </h1>

        {/* Drag and Drop Area */}
        <div className="flex items-center justify-center">
          <div className="w-full max-w-xl bg-white border-2 border-dashed border-gray-300 rounded-lg p-6 sm:p-8 flex flex-col items-center justify-center text-center">
            <div className="text-purple-600 text-3xl sm:text-4xl mb-4">📤</div>
            <p className="text-gray-500 text-sm sm:text-base">
              Drag your CSV file (<span className="font-medium">10MB</span>)
              here
            </p>
            <p className="text-gray-500 text-sm sm:text-base">
              or{" "}
              <a
                href="#"
                className="text-blue-600 underline hover:text-blue-800"
              >
                browse
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UploadCSV;
