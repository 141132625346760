import React from "react";
import { AiOutlineInfoCircle, AiOutlineEdit } from "react-icons/ai";

const NotificationSettings = () => {
  const notificationData = {
    Actions: [
      "Action assigned",
      "Action completed",
      "Comments added",
      "Media added",
      "Actions reminders due soon",
      "Actions reminders overdue",
    ],
    Issues: [
      "New issue reported",
      "Issue assigned or shared",
      "Issue resolved",
      "Comments, or answers added",
      "Media added",
      "Issue reminders due soon",
      "Issue reminders overdue",
    ],
    Inspections: [
        "Inspection triggered notifications",
        "Inspection request approval",
        "Inspection approved",
       
      ],
      Sensors: [
        "Sensor alert triggered",       
      ],
      HeadsUp: [
        "Heads Up assigned",
        "Assigned Heads Up reminder",
        "Heads Up completion rate",
       "Heads Up completed",
       "Published Heads Up reminder",
       "New comment or reply",
      ],
      Credentials: [
        "Credential reminder",       
      ],
  };

  const renderItems = (items) => {
    return items.map((item, index) => (
      <div key={index} className="flex  py-2">
        <div >
          <p className="text-sm font-medium text-gray-700">{item}</p>
          <div className="text-sm text-gray-500 text-start">Push+, Email</div>
        </div>
        <AiOutlineInfoCircle className="ml-2 text-gray-400" />
      </div>
    ));
  };

  return (
    <div className="container mx-auto  py-4">
      <div className="w-full bg-white rounded-lg  p-6 border border-[#dbe0eb]">
        <div className="flex justify-between items-center">
          <h2 className="text-lg font-semibold text-gray-900">
            Notification settings
          </h2>
          <button className="flex items-center text-purple-500 hover:underline">
            <AiOutlineEdit className="mr-1" /> Edit
          </button>
        </div>
        <div className="mt-8">
          <div>
            <h3 className="text-lg font-semibold text-gray-900 text-start">
              Actions
            </h3>
            <div className="mt-2 border-b border-gray-200 pb-4">
              {renderItems(notificationData.Actions)}
            </div>
          </div>
          <div className="mt-6">
            <h3 className="text-lg font-semibold text-gray-900 text-start">
              Issues
            </h3>
            <div className="mt-2">{renderItems(notificationData.Issues)}</div>
          </div>
          <div className="mt-6">
            <h3 className="text-lg font-semibold text-gray-900 text-start">
            Inspections
            </h3>
            <div className="mt-2">{renderItems(notificationData.Inspections)}</div>
          </div>
          <div className="mt-6">
            <h3 className="text-lg font-semibold text-gray-900 text-start">
            Sensors
            </h3>
            <div className="mt-2">{renderItems(notificationData.Sensors)}</div>
          </div>
          <div className="mt-6">
            <h3 className="text-lg font-semibold text-gray-900 text-start">
            Heads Up
            </h3>
            <div className="mt-2">{renderItems(notificationData.HeadsUp)}</div>
          </div>
          <div className="mt-6">
            <h3 className="text-lg font-semibold text-gray-900 text-start">
            Credentials
            </h3>
            <div className="mt-2">{renderItems(notificationData.HeadsUp)}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotificationSettings;
