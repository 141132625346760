import { FaTruck, FaShoppingBag, FaBuilding } from "react-icons/fa";
import { TbSettingsCog } from "react-icons/tb";
import { MdConstruction } from "react-icons/md";
import { LuHardHat } from "react-icons/lu";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

const Industries = () => {
  const { t } = useTranslation();
  const isArabic = i18next.language === "ar";

  // Define industries with translated names
  const industries = [
    { name: t("inspectionpage.name1"), icon: <TbSettingsCog /> },
    { name: t("inspectionpage.name2"), icon: <MdConstruction /> },
    { name: t("inspectionpage.name3"), icon: <FaTruck /> },
    { name: t("inspectionpage.name4"), icon: <LuHardHat /> },
    { name: t("inspectionpage.name5"), icon: <FaBuilding /> },
    { name: t("inspectionpage.name6"), icon: <FaShoppingBag /> },
  ];

  return (
    <div className="bg-[#F3F6FB] py-20 px-4">
      <div
        className={` ${isArabic ? "text-right" : "text-left"} ${
          isArabic ? "rtl" : ""
        }`}
      >
        {/* Heading */}
        <h2 className="text-lg sm:text-xl md:text-3xl lg:text-5xl font-bold text-[#293745] !leading-[60px]">
          {t("inspectionpage.industries")}
        </h2>

        {/* Industries grid */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 text-center pt-12">
          {industries.map((industry, index) => (
            <div key={index} className="flex flex-col items-center space-y-4">
              <div className="text-3xl text-gray-600">{industry.icon}</div>
              <p className="text-gray-700 font-medium">{industry.name}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Industries;
