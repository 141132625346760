import Homepage from "../pages/home";
import Platform from "../pages/products/platform";
import Inspection from "../pages/products/inspection";
import Training from "../pages/products/training";
import Assets from "../pages/products/assets";
import Integration from "../pages/products/integrations";
import LoneWorker from "../pages/products/loneWorker";
import SafetyDocs from "../pages/products/safetyDocs";
import SecureCare from "../pages/products/secureCare";
import Construction from "../pages/solutions/constructions";
import Manufacturing from "../pages/solutions/manufacturing";
import FacilitiesManagement from "../pages/solutions/facilitiesManagement";
import Hospatility from "../pages/solutions/hospatility";
import Retail from "../pages/solutions/retail";
import Transport from "../pages/solutions/transport";
import Mining from "../pages/solutions/mining";
import SupportPage from "../pages/support";
import Login from "../pages/login";
import SignUpPage from "../pages/signup";
import Customers from "../pages/customers";
import PricingPage from "../pages/pricing";
import { Route, Routes } from "react-router-dom";
import HealthSafety from "../pages/solutions/healthSafety";
import About from "../components/AboutUs/About";
import Career from "../components/CareerPage/Career";
import TermsCondition from "../components/TermsAndConditions/TermsCondition";
import PrivacyPolicy from "../components/PrivacyPotal/PrivacyPolicy";
import ForgotPassword from "../components/forgetPassword/ForgotPassword";
import ResetPassword from "../components/ResetPassword/ResetPassword";
import DemoForm from "../shared/form";
import EnterprisePage from "../pages/EnterprisePage";
import CreateBlankTemplate from "../shared/adminPortal/createblankTamplate";

function WebRoutes() {
  return (
    <div className="App overflow-hidden">
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/book-demo" element={<DemoForm />} />
        <Route path="/about" element={<About />} />
        <Route path="/career" element={<Career />} />
        <Route path="/terms-and-condition" element={<TermsCondition />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="/platform" element={<Platform />} />
        <Route path="/inspection" element={<Inspection />} />
        <Route path="/training" element={<Training />} />
        <Route path="/assets" element={<Assets />} />
        <Route path="/integrations" element={<Integration />} />
        <Route path="/lone-worker" element={<LoneWorker />} />
        <Route path="/safety-docs" element={<SafetyDocs />} />
        <Route path="/secure-care" element={<SecureCare />} />
        <Route path="/construction" element={<Construction />} />
        <Route path="/manufacturing" element={<Manufacturing />} />
        <Route
          path="/facilities-management"
          element={<FacilitiesManagement />}
        />
        <Route path="/hospitality" element={<Hospatility />} />
        <Route path="/retail" element={<Retail />} />
        <Route path="/transport-logistics" element={<Transport />} />
        <Route path="/health-safety" element={<HealthSafety />} />
        <Route path="/mining" element={<Mining />} />
        <Route path="/contact-us" element={<SupportPage />} />
        <Route path="/login" element={<Login />} />
        <Route path="/createTamplateBlank" element={<CreateBlankTemplate />} />
        <Route path="/signup" element={<SignUpPage />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/customer" element={<Customers />} />
        <Route path="/pricing" element={<PricingPage />} />
        <Route path="/pricing/enterprise" element={<EnterprisePage />} />
        <Route path="/reset-password" element={<ResetPassword />} />
      </Routes>
    </div>
  );
}

export default WebRoutes;
