import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const ScrollToTop = () => {
  const { pathname } = useLocation(); 

  useEffect(() => {
    window.scrollTo(0, 0); 
    
  }, [pathname]); // Runs whenever the route path changes

  return null; // This component does not render any visible content
};

export default ScrollToTop;
