import React, { useState } from "react";
import { FaSearch } from "react-icons/fa";

const Template = ({ template, setTemplate }) => {
  const [isTemplateDropdownOpen, setIsTemplateDropdownOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const templateOptions = ["Template 1", "Template 2"];

  const filteredTemplateOptions = templateOptions.filter((option) =>
    option.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="mt-6">
      <label className="block text-sm font-medium text-gray-700">
        Template
      </label>
      <div className="relative">
        <button
          onClick={() => setIsTemplateDropdownOpen(!isTemplateDropdownOpen)}
          className="mt-2 block w-full border border-grey rounded-md h-12 px-3 bg-white focus:ring-0 focus:border-black hover:border-indigo-500 transition text-left"
        >
          {template || "Add template..."}
        </button>
        {isTemplateDropdownOpen && (
          <div className="absolute mt-1 w-full bg-white border border-grey rounded-md shadow-lg z-10">
            <div className="relative">
              <input
                type="text"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                placeholder="Search templates..."
                className="w-full h-12 pl-10 pr-3 border-b border-grey focus:outline-none"
              />
              <FaSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
            </div>
            <ul className="max-h-48 overflow-y-auto">
              {filteredTemplateOptions.length > 0 ? (
                filteredTemplateOptions.map((option, index) => (
                  <li
                    key={index}
                    onClick={() => {
                      setTemplate(option);
                      setIsTemplateDropdownOpen(false);
                      setSearchTerm("");
                    }}
                    className="px-3 py-2 cursor-pointer hover:bg-indigo-500 hover:text-white transition"
                  >
                    {option}
                  </li>
                ))
              ) : (
                <li className="px-3 py-2 text-gray-500">No templates found</li>
              )}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default Template;
