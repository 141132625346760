import { useTranslation } from "react-i18next";
import i18next from "i18next";
const About = () => {
  const { t } = useTranslation();
  const isArabic = i18next.language === "ar";
  return (
    <div className="">
      <div className={`py-24 ${isArabic ? "text-right rtl" : "text-left"}`}>
        <div className="container mx-auto px-4">
          {/* Heading Section */}
          <div className="flex flex-col md:flex-row items-center justify-between max-w-7xl mx-auto">
            {/* Text Section */}
            <div className="text-center md:w-1/2">
              <h1 className="text-3xl md:text-4xl lg:text-5xl font-extrabold text-gray-900">
                <span>{t("aboutpage.aboutheading1")}</span>
              </h1>
              <p className="text-lg text-gray-600 max-w-3xl mx-auto md:mx-0 mt-4">
                {t("aboutpage.aboutpara")}
              </p>
            </div>

            {/* Image Section */}
            <div className="mt-6 md:mt-0 md:w-1/2">
              <img
                src={require("../../assets/aboutImages/about-team.jpeg")}
                alt="About Team"
                className="w-full h-auto md:h-96 mx-2 shadow-xl mb-5 object-contain rounded-l-[200px] rounded-r-none"
              />
            </div>
          </div>

          {/* Statistics Section */}
          <div className="mt-24 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 text-center">
            {/* Statistic 1 */}
            <div>
              <h2 className="text-4xl sm:text-5xl font-bold text-gray-900">
                1B
              </h2>
              <div className="w-64 h-1 bg-[#6559ff] my-2 mx-auto"></div>
              <p className="text-gray-600 text-sm">{t("aboutpage.star1")}</p>
            </div>
            {/* Statistic 2 */}
            <div>
              <h2 className="text-4xl sm:text-5xl font-bold text-gray-900">
                77K
              </h2>
              <div className="w-64 h-1 bg-[#00d1ff] my-2 mx-auto"></div>
              <p className="text-gray-600 text-sm">{t("aboutpage.star2")}</p>
            </div>
            {/* Statistic 3 */}
            <div>
              <h2 className="text-4xl sm:text-5xl font-bold text-gray-900">
                75K+
              </h2>
              <div className="w-64 h-1 bg-yellow-500 my-2 mx-auto"></div>
              <p className="text-gray-600 text-sm">{t("aboutpage.star3")}</p>
            </div>
            {/* Statistic 4 */}
            <div>
              <h2 className="text-4xl sm:text-5xl font-bold text-gray-900 w-64 border-b-4 border-black pb-2 mx-auto">
                180+
              </h2>
              <p className="text-gray-600 text-sm my-2">
                {t("aboutpage.star4")}
              </p>
            </div>
          </div>
        </div>

        {/* So Far Section */}
        <div className="bg-gray-100 py-16 mt-14">
          <div className="container max-w-7xl mx-auto px-4 grid grid-cols-1 lg:grid-cols-2 gap-10">
            {/* Left Side - Text Section */}
            <div>
              <h2 className="text-3xl sm:text-4xl md:text-5xl font-extrabold text-gray-900 mb-6">
                {t("aboutpage.sofarheading")}
              </h2>
              <p className="text-gray-700 mb-4 text-base py-2">
                {t("aboutpage.sofarpara1")}
              </p>
              <p className="text-gray-700 mb-4 text-base py-2">
                {t("aboutpage.sofarpara2")}
              </p>
              <p className="text-gray-700 mb-4 text-base py-2">
                {t("aboutpage.sofarpara3")}
              </p>
              <p className="text-gray-700 mb-4 text-base py-2">
                {t("aboutpage.sofarpara4")}
              </p>
            </div>

            {/* Right Side - Image Section */}
            <div className="mt-6 lg:mt-0">
              <img
                src={require("../../assets/aboutImages/SafetyCultureStory.jpg")}
                alt="Image 1"
                className="rounded-lg shadow-md w-full h-auto object-cover"
              />
            </div>
          </div>
        </div>

        {/* Our Platform Section */}
        <div className="py-16 mt-12">
          <div className="container max-w-5xl mx-auto px-4 text-center">
            {/* Section Header */}
            <h2 className="text-3xl sm:text-4xl lg:text-5xl font-black text-gray-900 mb-4">
              {t("aboutpage.ourplatformheading")}
            </h2>
            <p className="text-black text-xl mb-12 mt-12">
              {t("aboutpage.platfoempara")}
            </p>

            {/* Logo Section */}
            <div className="flex flex-wrap justify-center gap-12">
              <img
                src={require("../../assets/aboutImages/41.jpg")}
                alt="SafetyCulture Care"
                className="h-16"
              />
              <img
                src={require("../../assets/aboutImages/42.jpg")}
                alt="SafetyDocs"
                className="h-16"
              />
              <img
                src={require("../../assets/aboutImages/43.jpg")}
                alt="SHEQSY"
                className="h-16"
              />
              <img
                src={require("../../assets/aboutImages/44.jpg")}
                alt="EdApp"
                className="h-16"
              />
            </div>
          </div>
        </div>

        <div className="bg-gray-100 py-10">
          {/* Section heading */}
          <div className="text-center mb-10">
            <h2 className="text-4xl sm:text-5xl font-bold text-gray-800">
              {t("aboutpage.ourcustomerheading")}
            </h2>
          </div>

          {/* Logos Row */}
          <div className="flex flex-wrap justify-center items-center gap-4 sm:gap-8 md:gap-12 mb-10">
            {/* Logo 1 */}
            <div className="w-24 h-24 sm:w-28 sm:h-28 md:w-36 md:h-20 text-left flex justify-center items-center shadow-md rounded-md border">
              <img
                src={require("../../assets/aboutImages/20.jpg")}
                alt="AO Logo"
                className="w-12 h-12 sm:w-14 sm:h-14 md:w-16 md:h-16"
              />
            </div>

            {/* Logo 2 */}
            <div className="w-24 h-24 sm:w-28 sm:h-28 md:w-36 md:h-20 flex justify-center items-center shadow-md rounded-md border">
              <img
                src={require("../../assets/aboutImages/23.jpg")}
                alt="AO Logo"
                className="w-12 h-12 sm:w-14 sm:h-14 md:w-20 md:h-16"
              />
            </div>

            {/* Logo 3 */}
            <div className="w-24 h-24 sm:w-28 sm:h-28 md:w-36 md:h-20 flex justify-center items-center shadow-md rounded-md border">
              <img
                src={require("../../assets/aboutImages/19.jpg")}
                alt="JD Logo"
                className="w-12 h-12 sm:w-14 sm:h-14 md:w-16 md:h-16"
              />
            </div>
          </div>

          {/* Testimonial Section */}
          <div className="flex flex-col md:flex-row max-w-5xl mx-auto items-center justify-between mt-10">
            {/* Testimonial Content on the Left */}
            <div className="p-6 shadow-lg rounded-lg w-full flex flex-col md:flex-row mb-10 md:mb-0">
              {/* Testimonial Text */}
              <div className="flex-1">
                <div className="flex items-center mb-4">
                  <img
                    src={require("../../assets/aboutImages/20.jpg")}
                    alt="AO Logo"
                    className="w-16 h-12"
                  />
                </div>
                <blockquote className="text-gray-700 italic text-left">
                  "SafetyCulture has been in our corner for several years now,
                  supporting our operations team behind-the-scenes of the
                  Australian Open. Together we’re working towards a seamless,
                  safe and enjoyable event for the public."
                </blockquote>
                <p className="mt-4 font-semibold text-gray-900 text-sm text-left">
                  Alex Hamilton
                </p>
                <p className="text-gray-500 text-sm text-left">
                  Chief Event Officer, Tennis Australia
                </p>
              </div>

              {/* Image Section on the Right */}
              <div className="md:w-1/2 flex justify-center md:justify-end mt-6 md:mt-0">
                <img
                  src={require("../../assets/aboutImages/stadium.jpg")}
                  alt="Tennis Stadium"
                  className="shadow-xl object-cover rounded-l-[200px] rounded-r-none w-full md:w-auto md:h-96"
                />
              </div>
            </div>
          </div>

          {/* Just Take Section */}
          <div className="container max-w-5xl mx-auto px-4 text-center">
            <h3 className="text-xl sm:text-2xl font-bold mt-14 text-gray-800 mb-4">
              {t("aboutpage.justtakeheading")}
            </h3>

            {/* Logo Section */}
            <div className="flex flex-wrap justify-center gap-8">
              <img
                src={require("../../assets/aboutImages/20.jpg")}
                alt="SafetyCulture Care"
                className="h-12 sm:h-14"
              />
              <img
                src={require("../../assets/aboutImages/21.jpg")}
                alt="SafetyDocs"
                className="h-12 sm:h-14"
              />
              <img
                src={require("../../assets/aboutImages/22.jpg")}
                alt="SHEQSY"
                className="h-12 sm:h-14"
              />
              <img
                src={require("../../assets/aboutImages/23.jpg")}
                alt="EdApp"
                className="h-12 sm:h-14"
              />
              <img
                src={require("../../assets/aboutImages/24.jpg")}
                alt="EdApp"
                className="h-12 sm:h-14"
              />
            </div>
          </div>
        </div>

        <div className="py-12">
          {/* Section Heading */}
          <div className="text-center">
            <h2 className="text-4xl sm:text-5xl font-bold mb-2">
              {t("aboutpage.ourvalueheading")}
            </h2>
            <p className="text-lg text-gray-600 mb-8 mt-6 sm:mt-10">
              {t("aboutpage.ourvaluepara")}
            </p>
          </div>

          {/* Value Grid */}
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 max-w-7xl mx-auto gap-8 px-4 lg:px-12">
            {/* Value 1 */}
            <div className="text-center rounded-lg p-6 mx-2 md:mx-0">
              <img
                src={require("../../assets/aboutImages/1.jpg")}
                alt="Open Honest Always"
                className="w-full mb-4"
              />
              <p className="text-gray-600 text-sm text-center">
                {t("aboutpage.ourvaluepara")}
              </p>
            </div>

            {/* Value 2 */}
            <div className="text-center rounded-lg p-6 mx-2 md:mx-0">
              <img
                src={require("../../assets/aboutImages/2.jpg")}
                alt="Better As a Team"
                className="w-full mb-4"
              />
              <p className="text-gray-600 text-sm text-center">
                {t("aboutpage.ourvaluepara2")}
              </p>
            </div>

            {/* Value 3 */}
            <div className="text-center rounded-lg p-6 mx-2 md:mx-0">
              <img
                src={require("../../assets/aboutImages/3.jpg")}
                alt="Be Bold Bring Action"
                className="w-full mb-4"
              />
              <p className="text-gray-600 text-sm">
                {t("aboutpage.ourvaluepara3")}
              </p>
            </div>

            {/* Value 4 */}
            <div className="text-center rounded-lg p-6 mx-2 md:mx-0">
              <img
                src={require("../../assets/aboutImages/4.jpg")}
                alt="Think Customer"
                className="w-full mb-4"
              />
              <p className="text-gray-600 text-sm">
                {t("aboutpage.ourvaluepara4")}
              </p>
            </div>
          </div>
        </div>

        <div className="py-12 bg-gray-100">
          {/* Section Heading */}
          <div className="text-center mb-12 mt-10">
            <h2 className="text-4xl sm:text-5xl font-bold text-center">
              {t("aboutpage.ourhistoryheading")}
            </h2>
          </div>

          {/* History Grid */}
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 max-w-6xl mx-auto px-4">
            {/* History Item 1 */}
            <div className="p-8 rounded-xl shadow-md">
              <div className="mb-4 text-left">
                <span className="inline-block px-4 py-0 border border-gray-300 rounded-full text-gray-600 text-base">
                  {t("aboutpage.ouryear")}
                </span>
              </div>
              <h3 className="text-xl font-extrabold text-left mb-2 mt-8">
                {t("aboutpage.ourhis")}
              </h3>
              <p className="text-gray-600 text-base text-left mt-4">
                Our first business solution? Humble safety documents.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default About;
