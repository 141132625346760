import React from "react";
import i18next from "i18next";

const TextImagewFlex = ({
  heading,
  para,
  img,
  span,
  show,
  widthClass = "w-full", // Corrected prop name and default value
  reverse = false,
}) => {
  const isArabic = i18next.language === "ar";
  return (
    <section className="px-4 sm:px-6 lg:px-8">
      <div
        className={`max-w-7xl mx-auto flex flex-col-reverse lg:flex-row items-center justify-between ${
          reverse ? "lg:flex-row-reverse" : ""
        }`}
      >
        {/* Left Side (Text and Buttons) */}
        <div
          className={`lg:w-1/2 space-y-6 ${
            isArabic ? "text-right" : "text-left"
          } ${isArabic ? "rtl" : ""}`}
        >
          {show && (
            <span className="inline-block border-t border-b border-r border-[3px] border-l-[.1875rem] border-[#394653] text-sm text-[#293745] px-3 py-1 rounded-full">
              {span}
            </span>
          )}

          <h1 className="text-[2.2rem] md:text-[3.2rem] lg:text-[2.65rem] font-bold text-[#293745] md:leading-[53px]">
            {heading}
          </h1>
          <p className="text-md md:text-[1rem] text-[#293745]">{para}</p>
        </div>

        {/* Right Side (Image) */}
        <div className="lg:w-1/2 mb-12 lg:mb-0">
          <div
            className={`relative flex justify-center ${
              reverse ? "md:justify-start me-12" : "md:justify-end"
            }`}
          >
            <img
              src={img}
              alt="Digital Process"
              className={`rounded-xl h-full ${widthClass}`}
            />{" "}
            {/* Alt text added */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default TextImagewFlex;
