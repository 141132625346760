import FlexContent from "../../../shared/flexContent";
import FacilitiesImage from "../../../assets/safetyhealth/img1.jpg";
import { useTranslation } from "react-i18next";

const BoostProductivity = () => {
  const { t } = useTranslation();

  return (
    <div className={`bg-[#F3F6FB] py-24`}>
      <FlexContent
        heading={t("healthsafety.Boostheading")}
        para={t("healthsafety.Boostpara")}
        reverse
        italicText={t("healthsafety.boostitalic")}
        // name={t("healthsafety.Boostname")}
        // position={t("healthsafety.boostposition")}
        img={FacilitiesImage}
      />
    </div>
  );
};
export default BoostProductivity;
