import HealthSafetyHero from "../../components/solutions/healthSafety/hero";
import States from "../../components/solutions/healthSafety/states";
import BoostProductivity from "../../components/solutions/healthSafety/boostProductivity";
import SafeWorkSpaces from "../../components/solutions/healthSafety/preventIncidents";
import GainVisibilty from "../../components/solutions/healthSafety/gainvisibility";
import UpskillTeams from "../../components/solutions/healthSafety/deliverEffective";
import ImproveUptime from "../../components/solutions/healthSafety/improveUptime";
import ListDataSection from "../../components/solutions/healthSafety/listData";
import Improvement from "../../components/solutions/healthSafety/improvement";
import CustomizableCardsSection from "../../components/solutions/healthSafety/customizableCards";
import IndustryCards from "../../components/solutions/healthSafety/industryCards";
import ImprovementTwo from "../../components/solutions/healthSafety/improvementTwo";
import Faqs from "../../components/solutions/healthSafety/faqs";
const HealthSafety = () => {
  return (
    <div>
      <HealthSafetyHero />
      <States />
      <BoostProductivity />
      <GainVisibilty />
      <SafeWorkSpaces />
      <UpskillTeams />
      <ImproveUptime />
      <ListDataSection />
      <Improvement />
      <CustomizableCardsSection />
      <IndustryCards />
      <ImprovementTwo />
      <Faqs />
    </div>
  );
};
export default HealthSafety;
