import ContinousHero from "../../../assets/mining/assets-platform-1024x934-1.webp";
import TextImageFlex from "../../../shared/textImageFlex";
const  UpskillTeams = () => {
  return (
    <div className="py-10">
      <TextImageFlex
        heading="Improve uptime on critical equipment"
        para="Go beyond reactive repairs and keep things running smoothly with scheduled maintenance tasks that match the frequency you need. Gain a detailed overview of your assets, including history and upcoming activities. Set up automated reminders so teams are alerted when maintenance checks are due to be completed."
        img={ContinousHero}
        widhtClass="w-[96%]"
      />
    </div>
  );
};

export default  UpskillTeams;
