import TextImagewFlex from "../../../shared/textImageFlex";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import AnalyticsReporting from "../../../assets/inspection/AnalyticsReportingGraphs.webp";

const AnalyzeResults = () => {
  const isArabic = i18next.language === "ar";
  const { t } = useTranslation();
  return (
    <div className="py-20">
      <div>
        <TextImagewFlex
          img={AnalyticsReporting}
          heading={t("inspectionpage.analyzingheading")}
          para={
            <>
              {t("inspectionpage.analysispara")}
              <br />
              <br />
              {t("inspectionpage.analysispara1")}
            </>
          }
          reverse
          widhtClass="w-[90%]"
        />
      </div>
    </div>
  );
};

export default AnalyzeResults;
