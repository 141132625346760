import ConstructionHero from "../../../assets/construction/Construction-1.webp";
import TextImageFlex from "../../../shared/textImageFlex";
import { useTranslation } from "react-i18next";
const KeepTrack = () => {
  const { t } = useTranslation();
  return (
    <div className="py-10">
      <TextImageFlex
        heading={t("constructionpage.KeepTrackheading")}
        para={t("constructionpage.KeepTrackpara")}
        img={ConstructionHero}
        widhtClass="w-full"
        reverse
      />
    </div>
  );
};

export default KeepTrack;
