import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useApi } from "../../context/OrganizationContaxt";
const UserPermission = () => {
  const navigate = useNavigate();
  const [showPopup, setShowPopup] = useState(false);
  const [showTrialModal, setShowTrialModal] = useState(false);
  const [country, setCountry] = useState("Pakistan");
  const [paymentMethod, setPaymentMethod] = useState("Card");
  const [planType, setPlanType] = useState("Monthly");
  const handleTdClick = () => {
    navigate("/dashboard/administrator"); // Navigate to /dashboard/administrator
  };
  const { users } = useApi();
  const administrators = users?.filter(
    (item) => item?.role?.toLowerCase() === "administrator"
  );

  const managers = users?.filter(
    (item) => item?.role?.toLowerCase() === "Manager"
  );

  const Standard = users?.filter(
    (item) => item?.role?.toLowerCase() === "Standard"
  );
  const Basic = users?.filter((item) => item?.role?.toLowerCase() === "Basic");

  const Guest = users?.filter((item) => item?.role?.toLowerCase() === "Guest");
  console.log(administrators, managers);
  const colors = [
    "bg-blue-100 text-blue-700",
    "bg-green-100 text-green-700",
    "bg-yellow-100 text-yellow-700",
    "bg-red-100 text-red-700",
  ];

  return (
    <div className="p-8 mr-14">
      <button
        className="text-indigo-500 mb-4 flex items-left"
        onClick={() => navigate(-1)} // Navigate back to previous page
      >
        ← Back
      </button>
      {/* Header */}
      <h1 className="text-2xl font-bold mb-4 text-left">Permission sets</h1>

      {/* Info Banner */}
      <div>
        <div className="bg-indigo-100 p-6 rounded-lg flex items-center justify-between mb-6 mt-6">
          <div>
            <h2 className="text-lg font-semibold">
              Manage permissions sets for enhanced user control
            </h2>
            <p className="text-gray-600">
              Streamline your team’s experience by granting feature access only
              to those who need it.
            </p>
          </div>
          <button
            className="bg-indigo-500 text-white py-2 px-4 rounded-lg flex items-center"
            onClick={() => setShowPopup(true)}
          >
            <span className="mr-2">🚀</span> Upgrade to Premium
          </button>
        </div>

        {/* Initial Popup */}
        {showPopup && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="bg-white p-6 rounded-lg shadow-lg w-3/5 text-center max-w-2xl">
              <h3 className="text-lg font-semibold mb-4">
                Improve your production line with Premium
              </h3>
              <p className="text-gray-600 mb-6">
                30-day free trial. Cancel anytime. We'll remind you 7 days
                before your trial ends.
              </p>
              <button
                className="bg-indigo-500 text-white py-2 px-4 rounded-lg mb-4"
                onClick={() => {
                  setShowPopup(false);
                  setShowTrialModal(true);
                }}
              >
                Try Premium for free
              </button>
              <div className="grid grid-cols-2 gap-x-4 text-left mb-6">
                <ul className="list-disc list-inside text-gray-700 space-y-2">
                  <li>Real-time analytics to compare GMP compliance</li>
                  <li>Advanced permissions and user management</li>
                  <li>Unlimited team members, including lite seats</li>
                </ul>
                <ul className="list-disc list-inside text-gray-700 space-y-2">
                  <li>
                    Custom quality control forms, hazard reports, and more
                  </li>
                  <li>Integrations with business systems or calendars</li>
                  <li>Free access for casual workers, suppliers, and users</li>
                </ul>
              </div>
              <button
                className="text-gray-500"
                onClick={() => setShowPopup(false)}
              >
                Close
              </button>
            </div>
          </div>
        )}

        {/* Detailed Trial Modal */}
        {showTrialModal && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white rounded-lg shadow-lg p-8 w-3/5 max-w-4xl flex">
              {/* Left Section */}
              <div className="w-2/3 pr-8">
                <button
                  className="text-indigo-500 mb-4 flex items-left"
                  onClick={() => setShowTrialModal(false)}
                >
                  &larr; Back
                </button>
                <h2 className="text-2xl font-bold mb-6 text-center">
                  Start your 30-day free trial
                </h2>

                {/* Organization Section */}
                <div className="mb-8">
                  <h3 className="font-semibold text-lg mb-2 text-left">
                    1. Organization
                  </h3>
                  <label className="block text-gray-700 text-left">
                    Country*
                  </label>
                  <select
                    value={country}
                    onChange={(e) => setCountry(e.target.value)}
                    className="mt-1 w-full border border-gray-300 rounded-lg p-2"
                  >
                    <option>Pakistan</option>
                    <option>United States</option>
                    <option>Canada</option>
                    <option>Australia</option>
                  </select>
                </div>

                {/* Payment Method Section */}
                <div className="mb-8">
                  <h3 className="font-semibold text-lg mb-2 text-left">
                    2. Payment method
                  </h3>
                  <p className="text-gray-600 text-sm mb-4 text-left">
                    <span role="img" aria-label="lock">
                      🔒
                    </span>{" "}
                    Secured payment
                  </p>
                  <div className="flex mb-6">
                    <button
                      onClick={() => setPaymentMethod("Card")}
                      className={`px-4 py-2 rounded-lg mr-4 ${
                        paymentMethod === "Card"
                          ? "bg-indigo-500 text-white"
                          : "bg-gray-200 text-gray-700"
                      }`}
                    >
                      💳 Card
                    </button>
                    <button
                      onClick={() => setPaymentMethod("PayPal")}
                      className={`px-4 py-2 rounded-lg ${
                        paymentMethod === "PayPal"
                          ? "bg-indigo-500 text-white"
                          : "bg-gray-200 text-gray-700"
                      }`}
                    >
                      PayPal
                    </button>
                  </div>
                  <div className="grid grid-cols-2 gap-4">
                    <input
                      type="text"
                      placeholder="First name on card *"
                      className="border border-gray-300 rounded-lg p-2"
                    />
                    <input
                      type="text"
                      placeholder="Last name on card *"
                      className="border border-gray-300 rounded-lg p-2"
                    />
                    <input
                      type="text"
                      placeholder="Card number *"
                      className="border border-gray-300 rounded-lg p-2 col-span-2"
                    />
                    <input
                      type="text"
                      placeholder="Month *"
                      className="border border-gray-300 rounded-lg p-2"
                    />
                    <input
                      type="text"
                      placeholder="Year *"
                      className="border border-gray-300 rounded-lg p-2"
                    />
                    <input
                      type="text"
                      placeholder="CVV *"
                      className="border border-gray-300 rounded-lg p-2"
                    />
                  </div>
                </div>
              </div>

              {/* Right Section */}
              <div className="w-2/3 bg-gray-100 p-8 rounded-lg">
                <h3 className="font-semibold mb-6 text-xl">Premium Plan</h3>

                <div className="flex justify-between items-center mb-6">
                  <span className="text-lg">Monthly</span>
                  <label className="inline-flex items-center">
                    <input
                      type="checkbox"
                      className="form-checkbox text-indigo-500"
                      checked={planType === "Annual"}
                      onChange={() =>
                        setPlanType(
                          planType === "Monthly" ? "Annual" : "Monthly"
                        )
                      }
                    />
                    <span className="ml-2 text-sm text-gray-700">
                      Annual (Save 17%)
                    </span>
                  </label>
                </div>

                <div className="flex justify-between items-center mb-6">
                  <p className="text-gray-700 text-lg">
                    Estimated price after free trial
                  </p>
                  <p className="font-semibold text-lg">$29.00</p>
                </div>

                <button className="text-indigo-500 text-sm mb-6">
                  Add coupon code
                </button>

                <hr className="my-6" />

                <div className="flex justify-between items-center mb-6">
                  <p className="font-semibold text-lg">Today's total</p>
                  <p className="font-bold text-2xl">$0.00</p>
                </div>

                <button className="bg-indigo-500 text-white w-full py-3 rounded-lg flex items-center justify-center text-lg font-medium">
                  🚀 Start your free trial
                </button>

                <p className="text-gray-600 text-sm mt-6">
                  Cancel your free trial before{" "}
                  <span className="font-semibold">14 Dec 2024</span>, and we
                  won't charge your provided payment method. We'll remind you 7
                  days before your trial ends.
                </p>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="relative mb-4">
        <input
          type="text"
          placeholder="Search"
          className="flex items-left w-56 p-2 pl-10 border rounded-md text-gray-600 bg-gray-100"
        />
        <span className="absolute left-3 top-2 text-gray-400">🔍</span>
      </div>

      {/* Table */}
      <div className="overflow-x-auto">
        <table className="w-full border-collapse">
          <thead>
            <tr className="bg-gray-100 text-left">
              <th className="p-3 text-gray-700 font-medium">Name</th>
              <th className="p-3 text-gray-700 font-medium">Modified</th>
              <th className="p-3 text-gray-700 font-medium">Seat</th>
              <th className="p-3 text-gray-700 font-medium">Users</th>
            </tr>
          </thead>
          <tbody>
            <tr className="border-b hover:bg-gray-50 text-left">
              <td
                // className={`p-3 ${
                //   role.name === "Administrator"
                //     ? "text-indigo-600 font-medium"
                //     : "text-gray-600"
                // }`}
                onClick={handleTdClick} // Attach the click handler here
              >
                Administrator
                {/* {role.name} */}
              </td>
              <td className="p-3 text-gray-600">{/* {role.modified} */}</td>
              <td className="p-3 text-gray-600">
                {users.length === 2 ? "no seat available" : "available"}{" "}
              </td>

              <td className="p-3">
                {administrators.map((item, index) => (
                  <span
                    style={{ cursor: "pointer" }}
                    key={index}
                    className={` ${
                      colors[index % colors.length]
                    }  py-1 px-2 rounded-full text-xs font-medium`}
                  >
                    {(
                      item?.user.firstName?.charAt(0) +
                      item?.user.firstName?.charAt(
                        item.user.firstName.length - 1
                      )
                    ).toUpperCase()}
                  </span>
                ))}
              </td>
            </tr>
            <tr className="border-b hover:bg-gray-50 text-left">
              <td
                // className={`p-3 ${
                //   role.name === "Administrator"
                //     ? "text-indigo-600 font-medium"
                //     : "text-gray-600"
                // }`}

                onClick={handleTdClick} // Attach the click handler here
              >
                Standard
              </td>
              <td className="p-3 text-gray-600">{/* {role.modified} */}</td>
              <td className="p-3 text-gray-600">
                {users.length === 2 ? "no seat available" : "available"}
              </td>

              <td className="p-3">
                {Standard.map((item, index) => (
                  <span
                    style={{ cursor: "pointer" }}
                    key={index}
                    className={` ${
                      colors[index % colors.length]
                    }  py-1 px-2 rounded-full text-xs font-medium`}
                  >
                    {(
                      item?.user.firstName?.charAt(0) +
                      item?.user.firstName?.charAt(
                        item.user.firstName.length - 1
                      )
                    ).toUpperCase()}
                  </span>
                ))}
              </td>
            </tr>

            <tr className="border-b hover:bg-gray-50 text-left">
              <td
                // className={`p-3 ${
                //   role.name === "Administrator"
                //     ? "text-indigo-600 font-medium"
                //     : "text-gray-600"
                // }`}

                onClick={handleTdClick} // Attach the click handler here
              >
                Basic
              </td>
              <td className="p-3 text-gray-600"> </td>
              <td className="p-3 text-gray-600">
                {" "}
                {users.length === 2 ? "no seat available" : "available"}{" "}
              </td>

              <td className="p-3">
                {Basic.map((item, index) => (
                  <span
                    style={{ cursor: "pointer" }}
                    key={index}
                    className={` ${
                      colors[index % colors.length]
                    }  py-1 px-2 rounded-full text-xs font-medium`}
                  >
                    {(
                      item?.user.firstName?.charAt(0) +
                      item?.user.firstName?.charAt(
                        item.user.firstName.length - 1
                      )
                    ).toUpperCase()}
                  </span>
                ))}
              </td>
            </tr>
            <tr className="border-b hover:bg-gray-50 text-left">
              <td
                // className={`p-3 ${
                //   role.name === "Administrator"
                //     ? "text-indigo-600 font-medium"
                //     : "text-gray-600"
                // }`}

                onClick={handleTdClick} // Attach the click handler here
              >
                Guest
              </td>
              <td className="p-3 text-gray-600">{/* {role.modified} */}</td>
              <td className="p-3 text-gray-600">
                {" "}
                {users.length === 2 ? "no seat available" : "available"}{" "}
              </td>

              <td className="p-3">
                {Guest.map((item, index) => (
                  <span
                    style={{ cursor: "pointer" }}
                    key={index}
                    className={` ${
                      colors[index % colors.length]
                    }  py-1 px-2 rounded-full text-xs font-medium`}
                  >
                    {(
                      item?.user.firstName?.charAt(0) +
                      item?.user.firstName?.charAt(
                        item.user.firstName.length - 1
                      )
                    ).toUpperCase()}
                  </span>
                ))}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      {/* Rest of your component like Search Bar and Table */}
    </div>
  );
};

export default UserPermission;
