import React from "react";

const ReviewData = () => {
  return (
    <div className="flex flex-col items-center justify-center  px-4 sm:px-6 lg:px-2">
      <div className="text-start flex flex-col items-start justify-start w-full mb-5">
        <h2 className="text-xl font-semibold text-gray-800">Review data</h2>
        <p className="text-sm text-gray-500 mt-1">
          This is a preview of what will be uploaded or updated. Please review
          before continuing.
        </p>

        <div className="mt-4 border border-[#4740d4] bg-purple-50 text-[#4740d4] text-sm rounded-lg p-3">
          You'll be adding <span className="font-bold">1</span> new row and
          updating <span className="font-bold">0</span> existing rows.
        </div>
      </div>
      <div className="w-full  bg-white rounded-md shadow-md p-6">
        <div className="mt-4 overflow-x-auto border border-gray-300 rounded-lg">
          <table className="w-full text-left table-fixed">
            <thead>
              <tr className="bg-gray-200 text-gray-700">
                <th className="w-1/2 p-3 text-sm font-semibold">Location</th>
                <th className="w-1/2 p-3 text-sm font-semibold">
                  Site ID (Optional)
                </th>
              </tr>
            </thead>
            <tbody>
              <tr className="bg-white border-t border-gray-200">
                <td className="p-3 text-gray-600 text-sm">www.google.com</td>
                <td className="p-3 text-gray-600 text-sm">
                  ede68a35-7fbf-4f7a-a230-1dfc4ed96880
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="flex justify-end mt-8">
          <button className="text-[#4740d4] border me-3 bg-[#fffff] border-[#bfc6d4] rounded-md py-2 px-4 hover:bg-[#e9edf6]">
            Back
          </button>
          <button className="bg-[#4740d4] text-white rounded-md py-2 px-4 hover:bg-[#4740d4]">
           Save and upload
          </button>
        </div>
      </div>
    </div>
  );
};

export default ReviewData;
