import ImprovementSection from "../../../shared/improvementSection";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
const Improvement = () => {
  const isArabic = i18next.language === "ar";
  const { t } = useTranslation();
  return (
    <div
            className={` ${isArabic ? "text-right" : "text-left"} ${
              isArabic ? "rtl" : ""
            }`}
          >
    <ImprovementSection
      heading={t("improvementguest.improvementguesthead")}
      btn1={t("improvementguest.improvementguestbtn")}
      btn2={t("improvementguest.improvementguestbtn1")}
    />
    </div>
  );
};
export default Improvement;
