import React from "react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import safetyCultureLogo from "../../assets/home/logoSSC.jpg";
import sheqsyLogo from "../../assets/home/sheqsy.svg";
import { FiTwitter } from "react-icons/fi";
import { FaLinkedinIn, FaFacebookF } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
const Footer = () => {
  const { t } = useTranslation();
  const isArabic = i18next.language === "ar";
  const navigate = useNavigate();
  return (
    <footer className="text-start  pt-20">
      <div
        dir={isArabic ? "rtl" : "ltr"}
        className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8"
      >
        <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 gap-8">
          <div>
            <h3 className="text-md font-bold text-[#6559ff] mb-4">
              {t("footer.productheading")}
            </h3>
            <ul>
              <li className="mb-2">
                <a
                  href="#"
                  className="text-sm hover:underline hover:text-[#6559ff]"
                  onClick={() => navigate("/platform")}
                >
                  {t("footer.product1")}
                </a>
              </li>
              <li className="mb-2">
                <a
                  href="#"
                  className="text-sm hover:underline hover:text-[#6559ff]"
                  onClick={() => navigate("/inspection")}
                >
                  {t("footer.product2")}
                </a>
              </li>
              <li className="mb-2">
                <a
                  href="#"
                  className="text-sm hover:underline hover:text-[#6559ff]"
                  onClick={() => navigate("/assets")}
                >
                  {t("footer.product3")}
                </a>
              </li>
              {/* <li className="mb-2">
                <a
                  href="#"
                  className="text-sm hover:underline hover:text-[#6559ff]"
                  onClick={() => navigate("/training")}
                >
                  Training
                </a>
              </li> */}
              <li>
                <a
                  href="#"
                  className="text-sm hover:underline hover:text-[#6559ff]"
                  onClick={() => navigate("/integrations")}
                >
                  {t("footer.product4")}
                </a>
              </li>
            </ul>
          </div>
          <div>
            <h3 className="text-md font-bold text-[#6559ff] mb-4">
              {t("footer.industryheading")}
            </h3>
            <ul>
              <li className="mb-2">
                <a
                  href="#"
                  className="text-sm hover:underline hover:text-[#6559ff]"
                  onClick={() => navigate("/hospitality")}
                >
                  {t("footer.industry1")}
                </a>
              </li>
              <li className="mb-2">
                <a
                  href="#"
                  className="text-sm hover:underline hover:text-[#6559ff]"
                  onClick={() => navigate("/construction")}
                >
                  {t("footer.industry2")}
                </a>
              </li>
              <li className="mb-2">
                <a
                  href="#"
                  className="text-sm hover:underline hover:text-[#6559ff]"
                  onClick={() => navigate("/manufacturing")}
                >
                  {t("footer.industry3")}
                </a>
              </li>
              <li className="mb-2">
                <a
                  href="#"
                  className="text-sm hover:underline hover:text-[#6559ff]"
                  onClick={() => navigate("/facilities-management")}
                >
                  {t("footer.industry4")}
                </a>
              </li>

              <li className="mb-2">
                <a
                  href="#"
                  className="text-sm hover:underline hover:text-[#6559ff]"
                  onClick={() => navigate("/retail")}
                >
                  {t("footer.industry5")}
                </a>
              </li>
              <li className="mb-2">
                <a
                  href="#"
                  className="text-sm hover:underline hover:text-[#6559ff]"
                  onClick={() => navigate("/transport-logistics")}
                >
                  {t("footer.industry6")}
                </a>
              </li>
            </ul>
          </div>
          {/* <div>
            <h3 className="text-md font-bold text-[#6559ff] mb-4">Resources</h3>
            <ul>
              <li className="mb-2">
                <a
                  href="#"
                  className="text-sm hover:underline hover:text-[#6559ff]"
                >
                  Checklist library
                </a>
              </li>
              <li className="mb-2">
                <a
                  href="#"
                  className="text-sm hover:underline hover:text-[#6559ff]"
                >
                  App & software guides
                </a>
              </li>
              <li className="mb-2">
                <a
                  href="#"
                  className="text-sm hover:underline hover:text-[#6559ff]"
                >
                  Checklist guides
                </a>
              </li>
              <li className="mb-2">
                <a
                  href="#"
                  className="text-sm hover:underline hover:text-[#6559ff]"
                >
                  Topic guides
                </a>
              </li>
              <li className="mb-2">
                <a
                  href="#"
                  className="text-sm hover:underline hover:text-[#6559ff]"
                >
                  eBooks
                </a>
              </li>
              <li>
                <a
                  href="#"
                  className="text-sm hover:underline hover:text-[#6559ff]"
                >
                  Blog
                </a>
              </li>
            </ul>
          </div> */}
          <div>
            <h3 className="text-md font-bold text-[#6559ff] mb-4">
              {" "}
              {t("footer.companyheading")}
            </h3>
            <ul>
              <li className="mb-2">
                <a
                  href="#"
                  className="text-sm hover:underline hover:text-[#6559ff]"
                  onClick={() => navigate("/about")}
                >
                  {t("footer.company1")}
                </a>
              </li>
              <li className="mb-2">
                <a
                  href="#"
                  className="text-sm hover:underline hover:text-[#6559ff]"
                  onClick={() => navigate("/career")}
                >
                  {t("footer.company2")}
                </a>
              </li>
              <li className="mb-2" onClick={() => navigate("/contact-us")}>
                <a
                  href="#"
                  className="text-sm hover:underline hover:text-[#6559ff]"
                >
                  {t("footer.company3")}
                </a>
              </li>
              {/* <li className="mb-2">
                <a
                  href=""
                  className="text-sm hover:underline hover:text-[#6559ff]"
                  onClick={() => navigate("/customer")}
                >
                  Customers
                </a>
              </li> */}
              <li className="mb-2">
                <a
                  href="#"
                  className="text-sm hover:underline hover:text-[#6559ff]"
                  onClick={() => navigate("/pricing")}
                >
                  {t("footer.company4")}
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="flex flex-col md:flex-row justify-center space-x-0  md:space-x-4 mt-12 md:mt-24 mb-16">
          <div className="flex border-2 rounded-xl px-6  py-[14.5px] flex-col sm:flex-row justify-between items-center space-x-0 md:space-x-8 mb-8 md:mb-4">
            <img
              src={safetyCultureLogo}
              alt="SafetyCulture Logo"
              className="w-28 h-8"
            />
            <div className="flex space-x-4">
              {/* <img
                src="https://developer.apple.com/assets/elements/badges/download-on-the-app-store.svg"
                alt="App Store"
                className="w-24"
              />
              <img
                src="https://upload.wikimedia.org/wikipedia/commons/7/78/Google_Play_Store_badge_EN.svg"
                alt="Google Play"
                className="w-28"
              /> */}
            </div>
            {/* <img src={sheqsyLogo} alt="SHEQSY Logo" className="h-12" /> */}
          </div>
          {/* <div className="flex border-2 rounded-xl px-6 h-[84px] py-2 md:h-[68px] flex-col sm:flex-row justify-between items-center space-x-0 md:space-x-8 mb-4 sm:mb-0">
            <img src={sheqsyLogo} alt="SafetyCulture Logo" className="w-28" />
            <div className="flex space-x-4">
              <img
                src="https://developer.apple.com/assets/elements/badges/download-on-the-app-store.svg"
                alt="App Store"
                className="w-24"
              />
              <img
                src="https://upload.wikimedia.org/wikipedia/commons/7/78/Google_Play_Store_badge_EN.svg"
                alt="Google Play"
                className="w-28"
              />
            </div>
          </div> */}
        </div>
      </div>
      <div
        className={`px-14 pb-7 mt-8 flex flex-col lg:flex-row sm:items-start lg:justify-between border-t pt-8 ${
          isArabic ? "text-right" : "text-left"
        } ${isArabic ? "rtl" : ""}`}
      >
        <div>
          <div className="flex flex-col md:flex-row justify-start md:justify-center space-y-2 md:space-y-0 space-x-0 md:space-x-3">
            {/* <a
        href="#"
        className="text-gray-800 text-[.875rem] hover:underline hover:text-[#6559ff] border-0 md:border-e-[.0625rem] md:border-[#293745] pe-2"
      >
        Status
      </a> */}
            {/* <a
        href="#"
        className="text-gray-800 text-[.875rem] hover:underline hover:text-[#6559ff] border-0 md:border-e-[.0625rem] md:border-[#293745] pe-2"
      >
        Legal
      </a> */}
            <a
              href="#"
              className="text-gray-800 text-[.875rem] hover:underline hover:text-[#6559ff] border-0 md:border-e-[.0625rem] md:border-[#293745] pe-2"
              onClick={() => navigate("/terms-and-condition")}
            >
              {t("footer.terms")}
            </a>
            <a
              href=""
              className="text-gray-800 text-[.875rem] hover:underline hover:text-[#6559ff] border-0 md:border-e-[.0625rem] md:border-[#293745] pe-2"
              onClick={() => navigate("/privacy")}
            >
              {t("footer.privacy")}
            </a>
            {/* <a
        href="#"
        className="text-gray-800 text-[.875rem] hover:underline hover:text-[#6559ff] border-0 md:border-e-[.0625rem] md:border-[#293745] pe-2"
      >
        Security
      </a> */}
            <a href="#" className="text-[#a3abb3] text-[.875rem]">
              {t("footer.security")}
            </a>
          </div>
        </div>
        <div className="flex space-x-4 cursor-pointer pt-5 lg:pt-0">
          <div className="bg-[#293745] p-1 rounded-md text-white">
            <FiTwitter size={17} className="text-white" />
          </div>
          <div className="bg-[#293745] p-1 rounded-md text-white">
            <FaLinkedinIn size={17} className="text-white" />
          </div>
          <div className="bg-[#293745] p-1 rounded-md text-white">
            <FaFacebookF size={17} className="text-white" />
          </div>
          <div className="bg-[#293745] p-1 rounded-md text-white">
            <FaInstagram size={17} className="text-white" />
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
