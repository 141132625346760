import React from "react";
import ApproachImage from "../../../assets/secureCare/SCCareProactiveApproach.webp";
import TextImageFlex from "../../../shared/textImageFlex";
const Proactive = () => {
  return (
    <div className="py-12 md:py-24 ">
      <TextImageFlex
        heading="A proactive approach to insurance"
        para="We help you take action to reduce risk, every day. Powered by technology to bring you a proactive, risk-minded approach to insurance"
        img={ApproachImage}
        reverse
      />
    </div>
  );
};

export default Proactive;
