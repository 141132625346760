import FAQSection from "../../../shared/faqs";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

const Faqs = () => {
  const { t } = useTranslation(); // Translation hook
  const isArabic = i18next.language === "ar"; // Check for Arabic language

  // FAQ data, dynamically generated based on translation
  const faqData = [
    {
      question: t("assetpage.que1"),
      answer: t("assetpage.ans1"),
    },
    {
      question: t("assetpage.que2"),
      answer: t("assetpage.ans2"),
    },
    {
      question: t("assetpage.que3"),
      answer: t("assetpage.ans3"),
    },
    {
      question: t("assetpage.que4"),
      answer: t("assetpage.ans4"),
    },
    {
      question: t("assetpage.que5"),
      answer: t("assetpage.ans5"),
    },
  ];

  // Returning the FAQSection with the dynamic class for RTL
  return (
    <div
      className={` ${isArabic ? "text-right" : "text-left"} ${
        isArabic ? "rtl" : ""
      }`}
    >
      <FAQSection
        faqData={faqData}
        heading={t("inspectionpage.faqs")}
        className={isArabic ? "text-right rtl" : "text-left"}
      />
    </div>
  );
};

export default Faqs;
