import AnalyticsHero from "../../../assets/construction/ManufacturingAssets.webp";
import TextImageFlex from "../../../shared/textImageFlex";
import { useTranslation } from "react-i18next";
const ImproveUptime = () => {
  const { t } = useTranslation();
  return (
    <div className="py-10">
      <TextImageFlex
        heading={t("constructionpage.ImproveUptimeheading")}
        para={t("constructionpage.ImproveUptimepara")}
        img={AnalyticsHero}
        widhtClass="w-full"
        reverse
      />
    </div>
  );
};

export default ImproveUptime;
