import React from "react";
import FeaturesCards from "../../../shared/featuresCards";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import Card2 from "../../../assets/integrations/card2.webp";
import Card3 from "../../../assets/integrations/card3.webp";
import Card5 from "../../../assets/integrations/card5.webp";
import Card6 from "../../../assets/integrations/card6.webp";
import Card9 from "../../../assets/integrations/card9.webp";

const FeaturesSection = () => {
  const { t } = useTranslation();
  const isArabic = i18next.language === "ar";

  const features = [
    {
      img: Card2,
      title: t("integrationpage.card1feature"),
      description: t("integrationpage.card1description"),
    },
    {
      img: Card3,
      title: t("integrationpage.card2feature"),
      description: t("integrationpage.card2description"),
    },
    {
      img: Card5,
      title: t("integrationpage.card3feature"),
      description: t("integrationpage.card3description"),
    },
    {
      img: Card6,
      title: t("integrationpage.card4feature"),
      description: t("integrationpage.card4description"),
    },
    {
      img: Card6,
      title: t("integrationpage.card5feature"),
      description: t("integrationpage.card5description"),
    },
    {
      img: Card9,
      title: t("integrationpage.card6feature"),
      description: t("integrationpage.card6description"),
    },
  ];

  return (
    <div dir={isArabic ? "rtl" : "ltr"}>
      <FeaturesCards
        features={features}
        heading={t("integrationpage.featureheading")}
      />
    </div>
  );
};

export default FeaturesSection;
