import FlexContent from "../../../shared/flexContent";
import FacilitiesImage from "../../../assets/mining/Inspection-Reporting-Software-img-01.webp";
const BoostProductivity = () => {
  return (
    <div className="bg-[#F3F6FB] py-24">
      <FlexContent
        heading="Gain visibility across every asset"
        para="Use data from completed inspections, reported incidents, sensors, and asset history to keep workers safe, spot trends, and compare performance across every site. Start making better decisions and adapting processes to reduce operational costs."
        reverse
        italicText=" “It’s totally changed how we do things. We’ve moved from paper-based processes to a digital tool that gathers and backs up all our data, is easy and simple for staff to use, and enables us to pull out important data at the touch of a button.”"
        name="Keith Spires"
        position="Product and Innovation Director, Equipe Group"
        img={FacilitiesImage}
      />
    </div>
  );
};
export default BoostProductivity;
