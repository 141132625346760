import React, { useState } from "react";
import { IoIosSettings } from "react-icons/io";
import { FaSearch } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useApi } from "../../../context/OrganizationContaxt";
const TemplatePage = () => {
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const { getTamplateList } = useApi();
  console.log(getTamplateList);

  const data = [
    {
      id: 1,
      icon: "✅",
      title: "Start from scratch",
      description: "Get started with a blank templates.",
      completed: true,
    },
    {
      id: 2,
      icon: "➕",
      title: "Describe topic",
      description: "Enter a text prompt about your template.",
      completed: false,
    },
    {
      id: 3,
      icon: "✅",
      title: "Find Pre-made template",
      description: "Choose from over 100,000 editable templates.",
      completed: true,
    },
  ];
  const toggleFilterDropdown = () => {
    setIsFilterOpen(!isFilterOpen);
  };
  return (
    <div className=" pt-5 pe-10">
      <div className="bg-[#F8F9FC] p-8 rounded-lg shadow-md">
        <div className="flex">
          <h2 className="text-lg font-medium mb-4">
            Create your template from one of the options below.
          </h2>
        </div>

        {/* Cards Section */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          {data.map((item) => (
            <div
              key={item.id}
              className="flex flex-col items-center justify-center bg-white p-6 rounded-lg shadow-md text-center"
            >
              <div className="text-2xl">{item.icon}</div>
              <h3 className="mt-4 font-semibold text-[15px] text-[#545f70] mt-2">
                {item.title}
              </h3>
              <p className="mt-2 text-[13px] text-gray-500">
                {item.description}
              </p>
            </div>
          ))}
        </div>
      </div>
      <div>
        <h1 className="text-gray-800 font-medium text-md text-start pt-6">
          Templates (1 - 2 of 2)
        </h1>
        <div className="flex justify-between items-center mb-6 mt-5">
          {/* Search and filter */}
          <div className="flex items-center space-x-4">
            {/* Search box */}
            <div className="flex items-center border border-gray-300 rounded-md px-2 py-2">
              <FaSearch className="text-gray-500" />
              <input
                type="text"
                placeholder="Search users"
                className="ml-2 outline-none bg-transparent"
              />
            </div>

            {/* User status and filter */}
            <div className="flex items-center">
              <div className="relative">
                <button
                  onClick={toggleFilterDropdown}
                  className="text-[#6559ff] hover:text-blue-600"
                >
                  + Add filter
                </button>
                {isFilterOpen && (
                  <div className="absolute mt-2 w-48 rounded-md shadow-lg bg-white">
                    <ul className="py-1 text-gray-700">
                      <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer">
                        Filter 1
                      </li>
                      <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer">
                        Filter 2
                      </li>
                      <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer">
                        Filter 3
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* Create group and download */}
          <div className="flex items-center space-x-4">
            <Link
              to={"/dashboard/create-template"}
              className="bg-[#6559ff] text-white px-4 py-2 rounded-lg"
            >
              + Create
            </Link>
          </div>
        </div>
      </div>
      <div className="w-full  mx-auto p-4 bg-white rounded-lg shadow-md">
        {/* Header Row */}
        <div className="flex  items-center text-start border-b p-2 text-gray-500 text-sm font-medium">
          <div className="flex w-[45%]">
            <input type="checkbox" className="mr-4" />
            <span className="flex underline">Template</span>
          </div>

          <span className="text-start  underline w-[22%]">Last published</span>
          <span className="ml-4 underline w-[31%]">Access</span>
          <span>
            <IoIosSettings />
          </span>
        </div>

        {/* Table Rows */}
        {getTamplateList &&
          getTamplateList.data?.templates.map((item, index) => (
            <div className="divide-y">
              {/* Row 1 */}
              <div className="flex items-center p-2 hover:bg-gray-50">
                <div className="w-[45%] flex text-start">
                  <input type="checkbox" className="mr-4" />
                  <img
                    src="https://via.placeholder.com/20"
                    alt="SC Logo"
                    className="mr-4 mt-4 h-8"
                  />
                  <div>
                    <p className="font-semibold text-sm text-start text-gray-900">
                      {item?.title}
                    </p>
                    <p className="text-sm text-start text-gray-500">
                      {item?.description}
                    </p>
                  </div>
                </div>

                <span className=" text-sm text-gray-500 w-[22%] text-start">
                  9 hours ago
                </span>
                <div className="flex items-center ml-4 space-x-4 w-[31%]">
                  <span className="text-sm text-gray-500">All users</span>
                  <button className="text-[#4740d4] border border-[#bfc6d4] hover:bg-[#e9edf6] text-md px-3 py-1 rounded-lg">
                    Start inspection
                  </button>
                </div>
                <button className="text-sm text-gray-500 hover:text-gray-700 ">
                  ⋮
                </button>
              </div>
            </div>
          ))}

        {/* Pagination */}
        <div className="flex items-center justify-center space-x-4 mt-4">
          <button className="text-gray-400 hover:text-gray-600">1</button>
          <button className="text-gray-400 hover:text-gray-600">&gt;</button>
        </div>

        {/* Chat Icon */}
        <button className="fixed bottom-6 right-6 bg-purple-600 text-white rounded-full p-3 shadow-lg hover:bg-purple-700">
          😊
        </button>
      </div>
    </div>
  );
};

export default TemplatePage;
