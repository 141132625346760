import StatsSection from "../../../shared/stateSection";

const data = [
  {
    value: "60%",
    description: "improved auditing efficiency**",
    borderColor: "border-[#6559FF]",
  },
  {
    value: "50%",
    description: "improved efficiency in training*",
    borderColor: "border-cyan-500",
  },
  {
    value: "49%",
    description: "savings from safety & compliance improvements*",
    borderColor: "border-yellow-500",
  },
];
const States = () => {
  return <StatsSection data={data} />;
};
export default States;
