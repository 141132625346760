import IconCards from "../../../shared/iconCards";
import Icon from "../../../assets/construction/Feature-Stack-Icon.svg";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
const IndustryCards = () => {
  const { t } = useTranslation();
  const isArabic = i18next.language === "ar";
   
  const CardsData = [
    {
      img: Icon,
      title: t("industrycard.industrycardtitle1"),
      description: t("industrycard.industrycarddescription1"),
      linkText: "View this checklist",
    },
    {
      img: Icon,
      title: t("industrycard.industrycardtitle2"),
      description: t("industrycard.industrycarddescription2"),
      linkText: "View this checklist",
    },
    {
      img: Icon,
      title: t("industrycard.industrycardtitle3"),
      description: t("industrycard.industrycarddescription3"),
      linkText: "View this checklist",
    },
    {
      img: Icon,
      title: t("industrycard.industrycardtitle4"),
      description: t("industrycard.industrycarddescription4"),
      linkText: "View this checklist",
    },
    {
      img: Icon,
      title: t("industrycard.industrycardtitle5"),
      description: t("industrycard.industrycarddescription5"),
      linkText: "View this checklist",
    },
    {
      img: Icon,
      title: t("industrycard.industrycardtitle6"),
      description: t("industrycard.industrycarddescription6"),
      linkText: "View this checklist",
    },
 
  ];
  return (
    <div
            className={` ${isArabic ? "text-right" : "text-left"} ${
              isArabic ? "rtl" : ""
            }`}
          >
    <div className="py-10">
      <IconCards
        heading={t("hospitalitycard.hospitalitycardheading")}
        CardsData={CardsData}
      />
    </div>
  </div>
  );
};
export default IndustryCards;
