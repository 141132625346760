import React from "react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import AssetsHero from "../../../assets/assets/AssetsHeroAlt.webp";
import HeroFlex from "../../../shared/heroFlex";
const HeroSection = () => {
  const isArabic = i18next.language === "ar";
  const { t } = useTranslation();
  return (
    <div
      className={` ${isArabic ? "text-right" : "text-left"} ${
        isArabic ? "rtl" : ""
      }`}
    >
      <HeroFlex
        span={t("assetpage.assetspan")}
        heading={t("assetpage.assetheading")}
        para={t("assetpage.assetpara")}
        btn1={t("assetpage.assetbtn")}
        img={AssetsHero}
        btn1Navigate="/signup"
      />
    </div>
  );
};

export default HeroSection;
