import Platformhero from "../../components/products/platform/hero";
import PlatformCards from "../../components/products/platform/cards";
import TabsSection from "../../components/products/platform/tabs";
import ReviewCard from "../../components/products/platform/reviews";
import ImprovementSection from "../../components/products/platform/improvementPlatform";
import FAQSection from "../../components/products/platform/faqs";
const ProductsPlatform = () => {
  return (
    <div>
      <Platformhero />
      <PlatformCards />
      <TabsSection/>
      <ReviewCard/>
      <ImprovementSection/>
      <FAQSection/>
    </div>
  );
};
export default ProductsPlatform;
