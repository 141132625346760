import HomeNotifications from "../../../components/adminPortal/notifications/home";
const NotificationsPage = () => {
  return (
    <div className="bg-[#E9EDF6] w-full">
      <div className="container mx-auto w-[93%] ">
      <HomeNotifications />
    </div>
    </div>
  );
};
export default NotificationsPage;
