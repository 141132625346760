import CurveHeroFlex from "../../../shared/curveHero";
import HeroImage from "../../../assets/safetyhealth/facilitymanagnment.jpg";
import Image1 from "../../../assets/transport/image1.svg";
import Image2 from "../../../assets/transport/image2.svg";
import Image3 from "../../../assets/transport/image3.svg";
import Image4 from "../../../assets/transport/image4.svg";
import Image5 from "../../../assets/transport/image5.svg";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
const ImagesArray = [
  {
    id: 1,
    img: Image1,
  },
  {
    id: 2,
    img: Image2,
  },
  {
    id: 3,
    img: Image3,
  },
  {
    id: 4,
    img: Image4,
  },
  {
    id: 5,
    img: Image5,
  },
];
const HealthSafetyHero = () => {
  const { t } = useTranslation();
  const isArabic = i18next.language === "ar";
  return (
    <div
      className={`py-4 ${isArabic ? "text-right" : "text-left"} ${
        isArabic ? "rtl" : ""
      }`}
    >
      <CurveHeroFlex
        span={t("healthsafety.healthspan")}
        img={HeroImage}
        heading={t("healthsafety.healthtitle")}
        para={t("healthsafety.healthpara")}
        btn1={t("healthsafety.healthbtn1")}
        btn2={t("healthsafety.healthbtn2")}
        ImagesArray={ImagesArray}
      />
    </div>
  );
};
export default HealthSafetyHero;
