import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import axios from "axios";
import { useLocation } from "react-router-dom";
const DemoForm = ({ heading, para }) => {
  const isArabic = i18next.language === "ar";
  const { t } = useTranslation();
  const location = useLocation();
  const [countries, setCountries] = useState([]);
  const [formData, setFormData] = useState({
    email: "",
    phoneNumber: "",
    country: "",
    interests: [],
  });

  // Fetch countries from API
  useEffect(() => {
    axios
      .get("https://restcountries.com/v3.1/all")
      .then((response) => {
        const countryNames = response.data.map(
          (country) => country.name.common
        );
        setCountries(countryNames);
      })
      .catch((error) => console.error("Error fetching countries:", error));
  }, []);

  // Handle input change
  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <div
      className={` ${isArabic ? "text-right" : "text-left"} ${
        isArabic ? "rtl" : ""
      }`}
    >
      <div className="bg-[rgb(243,246,251)] py-16 px-4">
        <div className="container w-full md:w-[76%] mx-auto p-8 ">
          <h2 className="text-5xl font-bold text-[#293745] mb-7 text-center">
            {heading}
          </h2>
          <p className="text-center text-lg text-[#293745] mb-8">{para}</p>
          {location.pathname === "/training" && (
            <div>
              <h3 className="text-2xl text-start font-bold text-[#293745]">
                Book your live demo
              </h3>
              <p className="text-start text-base text-[#293745] mt-4 mb-1">
                Tell us a little about yourself and we’ll connect you with a
                product specialist to show you how you can transform your
                workplace with SafetyCulture.
              </p>
            </div>
          )}

          <form className="space-y-6">
            <div
              className={`grid grid-cols-1  gap-6 ${
                location.pathname === "/lone-worker"
                  ? "md:grid-cols-1"
                  : " md:grid-cols-2"
              } `}
            >
              <div>
                <label className="text-start block text-sm font-medium text-gray-700">
                  {t("demoform.domoform")}
                  <span className="text-red-600">*</span>
                </label>
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  className="mt-1 block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                  placeholder={t("demoform.emailplaceholder")}
                  required
                />
              </div>

              <div>
                <label className="text-start block text-sm font-medium text-gray-700">
                  {t("demoform.phoneno")}
                </label>
                <input
                  type="text"
                  name="phoneNumber"
                  value={formData.phoneNumber}
                  onChange={handleInputChange}
                  className="mt-1 block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                  placeholder={t("demoform.phonenoplaceholder")}
                />
              </div>
            </div>

            <div
              className={`w-full ${
                location.pathname === "/lone-worker"
                  ? "w-full"
                  : "w-full md:w-[50%] "
              }`}
            >
              <label className="text-start block text-sm font-medium text-gray-700">
                {t("demoform.country")}
              </label>
              <select
                name="country"
                value={formData.country}
                onChange={handleInputChange}
                className="mt-1 block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
              >
                <option value="">Please Select</option>
                {countries.map((country, index) => (
                  <option key={index} value={country}>
                    {country}
                  </option>
                ))}
              </select>
            </div>

            <div>
              <label className="text-start block text-sm font-medium text-gray-700 mb-2">
                {t("demoform.interested")}
              </label>
              <div className="space-y-2">
                {[
                  t("demoform.interested"),
                  t("demoform.li1"),
                  t("demoform.li2"),
                  t("demoform.li3"),
                  t("demoform.li4"),
                  t("demoform.li5"),
                  t("demoform.li6"),
                ].map((interest, index) => (
                  <div
                    key={index}
                    className={`flex items-center ${
                      isArabic ? "space-x-reverse space-x-4" : "ml-3"
                    }`}
                  >
                    <input
                      type="checkbox"
                      id={interest}
                      name="interests"
                      value={interest}
                      onChange={handleInputChange}
                      className="h-4 w-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
                    />
                    <label
                      htmlFor={interest}
                      className={`text-sm text-gray-700 ${
                        isArabic ? "mr-3" : "ml-3"
                      }`}
                    >
                      {interest}
                    </label>
                  </div>
                ))}
              </div>
            </div>

            <p className="text-start text-sm text-gray-500 mt-4">
              {t("demoform.para")}
            </p>

            <div className="text-center">
              <button
                type="submit"
                className="w-full py-3 px-6 bg-[#6559FF] text-white font-medium rounded-2xl hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition"
              >
                {t("demoform.demobtn")}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default DemoForm;
