import React from "react";
import SafetyHero from "../../../assets/secureCare/safetyculture-care-hero.webp";
import HeroFlex from "../../../shared/heroFlex";
const HeroSection = () => {
  return (
    <HeroFlex
      span="SecureSafetyCulture Care"
      heading="Improve your business. Don't just insure it."
      para="Combining risk management, insurance and technology into a single offering. With insights from the SecureSafetyCulture Platform, SecureSafetyCulture Care is ready to help businesses get better and smarter."
      iconbtn=" Visit SecureSafetyCulture Care"
      btn2="Take a tour"
      img={SafetyHero}
      
    />
  );
};

export default HeroSection;
