import React from "react";
import { FaArrowLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const NoAssets = () => {
  const navigate = useNavigate();
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-50 p-6">
      <div
        className="absolute top-4 left-4 flex items-center cursor-pointer"
        onClick={() => navigate(-1)}
      >
        <FaArrowLeft className="text-black text-2xl mr-2" />
      </div>
      <div className="max-w-5xl w-full p-6 rounded-lg shadow-md text-center">
        <h2 className="text-xl font-semibold text-gray-800 mb-4">
          No asset types created yet
        </h2>
        <p className="text-gray-600 mb-4">
          You'll need to create an asset type before uploading assets. Use types
          to categorize your assets and define the details you want to capture.
        </p>
        <button className="px-6 py-2 bg-indigo-500 text-white font-semibold rounded-lg shadow hover:bg-indigo-600">
          + Create a type
        </button>
      </div>
    </div>
  );
};

export default NoAssets;
