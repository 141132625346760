import React, { useState } from "react";
import { FaSearch } from "react-icons/fa";
import { FaFolderOpen } from "react-icons/fa";
import { IoMdSettings } from "react-icons/io";
const Archieve = () => {
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const toggleFilterDropdown = () => {
    setIsFilterOpen(!isFilterOpen);
  };
  return (
    <div className=" pt-5 pe-10">
      <div>
        <h1 className="text-gray-800 font-bold text-2xl text-start ">
          Template archive
        </h1>
        <div className="flex justify-between items-center mb-6 mt-5">
          {/* Search and filter */}
          <div className="flex items-center space-x-4">
            {/* Search box */}
            <div className="flex items-center border border-gray-300 rounded-md px-2 py-2">
              <FaSearch className="text-gray-500" />
              <input
                type="text"
                placeholder="Search users"
                className="ml-2 outline-none bg-transparent"
              />
            </div>

            {/* User status and filter */}
            <div className="flex items-center">
              <div className="relative">
                <button
                  onClick={toggleFilterDropdown}
                  className="text-[#6559ff] hover:text-blue-600"
                >
                  + Add filter
                </button>
                {isFilterOpen && (
                  <div className="absolute mt-2 w-48 rounded-md shadow-lg bg-white">
                    <ul className="py-1 text-gray-700">
                      <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer">
                        Filter 1
                      </li>
                      <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer">
                        Filter 2
                      </li>
                      <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer">
                        Filter 3
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* Create group and download */}
          <div className="flex items-center space-x-4">
            <p className=" text-gray-600 text-xs">No result</p>
          </div>
        </div>
      </div>
      <div className="flex items-center border-2 bg-[#F8F9FC] px-2 py-3 text-gray-500 text-sm font-medium">
        <div className="flex w-[60%]">
          <input type="checkbox" className="mr-4" />
          <span className=" text-gray-800 underline">Name</span>
        </div>
        <div className="w-[12%]">
          <span className=" text-gray-800 underline">Last published</span>
        </div>
        <div className="w-[23%]">
          <span className=" text-gray-800 underline">Access</span>
        </div>
        <div className="w-[2%] mt-1">
          <IoMdSettings size={21}/>
        </div>
      </div>

      {/* Empty State */}
      <div className="flex flex-col items-center justify-center py-16 border-2">
        <FaFolderOpen className="text-[#4740d4] text-6xl mb-4" />
        <p className="text-gray-600">There are no archived Template</p>
      </div>
    </div>
  );
};

export default Archieve;
