import React from "react";
import ListData from "../../../shared/listData";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

const ListDataSection = () => {
  const { t } = useTranslation();
  const isArabic = i18next.language === "ar";

  const categoriesData = [ 
    {
      title: t("transportlistdata.transportlistdata1.title"),
      items: t("transportlistdata.transportlistdata1.items", { returnObjects: true }),
    },
    {
      title: t("transportlistdata.transportlistdata2.title"),
      items: t("transportlistdata.transportlistdata2.items", { returnObjects: true }),
    },
    {
      title: t("transportlistdata.transportlistdata3.title"),
      items: t("transportlistdata.transportlistdata3.items", { returnObjects: true }),
    },
    {
      title: t("transportlistdata.transportlistdata4.title"),
      items: t("transportlistdata.transportlistdata4.items", { returnObjects: true }),
    },
  ];

  return (
    
    <div>
      <ListData
        title={t("transportlistdata.transportlistdataheading")}
        
        categories={categoriesData}
      />
    </div>
  );
};

export default ListDataSection;
