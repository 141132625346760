import React from "react";
import { useTranslation } from "react-i18next";
import { FaCheck } from "react-icons/fa6";
import { useLocation, useNavigate } from "react-router-dom";

const ImprovementSection = ({ heading, btn1, btn2, btn1Navigate }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const buttonClass =
    "font-bold py-3 px-6 rounded-xl hover:transition duration-300";

  const locationSpecificButtons = (
    <div className="flex flex-col sm:flex-row justify-center items-center space-y-4 sm:space-y-0 sm:space-x-5 mb-8">
      <button
        className={`bg-yellow-400 text-[#293745] ${buttonClass} hover:bg-yellow-500`}
      >
        {btn1}
      </button>
      <button className={`text-[#4740D8] ${buttonClass} hover:bg-gray-200`}>
        {btn2}
      </button>
    </div>
  );

  const defaultButton = (
    <div className="mb-8">
      <button
        className={`bg-yellow-400 text-[#293745] ${buttonClass} hover:bg-yellow-500`}
        onClick={() => btn1Navigate && navigate(btn1Navigate)}
      >
        {btn1}
      </button>
    </div>
  );

  return (
    <div className="w-full bg-[#6559ff] py-16">
      <div className="container mx-auto px-6 sm:px-12 md:px-16 lg:px-24 text-center text-white">
        {/* Heading */}
        <h2 className="text-3xl sm:text-3xl lg:text-[39px] font-bold mb-8">
          {heading}
        </h2>

        {/* Icon Row for /assets page */}
        {location.pathname === "/assets" && (
          <div className="flex flex-col sm:flex-row justify-center items-center space-y-4 sm:space-y-0 sm:space-x-8 mb-10">
            {[
              t("improvementsection.industries"),
              t("improvementsection.alwaysfree"),
              t("improvementsection.free"),
            ].map((text, idx) => (
              <div key={idx} className="flex items-center">
                <FaCheck className="text-[#FDD504] me-1.5" />
                <span>{text}</span>
              </div>
            ))}
          </div>
        )}

        {/* CTA Buttons */}
        {[
          "/construction",
          "/manufacturing",
          "/facilities-management",
          "/hospitality",
        ].includes(location.pathname)
          ? locationSpecificButtons
          : defaultButton}

        {/* App Store Buttons */}
        {/* <div className="flex justify-center items-center space-x-4">
          <img
            src="https://developer.apple.com/assets/elements/badges/download-on-the-app-store.svg"
            alt="App Store"
            className="w-32"
          />
          <img
            src="https://upload.wikimedia.org/wikipedia/commons/7/78/Google_Play_Store_badge_EN.svg"
            alt="Google Play"
            className="w-32"
          />
        </div> */}
      </div>
    </div>
  );
};

export default ImprovementSection;
