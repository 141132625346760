import ManufacturingHero from "../../../assets/hospatility/food-safety-sensors-hero.webp";
import TextImageFlex from "../../../shared/textImageFlex";
import { useTranslation } from "react-i18next";
const ImproveUptime = () => {
  const { t } = useTranslation();
  return (
    <div className="py-10">
      <TextImageFlex
        heading={t("hospitalitypage.ImproveUptimeheading")}
        para={t("hospitalitypage.ImproveUptimepara")}
        img={ManufacturingHero}
        widhtClass="w-full"
        reverse
      />
    </div>
  );
};

export default ImproveUptime;
