import React from "react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import Service1 from "../../assets/contactus/service1.webp";
import Service2 from "../../assets/contactus/service2.webp";
import Service3 from "../../assets/contactus/service3.webp";
import { useNavigate } from "react-router-dom";

const Services = () => {
  const { t } = useTranslation();
  const navigate = useNavigate(); // Initialize the navigate function

  const isArabic = i18next.language === "ar";

  // Define the services array inside the component
  const services = [
    {
      id: 1,
      image: Service1,
      title: t("supportpage.heroheader"), // Translated title
      buttonText: t("supportpage.servicepara1"), // Add keys in your translation file
      buttonLink:
        "mailto:support@example.com?subject=Support Request&body=Hi, I need help with...", // Mailto link for the first button
      isMailto: true,
    },
    {
      id: 2,
      image: Service2,
      title: t("supportpage.servicepara2"), // Add keys in your translation file
      buttonText: t("supportpage.servicepara3"),
      buttonLink: "/book-demo",
      isMailto: false,
    },
    {
      id: 3,
      image: Service3,
      title: t("supportpage.servicepara4"), // Add keys in your translation file
      buttonText: t("supportpage.servicepara5"),
      buttonLink: "/pricing/enterprise",
      isMailto: false,
    },
  ];

  const handleButtonClick = (service) => {
    if (service.isMailto) {
      window.location.href = service.buttonLink; // Use mailto for the first button
    } else {
      navigate(service.buttonLink); // Navigate for the other buttons
    }
  };
  return (
    <div className="container mx-auto px-4 py-20">
      <div className={` py-24 ${isArabic ? "text-right rtl" : "text-left"}`}>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {services.map((service) => (
            <div
              key={service.id}
              className="flex flex-col items-center text-center"
            >
              <img src={service.image} alt="" className="mb-4" />
              <h3 className="text-2xl text-[#293745] font-bold mb-5">
                {service.title}
              </h3>
              <button
                onClick={() => handleButtonClick(service)}
                className="px-6 py-2.5 bg-transparent text-[#4740DC] font-medium rounded-lg hover:bg-blue-200 transition duration-300 border-2 border-[#D0D7E1]"
              >
                {service.buttonText}
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Services;
