import FlexContent from "../../../shared/flexContent";
import InspectionImage from "../../../assets/hospatility/hospoHeadsUp.webp";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
const DeliverEfective = () => {
  const { t } = useTranslation();
  const isArabic = i18next.language === "ar";
  return (
    <div
      className={`py-24 ${isArabic ? "text-right" : "text-left"} ${
        isArabic ? "rtl" : ""
      }`}
    >
      <FlexContent
        heading={t("hospitalitypage.DeliverEfectiveheading")}
        para={t("hospitalitypage.DeliverEfectivepara")}
        italicText={t("hospitalitypage.DeliverEfectiveitalic")}
        name={t("hospitalitypage.Delivername")}
        position={t("hospitalitypage.Deliverposition")}
        img={InspectionImage}
      />
    </div>
  );
};
export default DeliverEfective;
