import React, { useEffect, useState } from "react";
import { FaCube, FaPen, FaClipboard } from "react-icons/fa";
import { FaCog } from "react-icons/fa";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Popup from "./asset2";
import useHttp from "../../../common/hooks/useHttp";
import { useAuth } from "../../context/AuthContext";

const Assetimg = require("../../../assets/adminassets/trolli.png");

const AssetsPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { authData } = useAuth();
  const { sendRequest } = useHttp();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [assets, setAssets] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  // const [isDropdown, setIsDropdown] = useState(false);

  const getAssets = async () => {
    try {
      await sendRequest(
        {
          url: "v1/asset/list",
          accessToken: authData.accessToken,
          headers: {
            userName: authData.userName,
            organizationId: authData.organizationId,
            deviceId: authData.deviceId,
          },
        },
        (response) => {
          console.log(response);
          setAssets(response.data.assets);
        }
      );
    } catch (error) {
      console.error("Error listing assets:", error);
    }
  };
  const addinArchive = () => {
    console.log("hello");
  };
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const toggleSettingsDropdown = () => {
    setIsSettingsOpen(!isSettingsOpen);
  };
  const handleStepOneClick = () => {
    navigate("/dashboard/assets/types");
  };

  const handleStepTwoClick = () => {
    setIsPopupOpen(true); // Open the popup when "Step 2" is clicked
  };
  const closePopup = () => {
    setIsPopupOpen(false); // Close the popup
  };
  const handleStep3Click = () => {
    navigate("/dashboard/templates");
  };

  useEffect(() => {
    getAssets();
  }, [authData]);
  const filteredAssets = assets.filter((asset) =>
    asset.displayName.toLowerCase().includes(searchTerm.toLowerCase())
  );
  return (
    <div className="p-4 bg-white min-h-screen mr-10">
      {/* Tabs */}
      <div className="flex items-center space-x-4 mb-6">
        <Link
          to="/dashboard/assets"
          className={`px-4 py-2 font-semibold ${
            location.pathname.startsWith("/dashboard/assets")
              ? "text-purple-600 border-b-2 border-purple-600"
              : "text-gray-500"
          }`}
        >
          Assets
        </Link>
        <Link
          to="/dashboard/archive"
          className={`px-4 py-2 font-semibold ${
            location.pathname.startsWith("/dashboard/archive")
              ? "text-purple-600 border-b-2 border-purple-600"
              : "text-gray-500"
          }`}
        >
          Archive
        </Link>
      </div>

      {/* Title and Top Buttons */}
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold">Assets</h1>
        <div className="flex items-center space-x-4">
          <button className="text-purple-600 border border-purple-600 px-4 py-2 rounded-md">
            Settings
          </button>
          <button
            onClick={() => {
              navigate("/dashboard/asset-prompt");
            }}
            className="bg-purple-600 text-white px-4 py-2 rounded-md"
          >
            Add asset <span className="ml-1">&#9662;</span>
          </button>
        </div>
      </div>

      {/* Steps Section */}
      <div className="p-6 bg-gray-50 rounded-lg shadow-md">
        <p className="text-left font-medium mb-6">
          Get started with assets by completing three easy steps.
        </p>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          {/* Step 1 */}
          <div
            className="p-4 bg-white h-48 rounded-md flex flex-col items-center cursor-pointer"
            onClick={handleStepOneClick}
          >
            <FaCube className="text-purple-600 text-3xl mb-6 mt-4" />
            <h3 className="font-semibold">1. Create asset types</h3>
            <p className="text-center text-gray-500 text-sm">
              Create and define the information you want to capture for each
              type of asset.
            </p>
          </div>
          {/* Step 2 */}
          <div
            className="p-4 bg-white rounded-md flex flex-col items-center cursor-pointer"
            onClick={handleStepTwoClick}
          >
            <FaPen className="text-yellow-500 text-3xl mb-6 mt-4" />
            <h3 className="font-semibold">2. Add assets</h3>
            <p className="text-center text-gray-500 text-sm">
              Add your assets one-by-one or in bulk by uploading a CSV file.
            </p>
          </div>
          {/* Step 3 */}
          <div
            className="p-4 bg-white rounded-md flex flex-col items-center cursor-pointer"
            onClick={handleStep3Click}
          >
            <FaClipboard className="text-blue-500 text-3xl mb-6 mt-4" />
            <h3 className="font-semibold">3. Link assets to templates</h3>
            <p className="text-center text-gray-500 text-sm">
              Add the "Asset" question to a template to track inspection
              activity for your assets.
            </p>
          </div>
        </div>
      </div>
      {isPopupOpen && <Popup onClose={closePopup} />}

      {/* Stats Section */}
      <div className="mt-10 flex justify-start items-center space-x-4">
        {/* Total assets */}
        <div className="flex items-center border border-black bg-white p-4 w-72 rounded-md shadow">
          <FaCube className="text-purple-600 text-3xl mr-4" />
          <div>
            <h4 className="text-lg font-semibold text-left">0</h4>
            <p className="text-gray-500 text-sm">Total assets</p>
          </div>
        </div>
        {/* Open actions */}
        <div className="flex items-center border border-black bg-white p-4 w-72 rounded-md shadow">
          <FaClipboard className="text-blue-500 text-3xl mr-4" />
          <div>
            <h4 className="text-lg font-semibold text-left">{assets.length}</h4>
            <p className="text-gray-500 text-sm">Open actions</p>
          </div>
        </div>
        {/* Inspections last 30 days */}
        <div className="flex items-center border border-black bg-white p-4 w-72 rounded-md shadow">
          <FaClipboard className="text-purple-600 text-3xl mr-4" />
          <div>
            <h4 className="text-lg font-semibold text-left">0</h4>
            <p className="text-gray-500 text-sm">Inspections last 30 days</p>
          </div>
        </div>
      </div>

      {/* Search and Filter */}
      <div className="mt-6 flex items-center space-x-4">
        <input
          type="text"
          placeholder="Search"
          className="px-4 py-2 border border-gray-300 rounded-md w-56"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <div className="relative">
          {/* Add Filter Button */}
          <button className="text-blue-500" onClick={toggleDropdown}>
            + Add filter
          </button>

          {/* Dropdown */}
          {isDropdownOpen && (
            <div className="absolute mt-2 w-48 bg-white border border-gray-200 rounded-md shadow-lg p-4">
              <div className="mb-2">
                <select className="w-full mt-1 p-2 border border-gray-300 rounded-md">
                  <option>Select option 1</option>
                  <option>Option 2</option>
                  <option>Option 3</option>
                </select>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="mt-4 rounded-md overflow-x-auto">
        <table className="w-full min-w-max text-left border border-gray-300">
          <thead>
            <tr className="bg-indigo-50 text-gray-700 font-thin h-14">
              <th className="p-2 text-xs sm:text-sm md:text-base">Unique id</th>
              <th className="p-2 text-xs sm:text-sm md:text-base">
                Display Name
              </th>
              <th className="p-2 text-xs sm:text-sm md:text-base">
                Last inspection date
              </th>
              <th className="p-2 text-xs sm:text-sm md:text-base">type</th>
              <th className="p-2 text-xs sm:text-sm md:text-base">
                option actions
              </th>
              <th className="p-2 text-xs sm:text-sm md:text-base">
                {/* Settings Button */}
                <button onClick={toggleSettingsDropdown} className="relative">
                  <FaCog className="text-gray-500 text-xl" />
                </button>
                {/* Dropdown Menu */}
                {isSettingsOpen && (
                  <div className="absolute right-0 mt-2 w-32 bg-white border border-gray-200 rounded-md shadow-lg z-10 p-2">
                    <ul className="text-gray-700">
                      <li className="p-2 hover:bg-gray-100 cursor-pointer">
                        Edit
                      </li>
                      <li
                        onClick={addinArchive}
                        className="p-2 hover:bg-gray-100 cursor-pointer"
                      >
                        Archive
                      </li>
                      <li className="p-2 hover:bg-gray-100 cursor-pointer">
                        View Details
                      </li>
                    </ul>
                  </div>
                )}
              </th>
            </tr>
          </thead>

          <tbody>
            {filteredAssets.length > 0 ? (
              filteredAssets.map((asset) => (
                <tr key={asset.id} className="border-t">
                  <td className="p-2 text-xs sm:text-sm md:text-base">
                    {asset.code}
                  </td>
                  <td className="p-2 text-xs sm:text-sm md:text-base">
                    {asset.displayName}
                  </td>
                  <td className="p-2 text-xs sm:text-sm md:text-base">
                    {new Date(asset.createdAt).toLocaleDateString()}
                  </td>
                  <td className="p-2 text-xs sm:text-sm md:text-base">
                    {asset?.assetType?.name}
                  </td>
                  <td className="p-2 text-xs sm:text-sm md:text-base">
                    <button className="bg-purple-600 text-white px-2 py-1 rounded-md">
                      Action
                    </button>
                  </td>
                  <td className="p-2 text-xs sm:text-sm md:text-base">
                    <button className="  text-black px-2 py-1 rounded-md">
                      ...
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="5" className="text-center p-4 sm:p-6">
                  <div className="flex flex-col items-center">
                    <img
                      src={Assetimg}
                      alt="No schedules"
                      className="mb-4 w-20 h-20 sm:w-32 sm:h-32 md:w-40 md:h-40"
                    />
                    <p className="text-gray-600 font-semibold text-sm sm:text-base md:text-lg">
                      No assets added yet
                    </p>
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AssetsPage;
