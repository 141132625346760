import Produvtivity from "../../../assets/platform/productivity-icon.svg";
import Engagement from "../../../assets/platform/engagement-icon.svg";
import Visibility from "../../../assets/platform/visibility-icon.svg";
import Quality from "../../../assets/platform/quality-icon.svg";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

const Cards = () => {
  const { t } = useTranslation();
  const isArabic = i18next.language === "ar";
  const cardData = [
    {
      icon: Produvtivity,
      Key: "Productivity",
    },
    {
      icon: Engagement,
      Key: "Engagement",
    },
    {
      icon: Visibility,
      Key: "Visibility",
    },
    {
      icon: Quality,
      Key: "Quality",
    },
  ];

  return (
    <div
      className={`${isArabic ? "text-right" : "text-left"} ${
        isArabic ? "rtl" : ""
      }`}
    >
      <div className="mx-[3%] lg:mx-[2%] xl:mx-[1%] 2xl:mx-auto 2xl:container">
        <p className="text-center pt-12 md:pt-28 pb-12 md:pb-16 text-[#293745] text-md md:text-[1.25rem] w-full md:w-2/3 mx-auto">
          {t("platformpage.work1")}
        </p>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8 px-4 md:px-8 xl:px-12 pb-12">
          {cardData.map((card, index) => (
            <div
              key={index}
              className="border border-[#d0d7e1] rounded-2xl p-6 flex flex-col items-start text-start space-y-4 h-[250px] md:h-[309px]"
            >
              <img src={card.icon} alt={card.Key} className="mx-auto" />
              <h3 className="text-[1.2rem] md:text-[1.5625rem] font-bold text-[#293745] pb-1.5">
                {t(`platformpage.${card.Key}.title`)}
              </h3>
              <p className="text-[#293745] text-base md:text-[1rem]">
                {t(`platformpage.${card.Key}.description`)}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Cards;
