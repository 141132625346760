import FlexContent from "../../../shared/flexContent";
import FacilitiesImage from "../../../assets/transport/AnalyticsReportingGraphs.webp";
import { useTranslation } from "react-i18next";
const BoostProductivity = () => {
  const { t } = useTranslation();
  return (
    <div className="bg-[#F3F6FB] py-24">
      <FlexContent
        heading={t("transportpage.Boostheading")}
        para={t("transportpage.Boostpara")}
        reverse
        italicText={t("transportpage.boostitalic")}
        name={t("transportpage.Boostname")}
        position={t("transportpage.boostposition")}
        img={FacilitiesImage}
      />
    </div>
  );
};
export default BoostProductivity;
