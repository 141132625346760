import Homepage from "../pages/adminPortal/dashboard";
import SearchPage from "../pages/adminPortal/search";
import NotificationsPage from "../pages/adminPortal/notifications";
import NotificationSetting from "../pages/adminPortal/notifications/notificationSettings";
import AddSites from "../components/adminPortal/notifications/addSite";
import SiteMatrix from "../components/adminPortal/notifications/siteMatrix";
import ItemsWithoutSites from "../components/adminPortal/notifications/itemsWithoutSites";
import BulkUploadSites from "../components/adminPortal/notifications/bulkUploadSites";
import BulkUplaodUsers from "../components/adminPortal/notifications/bulkUploadUsers";
import GroupMatrix from "../components/adminPortal/notifications/groupMatrix";
import AddCredentailsProcess from "../components/adminPortal/notifications/addCredentials";
import Templates from "../pages/adminPortal/templates";
import Inspection from "../pages/adminPortal/inspection";
import ActionPage from "../pages/adminPortal/actions";
import SettingsPage from "../pages/adminPortal/actions/settings";
import Createtemplate from "../shared/adminPortal/createTemplate";
import TemplateLibrary from "../components/adminPortal/templates/publicLibrary/publicLibrary";
import HeadUpPage from "../pages/adminPortal/Headsup";
import CreateHeadsUp from "../components/adminPortal/headsup/createHeadsUp";
import CustomizeLabelsModal from "../components/adminPortal/notifications/customizeSiteLabels";
import { Route, Routes } from "react-router-dom";
import MissedPage from "../components/adminPortal/schedule/Tabs/missed";
import managePage from "../components/adminPortal/schedule/Tabs/manage";
import SchedulePage from "../components/adminPortal/schedule/Tabs/mySchedules"; // Make sure this path is correct
import ScheduleInspectionPage from "../components/adminPortal/schedule/inspection/scheduleInspection";
import AssetsPage from "../components/adminPortal/assets/home";
import Archive from "../components/adminPortal/assets/archieve";
import IntegrationSection from "../components/adminPortal/assets/integrationsec";
import NoAsset from "../components/adminPortal/assets/noassetsec";
import AssetPrompt from "../components/adminPortal/assets/assetprompt";
import schadulePage from "../pages/adminPortal/schedule";
import Schedule from "../components/adminPortal/schedule/index";
import StartTheInspection from "../components/adminPortal/templates/publicLibrary/startTheInspection";
// import userProfile from "../components/adminPortal/profile/userProfile";
import UserProfile from "../components/adminPortal/profile/userProfile";
import UserSettings from "../components/adminPortal/profile/userSetting";
import UserTrainning from "../components/adminPortal/profile/userTrainning";
import UserDevice from "../components/adminPortal/profile/userDevice";
import UserGroup from "../components/adminPortal/profile/userGroup";
import UserSite from "../components/adminPortal/profile/userSite";
import UserCredential from "../components/adminPortal/profile/userCredential";
import UserTemplate from "../components/adminPortal/profile/userTemplate";
import UserNotification from "../components/adminPortal/profile/userNotification";
import User from "../pages/adminPortal/user";
import ManageInvites from "../components/adminPortal/users/manageInvites";
import UserPermission from "../components/adminPortal/users/userPermission";
import AdministratorPage from "../components/adminPortal/users/administratorPage";
import Group from "../components/adminPortal/users/userGroup";
import SetupStep from "../components/adminPortal/setup/setUp";
import IndustrySelection from "../components/adminPortal/setup/industrySelection";
import AssetField from "../components/adminPortal/assets/assetsfield";
import AddAssetsBulk from "../components/adminPortal/assets/addassetbulk";
import UploadCSV from "../components/adminPortal/assets/uploadcsv";
import AssetType from "../components/adminPortal/assets/asset1";

function PortalRoutes() {
  return (
    <div className="App overflow-x-hidden">
      <Routes>
        <Route path="/asset-bulk" element={<AddAssetsBulk />} />
        <Route path="/uploadcsv" element={<UploadCSV />} />
        <Route path="/" element={<Homepage />} />
        <Route path="/search" element={<SearchPage />} />
        <Route path="/setup" element={<SetupStep />} />
        <Route path="/industryselection" element={<IndustrySelection />} />
        <Route path="/notifications" element={<NotificationsPage />} />
        <Route path="/schedule" element={<SchedulePage />} />
        <Route path="/missed" element={<MissedPage />} />
        <Route path="/manage" element={<managePage />} />
        <Route path="/archive" element={<Archive />} />

        <Route path="/assets" element={<AssetsPage />} />
        <Route path="/assets/types" element={<AssetType />} />
        <Route path="/assets/types/:id" element={<AssetField />} />

        <Route path="/integrationsection" element={<IntegrationSection />} />
        <Route path="/no-asset-types" element={<NoAsset />} />
        <Route path="/asset-prompt" element={<AssetPrompt />} />
        <Route path="/schadulepage" element={<schadulePage />} />
        <Route path="/schadule" element={<Schedule />} />
        <Route
          path="/scheduleinspection"
          element={<ScheduleInspectionPage />}
        />
        <Route
          path="/notifications-settings"
          element={<NotificationSetting />}
        />
        <Route path="/profile" element={<UserProfile />} />
        <Route path="/user-setting" element={<UserSettings />} />
        <Route path="/user-trainning" element={<UserTrainning />} />
        <Route path="/user-device" element={<UserDevice />} />
        <Route path="/user-group" element={<UserGroup />} />
        <Route path="/user-site" element={<UserSite />} />
        <Route path="/user-credential" element={<UserCredential />} />
        <Route path="/user-template" element={<UserTemplate />} />
        <Route path="/user-notification" element={<UserNotification />} />
        <Route path="/user" element={<AddSites />} />
        <Route path="/user-permission" element={<UserPermission />} />
        <Route path="/manage-invite" element={<ManageInvites />} />
        <Route path="/administrator" element={<AdministratorPage />} />
        <Route path="/group" element={<Group />} />
        <Route path="/add-site" element={<AddSites />} />
        <Route path="/site-matrix" element={<SiteMatrix />} />
        <Route path="/items-without-site" element={<ItemsWithoutSites />} />
        <Route path="/bulk-upload-sites" element={<BulkUploadSites />} />
        <Route path="/bulk-upload-users" element={<BulkUplaodUsers />} />
        <Route path="/group-matrix" element={<GroupMatrix />} />
        <Route
          path="/add-credentials-process"
          element={<AddCredentailsProcess />}
        />
        <Route path="/templates" element={<Templates />} />
        <Route path="/inspection" element={<Inspection />} />
        <Route path="/actions" element={<ActionPage />} />
        <Route path="/action-settings" element={<SettingsPage />} />
        <Route path="/create-template" element={<Createtemplate />} />

        <Route path="/template-library" element={<TemplateLibrary />} />
        <Route path="/headsup" element={<HeadUpPage />} />
        <Route path="/create-headsup" element={<CreateHeadsUp />} />
        <Route path="/start-inspection" element={<StartTheInspection />} />
      </Routes>
    </div>
  );
}

export default PortalRoutes;
