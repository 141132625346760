import FlexContent from "../../../shared/flexContent";
import FacilitiesImage from "../../../assets/transport/Reporting-transport-and-logistics.webp";
import { useTranslation } from "react-i18next";
const SafeWorkSpaces = () => {
  const { t } = useTranslation();
  return (
    <div className="py-12 md:py-24">
      <FlexContent
        heading={t("transportpage.SafeWorkheading")}
        para={t("transportpage.SafeWorkpara")}
        img={FacilitiesImage}
        reverse
        italicText={t("transportpage.SafeWorkitalic")}
        name={t("transportpage.SafeWorkname")}
        position={t("transportpage.SafeWorkposition")}
      />
    </div>
  );
};
export default SafeWorkSpaces;
