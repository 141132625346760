import { useTranslation } from "react-i18next";
const Hero = () => {
  const { t } = useTranslation();
  return (
    <div className="py-24 bg-[#F3F6FB]">
      <h1 className="text-xl md:text-4xl lg:text-6xl text-center font-bold text-[#293745]">
        {t("supportpage.heroheader")}
      </h1>
    </div>
  );
};
export default Hero;
