import FAQSection from "../../../shared/faqs";
import { useTranslation } from "react-i18next";

const Faqs = () => {
  // Call the useTranslation hook
  const { t } = useTranslation();

  // Define the FAQ data using `t()` for translations
  const faqData = [
    {
      question: t("constructionfaqs.constructionfaqsquestion0"),
      answer: t("constructionfaqs.constructionfaqsanswer0"),
    },
    {
      question: t("constructionfaqs.constructionfaqsquestion1"),
      answer: t("constructionfaqs.constructionfaqsanswer1"),
    },
    {
      question: t("constructionfaqs.constructionfaqsquestion2"),
      answer:
      t("constructionfaqs.constructionfaqsanswer2"),
    },
    {
      question: t("constructionfaqs.constructionfaqsquestion3"),
      answer:
      t("constructionfaqs.constructionfaqsanswer3"),
    },
    {
      question: t("constructionfaqs.constructionfaqsquestion4"),
      answer:
      t("constructionfaqs.constructionfaqsanswer4"),
    },
  ];

  // Return the FAQ section with translated data
  return <FAQSection faqData={faqData} heading={t("constructionfaqs.constructionfaqsheading")} />;
};

export default Faqs;
