import React from "react";
import ListData from "../../../shared/listData";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

const ListDataSection = () => {
  const { t } = useTranslation();
  const isArabic = i18next.language === "ar";
  

  const categoriesData = [
    {
      title: t("constructionlistdata.constructionlistdata1.title"),
      items: t("constructionlistdata.constructionlistdata1.items", { returnObjects: true }),
    },
    {
      title: t("constructionlistdata.constructionlistdata2.title"),
      items: t("constructionlistdata.constructionlistdata2.items", { returnObjects: true }),
    },
    {
      title: t("constructionlistdata.constructionlistdata3.title"),
      items: t("constructionlistdata.constructionlistdata3.items", { returnObjects: true }),
    },
    {
      title: t("constructionlistdata.constructionlistdata4.title"),
      items: t("constructionlistdata.constructionlistdata4.items", { returnObjects: true }),
    },
  ];

  return (
    <div
            className={` ${isArabic ? "text-right" : "text-left"} ${
              isArabic ? "rtl" : ""
            }`}
          >
    <div>
      <ListData
        title={t("constructionlistdata.constructionlistdataheading")}
        
        categories={categoriesData}
      />
    </div>
    </div>
  );
};

export default ListDataSection;
