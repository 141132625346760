import React from "react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { useFormik } from "formik";
import * as Yup from "yup";
import useHttp from "../../common/hooks/useHttp";
import { useNotification } from "../../common/hooks/useNotification";

const stringValidation = (label, min, max, regex, message) =>
  Yup.string()
    .required(`${label} is required`)
    .min(min, `${label} must be at least ${min} characters`)
    .max(max, `${label} cannot exceed ${max} characters`)
    .matches(regex || /^[\w\s]*$/, message || `${label} is invalid`);

const ContactUsForm = () => {
  const { t } = useTranslation();
  const isArabic = i18next.language === "ar";
  const notify = useNotification();
  const { sendRequest, loading } = useHttp();

  // Form validation schema
  const validationSchema = Yup.object({
    firstName: stringValidation("First name", 2, 25),
    lastName: stringValidation("Last name", 2, 25),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required")
      .min(5, "Email must be at least 5 characters")
      .max(50, "Email cannot exceed 50 characters"),
    phoneNumber: Yup.string()
      .required("Phone number is required")
      .matches(
        /^05[0-9]{8}$/,
        "Phone number must start with '05' and be 10 digits long"
      ),
    jobTitle: stringValidation(
      "Job title",
      2,
      100,
      /^[a-zA-Z\s]+$/,
      "Job title can only contain letters and spaces"
    ),
    companyName: stringValidation(
      "Company name",
      2,
      100,
      /^[a-zA-Z0-9\s]+$/,
      "Company name can only contain letters, numbers, and spaces"
    ),
    enquiryMessage: stringValidation(
      "Message",
      10,
      500,
      /^[\w\s.,!?()&-]*$/,
      "Message can only contain letters, numbers, and basic punctuation"
    ),
    industry: stringValidation("Industry", 2, 50),
    numberOfEmployees: Yup.string()
      .required("Number of employees is required")
      .matches(/^[0-9]+$/, "Number of employees must be a number")
      .min(1, "Number of employees must be at least 1")
      .max(10, "Number of employees cannot exceed 10 digits"),
    country: stringValidation("Country", 2, 50),
    solutionNeeded: stringValidation("Solution needed", 2, 100),
    problemsToSolve: stringValidation(
      "Problems to solve",
      10,
      500,
      /^[\w\s.,!?()&-]*$/,
      "Problems to solve can only contain letters, numbers, and basic punctuation"
    ),
  });

  // Formik hook
  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      jobTitle: "",
      companyName: "",
      enquiryMessage: "",
      industry: "",
      numberOfEmployees: "",
      country: "",
      solutionNeeded: "",
      problemsToSolve: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        const response = await sendRequest({
          url: "v1/contact-us/submit",
          method: "POST",
          payload: values,
        });
        if (response && response.responseCode === "200") {
          formik.resetForm();
          notify(response.responseDesc, "success");
        } else {
          notify(response.responseDesc, "error");
        }
      } catch (error) {
        notify("An error occurred. Please try again.", "error");
      }
    },
  });

  return (
    <div className="py-24 bg-white">
      <div className="max-w-3xl mx-auto bg-white p-8 rounded-lg shadow-lg">
        <div className={`${isArabic ? "text-right rtl" : "text-left"}`}>
          <form onSubmit={formik.handleSubmit}>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
              {[
                { id: "firstName", label: t("enterprisepage.firstname") },
                { id: "lastName", label: t("enterprisepage.lastname") },
                { id: "email", label: t("enterprisepage.email") },
                { id: "phoneNumber", label: t("enterprisepage.phoneno") },
                { id: "jobTitle", label: t("enterprisepage.jobtitle") },
                { id: "companyName", label: t("enterprisepage.companyname") },
                { id: "industry", label: t("enterprisepage.industry") },
                {
                  id: "numberOfEmployees",
                  label: t("enterprisepage.numberofemployees"),
                },
                { id: "country", label: t("enterprisepage.country") },
                { id: "solutionNeeded", label: t("enterprisepage.solution") },
              ].map((field) => (
                <div key={field.id} className="flex flex-col">
                  <label
                    htmlFor={field.id}
                    className="text-sm font-semibold text-[#293745] mb-2"
                  >
                    {field.label}
                  </label>
                  <input
                    id={field.id}
                    name={field.id}
                    type="text"
                    className="p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-black"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values[field.id]}
                  />
                  {formik.touched[field.id] && formik.errors[field.id] && (
                    <div className="text-red-500 text-sm">
                      {formik.errors[field.id]}
                    </div>
                  )}
                </div>
              ))}

              <div className="flex flex-col col-span-2">
                <label
                  htmlFor="enquiryMessage"
                  className="text-sm font-semibold text-[#293745] mb-2"
                >
                  {t("enterprisepage.enquiry")}
                </label>
                <textarea
                  id="enquiryMessage"
                  name="enquiryMessage"
                  className="p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-black"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.enquiryMessage}
                />
                {formik.touched.enquiryMessage &&
                  formik.errors.enquiryMessage && (
                    <div className="text-red-500 text-sm">
                      {formik.errors.enquiryMessage}
                    </div>
                  )}
              </div>

              <div className="flex flex-col col-span-2">
                <label
                  htmlFor="problemsToSolve"
                  className="text-sm font-semibold text-[#293745] mb-2"
                >
                  {t("enterprisepage.problems")}
                </label>
                <textarea
                  id="problemsToSolve"
                  name="problemsToSolve"
                  className="p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-black"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.problemsToSolve}
                />
                {formik.touched.problemsToSolve &&
                  formik.errors.problemsToSolve && (
                    <div className="text-red-500 text-sm">
                      {formik.errors.problemsToSolve}
                    </div>
                  )}
              </div>
            </div>

            <div className="flex justify-end mt-6">
              <button
                type="submit"
                disabled={loading}
                className="px-6 py-2 bg-[#6559FF] text-white font-semibold rounded-md hover:bg-[#5146cc] disabled:opacity-50"
              >
                {loading ? t("loading") : t("submit")}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ContactUsForm;
