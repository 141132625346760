import React from "react";
import i18next from "i18next";

const ChecklistItem = ({ icon, strongText, paraText }) => {
  return (
    <div className="flex pb-6">
      <div>{icon}</div>
      <div>
        <strong className="text-md md:text-[1.1rem] text-[#293745]">
          {strongText}
        </strong>
        <p className="text-[#293745]">{paraText}</p>
      </div>
    </div>
  );
};

const ContentWithChecked = ({
  img,
  heading,
  para,
  strong1,
  strong2,
  icon,
  para1,
  para2,
  strong3,
  para3,
  reverse = false,
}) => {
  const isArabic = i18next.language === "ar"; // Dynamically check for Arabic

  return (
    <section className="px-4 sm:px-6 lg:px-8">
      <div
        className={`max-w-7xl mx-auto flex flex-col-reverse lg:flex-row items-start justify-between ${
          reverse ? "lg:flex-row-reverse" : ""
        }`}
      >
        {/* Left Side (Image) */}
        <div className="lg:w-1/2 mb-12 lg:mb-0">
          <div className="relative flex justify-center md:justify-start">
            <img
              src={img}
              alt="Inspection template tool"
              className="rounded-xl w-full h-full object-cover"
            />
          </div>
        </div>

        {/* Right Side (Text and Checklist) */}
        <div
          dir={isArabic ? "rtl" : "ltr"} // Dynamically set text direction
          className={`lg:w-1/2 space-y-6 pt-16 ${
            isArabic ? "text-right" : "text-left"
          }`} // Dynamically set text alignment
        >
          <h1 className="text-[2.2rem] md:text-[3.2rem] lg:text-[2.65rem] font-bold text-[#293745] md:leading-[53px]">
            {heading}
          </h1>
          <p className="text-[#293745]">{para}</p>
          <div>
            {/* Checklist Items */}
            <ChecklistItem icon={icon} strongText={strong1} paraText={para1} />
            <ChecklistItem icon={icon} strongText={strong2} paraText={para2} />
            <ChecklistItem icon={icon} strongText={strong3} paraText={para3} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContentWithChecked;
