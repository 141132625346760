import React from "react";
import { IoIosRocket } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { useApi } from "../../context/OrganizationContaxt";
import { useState } from "react";
function PermissionSets() {
  const navigate = useNavigate();
  const [showPopup, setShowPopup] = useState(false);
  const [showTrialModal, setShowTrialModal] = useState(false);
  const [country, setCountry] = useState("Pakistan");
  const [paymentMethod, setPaymentMethod] = useState("Card");
  const [planType, setPlanType] = useState("Monthly");
  const handleTdClick = () => {
    navigate("/dashboard/administrator"); // Navigate to /dashboard/administrator
  };
  const { users } = useApi();
  const administrators = users?.filter(
    (item) => item?.role?.toLowerCase() === "administrator"
  );

  const managers = users?.filter(
    (item) => item?.role?.toLowerCase() === "Manager"
  );

  const Standard = users?.filter(
    (item) => item?.role?.toLowerCase() === "Standard"
  );
  const Basic = users?.filter((item) => item?.role?.toLowerCase() === "Basic");

  const Guest = users?.filter((item) => item?.role?.toLowerCase() === "Guest");
  console.log(administrators, managers);
  const colors = [
    "bg-blue-100 text-blue-700",
    "bg-green-100 text-green-700",
    "bg-yellow-100 text-yellow-700",
    "bg-red-100 text-red-700",
  ];
  return (
    <div className="bg-white text-start  p-3 md:p-3">
      {/* Page Header */}
      <h1 className="text-2xl font-semibold text-gray-800 mb-6">
        Permission sets
      </h1>

      {/* Info Banner */}
      <div className="bg-gray-100 rounded-lg px-4 py-7 flex items-center justify-between mb-6">
        <div>
          <p className="font-semibold text-md text-gray-800 ">
            Manage permissions sets for enhanced user control
          </p>
          <p className="text-sm text-gray-600">
            Streamline your team's experience by granting feature access only to
            those who need it.
          </p>
        </div>
        <button className="flex  px-4 py-3 bg-[#6559ff] text-white text-sm font-medium rounded-md hover:bg-[#7470f5] transition">
          <IoIosRocket className="me-1" size={21} />
          Upgrade to Premium
        </button>
      </div>

      {/* Search Bar */}
      <div className="flex relative mb-4 w-[240px]">
        <svg
          className="w-5 h-5 text-gray-400 absolute left-3 top-3"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M21 21l-4.35-4.35M16 10a6 6 0 11-12 0 6 6 0 0112 0z"
          />
        </svg>
        <input
          type="text"
          placeholder="Search"
          className="w-full ps-10 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-indigo-600 focus:outline-none"
        />
      </div>
      {showPopup && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg w-3/5 text-center max-w-2xl">
            <h3 className="text-lg font-semibold mb-4">
              Improve your production line with Premium
            </h3>
            <p className="text-gray-600 mb-6">
              30-day free trial. Cancel anytime. We'll remind you 7 days before
              your trial ends.
            </p>
            <button
              className="bg-indigo-500 text-white py-2 px-4 rounded-lg mb-4"
              onClick={() => {
                setShowPopup(false);
                setShowTrialModal(true);
              }}
            >
              Try Premium for free
            </button>
            <div className="grid grid-cols-2 gap-x-4 text-left mb-6">
              <ul className="list-disc list-inside text-gray-700 space-y-2">
                <li>Real-time analytics to compare GMP compliance</li>
                <li>Advanced permissions and user management</li>
                <li>Unlimited team members, including lite seats</li>
              </ul>
              <ul className="list-disc list-inside text-gray-700 space-y-2">
                <li>Custom quality control forms, hazard reports, and more</li>
                <li>Integrations with business systems or calendars</li>
                <li>Free access for casual workers, suppliers, and users</li>
              </ul>
            </div>
            <button
              className="text-gray-500"
              onClick={() => setShowPopup(false)}
            >
              Close
            </button>
          </div>
        </div>
      )}

      {/* Detailed Trial Modal */}
      {showTrialModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg shadow-lg p-8 w-3/5 max-w-4xl flex">
            {/* Left Section */}
            <div className="w-2/3 pr-8">
              <button
                className="text-indigo-500 mb-4 flex items-left"
                onClick={() => setShowTrialModal(false)}
              >
                &larr; Back
              </button>
              <h2 className="text-2xl font-bold mb-6 text-center">
                Start your 30-day free trial
              </h2>

              {/* Organization Section */}
              <div className="mb-8">
                <h3 className="font-semibold text-lg mb-2 text-left">
                  1. Organization
                </h3>
                <label className="block text-gray-700 text-left">
                  Country*
                </label>
                <select
                  value={country}
                  onChange={(e) => setCountry(e.target.value)}
                  className="mt-1 w-full border border-gray-300 rounded-lg p-2"
                >
                  <option>Pakistan</option>
                  <option>United States</option>
                  <option>Canada</option>
                  <option>Australia</option>
                </select>
              </div>

              {/* Payment Method Section */}
              <div className="mb-8">
                <h3 className="font-semibold text-lg mb-2 text-left">
                  2. Payment method
                </h3>
                <p className="text-gray-600 text-sm mb-4 text-left">
                  <span role="img" aria-label="lock">
                    🔒
                  </span>{" "}
                  Secured payment
                </p>
                <div className="flex mb-6">
                  <button
                    onClick={() => setPaymentMethod("Card")}
                    className={`px-4 py-2 rounded-lg mr-4 ${
                      paymentMethod === "Card"
                        ? "bg-indigo-500 text-white"
                        : "bg-gray-200 text-gray-700"
                    }`}
                  >
                    💳 Card
                  </button>
                  <button
                    onClick={() => setPaymentMethod("PayPal")}
                    className={`px-4 py-2 rounded-lg ${
                      paymentMethod === "PayPal"
                        ? "bg-indigo-500 text-white"
                        : "bg-gray-200 text-gray-700"
                    }`}
                  >
                    PayPal
                  </button>
                </div>
                <div className="grid grid-cols-2 gap-4">
                  <input
                    type="text"
                    placeholder="First name on card *"
                    className="border border-gray-300 rounded-lg p-2"
                  />
                  <input
                    type="text"
                    placeholder="Last name on card *"
                    className="border border-gray-300 rounded-lg p-2"
                  />
                  <input
                    type="text"
                    placeholder="Card number *"
                    className="border border-gray-300 rounded-lg p-2 col-span-2"
                  />
                  <input
                    type="text"
                    placeholder="Month *"
                    className="border border-gray-300 rounded-lg p-2"
                  />
                  <input
                    type="text"
                    placeholder="Year *"
                    className="border border-gray-300 rounded-lg p-2"
                  />
                  <input
                    type="text"
                    placeholder="CVV *"
                    className="border border-gray-300 rounded-lg p-2"
                  />
                </div>
              </div>
            </div>

            {/* Right Section */}
            <div className="w-2/3 bg-gray-100 p-8 rounded-lg">
              <h3 className="font-semibold mb-6 text-xl">Premium Plan</h3>

              <div className="flex justify-between items-center mb-6">
                <span className="text-lg">Monthly</span>
                <label className="inline-flex items-center">
                  <input
                    type="checkbox"
                    className="form-checkbox text-indigo-500"
                    checked={planType === "Annual"}
                    onChange={() =>
                      setPlanType(planType === "Monthly" ? "Annual" : "Monthly")
                    }
                  />
                  <span className="ml-2 text-sm text-gray-700">
                    Annual (Save 17%)
                  </span>
                </label>
              </div>

              <div className="flex justify-between items-center mb-6">
                <p className="text-gray-700 text-lg">
                  Estimated price after free trial
                </p>
                <p className="font-semibold text-lg">$29.00</p>
              </div>

              <button className="text-indigo-500 text-sm mb-6">
                Add coupon code
              </button>

              <hr className="my-6" />

              <div className="flex justify-between items-center mb-6">
                <p className="font-semibold text-lg">Today's total</p>
                <p className="font-bold text-2xl">$0.00</p>
              </div>

              <button className="bg-indigo-500 text-white w-full py-3 rounded-lg flex items-center justify-center text-lg font-medium">
                🚀 Start your free trial
              </button>

              <p className="text-gray-600 text-sm mt-6">
                Cancel your free trial before{" "}
                <span className="font-semibold">14 Dec 2024</span>, and we won't
                charge your provided payment method. We'll remind you 7 days
                before your trial ends.
              </p>
            </div>
          </div>
        </div>
      )}
      {/* Permissions Table */}
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white border border-gray-200 rounded-lg">
          <thead>
            <tr className="bg-gray-50">
              <th className="py-2 px-4 text-left text-gray-500 font-medium border-b text-sm">
                Name
              </th>
              <th className="py-2 px-4 text-left text-gray-500 font-medium border-b text-sm ">
                Modified
              </th>
              <th className="py-2 px-4 text-left text-gray-500 font-medium border-b text-sm">
                Seat
              </th>
              <th className="py-2 px-4 text-left text-gray-500 font-medium border-b text-sm">
                Users
              </th>
            </tr>
          </thead>
          <tbody>
            <tr className="hover:bg-gray-50">
              <td className="py-3 px-4 text-[#7470f5] text-sm font-medium border-b">
                Administrator
              </td>
              <td className="py-3 px-4 text-gray-600 border-b text-sm"></td>
              <td className="py-3 px-4 text-gray-600 border-b text-sm">
                {users.length === 2 ? "no seat available" : "available"}
              </td>
              <td className="py-3 px-4 text-gray-600 border-b">
                {administrators.map((item, index) => (
                  <span
                    style={{ cursor: "pointer" }}
                    key={index}
                    className={` ${
                      colors[index % colors.length]
                    }  py-1 px-2 rounded-full text-xs font-medium`}
                  >
                    {(
                      item?.user.firstName?.charAt(0) +
                      item?.user.firstName?.charAt(
                        item.user.firstName.length - 1
                      )
                    ).toUpperCase()}
                  </span>
                ))}
                <div className="w-8 h-8 flex items-center justify-center rounded-full  text-sm font-semibold"></div>
              </td>
            </tr>

            <tr className="hover:bg-gray-50">
              <td className="py-3 px-4 text-[#7470f5] text-sm font-medium border-b">
                Basic
              </td>
              <td className="py-3 px-4 text-gray-600 border-b text-sm">
                53 minutes ago
              </td>
              <td className="py-3 px-4 text-gray-600 border-b text-sm">
                Lite seat
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      {/* Chat Button */}
      <button className="fixed bottom-8 right-8 bg-indigo-600 w-12 h-12 rounded-full flex items-center justify-center text-white shadow-lg hover:bg-indigo-700 transition">
        <svg
          className="w-6 h-6"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M7 8h10M7 12h4m1 8h5a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v7a2 2 0 002 2h3l4 4z"
          />
        </svg>
      </button>
    </div>
  );
}

export default PermissionSets;
