import ImprovementSection from "../../../shared/improvementSection";
import { useTranslation } from "react-i18next";
const Improvement = () => {
  const { t } = useTranslation();
  return (
    <ImprovementSection
      heading={t("assetpage.improvementheading")}
      btn1={t("assetpage.improvementbtn")}
    />
  );
};
export default Improvement;
