import React, { useState } from "react";
import { FaSearch } from "react-icons/fa";
import { FaMountainSun } from "react-icons/fa6";
const Archieve = () => {
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const toggleFilterDropdown = () => {
    setIsFilterOpen(!isFilterOpen);
  };
  return (
    <div className=" pt-5 pe-10">
      <div>
        <h1 className="text-gray-800 font-bold text-2xl text-start ">
          Inspection
        </h1>
        <div className="flex justify-between items-center mb-6 mt-5">
          {/* Search and filter */}
          <div className="flex items-center space-x-4">
            {/* Search box */}
            <div className="flex items-center border border-gray-300 rounded-md px-2 py-2">
              <FaSearch className="text-gray-500" />
              <input
                type="text"
                placeholder="Search users"
                className="ml-2 outline-none bg-transparent"
              />
            </div>

            {/* User status and filter */}
            <div className="flex items-center">
              <div className="relative">
                <button
                  onClick={toggleFilterDropdown}
                  className="text-[#6559ff] hover:text-blue-600"
                >
                  + Add filter
                </button>
                {isFilterOpen && (
                  <div className="absolute mt-2 w-48 rounded-md shadow-lg bg-white">
                    <ul className="py-1 text-gray-700">
                      <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer">
                        Filter 1
                      </li>
                      <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer">
                        Filter 2
                      </li>
                      <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer">
                        Filter 3
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* Create group and download */}
          <div className="flex items-center space-x-4">
            <p className=" text-gray-600 text-xs">No result</p>
          </div>
        </div>
      </div>
      <div className="flex items-center border-2 bg-[#F8F9FC] px-2 py-3 text-gray-500 text-sm font-medium">
        <input type="checkbox" className="mr-4" />
        <span className="flex-grow text-gray-800 underline">Inspection</span>
        <span className="flex-grow text-gray-800">Actions</span>
        <span className="flex-grow text-gray-800">Doc number</span>
        <span className="flex-grow text-gray-800 underline">Score</span>
        <span className="flex-grow text-gray-800 underline">Conducted</span>
        <span className="flex-grow text-gray-800 underline">Completed</span>
        <button className="ml-4 text-gray-400 hover:text-gray-600">
          <svg width="20" height="20" fill="currentColor" className="h-5 w-5">
            <path d="M10 2a8 8 0 1 0 0 16 8 8 0 0 0 0-16Zm1 11H9v-2h2v2Zm0-4H9V5h2v4Z"></path>
          </svg>
        </button>
      </div>

      {/* Empty State */}
      <div className="flex flex-col items-center justify-center py-16 border-2">
        <FaMountainSun className="text-[#4740d4] text-6xl mb-4" />
        <p className="text-gray-600 font-[600] ">
          Every inspection conducted by your team will appear here
        </p>
        <p className="text-gray-600 font-medium pt-1" >
          There are no inspections yet, <span className="text-[#4740d4]">Start an inspection</span>  or <span className="text-[#4740d4]"> create a template</span> 
        </p>
      </div>

      {/* Pagination */}
      {/* <div className="flex items-center justify-center space-x-4 mt-4">
      <button className="text-gray-400 hover:text-gray-600">&gt;</button>
        <button className="text-gray-400 hover:text-gray-600">1</button>
        <button className="text-gray-400 hover:text-gray-600">&gt;</button>
      </div> */}
    </div>
  );
};

export default Archieve;
