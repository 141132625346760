import FlexContent from "../../../shared/flexContent";
import FacilitiesImage from "../../../assets/mining/Continous-Improvement-img-03.webp";
const BoostProductivity = () => {
  return (
    <div className="py-12 md:py-16">
      <FlexContent
        heading="Deliver effective toolbox talks and pre-starts"
        para="Start delivering toolbox talks in a format fit for busy teams. With Heads Up, you can create and share video messages quickly and keep a record of acknowledgment. Ensure everyone has access to critical safety information at all times with the ability to reference past toolbox talks anytime."
        italicText=" “Instead of sending out a lengthy email, we are able to get out in front of everyone digitally. I really think it has helped our organization’s safety culture in general.”"
        name="Jonathan Howell"
        position="Assistant Safety Director, ClearWell Dynamics"
        img={FacilitiesImage}
      />
    </div>
  );
};
export default BoostProductivity;
