import React from "react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import DigitizeHero from "../../../assets/inspection/Import-forms-1.webp";
import TextImageFlex from "../../../shared/textImageFlex";

const DigitizeSection = () => {
  const isArabic = i18next.language === "ar";
  const { t } = useTranslation();
  return (
    <div
      className={` ${isArabic ? "text-right" : "text-left"} ${
        isArabic ? "rtl" : ""
      }`}
    >
      <div className="py-12 md:py-24">
        <TextImageFlex
          heading={t("inspectionpage.digitalizeheading")}
          para={t("inspectionpage.digitalizepara")}
          img={DigitizeHero}
          widhtClass="w-[88%]"
        />
      </div>
    </div>
  );
};

export default DigitizeSection;
