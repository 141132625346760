import React from 'react';
import { MdEdit } from "react-icons/md";
import { IoMdSend } from "react-icons/io";
const UserSettingsTab = () => {
  return (
    <div className="container mx-auto  py-4">
      <div className="flex flex-col lg:flex-row gap-8">
        {/* Left Side - Details Card */}
       <div className='w-full lg:w-1/2 space-y-4'>
       <div className=" bg-white text-start rounded-lg  p-6 border border-[#dbe0eb]">
          <div className="flex justify-between items-center">
            <h2 className="text-xl font-semibold">Details</h2>
            
            <button className="text-[#4740d4] bg-white border border-[#bfc6d4] flex rounded-xl py-2 px-3"><MdEdit className='me-1 mt-1'/> Edit</button>
          </div>
          <div className="mt-4 space-y-4">
            <div className="text-sm">
              <p className="font-semibold">Name</p>
              <p>uzair khan</p>
            </div>
            <div className="text-sm">
              <p className="font-semibold">Email</p>
              <p>uzairkhan55465@gmail.com</p>
            </div>
            <div className="text-sm">
              <p className="font-semibold">Time zone</p>
              <p>Los Angeles (GMT-08)</p>
            </div>
            <div className="text-sm">
              <p className="font-semibold">Date format</p>
              <p>d MMM yyyy (e.g. 1 Dec 2021)</p>
            </div>
            <div className="text-sm">
              <p className="font-semibold">Time format</p>
              <p>12-hour (e.g. 3:30 PM)</p>
            </div>
            <div className="text-sm">
              <p className="font-semibold">Temperature metric</p>
              <p>Celsius (°C)</p>
            </div>
            <div className="text-sm">
              <p className="font-semibold">Distance metric</p>
              <p>Kilometers (km)</p>
            </div>
            <div className="text-sm">
              <p className="font-semibold">Language</p>
              <p>English (US)</p>
            </div>
            <div className="text-sm">
              <p className="font-semibold">User ID</p>
              <div className="flex items-center justify-between bg-gray-100 p-2 rounded">
                <span>27030f48-2261-46b6-b447-7f590d3e4d31</span>
                <button className="text-purple-500 hover:underline">Copy</button>
              </div>
            </div>
          </div>
          
        </div>
       <div className="bg-white rounded-lg  p-6 border border-[#dbe0eb]">
            <div className="flex justify-between items-center">
              <h2 className="text-xl font-semibold">Password</h2>
              <button className="text-[#4740d4] bg-white border border-[#bfc6d4] flex rounded-xl py-2 px-3"><MdEdit className='me-1 mt-1'/> Edit</button>
            </div>
            <p className="text-sm mt-2 text-gray-600 text-start pt-3">Password</p>
            <p className='text-start'>*****************</p>
          </div>
       </div>
    

        {/* Right Side - Cards */}
        <div className="w-full lg:w-1/2 space-y-4">
          {/* Mobile Number Card */}
          <div className="bg-white rounded-lg  p-6 border border-[#dbe0eb]">
            <div className="flex justify-between items-center">
              <h2 className="text-xl font-semibold">Mobile number</h2>
              <button className="text-[#4740d4] bg-white border border-[#bfc6d4] flex rounded-xl py-2 px-3"><MdEdit className='me-1 mt-1'/> Edit</button>
            </div>
            <p className="text-sm mt-2 text-gray-600 text-start pt-3">Add your mobile number to enable SMS notifications.</p>
          </div>

          {/* Display Settings Card */}
          <div className="bg-white rounded-lg  p-6 border border-[#dbe0eb]">
            <div className="flex justify-between items-center">
              <h2 className="text-xl font-semibold">Display Settings</h2>
              <span className="bg-blue-100 text-blue-500 text-xs px-2 py-1 rounded-full">EARLY ACCESS</span>
              <button className="text-[#4740d4] bg-white border border-[#bfc6d4] flex rounded-xl py-2 px-3"><MdEdit className='me-1 mt-1'/> Edit</button>
            </div>
            <p className="text-sm mt-2 text-gray-600 text-start">Theme</p>
            <p className="text-sm text-black text-start">Light theme</p>
          </div>

          {/* Confirm Email Card */}
          <div className="bg-white rounded-lg  p-6 border border-[#dbe0eb]">
            <div className="flex justify-between items-center">
              <h2 className="text-xl font-semibold">Confirm your email address</h2>
              <button className="text-[#4740d4] bg-white border border-[#bfc6d4] flex rounded-xl py-2 px-3"><IoMdSend className='me-1 mt-1'/> Send</button>
            </div>
            <p className="text-sm mt-2 text-gray-600 text-start">
              Your email address is required to send you notifications and invites. Please check your spam folder if
              it's not showing in your inbox.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserSettingsTab;
