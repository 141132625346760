import React, { useState } from "react";
import Missed from "./Tabs/missed";
import Manage from "./Tabs/manage";
import MySchedules from "./Tabs/mySchedules";
import ScheduleInspection from "./inspection/scheduleInspection";

const Schedule = () => {
  const [activeTab, setActiveTab] = useState("My Schedules");
  const [showNewSchedule, setShowNewSchedule] = useState(false);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const handleNewScheduleClick = () => {
    setShowNewSchedule(true); // Show the NewSchedule component
  };

  return (
    <div className="font-sans w-11/12 mx-auto">
      <div className="max-w-7xl mx-auto p-4 md:p-8 lg:p-12">
        <div className="flex justify-between items-center mb-6">
          <h1 className="text-2xl font-semibold">Schedule</h1>
          <button
            onClick={handleNewScheduleClick}
            className="bg-indigo-600 text-white px-4 py-2 rounded-md text-sm md:text-base"
          >
            + Schedule Inspections
          </button>
        </div>

        {!showNewSchedule ? (
          // Main content with tabs
          <div>
            <div className="flex flex-col md:flex-row md:justify-between items-start md:items-center">
              <div className="w-full">
                <div className="border-b border-gray-400">
                  <ul
                    className="flex flex-col md:flex-row md:space-x-12 mb-2"
                    role="tablist"
                  >
                    <li
                      role="tab"
                      onClick={() => handleTabClick("My Schedules")}
                      className={`text-gray-600 font-medium cursor-pointer hover:bg-indigo-50 px-4 py-2 rounded-md ${
                        activeTab === "My Schedules"
                          ? "text-indigo-600 border-b-2 border-indigo-600"
                          : "border-b-2 border-transparent"
                      }`}
                    >
                      My Schedules
                    </li>
                    <li
                      role="tab"
                      onClick={() => handleTabClick("Manage Schedules")}
                      className={`text-gray-600 font-medium cursor-pointer hover:bg-indigo-50 px-4 py-2 rounded-md ${
                        activeTab === "Manage Schedules"
                          ? "text-indigo-600 border-b-2 border-indigo-600"
                          : "border-b-2 border-transparent"
                      }`}
                    >
                      Manage Schedules
                    </li>
                    <li
                      role="tab"
                      onClick={() => handleTabClick("Missed/Late Inspections")}
                      className={`text-gray-600 font-medium cursor-pointer hover:bg-indigo-50 px-4 py-2 rounded-md ${
                        activeTab === "Missed/Late Inspections"
                          ? "text-indigo-600 border-b-2 border-indigo-600"
                          : "border-b-2 border-transparent"
                      }`}
                    >
                      Missed/Late Inspections
                    </li>
                  </ul>
                </div>

                <div className="mt-4">
                  {activeTab === "My Schedules" && <MySchedules />}
                  {activeTab === "Manage Schedules" && <Manage />}
                  {activeTab === "Missed/Late Inspections" && <Missed />}
                </div>
              </div>
            </div>

            <div className="flex justify-end items-center mt-6 space-x-4">
              <button className="text-gray-500" aria-label="Previous page">
                &lt;
              </button>
              <span className="text-gray-600">1 / 1</span>
              <button className="text-gray-500" aria-label="Next page">
                &gt;
              </button>
            </div>
          </div>
        ) : (
          <ScheduleInspection />
        )}
      </div>
    </div>
  );
};

export default Schedule;
