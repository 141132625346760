import Training from "../../../assets/training/SafetyCulture-Training-hero-LP.webp";
import Hero from "../../../shared/hero";
const Traininghero = () => {
  return (
    <Hero
      heading="Onboard, train, and upskill your team in no time"
      para=" Train your teams on the same platform you use to conduct inspections, so they get the knowledge they need to be safe and compliant."
      btn1=" Sign up for free"
      btn2=" Book a demo"
      img={Training}
      imgWidth="56%"
    />
  );
};
export default Traininghero;
