import React from "react";
import DesktopreportImage from "../../../assets/loneWorker/SheqsyDesktopReports.webp";
import TextImageFlex from "../../../shared/textImageFlex";
const StayCompliant = () => {
  return (
    <div className="py-12 md:py-24">
      <TextImageFlex
        heading="Stay compliant with demanding health and safety regulations"
        para=" Feel confident you have a proactive safety solution in place complete with a digital audit trail."
        img={DesktopreportImage}
      
      />
    </div>
  );
};

export default StayCompliant;
