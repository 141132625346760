import ConstructionHero from "../../components/solutions/construction/hero";
import States from "../../components/solutions/construction/states";
import BoostProductivity from "../../components/solutions/construction/boostProductivity";
import PreventIncidents from "../../components/solutions/construction/preventIncidents";
import GainVisibilty from "../../components/solutions/construction/gainvisibility";
import DeliverEffective from "../../components/solutions/construction/deliverEffective";
import ImproveUptime from "../../components/solutions/construction/improveUptime";
import EasyKnowledge from "../../components/solutions/construction/easyknowledge";
import KeepTrack from "../../components/solutions/construction/keepTrack";
import ListDataSection from "../../components/solutions/construction/listData";
import Improvement from "../../components/solutions/construction/improvement";
import CustomizableCardsSection from "../../components/solutions/construction/customizableCards";
import IndustryCards from "../../components/solutions/construction/industryCards";
import ImprovementTwo from "../../components/solutions/construction/improvementTwo";
import Faqs from "../../components/solutions/construction/faqs";
const Construction = () => {
  return (
    <div>
      <ConstructionHero />
      <States />
      <BoostProductivity />
      <PreventIncidents/>
      <GainVisibilty/>
      <DeliverEffective/>
      <ImproveUptime/>
      <EasyKnowledge/>
      <KeepTrack/>
      <ListDataSection/>
      <Improvement/>
      <CustomizableCardsSection/>
      <IndustryCards/>
      <ImprovementTwo/>
      <Faqs/>
    </div>
  );
};
export default Construction;
