import React from "react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import {
  FaMobileAlt,
  FaChartLine,
  FaRocket,
  FaShieldAlt,
} from "react-icons/fa";
import { FiBarChart } from "react-icons/fi";
import { useNavigate } from "react-router-dom";

// Reusable Icon and Text Component for Cleanliness and Reusability
const IconTextItem = ({ icon, text }) => (
  <div className="flex items-center">
    <span className="text-xl mr-2">{icon}</span>
    <span>{text}</span>
  </div>
);

const ImprovementSection = () => {
  const isArabic = i18next.language === "ar";
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div
      className={` ${isArabic ? "text-right" : "text-left"} ${
        isArabic ? "rtl" : ""
      }`}
    >
      <div className="w-full bg-[#6559ff] py-12">
        <div className="container mx-auto px-6 sm:px-12 md:px-16 lg:px-24 text-center text-white">
          {/* Heading */}
          <h2 className="text-2xl sm:text-3xl lg:text-4xl font-bold mb-8">
            {t("improvementsection.startimprovement")}
          </h2>

          {/* Icon Row */}
          <div className="flex flex-col sm:flex-row justify-center items-center space-y-4 sm:space-y-0 sm:space-x-8 mb-10">
            <IconTextItem
              icon={<FaMobileAlt />}
              text={t("improvementsection.span1")}
            />
            <IconTextItem
              icon={<FaChartLine />}
              text={t("improvementsection.span2")}
            />
            <IconTextItem
              icon={<FiBarChart />}
              text={t("improvementsection.span3")}
            />
            <IconTextItem
              icon={<FaRocket />}
              text={t("improvementsection.span4")}
            />
            <IconTextItem
              icon={<FaShieldAlt />}
              text={t("improvementsection.span5")}
            />
          </div>

          {/* CTA Buttons */}
          <div className="flex flex-col sm:flex-row justify-center items-center space-y-4 sm:space-y-0 sm:space-x-6 mb-8">
            <button
              aria-label="Get started for free"
              className="bg-yellow-400 text-[#293745] font-semibold py-3 px-6 rounded-xl hover:bg-yellow-500 transition duration-300"
              onClick={() => {
                navigate("/signup");
              }}
            >
              {t("improvementsection.getfreebtn")}
            </button>
            <button
              aria-label="Book a demo"
              className="text-[#293745] font-semibold py-3 px-6 rounded-xl hover:bg-gray-200 transition duration-300"
              onClick={() => {
                navigate("/book-demo");
              }}
            >
              {t("improvementsection.bookdemobtn")}
            </button>
          </div>

          {/* App Store Buttons */}
          <div className="flex justify-center items-center space-x-4">
            {/* <img
              src="https://developer.apple.com/assets/elements/badges/download-on-the-app-store.svg"
              alt="Download on the App Store"
              className="w-40"
            />
            <img
              src="https://upload.wikimedia.org/wikipedia/commons/7/78/Google_Play_Store_badge_EN.svg"
              alt="Get it on Google Play"
              className="w-40"
            /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImprovementSection;
