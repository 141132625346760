import FacilitiesHero from "../../components/solutions/retail/hero";
import States from "../../components/solutions/retail/states";
import BoostProductivity from "../../components/solutions/retail/boostProductivity";
import PreventIncidents from "../../components/solutions/retail/preventIncidents";
import GainVisibilty from "../../components/solutions/retail/gainvisibility";
import DeliverEffective from "../../components/solutions/retail/deliverEffective";
import ImproveUptime from "../../components/solutions/retail/improveUptime";
import ListDataSection from "../../components/solutions/retail/listData";
import Improvement from "../../components/solutions/retail/improvement";
import CustomizableCardsSection from "../../components/solutions/retail/customizableCards";
import IndustryCards from "../../components/solutions/retail/industryCards";
import ImprovementTwo from "../../components/solutions/retail/improvementTwo";
import Faqs from "../../components/solutions/retail/faqs";
const Retail = () => {
  return (
    <div>
      <FacilitiesHero />
      <States />
      <BoostProductivity />
      <PreventIncidents/>
      <GainVisibilty/>
      <DeliverEffective/>
      <ImproveUptime/>
  
      <ListDataSection/>
      <Improvement/>
      <CustomizableCardsSection/>
      <IndustryCards/>
      <ImprovementTwo/>
      <Faqs/>
    </div>
  );
};
export default Retail;
