import React from "react";
import ExpertImage from "../../../assets/secureCare/SCCareExperts.webp";
import TextImageFlex from "../../../shared/textImageFlex";

const TrustedExperts = () => {
  return (
    <div className="py-12 md:py-24">
      {/* Text and Image Flex Component */}
      <TextImageFlex
        heading="Trusted experts in risk management and insurance"
        para="We help you take action to reduce risk, every day. You can trust our team of experts to help you with risk solutions, underwriting, and claims – that’s what we do."
        img={ExpertImage}
      />

      {/* Find Out More Button */}
      <div className="flex justify-start mt-6 md:mt-[-130px] px-4 sm:px-8 md:px-0">
        <a
          href="#"
          className="bg-[#6559FF] text-white text-base font-medium py-3 px-6 rounded-xl hover:bg-[#4740d4] transition-colors inline-flex items-center"
        >
          Find out more
          <span className="ml-2">
            <svg
              clipRule="evenodd"
              fill="none"
              fillRule="evenodd"
              height="16"
              viewBox="0 0 24 24"
              width="16"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.79293 16.2071C7.4024 15.8166 7.4024 15.1834 7.79292 14.7929L13.0856 9.50015H10C9.44772 9.50015 9 9.05244 9 8.50015C9 7.94787 9.44772 7.50015 10 7.50015L15.5 7.50015C16.0523 7.50015 16.5 7.94787 16.5 8.50015V14.0002C16.5 14.5524 16.0523 15.0002 15.5 15.0002C14.9477 15.0002 14.5 14.5524 14.5 14.0002L14.5 10.9142L9.20714 16.2071C8.81662 16.5976 8.18345 16.5976 7.79293 16.2071Z"
                fill="currentColor"
              ></path>
              <path
                d="M20 1C21.6569 1 23 2.34315 23 4L23 20C23 21.6569 21.6569 23 20 23H4C2.34315 23 1 21.6569 1 20L1 4C1 2.34315 2.34315 1 4 1L20 1ZM21 4C21 3.44772 20.5523 3 20 3L4 3C3.44772 3 3 3.44772 3 4L3 20C3 20.5523 3.44772 21 4 21L20 21C20.5523 21 21 20.5523 21 20L21 4Z"
                fill="currentColor"
              ></path>
            </svg>
          </span>
        </a>
      </div>
    </div>
  );
};

export default TrustedExperts;
