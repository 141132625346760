import React, { useState } from "react";
import { FiArrowLeft, FiSearch } from "react-icons/fi";
import TemplateModal from "./libraryModals";
const TemplateLibrary = () => {
  const [templateopen, setTemplateOpen] = useState(false);
  const industries = [
    { name: "Other", count: 42440 },
    { name: "Construction", count: 34305 },
    { name: "General", count: 20838 },
    { name: "Food & hospitality", count: 2838 },
    { name: "Manufacturing", count: 28382 },
    { name: "Transport & logistics", count: 28382 },
    { name: "Professional services", count: 28382 },
    { name: "Cleaning", count: 28382 },
    { name: "Agriculture", count: 28382 },
    { name: "Education ", count: 28382 },
    { name: "Health services ", count: 28382 },
    { name: "Local government", count: 28382 },
    { name: "Mining", count: 28382 },
    { name: "ICT", count: 28382 },
    { name: "Community services", count: 28382 },
    { name: "Emergency services", count: 28382 },
    { name: "Maritime", count: 28382 },
    { name: "Horticulture", count: 28382 },
    { name: "Financial", count: 28382 },
    { name: "Financial services", count: 28382 },
  ];

  const templates = [
    {
      title: "Skanska PAS Audit",
      category: "Construction",
      author: "Jason Blinn",
      downloads: 108,
    },
    {
      title: "Wellington/Chatham Islands Rural Fire...",
      category: "Emergency Services",
      author: "Anonymous",
      downloads: 10,
    },
    {
      title: "Plantilla de notas SOAP",
      category: "Professional Services",
      author: "blanca rosas",
      downloads: 1,
    },
    {
      title: "Wellington/Chatham Islands Rural Fire...",
      category: "Emergency Services",
      author: "Anonymous",
      downloads: 10,
    },
    {
      title: "Plantilla de notas SOAP",
      category: "Professional Services",
      author: "blanca rosas",
      downloads: 1,
    },
    {
      title: "Plantilla de notas SOAP",
      category: "Professional Services",
      author: "blanca rosas",
      downloads: 1,
    },
  ];

  return (
    <div className="h-screen overflow-y-auto">
      {templateopen && <TemplateModal setTemplateOpen={setTemplateOpen}/>}
      <div className="flex items-center   bg-white fixed w-full p-4">
        <FiArrowLeft className=" mr-2" size={20} />
        <span className="font-semibold">Template Library</span>
      </div>
      <div className="flex flex-col lg:flex-row  p-4 bg-gray-50 mt-[60px]">
        {/* Sidebar */}
        <aside className="w-full lg:w-1/4 p-4 bg-white  rounded-lg mb-6 lg:mb-0 lg:mr-6">
          <h3 className="text-md text-start font-[600] mb-4">
            Filter by industry
          </h3>
          <ul>
            {industries.map((industry, index) => (
              <li
                key={index}
                className="flex text-start justify-between py-2 border-b border-gray-200"
              >
                <span>{industry.name}</span>
                <span className="text-gray-500">{industry.count}</span>
              </li>
            ))}
          </ul>
        </aside>

        {/* Main Content */}
        <main className="flex-1">
          {/* Header */}

          {/* Centered Search Section */}
          <div className="bg-gradient-to-r from-blue-100 to-green-100 p-6 rounded-lg shadow-md mb-6 text-center">
            <h1 className="text-2xl font-bold">
              Discover 100,000+ free and editable templates
            </h1>

            <div className="relative max-w-md mx-auto  my-3">
              <input
                type="text"
                placeholder="Search"
                className="w-full p-3 pl-10 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
              <FiSearch
                className="absolute top-1/2 left-3 transform -translate-y-1/2 text-gray-400"
                size={20}
              />
            </div>
            <p className="text-gray-900 text-sm mb-4">
              Compatible on all devices · Generate instant reports · Track
              activity
            </p>
          </div>

          {/* Templates List */}
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-lg font-semibold">All templates</h2>
            <select className="p-2 border rounded-lg">
              <option>Relevance</option>
              <option>A-Z</option>
              <option>Downloads</option>
            </select>
          </div>
          <div className="bg-white p-4 rounded-lg ">
            <ul>
              {templates.map((template, index) => (
                <li
                  key={index}
                  onClick={()=>setTemplateOpen(true)}
                  className="flex justify-between items-center py-4 border-b border-gray-200 hover:bg-gray-50"
                >
                  <div>
                    <h3 className="text-md font-[500]">{template.title}</h3>
                    <h3 className="text-sm font-[500] text-gray-500 text-start">
                      Field audit
                    </h3>
                    <p className="text-gray-600 bg-gray-100 w-fit rounded-full p-1.5 mt-1 text-sm">
                      {template.category}
                    </p>
                  </div>
                  <div className="text-right text-gray-500 text-sm">
                    <p>By {template.author}</p>
                    <p>{template.downloads} downloads</p>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </main>
      </div>
    </div>
  );
};

export default TemplateLibrary;
