import React from "react";
import { FaRegSquareCheck } from "react-icons/fa6";

const cardData = [
  {
    id: 1,
    text: "Instantly download affordable and compliant documents.",
  },
  {
    id: 2,
    text: "Simple solutions tailored to your industry, easily branded and edited to make sense for your business.",
  },
  {
    id: 3,
    text: "Save time and focus on what you do best. Know you’re protected with documents ready for use.",
  },
];

const ServicesSection = () => {
  return (
    <section className="bg-[#F0F0FF] py-16 md:py-24">
      <div className="container mx-auto text-center px-4">
        {/* Title */}
        <h2 className="text-3xl md:text-4xl lg:text-5xl font-bold text-[#293745] mb-8 px-2 md:px-10">
          Health and safety documents, designed for your industry
        </h2>

        {/* Cards */}
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8 px-3 md:px-14 py-4">
          {cardData.map((card) => (
            <div
              key={card.id}
              className="p-6 rounded-xl border border-[#D0D7E1] flex flex-col items-start transition-transform transform hover:scale-105"
            >
              <FaRegSquareCheck className="text-[#6658FF] text-4xl mb-4" />

              {/* Text */}
              <p className="text-gray-700 text-sm md:text-base pt-2">
                {card.text}
              </p>
            </div>
          ))}
        </div>

        {/* Button */}
        <div className="mt-10">
          <a
            href="#"
            className="bg-[#6559FF] text-white text-[1rem] font-medium py-3 px-6 rounded-xl hover:bg-[#4740d4] transition-colors inline-flex items-center"
          >
            Visit SafetyDocs
            <span className="ml-2">
              <svg
                clipRule="evenodd"
                fill="none"
                fillRule="evenodd"
                height="16"
                viewBox="0 0 24 24"
                width="16"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.79293 16.2071C7.4024 15.8166 7.4024 15.1834 7.79292 14.7929L13.0856 9.50015H10C9.44772 9.50015 9 9.05244 9 8.50015C9 7.94787 9.44772 7.50015 10 7.50015L15.5 7.50015C16.0523 7.50015 16.5 7.94787 16.5 8.50015V14.0002C16.5 14.5524 16.0523 15.0002 15.5 15.0002C14.9477 15.0002 14.5 14.5524 14.5 14.0002L14.5 10.9142L9.20714 16.2071C8.81662 16.5976 8.18345 16.5976 7.79293 16.2071Z"
                  fill="currentColor"
                ></path>
                <path
                  d="M20 1C21.6569 1 23 2.34315 23 4L23 20C23 21.6569 21.6569 23 20 23H4C2.34315 23 1 21.6569 1 20L1 4C1 2.34315 2.34315 1 4 1L20 1ZM21 4C21 3.44772 20.5523 3 20 3L4 3C3.44772 3 3 3.44772 3 4L3 20C3 20.5523 3.44772 21 4 21L20 21C20.5523 21 21 20.5523 21 20L21 4Z"
                  fill="currentColor"
                ></path>
              </svg>
            </span>
          </a>
        </div>
      </div>
    </section>
  );
};

export default ServicesSection;
