import FAQSection from "../../../shared/faqs";
import { useTranslation } from "react-i18next";

const Faqs = () => {
  // Call the useTranslation hook
  const { t } = useTranslation();

  // Define the FAQ data using `t()` for translations
  const faqData = [
    {
      question: t("hospitalityfaqs.hospitalityfaqsquestion0"),
      answer: t("hospitalityfaqs.hospitalityfaqsanswer0"),
    },
    {
      question: t("hospitalityfaqs.hospitalityfaqsquestion1"),
      answer: t("hospitalityfaqs.hospitalityfaqsanswer1"),
    },
    {
      question: t("hospitalityfaqs.hospitalityfaqsquestion2"),
      answer:
      t("hospitalityfaqs.hospitalityfaqsanswer2"),
    },
    {
      question: t("hospitalityfaqs.hospitalityfaqsquestion3"),
      answer:
      t("hospitalityfaqs.hospitalityfaqsanswer3"),
    },
    {
      question: t("hospitalityfaqs.hospitalityfaqsquestion4"),
      answer:
      t("hospitalityfaqs.hospitalityfaqsanswer4"),
    },
  ];

  // Return the FAQ section with translated data
  return <FAQSection faqData={faqData} heading={t("hospitalityfaqs.hospitalityfaqsheading")} />;
};

export default Faqs;
