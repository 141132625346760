import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { useNavigate } from "react-router-dom";
const Pricing = () => {
  const { t } = useTranslation();
  const isArabic = i18next.language === "ar";
  const navigate = useNavigate();

  const scrollToPlanFeatures = () => {
    const element = document.getElementById("planFeatures");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div className="flex flex-col items-center px-4 py-8">
      {/* Header Section */}
      <h1 className="text-3xl md:text-4xl font-extrabold mt-6">
        {t("pricingpage.pricingheading")}
      </h1>
      <div className={` py-24 ${isArabic ? "text-right rtl" : "text-left"}`}>
        <div className="flex justify-center items-center mb-10">
          <div className="flex items-center">
            <span className="mr-4 text-sm">{t("pricingpage.billedm")}</span>
            <button className="bg-gray-200 rounded-full p-1">
              <span className="block bg-violet-700 w-8 h-4 rounded-full relative">
                <span className="block w-4 h-4 rounded-full absolute left-0 top-0 transform translate-x-1 transition-transform"></span>
              </span>
            </button>
            <span className="ml-4 text-sm text-green-600">
              {t("pricingpage.billedann")}
            </span>
          </div>
        </div>

        {/* Pricing Cards Section */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 max-w-6xl mt-8">
          {/* Free Plan */}
          <div className="border p-6 rounded-lg shadow-md">
            <h2 className="text-2xl font-semibold mb-2">
              {t("pricingpage.freeheading")}
            </h2>
            <p className="mb-4 text-black text-base">
              {t("pricingpage.teamlook")}
            </p>
            <div className="flex items-center ">
              <div className="text-4xl font-bold mb-2">
                <span>{t("pricingpage.currency")}</span>
              </div>
              <div className="ms-1 text-4xl font-bold mb-2">
                <span>0</span>
              </div>
              <div className="text-left text-xs ml-2 text-slate-600">
                {/* <div>/ User</div> */}
                <div>{t("pricingpage.member")}</div>
              </div>
            </div>
            <div className="border border-slate-300 text-purple-800 rounded-xl px-4 py-2 mb-6 w-full md:w-80 h-12 hover:bg-violet-700 hover:text-white transition-colors duration-300 flex items-center justify-center">
              <button className="w-full h-full text-sm md:text-base font-medium bg-transparent">
                {t("pricingpage.button1")}
              </button>
            </div>

            <ul className="list-inside list-disc text-gray-600 text-base">
              <li>{t("pricingpage.li1")}</li>
              <li>{t("pricingpage.li2")}</li>
              <li>{t("pricingpage.li3")}</li>
              <li>{t("pricingpage.li4")}</li>
              <li>{t("pricingpage.li5")}</li>
              <li>{t("pricingpage.li6")}</li>
            </ul>
          </div>

          {/* Premium Plan */}
          <div className="border-2 border-purple-600 p-6 rounded-lg shadow-md">
            <div className="bg-violet-700 -mt-12 text-center w-90 rounded-t-lg">
              <span className="text-white">
                <li>{t("pricingpage.popularheading")}</li>
              </span>
            </div>
            <h2 className="text-2xl font-semibold text-purple-800 mb-2 mt-5">
              {t("pricingpage.premium")}
            </h2>
            <p className="mb-4 text-black text-base text-purple-800">
              {t("pricingpage.para")}
            </p>
            <div className="flex items-center ">
              <div className="text-4xl font-bold mb-2">
                <span>{t("pricingpage.currency1")}</span>
              </div>
              <div className="ms-1 text-4xl font-bold mb-2">
                <span>{t("pricingpage.amount")}</span>
              </div>
              <div className="text-left text-xs ml-2 text-slate-600">
                <div>{t("pricingpage.month")}</div>
                <div>{t("pricingpage.seat")}</div>
                <div>{t("pricingpage.billedannual")}</div>
              </div>
            </div>
            <div className=" border bg-violet-700 border-slate-300 text-white rounded-xl px-4 py-2 mb-6 w-full md:w-80 h-12">
              <button className="w-full h-full text-sm md:text-base font-medium">
                {t("pricingpage.button1")}
              </button>
            </div>
            <ul className="list-inside list-disc text-gray-600 text-base">
              <li> {t("pricingpage.li7")}</li>
              <li>{t("pricingpage.li8")}</li>
              <li>{t("pricingpage.li9")}</li>
              <li>{t("pricingpage.li10")}</li>
              <li>{t("pricingpage.li11")}</li>
              <li>{t("pricingpage.li12")}</li>
              {/* <li>And more...</li> */}
            </ul>
          </div>

          {/* Enterprise Plan */}
          <div className="border p-6 rounded-lg shadow-md">
            <h2 className="text-2xl font-semibold mb-2">
              {t("pricingpage.enterpriseheading")}
            </h2>
            <p className="mb-4 text-black text-base">
              {t("pricingpage.enterprisepara")}
            </p>
            <div className="text-2xl font-semibold text-black mb-2 mt-8">
              {t("pricingpage.contactheading")}
            </div>
            <div className="border border-slate-300 text-purple-800 rounded-xl px-4 py-2 mb-6 w-full md:w-80 h-12 hover:bg-violet-700 hover:text-white transition-colors duration-300 flex items-center justify-center">
              <button
                className="w-full h-full text-sm md:text-base font-medium bg-transparent"
                onClick={() => navigate("/pricing/enterprise")}
              >
                {t("pricingpage.button1")}
              </button>
            </div>
          </div>
        </div>

        {/* Footer Section */}
        <div className="flex justify-end">
          <div className=" shadow-lg rounded-lg p-6 w-full mt-14">
            {/* Header Section */}
            <h3 className="text-3xl font-bold mb-2">
              {t("pricingpage.growheading")}
            </h3>
            <p className="text-gray-700 text-base mb-4">
              {t("pricingpage.growpara")}
            </p>

            <div className="flex flex-col lg:flex-row items-start mt-6 space-y-6 lg:space-y-0 lg:space-x-8">
              {/* Pricing Section */}
              <div className="flex items-center space-x-2">
                <div className="text-base mb-2">
                  <span>{t("pricingpage.growline2")}</span>
                </div>
                <div className="text-4xl font-bold mb-2">
                  <span>{t("pricingpage.growline3")}</span>
                </div>
                <div className="text-4xl font-bold mb-2">
                  <span>{t("pricingpage.159")}</span>
                </div>
                <div className="text-left text-xs ml-2 text-slate-600">
                  <div>{t("pricingpage.bill")}</div>
                  <div>{t("pricingpage.annualbilled")}</div>
                </div>
              </div>

              {/* Collaborate with Lite Seats Section */}
              <div className="flex flex-col">
                <h2 className="text-xl font-semibold mb-4 text-center lg:text-left">
                  {t("pricingpage.collaborate")}
                </h2>
                <ul className="list-disc list-inside text-gray-700 space-y-2">
                  <li> {t("pricingpage.li18")}</li>
                  <li>{t("pricingpage.li19")}</li>
                  <li>{t("pricingpage.li20")}</li>
                  <li>{t("pricingpage.li21")}</li>
                  <li>{t("pricingpage.li22")}</li>
                  <li>{t("pricingpage.li23")}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="text-center my-4 mt-10 text-base">
          <a
            onClick={scrollToPlanFeatures} // Attach the scroll handler
            className="text-blue-600 hover:underline flex items-center justify-center cursor-pointer"
          >
            {t("pricingpage.compareallplan")}
            <span className="ml-1 text-blue-600">↓</span> {/* Arrow symbol */}
          </a>
        </div>
        <div className="bg-slate-100 h-1/2 w-full">
          {" "}
          {/* Set minimum height to cover full viewport */}
          <div className="py-10 text-center mt-6">
            {/* Heading */}
            <h2 className="text-4xl font-extrabold mb-8 text-center mt-10">
              {t("pricingpage.calculatedheading")}
            </h2>

            {/* Steps */}
            <div className="flex flex-col md:flex-row justify-center items-center space-y-6 md:space-y-0 md:space-x-10 mt-10">
              {/* Step 1: Choose your plan */}
              <div className="flex flex-col items-center">
                <div className="mb-4">
                  {/* Icon (Replace with actual icon) */}
                  <span className="text-3xl">🖱️</span>
                </div>
                <h3 className="text-base font-semibold text-indigo-600">
                  {t("pricingpage.chooseplan")}
                </h3>
                <p className="text-gray-600 mt-2 text-base">
                  {t("pricingpage.paragraph")}
                </p>
                <a href="#" className="text-indigo-600 mt-2 underline">
                  {t("pricingpage.ancartag")}
                </a>
              </div>

              {/* Plus sign */}
              <span className="text-5xl hidden md:inline font-extrabold">
                +
              </span>

              {/* Step 2: Select your seats */}
              <div className="flex flex-col items-center">
                <div className="mb-4">
                  {/* Icon (Replace with actual icon) */}
                  <span className="text-3xl">👥</span>
                </div>
                <h3 className="text-lg font-semibold text-indigo-600">
                  {t("pricingpage.h3")}
                </h3>
                <p className="text-gray-600 mt-2 text-base">
                  {t("pricingpage.p")}
                </p>
                <a href="#" className="text-indigo-600 mt-2 underline">
                  {t("pricingpage.ancar1")}
                </a>
              </div>

              {/* Equal sign */}
              <span className="text-4xl  hidden md:inline font-extrabold">
                =
              </span>

              {/* Step 3: Your price */}
              <div className="flex flex-col items-center">
                <div className="mb-4">
                  {/* Icon (Replace with actual icon) */}
                  <span className="text-3xl">✔️</span>
                </div>
                <h3 className="text-lg font-semibold text-indigo-600">
                  {t("pricingpage.h4")}
                </h3>
                <p className="text-gray-600 mt-2 text-base">
                  {t("pricingpage.h5")}
                </p>
              </div>
            </div>

            {/* Call to Action Button */}
            <div>
              {" "}
              {/* Added flex, items-center, and justify-center */}
              <button className="mt-10 w-full md:w-72 py-2 border border-slate-300 text-purple-800 rounded-xl hover:bg-purple-800 hover:text-white transition-colors duration-300 mb-6 md:w-80 h-12 font-medium rounded-lg">
                {t("pricingpage.buttonfree")}
              </button>
            </div>
          </div>
        </div>
        <div
          id="planFeatures"
          className="w-10/12 mx-auto py-12 px-0 sm:px-6 lg:px-8"
        >
          {/* Heading */}
          <h2 className="text-5xl font-extrabold text-center mb-8 mt-6">
            {t("pricingpage.planheading")}
          </h2>

          {/* Plan buttons */}
          <div className="flex h-26">
            <div className="flex flex-col md:flex-row border rounded-xl overflow-hidden w-full max-w-lg md:max-w-3xl mt-10">
              {/* Free Plan */}
              <div className=" p-4 w-full text-center">
                <h3 className="font-bold">{t("pricingpage.free")}</h3>
                <button
                  className="mt-4 w-full md:w-72 py-2 border border-black text-indigo-600  font-medium rounded-lg hover:bg-violet-600 hover:text-white transition-colors duration-300"
                  onClick={() => {
                    navigate("/signup");
                  }}
                >
                  {t("pricingpage.startedbtn")}
                </button>
              </div>

              {/* Premium Plan */}
              <div className="bg-indigo-600 text-white p-4 w-full text-center">
                <h3 className="font-bold">{t("pricingpage.premium1")}</h3>
                <button
                  className="mt-4 w-full md:w-72 py-2 text-white border border-white text-indigo-600  font-medium rounded-lg hover:bg-violet-600 hover:text-white transition-colors duration-300"
                  onClick={() => {
                    navigate("/signup");
                  }}
                >
                  {t("pricingpage.starttrail")}
                </button>
              </div>
            </div>
          </div>

          {/* Features Table */}
          <div className="overflow-x-auto">
            <table className="w-full border-collapse border border-gray-300">
              <thead>
                <tr>
                  <th
                    className="border border-gray-300 px-4 py-4 bg-gray-100 font-bold"
                    colSpan="3"
                  >
                    {t("pricingpage.th")}
                  </th>
                </tr>
              </thead>
              <tbody>
                {/* Table Rows */}
                <tr>
                  <td
                    className="border border-gray-300 px-4 py-4"
                    style={{ width: "40%" }}
                  >
                    {t("pricingpage.td1")}
                  </td>
                  <td
                    className="border border-gray-300 px-4 py-4 text-center"
                    style={{ width: "30%" }}
                  >
                    {t("pricingpage.td2")}
                  </td>
                  <td
                    className="border border-gray-300 px-4 py-4 text-center"
                    style={{ width: "30%" }}
                  >
                    {t("pricingpage.td3")}
                  </td>
                </tr>
                <tr>
                  <td className="border border-gray-300 px-4 py-4">
                    {t("pricingpage.td4")}
                  </td>
                  <td className="border border-gray-300 px-4 py-4 text-center">
                    ✔️
                  </td>
                  <td className="border border-gray-300 px-4 py-4 text-center">
                    <span className="text-green-500">✔️</span>
                  </td>
                </tr>
                <tr>
                  <td className="border border-gray-300 px-4 py-4">
                    {t("pricingpage.td5")}
                  </td>
                  <td className="border border-gray-300 px-4 py-4 text-center">
                    -
                  </td>
                  <td className="border border-gray-300 px-4 py-4 text-center">
                    <span className="text-green-500">✔️</span>
                  </td>
                </tr>
                <tr>
                  <td className="border border-gray-300 px-4 py-4">
                    {t("pricingpage.td6")}
                  </td>
                  <td className="border border-gray-300 px-4 py-4 text-center">
                    -
                  </td>
                  <td className="border border-gray-300 px-4 py-4 text-center">
                    <span className="text-green-500">✔️</span>
                  </td>
                </tr>
                <tr>
                  <td className="border border-gray-300 px-4 py-4">
                    {t("pricingpage.td7")}
                  </td>
                  <td className="border border-gray-300 px-4 py-4 text-center">
                    -
                  </td>
                  <td className="border border-gray-300 px-4 py-4 text-center">
                    <span className="text-green-500">✔️</span>
                  </td>
                </tr>
                <tr>
                  <td className="border border-gray-300 px-4 py-4">
                    {t("pricingpage.td8")}
                  </td>
                  <td className="border border-gray-300 px-4 py-4 text-center">
                    -
                  </td>
                  <td className="border border-gray-300 px-4 py-4 text-center">
                    <span className="text-green-500">✔️</span>
                  </td>
                </tr>
              </tbody>
              <thead>
                <tr>
                  <th
                    className="border border-gray-300 px-4 py-4 bg-gray-100 font-bold"
                    colSpan="3"
                  >
                    {t("pricingpage.th2")}
                  </th>
                </tr>
              </thead>
              <tbody>
                {/* Table Rows */}
                <tr>
                  <td
                    className="border border-gray-300 px-4 py-4"
                    style={{ width: "40%" }}
                  >
                    {t("pricingpage.td9")}
                  </td>
                  <td
                    className="border border-gray-300 px-4 py-4 text-center"
                    style={{ width: "30%" }}
                  >
                    ✔️
                  </td>
                  <td
                    className="border border-gray-300 px-4 py-4 text-center"
                    style={{ width: "30%" }}
                  >
                    ✔️
                  </td>
                </tr>
                <tr>
                  <td className="border border-gray-300 px-4 py-4">
                    {t("pricingpage.td10")}
                  </td>
                  <td className="border border-gray-300 px-4 py-4 text-center">
                    ✔️
                  </td>
                  <td className="border border-gray-300 px-4 py-4 text-center">
                    <span className="text-green-500">✔️</span>
                  </td>
                </tr>
                <tr>
                  <td className="border border-gray-300 px-4 py-4">
                    {t("pricingpage.td11")}
                  </td>
                  <td className="border border-gray-300 px-4 py-4 text-center">
                    ✔️
                  </td>
                  <td className="border border-gray-300 px-4 py-4 text-center">
                    <span className="text-green-500">✔️</span>
                  </td>
                </tr>
                <tr>
                  <td className="border border-gray-300 px-4 py-4">
                    {t("pricingpage.td12")}
                  </td>
                  <td className="border border-gray-300 px-4 py-4 text-center">
                    {t("pricingpage.td13")}
                  </td>
                  <td className="border border-gray-300 px-4 py-4 text-center">
                    <span className="border-gray-300">
                      {t("pricingpage.td14")}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td className="border border-gray-300 px-4 py-4">
                    {t("pricingpage.td15")}
                  </td>
                  <td className="border border-gray-300 px-4 py-4 text-center">
                    {t("pricingpage.td16")}
                  </td>
                  <td className="border border-gray-300 px-4 py-4 text-center">
                    <span className="border-gray-300">
                      {t("pricingpage.td17")}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td className="border border-gray-300 px-4 py-4">
                    {t("pricingpage.td18")}
                  </td>
                  <td className="border border-gray-300 px-4 py-4 text-center"></td>
                  <td className="border border-gray-300 px-4 py-4 text-center">
                    <span className="text-green-500">✔️</span>
                  </td>
                </tr>
              </tbody>
              <thead>
                <tr>
                  <th
                    className="border border-gray-300 px-4 py-4 bg-gray-100 font-bold"
                    colSpan="3"
                  >
                    {t("pricingpage.th3")}
                  </th>
                </tr>
              </thead>
              <tbody>
                {/* Table Rows */}
                <tr>
                  <td
                    className="border border-gray-300 px-4 py-4"
                    style={{ width: "40%" }}
                  >
                    {t("pricingpage.th3")}
                  </td>
                  <td
                    className="border border-gray-300 px-4 py-4 text-center"
                    style={{ width: "30%" }}
                  >
                    ✔️
                  </td>
                  <td
                    className="border border-gray-300 px-4 py-4 text-center"
                    style={{ width: "30%" }}
                  >
                    ✔️
                  </td>
                </tr>
                <tr>
                  <td className="border border-gray-300 px-4 py-4">
                    {t("pricingpage.td20")}
                  </td>
                  <td className="border border-gray-300 px-4 py-4 text-center">
                    ✔️
                  </td>
                  <td className="border border-gray-300 px-4 py-4 text-center">
                    <span className="text-green-500">✔️</span>
                  </td>
                </tr>
                <tr>
                  <td className="border border-gray-300 px-4 py-4">
                    {t("pricingpage.td22")}
                  </td>
                  <td className="border border-gray-300 px-4 py-4 text-center">
                    ✔️
                  </td>
                  <td className="border border-gray-300 px-4 py-4 text-center">
                    <span className="text-green-500">✔️</span>
                  </td>
                </tr>
                <tr>
                  <td className="border border-gray-300 px-4 py-4">
                    {t("pricingpage.td23")}
                  </td>
                  <td className="border border-gray-300 px-4 py-4 text-center">
                    -
                  </td>
                  <td className="border border-gray-300 px-4 py-4 text-center">
                    <span className="border-gray-300">✔️</span>
                  </td>
                </tr>
              </tbody>
              {/* <thead>
                <tr>
                  <th
                    className="border border-gray-300 px-4 py-4 bg-gray-100 text-left font-bold"
                    colSpan="3"
                  >
                    Easily train and communicate with your team
                  </th>
                </tr>
              </thead> */}
              {/* <tbody>
                <tr>
                  <td
                    className="border border-gray-300 px-4 py-4"
                    style={{ width: "40%" }}
                  >
                    Access over 100K free, industry-backed, editable courses
                  </td>
                  <td
                    className="border border-gray-300 px-4 py-4 text-center"
                    style={{ width: "30%" }}
                  >
                    ✔️
                  </td>
                  <td
                    className="border border-gray-300 px-4 py-4 text-center"
                    style={{ width: "30%" }}
                  >
                    ✔️
                  </td>
                </tr>
                <tr>
                  <td className="border border-gray-300 px-4 py-4">
                    Create your own interactive lessons with AI-powered creator
                    tool
                  </td>
                  <td className="border border-gray-300 px-4 py-4 text-center">
                    ✔️
                  </td>
                  <td className="border border-gray-300 px-4 py-4 text-center">
                    <span className="text-green-500">✔️</span>
                  </td>
                </tr>
                <tr>
                  <td className="border border-gray-300 px-4 py-4">
                    Import existing SCORM and PPT/Word files
                  </td>
                  <td className="border border-gray-300 px-4 py-4 text-center">
                    ✔️
                  </td>
                  <td className="border border-gray-300 px-4 py-4 text-center">
                    <span className="text-green-500">✔️</span>
                  </td>
                </tr>

                <tr>
                  <td className="border border-gray-300 px-4 py-4">
                    Track in-person training attendance and competency
                    assessments
                  </td>
                  <td className="border border-gray-300 px-4 py-4 text-center">
                    ✔️
                  </td>
                  <td className="border border-gray-300 px-4 py-4 text-center">
                    <span className="border-gray-300">✔️</span>
                  </td>
                </tr>
                <tr>
                  <td className="border border-gray-300 px-4 py-4">
                    Track in-person training attendance and competency
                    assessments
                  </td>
                  <td className="border border-gray-300 px-4 py-4 text-center">
                    ✔️
                  </td>
                  <td className="border border-gray-300 px-4 py-4 text-center">
                    <span className="border-gray-300">✔️</span>
                  </td>
                </tr>
                <tr>
                  <td className="border border-gray-300 px-4 py-4">
                    Track in-person training attendance and competency
                    assessments
                  </td>
                  <td className="border border-gray-300 px-4 py-4 text-center">
                    ✔️
                  </td>
                  <td className="border border-gray-300 px-4 py-4 text-center">
                    <span className="border-gray-300">✔️</span>
                  </td>
                </tr>
                <tr>
                  <td className="border border-gray-300 px-4 py-4">
                    Track in-person training attendance and competency
                    assessments
                  </td>
                  <td className="border border-gray-300 px-4 py-4 text-center">
                    ✔️
                  </td>
                  <td className="border border-gray-300 px-4 py-4 text-center">
                    <span className="border-gray-300">✔️</span>
                  </td>
                </tr>
              </tbody> */}
              <thead>
                <tr>
                  <th
                    className="border border-gray-300 px-4 py-4 bg-gray-100 font-bold"
                    colSpan="3"
                  >
                    {t("pricingpage.th4")}
                  </th>
                </tr>
              </thead>
              <tbody>
                {/* Table Rows */}
                <tr>
                  <td
                    className="border border-gray-300 px-4 py-4"
                    style={{ width: "40%" }}
                  >
                    {t("pricingpage.td24")}
                  </td>
                  <td
                    className="border border-gray-300 px-4 py-4 text-center"
                    style={{ width: "30%" }}
                  >
                    {t("pricingpage.td33")}
                  </td>
                  <td
                    className="border border-gray-300 px-4 py-4 text-center"
                    style={{ width: "30%" }}
                  >
                    {t("pricingpage.td34")}
                  </td>
                </tr>
                <tr>
                  <td className="border border-gray-300 px-4 py-4">
                    {t("pricingpage.td25")}
                  </td>
                  <td className="border border-gray-300 px-4 py-4 text-center">
                    1 year
                  </td>
                  <td className="border border-gray-300 px-4 py-4 text-center">
                    <span className="border-gray-300">3 year</span>
                  </td>
                </tr>
                <tr>
                  <td className="border border-gray-300 px-4 py-4">
                    {t("pricingpage.td26")}
                  </td>
                  <td className="border border-gray-300 px-4 py-4 text-center">
                    <span className="border-gray-300">✔️</span>
                  </td>
                  <td className="border border-gray-300 px-4 py-4 text-center">
                    <span className="border-gray-300">✔️</span>
                  </td>
                </tr>
                <tr>
                  <td className="border border-gray-300 px-4 py-4">
                    {t("pricingpage.td27")}
                  </td>
                  <td className="border border-gray-300 px-4 py-4 text-center">
                    <span className="border-gray-300">✔️</span>
                  </td>
                  <td className="border border-gray-300 px-4 py-4 text-center">
                    <span className="border-gray-300">✔️</span>
                  </td>
                </tr>
                <tr>
                  <td className="border border-gray-300 px-4 py-4">
                    {t("pricingpage.td28")}
                  </td>
                  <td className="border border-gray-300 px-4 py-4 text-center">
                    <span className="border-gray-300">✔️</span>
                  </td>
                  <td className="border border-gray-300 px-4 py-4 text-center">
                    <span className="border-gray-300">✔️</span>
                  </td>
                </tr>
                <tr>
                  <td className="border border-gray-300 px-4 py-4">
                    {t("pricingpage.td29")}
                  </td>
                  <td className="border border-gray-300 px-4 py-4 text-center"></td>
                  <td className="border border-gray-300 px-4 py-4 text-center">
                    <span className="border-gray-300">
                      {t("pricingpage.td32")}
                    </span>
                  </td>
                </tr>
              </tbody>
              <thead>
                <tr>
                  <th
                    className="border border-gray-300 px-4 py-4 bg-gray-100 font-bold"
                    colSpan="3"
                  >
                    {t("pricingpage.td38")}
                    <br />
                    <p className="text-xs text-black">
                      {t("pricingpage.td39")}
                    </p>
                  </th>
                </tr>
                {/* <tr>
                  <th
                    className="border border-gray-300 px-4 py-4 bg-gray-100 text-left font-bold"
                    colSpan="3"
                  >
                    Seat types
                    <br />
                    <p className="text-xs text-black">
                      User access to platform capabilities is determined by seat
                      type
                    </p>
                  </th>
                </tr> */}
                <tr>
                  <th
                    className="border border-gray-300 px-4 py-4 bg-gray-100  font-bold"
                    colSpan=""
                  >
                    {t("pricingpage.td36")}
                    <br />
                    <p className="text-xs text-black">
                      {t("pricingpage.td37")}
                    </p>
                  </th>
                  <td className="border border-gray-300 px-4 py-4 text-center">
                    <span className="border-gray-300">-</span>
                  </td>
                  <td className="border border-gray-300 px-4 py-4 text-center">
                    <span className="border-gray-300">
                      {t("pricingpage.td35")}
                    </span>
                  </td>
                </tr>
              </thead>
              {/* <tbody>
                <tr>
                  <td
                    className="border border-gray-300 px-4 py-4"
                    style={{ width: "40%" }}
                  >
                    Data storage
                  </td>
                  <td
                    className="border border-gray-300 px-4 py-4 text-center"
                    style={{ width: "30%" }}
                  >
                    5 GB
                  </td>
                  <td
                    className="border border-gray-300 px-4 py-4 text-center"
                    style={{ width: "30%" }}
                  >
                    500 GB
                  </td>
                </tr>
                <tr>
                  <td className="border border-gray-300 px-4 py-4">
                    Data & evidence history
                  </td>
                  <td className="border border-gray-300 px-4 py-4 text-center">
                    3 years
                  </td>
                  <td className="border border-gray-300 px-4 py-4 text-center">
                    <span className="border-gray-300">Unlimited</span>
                  </td>
                </tr>
                <tr>
                  <td className="border border-gray-300 px-4 py-4">
                    Data storage
                  </td>
                  <td className="border border-gray-300 px-4 py-4 text-center">
                    5 GB
                  </td>
                  <td className="border border-gray-300 px-4 py-4 text-center">
                    <span className="border-gray-300">500 GB</span>
                  </td>
                </tr>
              </tbody> */}
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Pricing;
