import CustomizableCards from "../../../shared/customizableCards";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
const CustomizableCardsSection = () => {
  const { t } = useTranslation();
  const isArabic = i18next.language === "ar";
  const CardsData = [
    {
      badge: (
        <span className="border-t border-b border-r border-[3px] border-l-[.1875rem] border-[#6559ff] text-sm text-[#293745]  px-3 py-1 rounded-full">
          {t("manufacturingcustom.manufacturingcustombadge")}
        </span>
      ),
      title: t("manufacturingcustom.manufacturingtitle1"),
      description: t("manufacturingcustom.manufacturingdescription1"),
      linkText: "Learn more",
    },
    {
      badge: (
        <span className="border-t border-b border-r border-[3px] border-l-[.1875rem] border-[#00d1ff] text-sm text-[#293745]  px-3 py-1 rounded-full">
          {t("manufacturingcustom.manufacturingcustombadge1")}
        </span>
      ),
      title: t("manufacturingcustom.manufacturingtitle2"),
      description: t("manufacturingcustom.manufacturingdescription2"),
      linkText: "Learn more",
    },
    {
      badge: (
        <span className="border-t border-b border-r border-[3px] border-l-[.1875rem] border-[#ffd700] text-sm text-[#293745]  px-3 py-1 rounded-full">
          {t("manufacturingcustom.manufacturingcustombadge2")}
        </span>
      ),
      title: t("manufacturingcustom.manufacturingtitle3"),
      description: t("manufacturingcustom.manufacturingdescription3"),
      linkText: "Learn more",
    },
    {
      badge: (
        <span className="border-t border-b border-r border-[3px] border-l-[.1875rem] border-[#293745] text-sm text-[#293745]  px-3 py-1 rounded-full">
          {t("manufacturingcustom.manufacturingcustombadge3")}
        </span>
      ),
      title: t("manufacturingcustom.manufacturingtitle4"),
      description:t("manufacturingcustom.manufacturingdescription4"),
      linkText: "Learn more",
    },
  ];
  return (
    <div
            className={` ${isArabic ? "text-right" : "text-left"} ${
              isArabic ? "rtl" : ""
            }`}
          >
    <div className="bg-[#F3F6FB] py-10">
      <CustomizableCards
        heading={t("manufacturingcustom.manufacturingcoustomheading")}
        CardsData={CardsData}
    
      />
    </div>
    </div>
  );
};
export default CustomizableCardsSection;
