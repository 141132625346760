import { useNavigate } from "react-router-dom";
import Dashboard from "../../assets/home/client.jpg";
import { useTranslation } from "react-i18next";

const ExplorePlatform = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className="flex flex-col items-center w-full">
      {/* Title */}
      <h1 className="text-[28px] sm:text-[32px] md:text-[42px] lg:text-[52px] text-[#293745] font-bold mb-2 md:leading-[60px] lg:leading-[66px] text-center px-4">
        {t("homepage.exploretitle")}
      </h1>

      {/* Description */}
      <p className="text-[1rem] sm:text-[1.2rem] md:text-[1.25rem] text-[#293745] py-3 sm:py-4 mx-4 sm:mx-10 md:mx-16 lg:mx-24 text-center">
        {t("homepage.exploredesc")}
      </p>

      {/* Button */}
      <button
        className="bg-[#6559ff] text-white px-5 py-3 rounded-lg text-[1rem] mb-8 sm:mb-10 mt-4 sm:mt-6"
        onClick={() => navigate("/platform")}
      >
        {t("homepage.explorebtn")}
      </button>

      {/* Image */}
      <img
        src={Dashboard}
        alt=""
        className="w-full h-[300px] sm:h-[500px] md:h-[700px] lg:h-[940px] object-cover"
      />
    </div>
  );
};

export default ExplorePlatform;
