import IconCards from "../../../shared/iconCards";
import Icon from "../../../assets/construction/Feature-Stack-Icon.svg";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
const IndustryCards = () => {
  const { t } = useTranslation();
  const isArabic = i18next.language === "ar";
   
  const CardsData = [
    
    {
      img: Icon,
      title: t("constructionindustryCards.constructionindustrycardtitle1"),
      description: t("constructionindustryCards.constructionindustrycarddescription1"),
      linkText: "View this checklist",
    },
    {
      
      img: Icon,
      title: t("constructionindustryCards.constructionindustrycardtitle2"),
      description: t("constructionindustryCards.constructionindustrycarddescription2"),
      linkText: "View this checklist",
    },
    {
      img: Icon,
      title: t("constructionindustryCards.constructionindustrycardtitle3"),
      description: t("constructionindustryCards.constructionindustrycarddescription3"),
      linkText: "View this checklist",
    },
    {
      img: Icon,
      title: t("constructionindustryCards.constructionindustrycardtitle4"),
      description: t("constructionindustryCards.constructionindustrycarddescription4"),
      linkText: "View this checklist",
    },
    {
      img: Icon,
      title: t("constructionindustryCards.constructionindustrycardtitle5"),
      description: t("constructionindustryCards.constructionindustrycarddescription5"),
      linkText: "View this checklist",
    },
    {
      img: Icon,
      title: t("constructionindustryCards.constructionindustrycardtitle6"),
      description: t("constructionindustryCards.constructionindustrycarddescription6"),
      linkText: "View this checklist",
    },
 
  ];
  return (
    <div
            className={` ${isArabic ? "text-right" : "text-left"} ${
              isArabic ? "rtl" : ""
            }`}
          >
    <div className="py-10">
      <IconCards
        heading={t("constructionindustryCards.constructionindustrycardheading")}
        CardsData={CardsData}
      />
    </div>
  </div>
  );
};
export default IndustryCards;
