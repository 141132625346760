import TextImagewFlex from "../../../shared/textImageFlex";
import TrainingImage from "../../../assets/training/Training-one-platform.webp";
const AllInOnePlatform = () => {
  return (
    <div >
      <TextImagewFlex
        span="All-in-one platform"
        img={TrainingImage}
        heading="Make training a part of your improvement movement"
        para="Give your frontline everything they need to act, observe, and learn from the SafetyCulture platform. Make training a part of how your team mitigates risk and improves every day. Create behavioral change with Training."
        show
        reverse
      />
    </div>
  );
};
export default AllInOnePlatform;
