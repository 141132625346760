import Email from "../../../assets/notification/email.avif";
const BulkUplaodUsers = () => {
  return (
    <div className="mx-auto w-full md:w-[70%] flex flex-col justify-center items-center pt-14">
      <div>
        <img src={Email} alt="" className="w-44"/>
      </div>
      <h1 className="font-bold ">Email confirmation required</h1>
      <p className="text-base text-gray-900 py-2">
        To continue adding users to your organization, please confirm your
        account's email address. If you haven't received the email, please check
        your junk or spam folder or request a new email.
      </p>
      <button className="bg-[#6559ff] py-2 px-3 text-white rounded-lg">Resend confirmation email</button>
    </div>
  );
};
export default BulkUplaodUsers;
