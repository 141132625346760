import TextImagewFlex from "../../../shared/textImageFlex";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import Report from "../../../assets/inspection/reports.webp";
import InspectionAudit from "../../../assets/inspection/inspection_audit_trail.webp";

const ProfessionalReports = () => {
  const isArabic = i18next.language === "ar";
  const { t } = useTranslation();
  return (
    <section className="bg-[#F3F6FB] py-24">
      {/* Section Heading */}
      <h2 className="text-lg sm:text-xl md:text-3xl lg:text-5xl font-bold text-[#293745] !leading-[60px]">
        {t("inspectionpage.professionalreportheading")}
      </h2>

      {/* Section Description */}
      <p className="mt-4 text-md md:text-[1.25rem] text-[#293745] px-2 md:px-32 py-2 mb-12">
        {t("inspectionpage.professionalreportpara")}
      </p>

      {/* Showcase Brand Section */}
      <div>
        <TextImagewFlex
          img={Report}
          span={t("inspectionpage.reportspan")}
          heading={t("inspectionpage.reportheading")}
          para={t("inspectionpage.reportpara")}
          reverse
          show
          widthClass="w-full"
        />
      </div>

      <div
        className={` ${isArabic ? "text-right" : "text-left"} ${
          isArabic ? "rtl" : ""
        }`}
      >
        <div className="pt-16">
          <TextImagewFlex
            img={InspectionAudit}
            heading={t("inspectionpage.aduitheading")}
            para={t("inspectionpage.aduitpara")}
            widhtClass="w-full"
          />
        </div>
      </div>
    </section>
  );
};

export default ProfessionalReports;
