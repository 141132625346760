import TrustedImages from "../../../assets/safetyDocs/SafetyDocsJourney.webp";

const TrustedResource = () => {
  return (
    <section className="text-center py-6 md:py-10 lg:py-16">
      <div className="container mx-auto">
        {/* Heading */}
        <h1 className="text-3xl md:text-4xl lg:text-5xl font-bold text-gray-900 mb-4">
          Your trusted resource partner
        </h1>

        <div className="flex flex-col md:flex-row justify-center md:justify-between w-full">
          {/* Image Section */}
          <div className="relative md:w-1/2 mt-4 md:mt-0 flex justify-center w-3/4 mx-auto">
            <img
              src={TrustedImages}
              alt="SafetyDocs Journey - Trusted Resource"
              className="max-w-full h-auto"
            />
          </div>

          {/* Text Section */}
          <div className="w-full md:w-1/2 flex flex-col justify-center text-start px-6 md:px-10">
            <h2 className="text-xl md:text-3xl font-bold text-gray-800 mb-2">
              Where SafetyCulture’s journey started
            </h2>
            <p className="w-full text-gray-700 text-sm md:text-base pt-3 pb-5">
              Since 2004, our team of health and safety experts have been
              delivering a wide range of content-rich documents so you can be
              sure everything runs smoothly and efficiently.
            </p>

            <ul className="text-[14px] text-[#293745] list-disc list-inside space-y-2">
              <li>Safe Work Method Statements (SWMS)</li>
              <li>Safe Operating Procedures (SOPs)</li>
              <li>Management Systems</li>
              <li>Safety Management Plans</li>
              <li>Policies & Procedures</li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TrustedResource;
