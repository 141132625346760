// src/components/Layout.js
import React from "react";
import PortalSidebar from "../portalSidebar";

const PotalLayout = ({ children }) => {
  return (
    <div className="layout overflow-hidden ">
      <main className="flex">
        <div className=" w-[20%]">
          <PortalSidebar />
        </div>
        <div className="w-[83%]  container mx-auto">{children}</div>
      </main>
    </div>
  );
};

export default PotalLayout;
