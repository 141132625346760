import EquipmentHero from "../../../assets/mining/Mining-1-1.webp";
import TextImageFlex from "../../../shared/textImageFlex";
const GainVisibilty = () => {
  return (
    <div className="py-12 md:py-24">
      <TextImageFlex
        heading="Prevent incidents and promote a safety culture"
        para="Encourage a supportive and inclusive culture where teams are comfortable reporting safety concerns and addressing potential risks. In-vehicle monitoring systems (IVMS) improve driver safety and efficiency."
        img={EquipmentHero}
        widhtClass="w-full"
        reverse
      />
    </div>
  );
};

export default GainVisibilty;
