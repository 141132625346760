import React from "react";
import ToolImage from "../../../assets/loneWorker/Teams.webp";
import TextImageFlex from "../../../shared/textImageFlex";
const SimpleTool = () => {
  return (
    <div className="py-12 md:py-24">
      <TextImageFlex
        heading="Give teams a simple tool they can trust"
        para={
          <>
            Our app was built to be easy for the frontline with intuitive ways
            to activate a duress alarm, confirm check-ins, or notify leaders
            when a job doesn’t go to plan.
            <br />
            <br />
            With SHEQSY, location visibility is always employee-activated,
            protecting their personal privacy.
          </>
        }
        img={ToolImage}
        reverse
      />
    </div>
  );
};

export default SimpleTool;
