import React, { useState } from "react";
import NoGroup from "../../../assets/notification/nogroup.PNG";
import { FaSearch } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import CreateGroupModal from "./createGroupModal";
const GroupNotifications = () => {
  const navigate = useNavigate();
  const [createGroupOpen, setCreateGroupOpen] = useState(false);

  return (
    <div className="py-4 container mx-auto ">
      {createGroupOpen && <CreateGroupModal setCreateGroupOpen={setCreateGroupOpen}/>}
      {/* Header */}
      <div className="flex justify-between items-center py-4 ">
        <h1 className="text-2xl font-bold">Group</h1>
        <div className="flex items-center space-x-4">
          {/* Search */}
          <button
            className="bg-white hover:bg-[#e0e4ff] border border-[#bfc6d4] text-[#6559ff] px-4 py-2 rounded-lg"
            onClick={() => navigate("/dashboard/group-matrix")}
          >
            Group matrix
          </button>
          {/* Add site */}
          <button className="bg-[#6559ff] hover:bg-[#7470f5] text-white px-4 py-2 rounded-lg" onClick={()=>setCreateGroupOpen(true)}>
            + Create group
          </button>
          {/* More Options */}
        </div>
      </div>
      <div className="flex justify-between mt-3">
        <div className="flex items-center border border-gray-300 rounded-md px-2 py-1">
          <FaSearch className="text-gray-500" />
          <input
            type="text"
            placeholder="Search"
            className="ml-2 outline-none "
          />
        </div>
        <div>
          <p className="text-gray-600 text-sm">no results</p>
        </div>
      </div>
      <div className="flex flex-col items-center justify-center  py-8">
        <div className="w-full  mx-auto bg-white shadow-md rounded-lg">
          <div className="overflow-x-auto">
            <table className="min-w-full border border-gray-200 rounded-t-lg">
              <thead className="bg-gray-100 border-b">
                <tr>
                  <th className="px-4 py-3 text-left font-medium text-gray-500">
                    <input type="checkbox" className="form-checkbox" />
                  </th>
                  <th className="px-4 py-3 text-left font-medium text-gray-500">
                    Name
                    <span className="ml-1">&#8593;</span>
                  </th>
                  <th className="px-4 py-3 text-left font-medium text-gray-500">
                    Description
                  </th>
                  <th className="px-4 py-3 text-left font-medium text-gray-500">
                    Active users
                  </th>
                  <th className="px-4 py-3 text-right">
                    <button className="text-gray-400 hover:text-gray-600">
                      <span>&#9881;</span>
                    </button>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td
                    colSpan="5"
                    className="px-4 py-12 text-center text-gray-500"
                  >
                    <div className="flex flex-col items-center">
                      <div className="mb-4">
                        <img src={NoGroup} alt="" />
                      </div>
                      <p className="text-lg font-medium">
                        Your organization has no groups
                      </p>
                      <p className="text-gray-400">
                        Create groups to help manage your users.
                      </p>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="flex items-center justify-end p-4 bg-white border-t rounded-b-lg">
              <div className="flex space-x-2">
                <button
                  className="p-1 bg-gray-200 text-gray-500 rounded-lg hover:bg-gray-300"
                  disabled
                >
                  &#8249;
                </button>
                <span className="text-sm text-gray-500 mt-1">1 / 1</span>
                <button className="p-1 bg-gray-200 text-gray-500 rounded-lg hover:bg-gray-300">
                  &#8250;
                </button>
              </div>
            </div>
          </div>
        </div>
        <button className="fixed bottom-4 right-4 p-4 bg-purple-600 text-white rounded-full shadow-lg">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M12 5v14M5 12h14" />
          </svg>
        </button>
      </div>
    </div>
  );
};

export default GroupNotifications;
