import SupportHero from "../../components/support/hero";
import Services from "../../components/support/services";
import GlobalSupport from "../../components/support/globalSupport";
const SupportPage = () => {
  return (
    <div>
      <SupportHero />
      <Services/>
      <GlobalSupport/>
    </div>
  );
};
export default SupportPage;
