import HeroSection from "../../components/products/secureCare/hero";
import ReliableSupport from "../../components/products/secureCare/reliableSupport";
import TrustedExperts from "../../components/products/secureCare/trustedExperts";
import Proactive from "../../components/products/secureCare/proactiveApproach";
import Video from "../../components/products/secureCare/video";
import Insurance from "../../components/products/secureCare/insurance";
const SecureCare = () => {
  return (
    <div>
      <HeroSection />
      <ReliableSupport/>
      <TrustedExperts/>
      <Proactive/>
      <Video/>
      <Insurance/>
    </div>
  );
};
export default SecureCare;
