import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import MyHeadsUp from "./myHeadsup";
import ManageHeadsUp from "./manageHeadsup ";
const HeadsUp = () => {
  const navigate = useNavigate();
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [activeTab, setActiveTab] = useState("My Heads Up");
  const renderContent = () => {
    switch (activeTab) {
        case "My Heads Up":
          return <MyHeadsUp />;
        case "Manage Heads Up":
          return <ManageHeadsUp />;
      default:
        return null;
    }
  };

  const toggleFilterDropdown = () => {
    setIsFilterOpen(!isFilterOpen);
  };

  return (
    <div className="container mx-auto ">
      {/* Tabs */}
      <div className="mt-6">
        <nav className="flex space-x-7 border-b border-gray-300">
          {["My Heads Up", "Manage Heads Up"].map((tab) => (
            <button
              key={tab}
              onClick={() => setActiveTab(tab)}
              className={`pb-2 text-gray-600 ${
                activeTab === tab
                  ? "border-b-2 border-blue-600 text-blue-600"
                  : ""
              }`}
            >
              {tab}
            </button>
          ))}
        </nav>

        {/* Tab Content */}
        <div className="mt-4">{renderContent()}</div>
      </div>
    </div>
  );
};

export default HeadsUp;
