import React, { useState } from "react";
import {  FaThList, FaSearch } from "react-icons/fa";
import { CiNoWaitingSign } from "react-icons/ci";
import { IoIosSettings } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { Calendar, momentLocalizer } from "react-big-calendar";
import dayjs from "dayjs";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import moment from "moment";
import DatePicker from "react-datepicker";
import { FaTasks } from "react-icons/fa";
const localizer = momentLocalizer(moment);

const MyHeadsUp = () => {
  const navigate = useNavigate();
  const [CreatedropdownOpen, setCreateDropdownOpen] = useState(false);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("Due date");
  const options = ["Completed", "Published date", "Title"];

 

  const toggleDropdown2 = () => setIsOpen(!isOpen);

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
  };

  const toggleFilterDropdown = () => {
    setIsFilterOpen(!isFilterOpen);
  };
  return (
    <div className="flex flex-col items-center pe-8 ">
      <div className="flex justify-between items-center mb-1 w-full pt-3">
        <h1 className="text-2xl font-bold text-[#1f2533]">Heads Up</h1>
        <div >
       
          <button
            className="bg-[#6559ff] text-white px-5 py-3 rounded-lg hover:bg-[#7470f5] shadow"
            onClick={() => navigate("/dashboard/create-headsup")}
          >
            + Create Heads Up
          </button>
        </div>
      </div>

      <div className="flex justify-between w-full items-center mb-6 mt-3">
        {/* Search and filter */}
        <div className="flex items-center space-x-4">
          {/* Search box */}
          <div className="flex items-center border border-gray-300 rounded-md px-2 py-2">
            <FaSearch className="text-gray-500" />
            <input
              type="text"
              placeholder="Search "
              className="ml-2 outline-none bg-transparent"
            />
          </div>
        
          {/* User status and filter */}
          <div className="flex items-center">
            <div className="relative">
              <button
                onClick={toggleFilterDropdown}
                className="text-[#6559ff] hover:text-blue-600"
              >
                + Add filter
              </button>
              {isFilterOpen && (
                <div className="absolute mt-2 w-48 rounded-md shadow-lg bg-white">
                  <ul className="py-1 text-gray-700">
                    <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer">
                      Filter 1
                    </li>
                    <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer">
                      Filter 2
                    </li>
                    <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer">
                      Filter 3
                    </li>
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Create group and download */}

      
      </div>

      <div className="w-full flex justify-center mb-4">
      

        {/* Dropdown */}
        <div className="relative">
          <button
            onClick={toggleDropdown2}
            className="flex items-center px-4 py-2 bg-white  text-[#6559ff] hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-indigo-500"
          >
            <span>{selectedOption}</span>
            <svg
              className={`ml-2 w-4 h-4 transform transition-transform ${
                isOpen ? "rotate-180" : "rotate-0"
              }`}
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M19 9l-7 7-7-7"
              ></path>
            </svg>
          </button>

          {/* Dropdown menu */}
          {isOpen && (
            <ul className="absolute mt-2 w-full bg-white border border-gray-300 rounded-md shadow-lg z-10 text-gray-700">
              {options.map((option) => (
                <li
                  key={option}
                  onClick={() => handleOptionClick(option)}
                  className={`px-4 py-2 cursor-pointer hover:bg-indigo-100 ${
                    selectedOption === option ? "bg-indigo-100 " : ""
                  }`}
                >
                  {option}
                </li>
              ))}
            </ul>
          )}
        </div>

       
      </div>

      <div className="w-full text-center py-16">
        
          <div className="flex flex-col items-center justify-center h-full pt-4">
            <CiNoWaitingSign className="text-gray-500 mb-4 text-7xl" />
           
            <p className="text-gray-800">
            Receive important information from your team
            </p>
            <p className=" pt-2 text-gray-800">
            Any Heads Ups received will appear here.
            </p>
          </div>
   
      
     
      </div>
    
    </div>
  );
};

export default MyHeadsUp;
