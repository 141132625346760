import FlexContent from "../../../shared/flexContent";
import ConstructionImage from "../../../assets/retail/retailTrainingImage.webp";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
const PreventIncidents = () => {
  const { t } = useTranslation();
  const isArabic = i18next.language === "ar";
  return (
    <div
      className={`bg-[#F3F6FB] pt-4 pb-24 ${
        isArabic ? "text-right" : "text-left"
      } ${isArabic ? "rtl" : ""}`}
    >
      <FlexContent
        heading={t("retailpage.PreventIncidentsheading")}
        para={t("retailpage.PreventIncidentspara")}
        img={ConstructionImage}
        italicText={t("retailpage.PreventIncidentsitalic")}
        name={t("retailpage.PreventIncidentsname")}
        position={t("retailpage.PreventIncidentsposition")}
      />
    </div>
  );
};
export default PreventIncidents;
