import React from "react";
import { FaSearch } from "react-icons/fa";

const Filter = ({ isFilterOpen, toggleFilterDropdown }) => {
  return (
    <div className="flex items-center">
      <div className="relative">
        <button
          onClick={toggleFilterDropdown}
          className="text-[#6559ff] hover:text-blue-600"
        >
          + Add filter
        </button>
        {isFilterOpen && (
          <div className="absolute mt-2 w-48 rounded-md shadow-lg bg-white">
            <ul className="py-1 text-gray-700">
              <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer">
                Filter 1
              </li>
              <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer">
                Filter 2
              </li>
              <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer">
                Filter 3
              </li>
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default Filter;
