import FAQSection from "../../../shared/faqs";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

const Faqs = () => {
  const isArabic = i18next.language === "ar";
  const { t } = useTranslation();

  const faqData = [
    {
      question: t("inspectionpage.que1"),
      answer: t("inspectionpage.ans1"),
    },
    {
      question: t("inspectionpage.que2"),
      answer: t("inspectionpage.ans2"),
    },
    {
      question: t("inspectionpage.que3"),
      answer: t("inspectionpage.ans3"),
    },
    {
      question: t("inspectionpage.que4"),
      answer: t("inspectionpage.ans4"),
    },
    {
      question: t("inspectionpage.que5"),
      answer: t("inspectionpage.ans5"),
    },
    {
      question: t("inspectionpage.que6"),
      answer: t("inspectionpage.ans6"),
    },
  ];

  return (
    <div
      className={` ${isArabic ? "text-right" : "text-left"} ${
        isArabic ? "rtl" : ""
      }`}
    >
      <FAQSection
        faqData={faqData}
        heading={t("inspectionpage.faqs")}
        className={isArabic ? "text-right rtl" : "text-left"}
      />
    </div>
  );
};

export default Faqs;
