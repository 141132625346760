import FeaturesSection from "../../components/products/integrations/featuresCards";
import Hero from "../../components/products/integrations/hero";
import ConnectToolsSection from "../../components/products/integrations/connectTools";
import CardSection from "../../components/products/integrations/cardsSection";
import YouTubeEmbed from "../../components/products/integrations/videoSection";
const Inspection = () => {
  return (
    <div>
      <Hero />
      <ConnectToolsSection />
      <FeaturesSection />
      <CardSection />
      <YouTubeEmbed />
    </div>
  );
};
export default Inspection;
