import ConstructionHero from "../../components/solutions/manufacturing/hero";
import States from "../../components/solutions/manufacturing/states";
import BoostProductivity from "../../components/solutions/manufacturing/boostProductivity";
import PreventIncidents from "../../components/solutions/manufacturing/preventIncidents";
import GainVisibilty from "../../components/solutions/manufacturing/gainvisibility";
import DeliverEffective from "../../components/solutions/manufacturing/deliverEffective";
import Keepfoodfresh from "../../components/solutions/manufacturing/keepFoodFresh";
import Onboard from "../../components/solutions/manufacturing/onboard";
import ListDataSection from "../../components/solutions/manufacturing/listData";
import Improvement from "../../components/solutions/manufacturing/improvement";
import CustomizableCardsSection from "../../components/solutions/manufacturing/customizableCards";
import IndustryCards from "../../components/solutions/manufacturing/industryCards";
import ImprovementTwo from "../../components/solutions/manufacturing/improvementTwo";
import Faqs from "../../components/solutions/manufacturing/faqs";
const Manufacturing = () => {
  return (
    <div>
      <ConstructionHero />
      <States />
      <BoostProductivity />
      <PreventIncidents />
      <GainVisibilty />
      <DeliverEffective />
      <Keepfoodfresh />
      <Onboard />
      <ListDataSection />
      <Improvement />
      <IndustryCards />
      <CustomizableCardsSection />

      <ImprovementTwo />
      <Faqs />
    </div>
  );
};
export default Manufacturing;
