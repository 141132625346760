import FlexContent from "../../../shared/flexContent";
import FacilitiesImage from "../../../assets/facilities/Inspection-Reporting-Software-img-03.4750cc78c0031999f646.webp";
import { useTranslation } from "react-i18next";
const BoostProductivity = () => {
  const { t } = useTranslation();
  return (
    <div className="bg-[#F3F6FB] py-24">
      <FlexContent
        heading={t("constructionpage.BoostProductheading")}
        para={t("constructionpage.BoostProductivitypara")}
        reverse
        italicText={t("constructionpage.BoostProductivityitalic")}
        name={t("constructionpage.BoostProductivityname")}
        position={t("constructionpage.BoostProductivityposition")}
        img={FacilitiesImage}
      />
    </div>
  );
};
export default BoostProductivity;
