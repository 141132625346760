import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import RightImage from "../../../assets/home/db-schenker.webp";
import Image1 from "../../../assets/home/schenker.webp";
import { FaArrowRight, FaArrowLeft } from "react-icons/fa";
const TabThreeDetails = () => {
  const navigate = useNavigate();
  const isArabic = i18next.language === "ar";
  const { t } = useTranslation();

  return (
    <div
      className={`flex flex-col lg:flex-row items-center lg:items-start ps-7 md:ps-14 rounded-lg shadow-xl mx-3 md:mx-7 ${
        isArabic ? "text-right rtl" : "text-left"
      }`}
    >
      <div className="lg:w-[50%] text-left pt-[73px] pe-3 md:pe-12">
        <img src={Image1} alt="" className="w-[160px]" />
        <div className="h-[300px] flex items-center">
          <button
            className="flex mt-12 mb-10 lg:my-0 text-[14px] text-[1rem] font-[600] text-[#4740d4] border border-[#d0d7e1] py-2 px-4 rounded-lg"
            onClick={() => navigate("/transport-logistics")}
          >
            {t("tabcardspage.explorebtn3")}{" "}
            {isArabic ? (
              <FaArrowLeft className="me-1 mt-1" />
            ) : (
              <FaArrowRight className="ms-1 mt-1" />
            )}
          </button>
        </div>
      </div>
      <div className="lg:w-[50%] mt-6 lg:mt-0 flex justify-center lg:justify-end hidden sm:block">
        <div className=" rounded-s-full overflow-hidden">
          <img
            src={RightImage}
            alt="Image"
            className="object-cover w-full h-[410px]"
          />
        </div>
      </div>
    </div>
  );
};
export default TabThreeDetails;
