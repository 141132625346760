import React, { useState } from "react";
import { useNotification } from "../../../common/hooks/useNotification";
import useHttp from "../../../common/hooks/useHttp";
import { useAuth } from "../../context/AuthContext";
import { useDropzone } from "react-dropzone";

const AddCredentialsProcess = () => {
  const { authData } = useAuth();
  const [selectedCredentialType, setSelectedCredentialType] = useState(null);
  const [searchCredentialType, setSearchCredentialType] = useState("");
  const [setIsModalOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  // const [ setIsUserOpen] = useState(false);
  const [credentialTypes, setCredentialTypes] = useState([]);
  const [credentialTypeName, setCredentialTypeName] = useState("");
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [searchUser] = useState("");
  const [issueDate, setIssueDate] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [uploadedFiles, setUploadedFiles] = useState([]);

  const { sendRequest } = useHttp();
  const notify = useNotification();

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: (acceptedFiles) => {
      setUploadedFiles((prevFiles) => [...prevFiles, ...acceptedFiles]);
    },
    accept: "image/*,application/pdf",
  });

  // const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const getCredentialTypes = async () => {
    try {
      await sendRequest(
        {
          url: "v1/credential/types",
          accessToken: authData.accessToken,
          headers: {
            userName: authData.userName,
            organizationId: authData.organizationId,
            deviceId: authData.deviceId,
          },
        },
        (response) => {
          setCredentialTypes(response.data.credentialTypes);
        }
      );
    } catch (error) {
      console.error("Error fetching credential types:", error);
    }
  };

  const handleCredentialTypeCreation = async () => {
    try {
      await sendRequest(
        {
          url: "v1/credential/type/create",
          method: "POST",
          accessToken: authData.accessToken,
          headers: {
            userName: authData.userName,
            organizationId: authData.organizationId,
            deviceId: authData.deviceId,
          },
          payload: {
            organizationId: authData.organizationId,
            credentialTypeName: credentialTypeName,
          },
        },
        (response) => {
          if (response.responseCode === "200") {
            setCredentialTypeName("");
            closeModal();
            getCredentialTypes();
            notify(response.responseDesc, "success");
          } else {
            notify(response.responseDesc, "error");
          }
        }
      );
    } catch (error) {
      console.error("Error getting:", error.response);
    }
  };
  const getOrganizationUsers = async () => {
    try {
      await sendRequest(
        {
          url: "v1/organization/users",
          accessToken: authData.accessToken,
          headers: {
            userName: authData.userName,
            organizationId: authData.organizationId,
            deviceId: authData.deviceId,
          },
        },
        (response) => {
          console.log(response, "users response");
          setUsers(response.data.organizationUsers);
        }
      );
    } catch (error) {
      console.error("Error fetching organization users:", error);
    }
  };

  const addTypeToCredentialHandler = async (type) => {
    setSelectedCredentialType(type);
  };
  const filteredCredentialTypes = credentialTypes.filter((type) =>
    type.name.toLowerCase().includes(searchCredentialType.toLowerCase())
  );

  const addUsertoSiteHandler = async (user) => {
    setSelectedUser(user);
  };
  const filteredUsers = users.filter((user) => {
    const firstNameMatch = user.user.firstName
      ?.toLowerCase()
      .includes(searchUser.toLowerCase());
    const lastNameMatch = user.user.lastName
      ?.toLowerCase()
      .includes(searchUser.toLowerCase());
    return firstNameMatch || lastNameMatch;
  });

  // const handleSave = async () => {
  //   if (uploadedFiles.length === 0) {
  //     alert("Please select a file.");
  //     return;
  //   }

  //   const formData = new FormData();

  //   // Sample credentialRequest data (ensure to update these fields)
  //   const credentialRequest = {
  //     credentialTypeId: selectedCredentialType.id,
  //     userId: selectedUser.id,
  //     issueDate: issueDate,
  //     expirationDate: expiryDate,
  //   };
  //   formData.append("credentialRequest", JSON.stringify(credentialRequest));

  //   // Append each selected file to FormData
  //   uploadedFiles.forEach((file) => {
  //     formData.append("mediaFiles", file);
  //   });

  //   try {
  //     const response = await fetch("http://15.184.210.163:8080/ssc/api/v1/credential/add", {
  //       method: "POST",
  //       headers: {
  //         Authorization: `Bearer ${authData.accessToken}`,
  //         userName: authData.userName,
  //         organizationId: authData.organizationId,
  //         deviceId: authData.deviceId,
  //       },
  //       body: formData,
  //     });

  //     const data = await response.json();
  //     if (data) {
  //       if (data.responseCode == "200") {
  //         // setCredentialTypeName("");
  //         // closeModal();
  //         // getCredentialTypes();
  //         notify(data.responseDesc, "success");
  //       } else {
  //         notify(data.responseDesc, "error");
  //       }
  //     }
  //     console.log("Response:", data);
  //   } catch (error) {
  //     console.error("Error:", error);
  //   }
  // };

  const handleSave = async () => {
    if (
      !selectedCredentialType ||
      !selectedUser ||
      !issueDate ||
      !expiryDate ||
      uploadedFiles.length === 0
    ) {
      alert("Please fill in all required fields!");
      return;
    }

    const formData = new FormData();
    formData.append(
      "credentialRequest",
      JSON.stringify({
        credentialTypeId: selectedCredentialType.id,
        userId: selectedUser.id,
        issueDate,
        expirationDate: expiryDate,
      })
    );
    uploadedFiles.forEach((file) => formData.append("mediaFiles", file));

    try {
      await sendRequest(
        {
          url: "v1/credential/add",
          method: "POST",
          accessToken: authData.accessToken,
          headers: {
            "Content-Type": "multipart/form-data",
            userName: authData.userName,
            organizationId: authData.organizationId,
            deviceId: authData.deviceId,
          },
          payload: formData,
          // payload: {
          //   mediaFiles: formData,
          // },
        },
        (response) => {
          if (response.responseCode === "200") {
            notify(response.responseDesc, "success");
          } else {
            notify(response.responseDesc, "error");
          }
        }
      );
    } catch (error) {
      console.error("Error saving credential:", error);
    }
  };

  return (
    <div className="flex justify-center items-center min-h-screen bg-gray-100">
      <div className="bg-white w-full max-w-md rounded-lg shadow-lg p-6 text-start">
        <h2 className="text-lg font-semibold text-gray-800 mb-4">
          Add Credential
        </h2>

        {/* Drag and Drop Section */}
        <div className="mb-4">
          <label className="block text-gray-700 font-medium mb-2">
            Media <span className="text-gray-700">(required)</span>
          </label>
          <div
            {...getRootProps()}
            className={`border-dashed border-2 rounded-lg p-4 flex items-center justify-center text-gray-500 ${
              isDragActive ? "border-blue-500" : "border-gray-300"
            }`}
          >
            <input {...getInputProps()} />
            {isDragActive ? (
              <p className="text-blue-500">Drop your file here...</p>
            ) : (
              <p>Drag and drop files here or click to browse.</p>
            )}
          </div>
        </div>

        {/* Uploaded Files */}
        {uploadedFiles.length > 0 && (
          <div className="mb-4">
            <label className="block text-gray-700 font-medium mb-2">
              Uploaded Files
            </label>
            <ul>
              {uploadedFiles.map((file, index) => (
                <li
                  key={index}
                  className="flex justify-between items-center border rounded-lg p-2 mb-2"
                >
                  <span className="truncate">{file.name}</span>
                  <button
                    onClick={() =>
                      setUploadedFiles((prevFiles) =>
                        prevFiles.filter((_, i) => i !== index)
                      )
                    }
                    className="text-red-500 hover:text-red-700"
                  >
                    Remove
                  </button>
                </li>
              ))}
            </ul>
          </div>
        )}

        {/* Credential Type Dropdown */}
        <div className="mb-4">
          <label className="block text-gray-700 font-medium mb-2">
            Credential Type <span className="text-gray-700">(required)</span>
          </label>
          <div className="relative">
            <button
              onClick={() => {
                setIsOpen(!isOpen);
                getCredentialTypes();
              }}
              className="w-full border border-gray-300 rounded-md px-4 py-2 text-left"
            >
              {selectedCredentialType
                ? selectedCredentialType.name
                : "Select Type..."}
            </button>

            {isOpen && (
              <div className="absolute mt-2 bg-white shadow-md rounded-md w-full z-10">
                <input
                  type="text"
                  placeholder="Search..."
                  value={searchCredentialType}
                  onChange={(e) => setSearchCredentialType(e.target.value)}
                  className="w-full px-4 py-2 border-b focus:outline-none"
                />
                <ul className="max-h-40 overflow-auto">
                  {filteredCredentialTypes.map((type, index) => (
                    <li
                      key={index}
                      className="px-4 py-2 hover:bg-blue-100 cursor-pointer"
                      onClick={() => {
                        setSelectedCredentialType(type);
                        setIsOpen(false);
                      }}
                    >
                      {type.name}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </div>

        {/* Issue and Expiry Dates */}
        <div className="mb-4">
          <label className="block text-gray-700 font-medium mb-2">
            Issue Date <span className="text-gray-700">(required)</span>
          </label>
          <input
            type="date"
            value={issueDate}
            onChange={(e) => setIssueDate(e.target.value)}
            className="w-full border border-gray-300 rounded-md px-4 py-2"
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 font-medium mb-2">
            Expiry Date <span className="text-gray-700">(required)</span>
          </label>
          <input
            type="date"
            value={expiryDate}
            onChange={(e) => setExpiryDate(e.target.value)}
            className="w-full border border-gray-300 rounded-md px-4 py-2"
          />
        </div>

        {/* Save Button */}
        <button
          onClick={handleSave}
          className="w-full bg-blue-600 text-white py-2 rounded-md hover:bg-blue-700"
        >
          Save Credential
        </button>
      </div>
    </div>
  );
};

export default AddCredentialsProcess;
