import React from "react";
import ListData from "../../../shared/listData";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

const ListDataSection = () => {
  const { t } = useTranslation();
  const isArabic = i18next.language === "ar";

  const categoriesData = [ 
    {
      title: t("manufacturinglistdata.manufacturinglistdata1.title"),
      items: t("manufacturinglistdata.manufacturinglistdata1.items", { returnObjects: true }),
    },
    {
      title: t("manufacturinglistdata.manufacturinglistdata2.title"),
      items: t("manufacturinglistdata.manufacturinglistdata2.items", { returnObjects: true }),
    },
    {
      title: t("manufacturinglistdata.manufacturinglistdata3.title"),
      items: t("manufacturinglistdata.manufacturinglistdata3.items", { returnObjects: true }),
    },
    {
      title: t("manufacturinglistdata.manufacturinglistdata4.title"),
      items: t("manufacturinglistdata.manufacturinglistdata4.items", { returnObjects: true }),
    },
  ];

  return (
    
    <div>
      <ListData
        title={t("manufacturinglistdata.manufacturinglistdataheading")}
        
        categories={categoriesData}
      />
    </div>
  );
};

export default ListDataSection;
