import TextImagewFlex from "../../../shared/textImageFlex";
import TrainingImage from "../../../assets/training/TransportTraining.webp";
const ExpertTraining = () => {
  return (
    <div className="py-24 ">
      <TextImagewFlex
        span="Experts in training"
        img={TrainingImage}
        heading="Let us do the hard work for you"
        para="We’re here to help. Our team of training experts is here to support you throughout the entire process. So you can convert your SOPs and safework docs into interactive, bite-sized training without lifting a finger."
        show
        
      />
    </div>
  );
};
export default ExpertTraining;
