import React, { useEffect, useState } from "react";
import { FiMoreVertical, FiCheck, FiX } from "react-icons/fi";
import { FaSearch } from "react-icons/fa";
import { MdEdit } from "react-icons/md";
import { FaPlus } from "react-icons/fa6";
import { HiUserAdd } from "react-icons/hi";
import { AiOutlineIssuesClose } from "react-icons/ai";
import SitePermissionsModal from "../sitePermissionModal";
import CustomizeLabelsModal from "../customizeSiteLabels";
import { useNavigate } from "react-router-dom";
import useHttp from "../../../../common/hooks/useHttp";
import { useAuth } from "../../../context/AuthContext";
import { useNotification } from "../../../../common/hooks/useNotification";
const AddSites = () => {
  const { authData } = useAuth();

  const { sendRequest } = useHttp();
  const navigate = useNavigate();
  const notify = useNotification();
  // Pre-populate with one row
  const [sites, setSites] = useState([]);
  const [customizeSiteOpen, setCustomizelabel] = useState(false);
  const [SitePermissionOpen, setSitePermissionOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedSite, setSelectedSite] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);

  const [isLevelOpen, setIsLevelOpen] = useState(false);
  const [isDotOpen, setIsDotOpen] = useState(false);
  const [searchUser, setSearchUser] = useState("");
  const [searchSite, setSearchSite] = useState("");

  const [levelMenuOpenIndex, setLevelMenuOpenIndex] = useState(null);
  const [users, setUsers] = useState([]);

  // Open level menu for the row
  const toggleLevelMenu = (index) => {
    setLevelMenuOpenIndex(levelMenuOpenIndex === index ? null : index);
  };

  // Add site row above or below
  const addSiteAtPosition = (index, position) => {
    const newSite = { name: "", isNew: true };
    const updatedSites = [...sites];
    if (position === "above") {
      updatedSites.splice(index, 0, newSite); // Insert above
    } else if (position === "below") {
      updatedSites.splice(index + 1, 0, newSite); // Insert below
    }
    setSites(updatedSites);
    setIsEditing(position === "above" ? index : index + 1); // Set new row to editable
    setLevelMenuOpenIndex(null); // Close level menu
  };

  // Filtered options based on the search query
  const filteredUsers = users.filter((user) => {
    const firstNameMatch = user.user.firstName
      ?.toLowerCase()
      .includes(searchUser.toLowerCase());
    const lastNameMatch = user.user.lastName
      ?.toLowerCase()
      .includes(searchUser.toLowerCase());
    return firstNameMatch || lastNameMatch;
  });
  const filteredSites = sites.filter((site) =>
    site.name.toLowerCase().includes(searchSite.toLowerCase())
  );
  const openModal = (site) => {
    console.log(site);
    setSelectedSite(site); // Set the selected site when the button is clicked
    setIsModalOpen(true); // Open the modal
  };

  const closeModal = () => {
    setIsModalOpen(false); // Close the modal
    setSelectedSite(null); // Optionally clear the selected site when modal is closed
    setSelectedUser(null);
    setSearchUser(""); // Optionally clear the selected site when modal is closed
  };

  // Add a new site row
  const addSite = () => {
    setSites([...sites, { name: "", isNew: true }]);
    setIsEditing(sites.length); // Set the new row to be editable
  };

  // Save edited site name
  const addUsertoSiteHandler = async (user) => {
    setSelectedUser(user);
  };
  const addUsertoSite = async () => {
    try {
      await sendRequest(
        {
          url: "v1/site/user/add",
          method: "POST",
          accessToken: authData.accessToken,
          headers: {
            userName: authData.userName,
            organizationId: authData.organizationId,
            deviceId: authData.deviceId,
          },
          payload: {
            siteId: selectedSite.id,
            userId: selectedUser.id,
          },
        },
        (response) => {
          console.log(response);

          if (response) {
            if (response.responseCode == "200") {
              notify(response.responseDesc, "success");
              getOrganizationSites();
              closeModal();
            } else {
              notify(response.responseDesc, "error");
            }
          }
        }
      );
    } catch (error) {
      console.error("Error saving/updating site:", error.response);
    }
  };

  // Delete a site row
  const removeSite = async (index) => {
    const site = sites[index];

    try {
      if (site.id) {
        await sendRequest(
          {
            url: "v1/site/delete",
            method: "POST",
            accessToken: authData.accessToken,
            headers: {
              userName: authData.userName,
              organizationId: authData.organizationId,
              deviceId: authData.deviceId,
            },
            payload: {
              siteId: site.id,
            },
          },

          (response) => {
            if (response) {
              if (response.responseCode == "200") {
                notify(response.responseDesc, "success");
                setSites(sites.filter((_, i) => i !== index));
              } else {
                notify(response.responseDesc, "error");
              }
            }
          }
        );
      }
    } catch (error) {
      console.error("Error saving/updating site:", error.response);
    }
  };
  const dotMenuOpen = () => {
    if (isDotOpen === false) {
      setIsDotOpen(true);
    } else {
      setIsDotOpen(false);
    }
  };

  const saveSite = async (index) => {
    const site = sites[index];
    const url = site.isNew ? "v1/site/create" : "v1/site/update";

    if (!site.name) {
      alert("Site name cannot be empty!");
      return;
    }

    try {
      await sendRequest(
        {
          url,
          method: "POST",
          accessToken: authData.accessToken,
          headers: {
            userName: authData.userName,
            organizationId: authData.organizationId,
            deviceId: authData.deviceId,
          },
          payload: {
            ...(site.isNew ? {} : { siteId: site.id }),
            siteName: site.name,
            ...(site.isNew && { siteLabel: "Site" }),
          },
        },

        (response) => {
          if (response) {
            if (response.responseCode == "200") {
              notify(response.responseDesc, "success");
              const { siteId, name, label, membersCount } =
                response.data.siteDetails;

              const updatedSites = [...sites];
              if (site.isNew) {
                // Replace the new site with the response data
                updatedSites[index] = {
                  id: siteId,
                  name,
                  label,
                  memberCount: membersCount,
                  isNew: false, // Mark the site as saved
                };
              } else {
                // Update existing site
                updatedSites[index] = {
                  ...updatedSites[index],
                  name,
                  label,
                  memberCount: membersCount,
                };
              }

              setSites(updatedSites);
              setIsEditing(null); // Exit editing mode
            } else {
              notify(response.responseDesc, "error");
            }
          }
        }
      );
    } catch (error) {
      console.error("Error saving/updating site:", error.response);
    }
  };

  const getOrganizationUsers = async () => {
    try {
      await sendRequest(
        {
          url: "v1/organization/users",
          accessToken: authData.accessToken,
          headers: {
            userName: authData.userName,
            organizationId: authData.organizationId,
            deviceId: authData.deviceId,
          },
        },
        (response) => {
          console.log(response, "users response");
          setUsers(response.data.organizationUsers);
        }
      );
    } catch (error) {
      console.error("Error fetching organization users:", error);
    }
  };

  const getOrganizationSites = async () => {
    try {
      if (authData) {
        await sendRequest(
          {
            url: "v1/organization/sites",
            accessToken: authData.accessToken,
            headers: {
              userName: authData.userName,
              organizationId: authData.organizationId,
              deviceId: authData.deviceId,
            },
          },
          (response) => {
            if (response.data?.sites) {
              const fetchedSites = response.data.sites.map((site) => ({
                id: site.siteId,
                name: site.siteName,
                isNew: false,
                memberCount: site.memberCount,
              }));
              setSites(fetchedSites);
            }
          }
        );
      }
    } catch (error) {
      console.error("Error fetching organization users:", error);
    }
  };
  useEffect(() => {
    getOrganizationSites();
  }, [authData]);

  return (
    <div className="py-4 container mx-auto pe-10">
      {customizeSiteOpen && (
        <CustomizeLabelsModal setCustomizelabel={setCustomizelabel} />
      )}
      {SitePermissionOpen && (
        <SitePermissionsModal setSitePermissionOpen={setSitePermissionOpen} />
      )}
      {/* Header */}
      <div className="flex justify-between items-center py-4 ">
        <h1 className="text-2xl font-bold">Sites</h1>
        <div className="flex items-center space-x-4">
          {/* Search */}
          <button
            className="bg-white hover:bg-[#e0e4ff] border border-[#bfc6d4] text-[#6559ff] px-4 py-2 rounded-lg"
            onClick={() => navigate("/dashboard/site-matrix")}
          >
            Site matrix
          </button>
          {/* Add site */}
          <button
            onClick={addSite}
            className="bg-[#6559ff] hover:bg-[#7470f5] text-white px-4 py-2 rounded-lg"
          >
            + Add site
          </button>
          {/* More Options */}
          <button
            className="p-2 rounded-md border border-gray-300"
            onClick={() => dotMenuOpen()}
          >
            <FiMoreVertical />
          </button>
          <div className="relative">
            {isDotOpen && (
              <div className="absolute z-50 text-start space-y-3 ps-4 py-3 cursor-pointer left-[-250px] right-0 mt-10 bg-white w-[270px]">
                <p onClick={() => navigate("/dashboard/items-without-site")}>
                  Items without sites
                </p>
                <p onClick={() => navigate("/dashboard/bulk-upload-sites")}>
                  Bulk Uplaod sites (CSV)
                </p>
                <p>Downlaod sites (CSV)</p>
                <p
                  onClick={() => {
                    setCustomizelabel(true);
                    setIsDotOpen(false);
                  }}
                >
                  Customize site labels
                </p>
                <p
                  onClick={() => {
                    setSitePermissionOpen(true);
                    setIsDotOpen(false);
                  }}
                >
                  Site selection settings
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="flex ">
        <div className="flex items-center border border-gray-300 rounded-md px-2 py-1">
          <FaSearch className="text-gray-500" />
          <input
            type="text"
            placeholder="Search"
            className="ml-2 outline-none"
            value={searchSite} // Bind input to search query
            onChange={(e) => setSearchSite(e.target.value)} // Update search query
          />
        </div>
        {/* Filter */}
        <button className="text-[#4740d4]  hover:bg-[#ecedfe] ms-3 rounded-lg  px-4 py-2">
          + Add filter
        </button>
      </div>

      {/* Table */}
      <div className="mt-4">
        <table className="min-w-full bg-white shadow-md">
          <thead>
            <tr className="bg-gray-100 text-left">
              <th className="p-4 text-sm font-normal text-left">Site name</th>
              <th className="p-4 text-sm font-normal text-center">Members</th>
            </tr>
          </thead>
          <tbody>
            {filteredSites.length === 0 ? (
              <tr>
                <td colSpan="2" className="p-3 text-center text-gray-500">
                  No records found
                </td>
              </tr>
            ) : (
              filteredSites.map((site, index) => (
                <tr key={index} className="border-t">
                  <td className="p-3 w-[60%]">
                    {isEditing === index || site.isNew ? (
                      <div className="flex justify-between items-center w-full">
                        <input
                          type="text"
                          value={site.name}
                          onChange={(e) => {
                            const updatedSites = [...filteredSites];
                            updatedSites[index].name = e.target.value;
                            setSites(updatedSites);
                          }}
                          className="border-transparent rounded-md px-2 py-1 w-full"
                          placeholder="Enter name..."
                        />
                        <div className="flex">
                          <button
                            onClick={() => saveSite(index)}
                            className="text-green-500 ml-2"
                          >
                            <FiCheck size={22} />
                          </button>
                          <button
                            onClick={() => removeSite(index)}
                            className="text-red-500 ml-2"
                          >
                            <FiX size={22} />
                          </button>
                        </div>
                      </div>
                    ) : (
                      <div className="flex items-center justify-between">
                        <div className="text-base">{site.name}</div>
                        <div className="flex">
                          <button className="text-gray-500 ml-2">
                            <HiUserAdd
                              size={21}
                              onClick={() => openModal(site)} // Pass the selected site
                            />
                          </button>
                          <button
                            onClick={() => setIsEditing(index)}
                            className="text-gray-500 ml-2"
                          >
                            <MdEdit size={21} />
                          </button>
                          <button className="text-gray-500 ml-2">
                            <FaPlus
                              size={21}
                              onClick={() => {
                                toggleLevelMenu(index);
                                setIsLevelOpen(true);
                              }}
                            />
                          </button>
                          {levelMenuOpenIndex === index && (
                            <div className="absolute mt-8 p-4 rounded-md shadow-lg bg-white z-10">
                              <p
                                className="text-sm mb-3 text-start cursor-pointer"
                                onClick={() =>
                                  addSiteAtPosition(index, "above")
                                }
                              >
                                Add level above (Area)
                              </p>
                              <p
                                className="text-sm text-start mb-3 cursor-pointer"
                                onClick={() =>
                                  addSiteAtPosition(index, "below")
                                }
                              >
                                Add same level (site)
                              </p>
                              <p
                                className="text-sm text-start"
                                onClick={() =>
                                  addSiteAtPosition(index, "below")
                                }
                              >
                                Add level below
                              </p>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </td>

                  <td className="p-3 text-center">
                    <div>{site.memberCount}</div>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>

      {/* Modal Overlay */}
      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-end">
          {/* Modal Content */}
          <div className="bg-[#e9edf6] w-full max-w-md h-full p-6 relative">
            <h2 className="text-lg font-semibold mb-4 text-start">
              Add users to site
            </h2>

            {/* Select Box with Search Bar Inside */}
            <div className="mb-4 relative bg-white px-4 pb-4">
              <label className="block text-sm font-medium mb-2 text-start py-3">
                Add to {selectedSite.name}
              </label>
              <div className="relative border border-gray-300 rounded-lg">
                {/* Search Bar Inside Select */}

                <button
                  onClick={() => {
                    setIsOpen(!isOpen);
                    getOrganizationUsers();
                  }}
                  className="inline-flex justify-between w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none"
                >
                  {selectedUser
                    ? `${selectedUser.firstName} ${selectedUser.lastName}`
                    : "Select users ...."}
                  <svg
                    className="ml-2 -mr-1 h-5 w-5"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>

                {/* Dropdown panel */}
                {isOpen && (
                  <div className="origin-top-right absolute mt-2 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10">
                    <div className="p-4">
                      {/* Search Input with Search Icon */}
                      <div className="relative mb-4">
                        <input
                          type="text"
                          value={searchUser}
                          onChange={(e) => setSearchUser(e.target.value)}
                          placeholder="Search..."
                          className="w-full border border-gray-300 rounded-md px-4 py-2 pr-10 focus:outline-none focus:ring-2 focus:ring-blue-600"
                        />
                        <svg
                          className="absolute right-3 top-3 h-5 w-5 text-gray-500"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fillRule="evenodd"
                            d="M8 4a6 6 0 100 12A6 6 0 008 4zm4.707 10.293a1 1 0 00-1.414-1.414L9 10.586V9a1 1 0 10-2 0v2a1 1 0 00.293.707l2.586 2.586a1 1 0 001.414 0z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </div>

                      {/* List of options */}
                      <ul className="max-h-40 overflow-auto">
                        {filteredUsers.length > 0 ? (
                          filteredUsers.map((user, index) => (
                            <li
                              key={index}
                              className="cursor-pointer px-4 py-2 hover:bg-blue-100"
                              onClick={() => {
                                setSearchUser("");
                                addUsertoSiteHandler(user.user);
                                setIsOpen(false); // Close dropdown on selection
                              }}
                            >
                              {user.user.firstName} {user.user.lastName}
                              {/* Set a strong text color */}
                            </li>
                          ))
                        ) : (
                          <li className="px-4 py-2 text-gray-500">
                            No results found
                          </li>
                        )}
                      </ul>

                      {/* Done Button */}
                      <button
                        onClick={() => {
                          setIsOpen(false);
                          setSearchUser("");
                        }}
                        className="mt-4 w-fit flex justify-end p-3 bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700 text-sm"
                      >
                        Done
                      </button>
                    </div>
                  </div>
                )}
              </div>
              <div className="bg-white">
                <p className="text-sm text-start pt-4 flex">
                  <AiOutlineIssuesClose className="me-2 " size={24} /> Any
                  members of this site will inherit the membership of all sites
                  nested below
                </p>
                <p className="pt-3 text-sm hover:underline text-[#4740d4] text-start ps-6">
                  Learn more about site membership
                </p>
              </div>
            </div>

            {/* Done Button at Bottom */}
            <div className="flex">
              {/* Save and Apply Button */}
              <button
                className={`bg-[#f8f9fc] font-bold py-2 px-4 rounded-xl ${
                  selectedUser
                    ? "text-[#4740d4] opacity-100 cursor-pointer hover:bg-[#e0e4ff]"
                    : "text-[#8d98a8] opacity-50 cursor-not-allowed"
                }`}
                disabled={!selectedUser} // Disabled when selectedUser is null
                onClick={selectedUser ? addUsertoSite : null} // Call function if selectedUser exists
              >
                Save and Apply
              </button>

              {/* Cancel Button */}
              <button
                className="!bg-white text-[#4740d4] font-bold py-2 px-4 rounded-xl opacity-50 border border-[#bfc6d4] hover:bg-[#e0e4ff]"
                onClick={closeModal}
              >
                Cancel
              </button>
            </div>

            {/* Close Modal */}
            <button
              onClick={closeModal}
              className="absolute top-4 right-4 text-gray-600 hover:text-gray-800"
            >
              &#10005;
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default AddSites;
