import ManufacturingHero from "../../../assets/manufacturing/manufacturingTrainingImage.webp";
import TextImageFlex from "../../../shared/textImageFlex";
import { useTranslation } from "react-i18next";
import i18next from "i18next"; // Import i18next to check the current language

const Onboard = () => {
  const { t } = useTranslation();
  const isArabic = i18next.language === "ar";

  return (
    <div
      className={`py-4 ${isArabic ? "text-right" : "text-left"} ${
        isArabic ? "rtl" : ""
      }`}
    >
      <TextImageFlex
        heading={t("manufacturingpage.Onboardheading")}
        para={t("manufacturingpage.Onboardpara")}
        img={ManufacturingHero}
        widhtClass="w-[98%]"
      />
    </div>
  );
};

export default Onboard;
