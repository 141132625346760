import React from "react";
import { useTranslation } from "react-i18next";
import "./TermsCondition.css";
import i18next from "i18next";
const TermsCondition = () => {
  const { t } = useTranslation();
  const isArabic = i18next.language === "ar";
  return (
    <div className="container">
      <div className="terms-container ">
        <p className="heading">{t("termandcondition.termandcondition")}</p>
      </div>
      <div className={` ${isArabic ? "text-right rtl" : "text-left"}`}>
        <div className="overview-container">
          <h2>{t("termandcondition.heading")}</h2>
          <p className="paragraph">{t("termandcondition.overviewpara1")}</p>
          <p className="paragraph">{t("termandcondition.overviewpara2")}</p>
          <p className="paragraph">{t("termandcondition.overviewpara3")}</p>
          <p className="paragraph">{t("termandcondition.overviewpara4")}</p>
          <p className="paragraph">{t("termandcondition.overviewpara5")}</p>
        </div>
        <div className="overview-container-2">
          <h2>{t("termandcondition.accountheading")}</h2>
          <p className="paragraph">{t("termandcondition.accountpara1")}</p>
          <p className="paragraph">{t("termandcondition.accountpara2")}</p>
          <p className="paragraph">{t("termandcondition.accountpara3")}</p>
          <p className="paragraph">{t("termandcondition.accountpara4")}</p>
        </div>
        <div className="overview-container-3">
          <h2>{t("termandcondition.accountcancellationheading")}</h2>
          <p className="paragraph">
            {t("termandcondition.accountcancellationpara1")}
          </p>
          <p className="paragraph">
            {t("termandcondition.accountcancellationpara2")}
          </p>
        </div>
        <div className="overview-container-4">
          <h2>{t("termandcondition.confidentialityheading")}</h2>
          <p className="paragraph">
            {t("termandcondition.confidentialitypara1")}
          </p>
          <p className="paragraph">
            {t("termandcondition.confidentialitypara2")}
          </p>
        </div>
        <div className="overview-container-5">
          <h2>{t("termandcondition.dataprivacyheading")}</h2>
          <p className="paragraph">{t("termandcondition.dataprivacypara1")}</p>
        </div>
        <div className="overview-container-6">
          <h2>{t("termandcondition.permittedheading")}</h2>
          <p className="paragraph">{t("termandcondition.permittedpara")}</p>
          <ul>
            <li>{t("termandcondition.permittedli1")}</li>
            <li>{t("termandcondition.permittedli2")}</li>
            <li>{t("termandcondition.permittedli3")}</li>
            <li>{t("termandcondition.permittedli4")}</li>
            <li>{t("termandcondition.permittedli5")}</li>
            <li>{t("termandcondition.permittedli6")}</li>
            <li>{t("termandcondition.permittedli7")}</li>
            <li>{t("termandcondition.permittedli8")}</li>
            <li>{t("termandcondition.permittedli9")}</li>
          </ul>
        </div>
        <div className="overview-container-7">
          <h2>{t("termandcondition.userheading")}</h2>
          <p className="paragraph">{t("termandcondition.userpara1")}</p>
          <p className="paragraph">{t("termandcondition.userpara2")}</p>
          <h3 className="following">{t("termandcondition.userfollowing")}</h3>
          <ul>
            <li>{t("termandcondition.userli1")}</li>
            <li>{t("termandcondition.userli2")}</li>
            <li>{t("termandcondition.userli2")}</li>
            <li>{t("termandcondition.userli3")}</li>
            <li>{t("termandcondition.userli4")}</li>
          </ul>
        </div>
        <div className="overview-container-8">
          <h2>{t("termandcondition.privacyheading")}</h2>
          <p className="paragraph">{t("termandcondition.privacypara")}</p>
        </div>
        <div className="overview-container-9">
          <h2>{t("termandcondition.Third-Partyheading")}</h2>
          <p className="paragraph">{t("termandcondition.Third-Partypara1")}</p>
          <p className="paragraph">{t("termandcondition.Third-Partypara2")}</p>
          <h3 className="precaution">
            {t("termandcondition.Third-Partypara3")}
          </h3>
          <p className="paragraph">{t("termandcondition.Third-Partypara4")}</p>
        </div>
        <div className="overview-container-10">
          <h2>{t("termandcondition.Communicationheading")}</h2>
          <p className="paragraph">
            {t("termandcondition.Communicationpara1")}
          </p>
          <p className="paragraph">
            {t("termandcondition.Communicationpara2")}
          </p>
        </div>
        <div className="overview-container-11">
          <h2>{t("termandcondition.Closeingheading")}</h2>
          <p className="paragraph">{t("termandcondition.closingpara1")}</p>
          <p className="paragraph">{t("termandcondition.closingpara2")}</p>
          <p className="paragraph">{t("termandcondition.closingpara3")}</p>
          <ul>
            <li>{t("termandcondition.closingli1")}</li>
            <li>{t("termandcondition.closingli2")}</li>
            <li>{t("termandcondition.closingli3")}</li>
          </ul>
        </div>
        <div className="overview-container-12">
          <h2>{t("termandcondition.disclaimerheading")}</h2>
          <p className="paragraph">{t("termandcondition.disclaimerpara")}</p>
          <ul>
            <li>{t("termandcondition.disclaimerli1")}</li>
            <li>{t("termandcondition.disclaimerli2")}</li>
            <li>{t("termandcondition.disclaimerli3")}</li>
            <li>{t("termandcondition.disclaimerli4")}</li>
          </ul>
          <p className="paragraph">{t("termandcondition.disclaimerpara2")}</p>
          <p className="paragraph">{t("termandcondition.disclaimerpara3")}</p>
        </div>
      </div>

      {/* Table of Contents Section */}
    </div>
  );
};

export default TermsCondition;
