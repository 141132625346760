import TestimonialCard from "../../../shared/testimonialCard";
const Testimonial = () => {
  return (
    <div className="py-24 bg-[#F3F6FB]">
        <h1 className="text-center font-black text-xl md:text-5xl mb-14">How we’re keeping our customers safe</h1>
      <TestimonialCard
        para=" The SWMS documents were extremely thorough and well researched. It saved me an extraordinary amount of time and met all of the required criteria for my trade. The documents are easily reviewed and updated onsite as further risks or hazards are identified. They are easy to understand and very professional. Highly recommended!"
        name="Christopher Zeidler"
        post="Protek Electric Services"
      />
    </div>
  );
};
export default Testimonial;
