import React, { useState } from "react";
import { BiArrowBack } from "react-icons/bi";
import { FiPlusCircle, FiPenTool, FiUploadCloud } from "react-icons/fi";
import DescribetopicModal from "./describetopic";
import { Link } from "react-router-dom";
const Createtemplate = () => {
  const [describeTopicOpen, setDescribetopicOpen] = useState(false);
  const templates = [
    {
      title: "ISO 9001:2015 Audit Checklist",
      downloads: "14,057 downloads",
      createdBy: "SafetyCulture Staff",
    },
    {
      title: "Construction Site HSE Audit UK",
      downloads: "29,944 downloads",
      createdBy: "Corsan Northern Ltd",
    },
    {
      title: "Light Vehicle Safety Inspection",
      downloads: "37,477 downloads",
      createdBy: "Western Power",
    },
    {
      title: "JOB SAFETY ANALYSIS (JSA) FORM",
      downloads: "9,719 downloads",
      createdBy: "Aurora Mechanical Ltd",
    },
  ];

  return (
    <div className="p-4 md:p-8 bg-gray-100 ">
      {describeTopicOpen && <DescribetopicModal />}
      {/* Back icon */}
      <div className="text-gray-600 mb-6">
        <BiArrowBack size={24} />
      </div>

      <div className="container mx-auto w-full md:w-[73%]">
        <div className=" grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 mb-8">
          <div className="flex flex-col items-center justify-center bg-[#e0e4ff] rounded-md p-6 text-center">
            <FiPlusCircle size={30} className="text-[#6659ff] mb-4" />
            <h3 className="text-base font-semibold text-[#6659ff]">
              <Link to={"/createTamplateBlank"}>Blank template</Link>
            </h3>
          </div>
          <div
            className="flex flex-col items-center justify-center bg-[#dbf8ff] rounded-md p-6 text-center"
            onClick={() => setDescribetopicOpen(true)}
          >
            <FiPenTool size={30} className="text-[#0d75b5] mb-4" />
            <h3 className="text-base font-semibold text-[#0d75b5]">
              Describe topic
            </h3>
          </div>
          <div className="flex flex-col items-center justify-center bg-[#fff1cc] rounded-md p-6 text-center">
            <FiUploadCloud size={30} className="text-[#9e4a00] mb-4" />
            <h3 className="text-base font-semibold text-[#9e4a00]">
              Convert an image/PDF
            </h3>
          </div>
        </div>
        {/* Text and input */}
        <h2 className="text-lg text-start text-gray-700 mb-2">
          Need inspiration?
        </h2>
        <p className="text-gray-500 text-lg text-start font-[600]  mb-4">
          Find and customize a template from the Public Library
        </p>

        {/* Search and Filter */}
        <div className="flex flex-col md:flex-row gap-4 mb-8">
          <input
            type="text"
            placeholder="Search inspection templates"
            className="flex-1 p-3 border border-gray-300 rounded-md outline-none"
          />
          <select className="flex-1 md:max-w-xs p-3 border border-gray-300 rounded-md outline-none">
            <option>Filter by industry</option>
            <option>Agriculture</option>
            <option>Cleaning</option>
            <option>Community services</option>
            <option>Construction</option>
            <option>Dept of Defence</option>
            <option>Education</option>
            <option>Emergency services</option>
            <option>Financial services</option>
            {/* Add more options as needed */}
          </select>
        </div>

        {/* Template List */}
        <div>
          {templates.map((template, index) => (
            <div
              key={index}
              className="p-4 border border-gray-200 text-start bg-white"
            >
              <h3 className="text-[#6659ff] ">{template.title}</h3>
              <p className="text-gray-500 text-sm">
                Created by {template.createdBy} · {template.downloads}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Createtemplate;
