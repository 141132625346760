import FlexContent from "../../../shared/flexContent";
import FacilitiesImage from "../../../assets/mining/Inspection-Reporting-Software-img-03.webp";
const SafeWorkSpaces = () => {
  return (
    <div className="bg-[#F3F6FB] pt-4 pb-24">
      <FlexContent
        heading="Boost productivity on site"
        para="From maintaining village facilities to assessing heritage land, every day presents new challenges across a mining operation. Keep everyone informed and aligned by digitizing processes and procedures such as vehicle and drill pre-starts, site safety assessments, statutory reports, and inductions. The moment something changes you can adapt in a few clicks, with changes rolling out instantly to everyone on site."
        img={FacilitiesImage}
        italicText="“I can do easily seven or eight times the inspections I used to do. Before SafetyCulture platform, it would take me three to four hours to do a report. Now, it takes me minutes.”"
        name="Andrew Johnston"
        position="Safety Officer, Newmont (formally Goldcorp)"
      />
    </div>
  );
};
export default SafeWorkSpaces;
