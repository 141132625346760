// src/components/Layout.js
import React from "react";
import { useLocation } from "react-router-dom";
import Header from "../header";
import Footer from "../footer/Footer";

const Layout = ({ children }) => {
  const location = useLocation();

  // Check if the current route is login or signup
  const hideLayout =
    location.pathname === "/login" ||
    location.pathname === "/signup" ||
    location.pathname === "/createTamplateBlank" ||
    location.pathname === "/forgot-password" ||
    location.pathname.includes("/reset-password");

  return (
    <div className="layout">
      {/* Conditionally render the Header and Footer based on the route */}
      {!hideLayout && <Header />}

      {/* Main content */}
      <main className={`content ${!hideLayout ? "mt-[100px]" : ""}`}>
        {children}
      </main>

      {/* Conditionally render the Footer based on the route */}
      {!hideLayout && <Footer />}
    </div>
  );
};

export default Layout;
