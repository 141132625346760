import React, { useState } from "react";
import Filter from "./filter";
const ScheduleImage = require("../../../../assets/schedule/2.jpg");

const Manage = () => {
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const toggleFilterDropdown = () => {
    setIsFilterOpen(!isFilterOpen);
  };

  return (
    <div role="tabpanel" className="px-2 sm:px-4 md:px-8 lg:px-12">
      <div className="mt-6 flex flex-col sm:flex-row sm:items-center space-y-4 sm:space-y-0 sm:space-x-4">
        <input
          type="text"
          placeholder="Search"
          className="border rounded-md p-2 w-full sm:w-64"
        />
        <div>
          <Filter
            isFilterOpen={isFilterOpen}
            toggleFilterDropdown={toggleFilterDropdown}
          />
        </div>
      </div>

      <div className="mt-4 rounded-md overflow-x-auto">
        <table className="w-full min-w-max text-left border border-gray-300">
          <thead>
            <tr className="bg-indigo-50 text-gray-700 font-thin h-14">
              <th className="p-2 text-xs sm:text-sm md:text-base">Title</th>
              <th className="p-2 text-xs sm:text-sm md:text-base">
                Assigned by
              </th>
              <th className="p-2 text-xs sm:text-sm md:text-base">
                Assigned to
              </th>
              <th className="p-2 text-xs sm:text-sm md:text-base">Status</th>
              <th className="p-2 text-xs sm:text-sm md:text-base">Next due</th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td colSpan="5" className="text-center p-4 sm:p-6">
                <div className="flex flex-col items-center">
                  <img
                    src={ScheduleImage}
                    alt="No schedules"
                    className="mb-4 w-20 h-20 sm:w-32 sm:h-32 md:w-40 md:h-40"
                  />
                  <p className="text-gray-600 font-semibold text-sm sm:text-base md:text-lg">
                    No missed schedules
                  </p>
                  <p className="text-gray-500 text-xs sm:text-sm">
                    Yay! No scheduled inspections have been missed.
                  </p>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Manage;
