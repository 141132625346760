import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaEdit } from "react-icons/fa";
import { useAuth } from "../../context/AuthContext";
const usersite = require("../../../assets/usersite/sites.png");

const UserSite = () => {
  const navigate = useNavigate();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false); // State for sidebar visibility
  // const [isAssetDropdownOpen, setIsAssetDropdownOpen] = useState(false);
  const { authData } = useAuth();
  const handleNavigation = (tab) => {
    const routes = {
      "User settings": "/dashboard/user-setting",
      Training: "/dashboard/user-trainning",
      Notifications: "/dashboard/user-notification",
      Devices: "/dashboard/user-device",
      Templates: "/dashboard/user-template",
      Groups: "/dashboard/user-group",
      Sites: "/dashboard/user-site",
      Credentials: "/dashboard/user-credential",
    };
    navigate(routes[tab]);
  };

  const handleSidebarToggle = () => {
    setIsSidebarOpen(!isSidebarOpen); // Toggle sidebar visibility
  };
  const handleBack = () => {
    navigate(-1); // Moves back to the previous page
  };

  const tableHeaders = ["Name", "Label", "Members"];
  return (
    <div className="min-h-screen p-4 mr-10">
      <div className="text-left">
        <button onClick={handleBack} className="text-sm text-gray-500 mb-4">
          &larr; Back
        </button>
      </div>

      {/* Profile Section */}
      <div className="bg-gradient-to-r from-blue-200 to-purple-200 rounded-t-lg p-4 flex items-center relative">
        <div className="w-16 h-16 rounded-full bg-gray-300 flex items-center justify-center text-2xl text-white font-bold relative">
          {(
            authData.userName.charAt(0) +
            authData.userName.charAt(authData.userName.length - 1)
          ).toUpperCase()}
          <FaEdit
            onClick={handleSidebarToggle} // Opens sidebar
            className="absolute bottom-0 right-0 bg-white p-1 rounded-full text-blue-500 cursor-pointer text-lg"
          />
        </div>
        <div className="ml-4">
          <h2 className="text-xl font-semibold"> {authData?.userName} </h2>
          <p className="text-gray-600">{authData?.userDetails?.email}</p>
        </div>
      </div>

      {/* Navigation Tabs */}
      <div className="bg-white border-b flex justify-start items-center space-x-4 px-4 py-2">
        {[
          "User settings",
          "Training",
          "Notifications",
          "Devices",
          "Templates",
          "Groups",
          "Sites",
          "Credentials",
        ].map((tab) => (
          <button
            key={tab}
            onClick={() => handleNavigation(tab)}
            className="text-sm text-gray-600 hover:text-blue-500 mx-4 my-2"
          >
            {tab}
          </button>
        ))}
      </div>

      {/* Search and Buttons Layout */}
      <div className="mt-6">
        {/* Groups heading and Add users to group button */}
        <div className="flex flex-col sm:flex-row sm:items-center space-y-4 sm:space-y-0 sm:space-x-4">
          <div className="flex items-center sm:w-1/2 justify-start">
            <h2 className="text-lg font-semibold">Sites</h2>
          </div>

          {/* Add users to group button */}
          <div className="flex items-center sm:w-1/2 justify-end">
            <button
              onClick={handleSidebarToggle} // Open sidebar
              className="bg-indigo-500 text-white px-4 py-2 rounded-md"
            >
              Add users to Sites
            </button>
          </div>
        </div>

        <div className="mt-4 flex items-left">
          <input
            type="text"
            placeholder="Search"
            className="border rounded-md p-2 w-full sm:w-64"
          />
        </div>
      </div>

      {/* Table Section */}
      <div className="mt-4 rounded-md overflow-x-auto">
        <table className="w-full min-w-max text-left border border-gray-300">
          <thead>
            <tr className="bg-indigo-50 text-gray-700 font-thin h-14">
              {tableHeaders.map((header) => (
                <th
                  key={header}
                  className="w-32 p-2 text-xs sm:text-sm md:text-base"
                >
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td colSpan="5" className="text-center p-4 sm:p-6">
                <div className="flex flex-col items-center">
                  <img
                    src={usersite}
                    alt="No schedules"
                    className="mb-4 w-20 h-20 sm:w-32 sm:h-32 md:w-40 md:h-40"
                  />
                  <p className="text-gray-600 font-semibold text-sm sm:text-base md:text-lg">
                    You are not a member of any sites.
                  </p>
                  <p className="text-gray-500 text-xs sm:text-sm">
                    Site membership gives users a more relevant experience when
                    selecting sites in inspections, actions, issues, and more.
                  </p>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      {/* Sidebar (Right side) */}
      {isSidebarOpen && (
        <div className="fixed top-0 right-0 w-1/3 bg-gray-100 h-full shadow-lg z-50 transition-transform transform translate-x-0">
          <div className="p-4">
            <h2 className="text-xl font-semibold">Add Users to Site</h2>
            {/* Sidebar content */}
            <div className="relative mt-12 bg-white">
              <div className="flex flex-col items-center">
                <img
                  src={usersite}
                  alt="No schedules"
                  className="mb-4 mt-4 w-20 h-20 sm:w-32 sm:h-32 md:w-40 md:h-40"
                />
                <p className="text-gray-600 font-semibold text-sm sm:text-base md:text-lg">
                  Your team has not created any sites
                </p>
                <p className="text-gray-500 text-xs sm:text-sm mb-4">
                  Add sites to help your team manage and analyze the locations,
                  projects, or assets that matter the most to your organization.
                  Site membership makes it easier for users to select the right
                  site, and find information relevant to them.
                </p>
              </div>
            </div>
            <div className="mt-4">
              <button
                onClick={handleSidebarToggle}
                className="bg-gray-300 text-gray-700 rounded-md px-4 py-2"
              >
                Close Sidebar
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserSite;
