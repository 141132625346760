import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

const HeroFlex = ({
  span,
  heading,
  para,
  btn1,
  btn1Navigate,
  btn2Navigate,
  btn2,
  img,
  iconbtn,
}) => {
  const location = useLocation();
  const navigate = useNavigate();

  const renderButtonWithIcon = () => {
    if (
      location.pathname === "/safety-docs" ||
      location.pathname === "/secure-care"
    ) {
      return (
        <div>
          <a
            href="#"
            className="bg-[#6559FF] text-white text-[1rem] font-medium py-3 px-4 rounded-xl hover:bg-[#4740d4] transition-colors inline-flex items-center"
          >
            {iconbtn}
            <span className="ml-1">
              <svg
                clipRule="evenodd"
                fill="none"
                fillRule="evenodd"
                height="16"
                viewBox="0 0 24 24"
                width="16"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.79293 16.2071C7.4024 15.8166 7.4024 15.1834 7.79292 14.7929L13.0856 9.50015H10C9.44772 9.50015 9 9.05244 9 8.50015C9 7.94787 9.44772 7.50015 10 7.50015L15.5 7.50015C16.0523 7.50015 16.5 7.94787 16.5 8.50015V14.0002C16.5 14.5524 16.0523 15.0002 15.5 15.0002C14.9477 15.0002 14.5 14.5524 14.5 14.0002L14.5 10.9142L9.20714 16.2071C8.81662 16.5976 8.18345 16.5976 7.79293 16.2071Z"
                  fill="currentColor"
                ></path>
                <path
                  d="M20 1C21.6569 1 23 2.34315 23 4L23 20C23 21.6569 21.6569 23 20 23H4C2.34315 23 1 21.6569 1 20L1 4C1 2.34315 2.34315 1 4 1L20 1ZM21 4C21 3.44772 20.5523 3 20 3L4 3C3.44772 3 3 3.44772 3 4L3 20C3 20.5523 3.44772 21 4 21L20 21C20.5523 21 21 20.5523 21 20L21 4Z"
                  fill="currentColor"
                ></path>
              </svg>
            </span>
          </a>
        </div>
      );
    }
    return (
      <button
        className="bg-[#6559FF] text-white py-3 px-6 rounded-lg hover:bg-blue-700"
        onClick={() => btn1Navigate && navigate(btn1Navigate)}
      >
        {btn1}
      </button>
    );
  };

  const renderStoreBadges = () => {
    if (
      location.pathname === "/inspection" ||
      location.pathname === "/integrations" ||
      location.pathname === "/assets"
    ) {
      return (
        <></>
        // <div className="flex space-x-4">
        //   <img
        //     src="https://developer.apple.com/assets/elements/badges/download-on-the-app-store.svg"
        //     alt="App Store"
        //     className="w-32"
        //   />
        //   <img
        //     src="https://upload.wikimedia.org/wikipedia/commons/7/78/Google_Play_Store_badge_EN.svg"
        //     alt="Google Play"
        //     className="w-32"
        //   />
        // </div>
      );
    }
    return null;
  };

  return (
    <section className="bg-[#F3F6FB] md:py-16 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto flex flex-col md:flex-row items-center justify-between">
        {/* Left Side (Text and Buttons) */}
        <div className="lg:w-1/2 text-start space-y-6">
          {/* Conditionally render the span */}
          {(location.pathname === "/assets" ||
            location.pathname === "/integrations" ||
            location.pathname === "/safety-docs" ||
            location.pathname === "/secure-care") && (
            <span className="inline-block border-t border-b border-r border-[3px] border-l-[.1875rem] border-[#293745] text-sm text-[#293745] px-3 py-1 rounded-full">
              {span}
            </span>
          )}

          {/* Heading */}
          <h1
            className={`text-[2.2rem] md:text-[3.2rem] lg:text-[3.8125rem] font-bold text-[#293745] md:leading-[77px] ${
              location.pathname === "/inspection" ? "pt-5" : ""
            }`}
          >
            {heading}
          </h1>
          <p className="text-md md:text-[1.25rem] text-[#293745]">{para}</p>

          {/* Buttons */}
          <div className="flex space-x-4 mb-2 flex-wrap">
            {renderButtonWithIcon()}

            {/* Render secondary button only for specific paths */}
            {(location.pathname === "/inspection" ||
              location.pathname === "/integrations") && (
              <button
                className="border border-[#d0d7e1] font-medium text-[#6559FF] py-3 px-6 rounded-lg hover:bg-[#f1ebff]"
                onClick={() => btn2Navigate && navigate(btn2Navigate)}
              >
                {btn2}
              </button>
            )}
          </div>

          {/* Store Badges */}
          {renderStoreBadges()}
        </div>

        {/* Right Side (Image) */}
        <div className="lg:w-1/2 mb-12 lg:mb-0">
          <div className="relative flex justify-center md:justify-end">
            <img
              src={img}
              alt="Inspecting"
              className="rounded-xl w-[84%] md:w-full"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroFlex;
