import React from "react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import CustomTemplate from "../../../assets/inspection/Template-library.webp";
import { FaArrowRight, FaArrowLeft } from "react-icons/fa";

const CustomizeTemplate = () => {
  const isArabic = i18next.language === "ar";
  const { t } = useTranslation();

  return (
    <div
      className={` ${isArabic ? "text-right" : "text-left"} ${
        isArabic ? "rtl" : ""
      }`}
    >
      <section className="py-10 px-4 sm:px-6 lg:px-8">
        <div className="max-w-7xl mx-auto flex flex-col-reverse lg:flex-row items-center justify-between">
          {/* Left Side (Text and Buttons) */}
          <div className={`lg:w-1/2 space-y-6`}>
            <h1 className="text-[2.2rem] md:text-[3.2rem] lg:text-[2.65rem] font-bold text-[#293745] md:leading-[53px]">
              {t("inspectionpage.customizetempheading")}
            </h1>
            <p className="text-md md:text-[1.1rem] text-[#293745]">
              {t("inspectionpage.customizetemppara")}
            </p>
            <button className="bg-transparent flex font-[500] text-[1rem] text-[#4740d4] hover:underline pt-3 ps-3">
              {t("inspectionpage.customisetempbtn")}{" "}
              {isArabic ? (
                <FaArrowLeft className="mt-[3px] ms-2" />
              ) : (
                <FaArrowRight className="mt-[3px] ms-2" />
              )}
            </button>
          </div>
          {/* Right Side (Image) */}
          <div className="lg:w-1/2 mb-12 lg:mb-0">
            <div className="relative flex justify-center md:justify-end">
              <img
                src={CustomTemplate}
                alt="Inspecting"
                className="rounded-xl w-[91%] h-full"
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default CustomizeTemplate;
