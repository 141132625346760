import React, { useState, useEffect, useRef } from "react";
import { FaChevronDown, FaBars, FaTimes } from "react-icons/fa";
import { IoIosArrowDown, IoIosLogOut, IoIosArrowUp } from "react-icons/io";
import { RxCross2 } from "react-icons/rx";
import i18n from "../../i18n/i18n";
import Logo from "../../assets/home/logoSSC.jpg";
import { GoArrowRight } from "react-icons/go";
import { GoChecklist } from "react-icons/go";
import { IoCubeOutline } from "react-icons/io5";
import { RiGraduationCapLine } from "react-icons/ri";
import { FaChevronUp } from "react-icons/fa";
import TawkMessengerReact from "@tawk.to/tawk-messenger-react";
import Operations from "../../assets/home/operations-platform-updated.webp";
import SuadiaFlag from "../../assets/home/suadiaflag.png";
import { IoLogoCapacitor } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
const Header = () => {
  const { t } = useTranslation();
  const isArabic = i18next.language === "ar";
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isDropdown2Open, setIsDropdown2Open] = useState(false);
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [mobileProduct, setMobileProduct] = useState(false);
  const [mobileSolution, setMobileSolution] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState("Arabic");
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const savedLanguage = localStorage.getItem("i18nextLng");
    if (savedLanguage) {
      const language = savedLanguage === "en" ? "English" : "Arabic";
      setSelectedLanguage(language); // Update state with the saved language
      i18n.changeLanguage(savedLanguage); // Apply saved language to i18n
    }
  }, [i18n]);

  const toggleDropdownlang = () => {
    setIsOpen(!isOpen);
  };

  // Close the dropdown if clicked outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const usaFlag = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 7410 3900"
      className="w-6 h-6 mr-2"
    >
      <rect width="7410" height="3900" fill="#b22234" />
      <path
        fill="#fff"
        d="M0 450h7410v300H0zm0 600h7410v300H0zm0 600h7410v300H0zm0 600h7410v300H0zm0 600h7410v300H0zm0 600h7410v300H0z"
      />
      <path fill="#3c3b6e" d="M0 0h2964v2100H0z" />
      <g fill="#fff">
        <g id="d">
          <g id="c">
            <g id="e">
              <g id="b">
                <path
                  id="a"
                  d="M247 90l-76.3 234.9L28 114.6l243.6 178.3L29.8 471.7z"
                />
                <use transform="scale(1 -1)" xlinkHref="#a" />
              </g>
              <use transform="translate(247)" xlinkHref="#b" />
            </g>
            <use transform="translate(494)" xlinkHref="#b" />
          </g>
          <use transform="translate(0 210)" xlinkHref="#c" />
        </g>
        <use transform="translate(0 420)" xlinkHref="#d" />
        <use transform="translate(0 840)" xlinkHref="#d" />
        <use transform="translate(0 1260)" xlinkHref="#d" />
        <use transform="translate(0 1680)" xlinkHref="#d" />
        <use transform="translate(0 2100)" xlinkHref="#d" />
        <use transform="translate(0 2520)" xlinkHref="#d" />
        <use transform="translate(0 2940)" xlinkHref="#d" />
      </g>
    </svg>
  );

  const saudiFlag = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 3 2"
      className="w-6 h-6 mr-2"
    >
      <rect width="3" height="2" fill="#006c35" />
      <path
        d="M1.5.75h.5c.03.015.057.041.07.07.013.03.013.045.03.075.014.03.014.045.03.07 0 .015-.015.03-.03.045v.01c-.02.03-.047.04-.075.045-.03.005-.045.005-.075 0-.015 0-.03-.015-.045-.015H1.5v-.25zm-.05.55c0-.07 0-.1.05-.1h.15v.1c0 .07 0 .1-.05.1h-.15v-.1z"
        fill="#fff"
      />
    </svg>
  );

  const navigate = useNavigate();
  const handleMouseEnter = (index) => {
    console.log("index", index);
    if (index === 0) {
      setIsDropdownOpen(true);
      setIsDropdown2Open(false);
    } else if (index === 1) {
      setIsDropdown2Open(true);
      setIsDropdownOpen(false);
    }
  };

  const handleMouseLeave = () => {
    setIsDropdownOpen(false);
    setIsDropdown2Open(false);
  };

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };
  const mobileProducthandler = () => {
    if (mobileProduct === false) {
      setMobileProduct(true);
      setMobileSolution(false);
    } else {
      setMobileProduct(false);
    }
  };
  const mobilesSolutionhandler = () => {
    if (mobileSolution === false) {
      setMobileSolution(true);
      setMobileProduct(false);
    } else {
      setMobileSolution(false);
    }
  };
  const closeHandler = () => {
    setMenuOpen(false);
    setMobileSolution(false);
    setMobileProduct(false);
  };

  const handleLanguageChange = (language) => {
    const newLang = language === "English" ? "en" : "ar";
    i18n.changeLanguage(newLang);
    localStorage.setItem("i18nextLng", newLang); // Save the selected language to localStorage
    setSelectedLanguage(language);
    setIsOpen(false); // Close dropdown after selection
  };
  return (
    <div>
      <div className="">
        {/* Other components of your app */}

        <TawkMessengerReact propertyId="property_id" widgetId="default" />
      </div>
      <div
        className={`py-4 ${isArabic ? "text-right" : "text-left"} ${
          isArabic ? "rtl" : ""
        }`}
      >
        <header className=" border-b-2 w-full bg-white px-4 h-[99.5px] w-full fixed top-0 left-0 right-0  z-50 lg:px-4.5 bg-white flex items-center justify-between">
          {/* Left: Logo */}
          <div className="flex">
            <div className="flex items-center ">
              <img
                src={Logo}
                alt="Logo"
                className="w-24 md:w-48  h-16 "
                onClick={() => navigate("/")}
              />
            </div>

            {/* Middle: Navigation */}
            <ul className="hidden lg:flex items-center space-x-5 text-black font-bold ms-14 pt-9 list-none">
              {["Product", "Solutions", "Support", "Pricing"].map(
                (item, index) => (
                  <li
                    key={item}
                    className="relative group cursor-pointer"
                    onMouseEnter={() => handleMouseEnter(index)}
                    onMouseLeave={handleMouseLeave}
                  >
                    <button
                      onClick={() =>
                        navigate(
                          index === 2
                            ? "/contact-us"
                            : index === 3
                            ? "/pricing"
                            : "/"
                        )
                      }
                      className="flex items-center hover:text-[#5f5bd7] pb-8  border-b-[4px] border-transparent hover:border-b-[4px] hover:border-[#5f5bd7]"
                    >
                      {t(item)}
                      {(index === 0 || index === 1) && (
                        <span>
                          {isDropdownOpen && index === 0 ? (
                            <IoIosArrowUp className="ml-1 !font-[300]" />
                          ) : (
                            <IoIosArrowDown className="ml-1 !font-[300]" />
                          )}
                        </span>
                      )}
                    </button>

                    {/* Full-page dropdown for 'Product' */}
                    {isDropdownOpen && index === 0 && (
                      <div
                        className={`absolute inset-x-0 top-full w-screen px-4 lg:px-8 bg-[#f3f6fb] h-fit flex flex-col lg:flex-row justify-start py-6 lg:py-12 rounded-lg shadow-lg z-20 ${
                          isArabic ? "text-right" : "text-left"
                        }`}
                      >
                        <div className="flex justify-between space-x-8">
                          <div className="group  p-4 w-[560px] h-[338px] rounded-xl px-6 py-7 border-2 border-transparent hover:border-2 hover:border-[#4740d4]">
                            <div
                              className="flex justify-between w-full cursor-pointer"
                              onClick={() => {
                                navigate("/platform");
                                setIsDropdownOpen(false);
                                setIsDropdown2Open(false);
                              }}
                            >
                              <h1 className="text-[#363834] cursor-pointer">
                                {t("header.producttitle")}
                              </h1>
                              <GoArrowRight size={25} />
                            </div>
                            <p className="text-start text-[14px] pt-4 text-[#5d6361] font-normal">
                              {t("header.productheading")}
                            </p>
                            <div className="flex justify-between">
                              <ul className="space-y-5 pt-5 ps-2">
                                <li
                                  className="text-[#4f504a] flex text-sm hover:text-[#4740d4] hover:bg-[#f3f6fb] cursor-pointer"
                                  onClick={() => {
                                    navigate("/inspection");
                                    setIsDropdownOpen(false);
                                    setIsDropdown2Open(false);
                                  }}
                                >
                                  <GoChecklist
                                    size={16}
                                    className="me-2.5 mt-1"
                                  />{" "}
                                  {t("header.Inspections")}
                                </li>
                                <li
                                  className="text-[#4f504a] flex text-sm cursor-pointer hover:text-[#4740d4]"
                                  onClick={() => {
                                    navigate("/assets");
                                    setIsDropdownOpen(false);
                                    setIsDropdown2Open(false);
                                  }}
                                >
                                  <IoCubeOutline
                                    size={16}
                                    className="me-2.5 mt-1"
                                  />{" "}
                                  {t("header.Assets")}
                                </li>

                                <li
                                  className="text-[#4f504a] flex text-sm hover:text-[#4740d4] cursor-pointer"
                                  onClick={() => {
                                    navigate("/integrations");
                                    setIsDropdownOpen(false);
                                    setIsDropdown2Open(false);
                                  }}
                                >
                                  <IoLogoCapacitor
                                    size={16}
                                    className="me-2.5 mt-1"
                                  />{" "}
                                  {t("header.Integrations")}
                                </li>
                              </ul>
                              <img
                                src={Operations}
                                alt=""
                                className="h-[180px] w-[260px] hover:text-[#4740d4]"
                              />
                            </div>
                          </div>

                          <div>
                            {/* <div
                          className="group border-2 border-transparent hover:border-[#4740d4]  p-4 w-[270px] mb-4 rounded-xl px-6 py-7 h-[160px]"
                          onClick={() => {
                            navigate("/lone-worker");
                            setIsDropdownOpen(false);
                            setIsDropdown2Open(false);
                          }}
                        >
                          <div className="flex justify-between w-full">
                            <h1 className="!text-[#363834] !group-hover:text-[#4740d4]">
                              Lone Worker
                            </h1>
                            <GoArrowRight
                              size={25}
                              className="!text-[#363834] !group-hover:text-[#4740d4] transition-colors duration-300" // Set default color and change it on hover
                            />
                          </div>
                          <p className="text-start text-[14px] pt-4 text-[#5d6361] font-normal">
                            Protect remote workers in real-time.
                          </p>
                        </div> */}

                            {/* <div
                              className="group border-2 border-transparent hover:border-[#4740d4]  p-4 w-[270px] rounded-xl px-6 py-7 h-[160px]"
                              onClick={() => {
                                navigate("/secure-care");
                                setIsDropdownOpen(false);
                                setIsDropdown2Open(false);
                              }}
                            >
                              <div className="flex justify-between w-full">
                                <h1 className="text-[#363834]">Insurance</h1>
                                <GoArrowRight size={25} />
                              </div>
                              <p className="text-start text-[14px] pt-4 text-[#5d6361] font-normal">
                                SafetyCulture Care, combining risk management,
                                tech, and insurance
                              </p>
                            </div> */}
                          </div>
                          {/* <div
                            className="group border-2 border-transparent hover:border-[#4740d4]  p-4 w-[270px] rounded-xl px-6 py-7 h-[160px]"
                            onClick={() => {
                              navigate("/safety-docs");
                              setIsDropdownOpen(false);
                              setIsDropdown2Open(false);
                            }}
                          >
                            <div className="flex justify-between w-full">
                              <h1 className="text-[#363834]">
                                HSEQ Documentation
                              </h1>
                              <GoArrowRight size={25} />
                            </div>
                            <p className="text-start text-[14px] pt-4 text-[#5d6361] font-normal">
                              SafetyDocs, trusted workplace health, safety, and
                              quality documents
                            </p>
                          </div> */}
                        </div>
                      </div>
                    )}
                    {isDropdown2Open && index === 1 && (
                      <div
                        className={`absolute inset-x-0 top-full px-4 lg:px-16 bg-[#f3f6fb] w-screen h-fit flex flex-col lg:flex-row justify-right z-20 ${
                          isArabic ? "text-right" : "text-left"
                        }`}
                      >
                        <div className="flex justify-center py-10">
                          <div className="group  p-4 w-[400px] h-[348px] rounded-xl px-6 py-7 border-2 border-transparent hover:border-2 hover:border-[#4740d4] bg-white mr-6">
                            <div className="flex justify-between w-full cursor-pointer">
                              <h1 className="text-[#363834] cursor-pointer">
                                {t("header.By Industry")}
                              </h1>
                            </div>

                            <div className="flex justify-between">
                              <ul className="space-y-5 pt-5 ps-2">
                                <li
                                  className="text-[#4f504a] flex text-sm hover:text-[#4740d4] cursor-pointer"
                                  onClick={() => {
                                    navigate("/hospitality");
                                    setIsDropdownOpen(false);
                                    setIsDropdown2Open(false);
                                  }}
                                >
                                  {t("header.Hospitality")}
                                </li>
                                <li
                                  className="text-[#4f504a] flex text-sm hover:text-[#4740d4] hover:bg-[#f3f6fb] cursor-pointer"
                                  onClick={() => {
                                    navigate("/construction");
                                    setIsDropdownOpen(false);
                                    setIsDropdown2Open(false);
                                  }}
                                >
                                  {t("header.Construction")}
                                </li>
                                <li
                                  className="text-[#4f504a] flex text-sm cursor-pointer hover:text-[#4740d4]"
                                  onClick={() => {
                                    navigate("/manufacturing");
                                    setIsDropdownOpen(false);
                                    setIsDropdown2Open(false);
                                  }}
                                >
                                  {t("header.Manufacturing")}
                                </li>
                                <li
                                  className="text-[#4f504a] flex text-sm hover:text-[#4740d4] cursor-pointer"
                                  onClick={() => {
                                    navigate("/facilities-management");
                                    setIsDropdownOpen(false);
                                    setIsDropdown2Open(false);
                                  }}
                                >
                                  {t("header.Facilities Management")}
                                </li>
                                {/* <li
                                  className="text-[#4f504a] flex text-sm hover:text-[#4740d4] cursor-pointer"
                                  onClick={() => {
                                    navigate("/hospitality");
                                    setIsDropdownOpen(false);
                                    setIsDropdown2Open(false);
                                  }}
                                >
                                  Hospitality
                                </li> */}
                                <li
                                  className="text-[#4f504a] flex text-sm hover:text-[#4740d4] cursor-pointer"
                                  onClick={() => {
                                    navigate("/retail");
                                    setIsDropdownOpen(false);
                                    setIsDropdown2Open(false);
                                  }}
                                >
                                  {t("header.Retail")}
                                </li>
                                <li
                                  className="text-[#4f504a] flex text-sm hover:text-[#4740d4] cursor-pointer"
                                  onClick={() => {
                                    navigate("/transport-logistics");
                                    setIsDropdownOpen(false);
                                    setIsDropdown2Open(false);
                                  }}
                                >
                                  {t("header.Transport")}
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="flex justify-center py-10">
                          <div className="group p-4 w-[400px] h-[348px] rounded-xl px-6 py-7 border-2 border-transparent hover:border-2 hover:border-[#4740d4] bg-white mr-6">
                            <div className="flex justify-between w-full cursor-pointer">
                              <h1 className="text-[#363834] cursor-pointer">
                                {t("header.By Business Needs")}
                              </h1>
                            </div>

                            <div className="flex justify-between">
                              <ul className="space-y-5 pt-5 ps-2">
                                <li
                                  className="text-[#4f504a] flex text-sm hover:text-[#4740d4] cursor-pointer"
                                  onClick={() => {
                                    navigate("/health-safety");
                                    setIsDropdownOpen(false);
                                    setIsDropdown2Open(false);
                                  }}
                                >
                                  {t("header.health and safety")}
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </li>
                )
              )}
            </ul>
          </div>

          {/* Right: Buttons */}
          <div className="flex items-center space-x-4 pt-1">
            {/* Language Dropdown (Always visible) */}
            <div className="relative inline-block text-left" ref={dropdownRef}>
              <div>
                <button
                  onClick={toggleDropdownlang}
                  className="inline-flex w-full justify-center rounded-xl border border-transparent px-4 py-2.5 font-medium text-[#293745] hover:bg-[#f0f0ff] focus:outline-none"
                >
                  {selectedLanguage === "English" ? (
                    <>
                      {usaFlag}
                      {t("header.English")}
                    </>
                  ) : (
                    <>
                      <img src={SuadiaFlag} alt="" className="w-6 h-5 me-2" />
                      {t("header.Arabic")}
                    </>
                  )}
                  <svg
                    className="ml-2 h-5 w-5"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M5.23 7.21a.75.75 0 011.06.02L10 10.94l3.71-3.71a.75.75 0 011.06 1.06l-4 4a.75.75 0 01-1.06 0l-4-4a.75.75 0 01.02-1.06z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
              </div>

              {isOpen && (
                <div className="absolute mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-50">
                  <div className="py-1">
                    <button
                      onClick={() => handleLanguageChange("English")}
                      className="flex items-center w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                    >
                      {usaFlag} English
                    </button>
                    <button
                      onClick={() => handleLanguageChange("Arabic")}
                      className="flex items-center w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                    >
                      <img src={SuadiaFlag} alt="" className="w-6 h-5 me-2.5" />{" "}
                      العربیۃ
                    </button>
                  </div>
                </div>
              )}
            </div>

            {/* Buttons */}
            <div
              className={`hidden lg:flex items-end ${
                selectedLanguage === "Arabic"
                  ? "flex-row-reverse space-x-reverse"
                  : "space-x-2"
              }`}
            >
              <button
                className={`flex items-center ${
                  selectedLanguage === "Arabic" ? "flex-row-reverse" : ""
                } text-[#5f5bd7] border border-[#d0d7e1] px-4 py-2.5 rounded-xl text-[16px] font-[600]`}
                onClick={() => navigate("/login")}
              >
                <IoIosLogOut className="text-base" />
                <span>{t("header.Log in")}</span>
              </button>

              <button
                className={`text-white text-[16px] font-[500] bg-[#6559ff] px-4 py-3 rounded-xl text-sm hover:bg-blue-700 ${
                  selectedLanguage === "Arabic" ? "ml-1" : ""
                }`}
                onClick={() => navigate("/signup")}
              >
                {t("header.Sign up for free")}
              </button>
            </div>
          </div>

          {/* Hamburger Menu */}
          <div className="lg:hidden">
            <button onClick={toggleMenu}>
              {isMenuOpen ? (
                <FaTimes className="text-2xl" />
              ) : (
                <FaBars className="text-2xl" />
              )}
            </button>
          </div>

          {/* Mobile Menu */}
          {isMenuOpen && (
            <div className="fixed inset-0 bg-[#F3F6FB] z-50 flex flex-col">
              <div className="flex justify-between  px-3 w-full pb-4 border-b">
                <div>
                  <img
                    src={Logo}
                    alt="Logo"
                    className="w-24 md:w-48 h-16 mt-3"
                    onClick={() => navigate("/")}
                  />
                </div>
                <RxCross2
                  size={25}
                  onClick={() => closeHandler()}
                  className="mt-7 text-[#626C77]"
                />
              </div>

              <ul className="space-y-4 text-gray-800 text-lg pt-3">
                {[
                  t("mobilemenu.menuul1"),
                  t("mobilemenu.menuul2"),
                  t("mobilemenu.menuul3"),
                  t("mobilemenu.menuul4"),
                ].map((item, index) => (
                  <div key={item}>
                    <div
                      className={`flex justify-between border-b pt-1 pb-3 ${
                        isArabic && index === 0 ? "mb-6" : "mb-2" // Add extra space after menu 1 in Arabic
                      }`}
                      onClick={() =>
                        index === 0
                          ? mobileProducthandler()
                          : index === 1
                          ? mobilesSolutionhandler()
                          : index === 2
                          ? (navigate("/contact-us"), setMenuOpen(false))
                          : index === 3
                          ? (navigate("/pricing"), setMenuOpen(false))
                          : null
                      }
                    >
                      <li className="flex items-center text-base font-bold hover:text-blue-500">
                        {item}
                      </li>
                      <div>
                        {(index === 0 && mobileProduct) ||
                        (index === 1 && mobileSolution) ? (
                          <FaChevronUp className="ml-1 mt-0.5 font-[400] text-[#626C77]" />
                        ) : (
                          (index === 0 || index === 1) && (
                            <FaChevronDown className="ml-1 mt-0.5 font-[400] text-[#626C77]" />
                          )
                        )}
                      </div>
                    </div>

                    {/* Dropdown for "Product" */}
                    {index === 0 && mobileProduct && (
                      <div className="py-8 ">
                        <div className="text-start ps-4 space-y-6">
                          <p
                            onClick={() => {
                              navigate("/platform");
                              setMenuOpen(!isMenuOpen);
                              setMobileSolution(false);
                              setMobileProduct(false);
                            }}
                          >
                            {t("mobilemenu.productdropdown1")}
                          </p>
                          <p
                            onClick={() => {
                              navigate("/inspection");
                              setMenuOpen(!isMenuOpen);
                              setMobileSolution(false);
                              setMobileProduct(false);
                            }}
                          >
                            {t("mobilemenu.productdropdown2")}
                          </p>
                          <p
                            onClick={() => {
                              navigate("/assets");
                              setMenuOpen(!isMenuOpen);
                              setMobileSolution(false);
                              setMobileProduct(false);
                            }}
                          >
                            {t("mobilemenu.productdropdown3")}
                          </p>
                          <p
                            onClick={() => {
                              navigate("/integrations");
                              setMenuOpen(!isMenuOpen);
                              setMobileSolution(false);
                              setMobileProduct(false);
                            }}
                          >
                            {t("mobilemenu.productdropdown4")}
                          </p>
                        </div>
                      </div>
                    )}

                    {/* Dropdown for "Solutions" */}
                    {index === 1 && mobileSolution && (
                      <div className="py-8 ">
                        <div className="text-start ps-4 space-y-6">
                          <h1 className="font-bold">By industry</h1>
                          <p
                            onClick={() => {
                              navigate("/hospitality");
                              setMenuOpen(!isMenuOpen);
                              setMobileSolution(false);
                              setMobileProduct(false);
                            }}
                          >
                            {t("mobilemenu.solutiondropdown1")}
                          </p>
                          <p
                            onClick={() => {
                              navigate("/manufacturing");
                              setMenuOpen(!isMenuOpen);
                              setMobileSolution(false);
                              setMobileProduct(false);
                            }}
                          >
                            {t("mobilemenu.solutiondropdown2")}
                          </p>
                          <p
                            onClick={() => {
                              navigate("/facilities-management");
                              setMenuOpen(!isMenuOpen);
                              setMobileSolution(false);
                              setMobileProduct(false);
                            }}
                          >
                            {t("mobilemenu.solutiondropdown3")}
                          </p>
                          <p
                            onClick={() => {
                              navigate("/construction");
                              setMenuOpen(!isMenuOpen);
                              setMobileSolution(false);
                              setMobileProduct(false);
                            }}
                          >
                            {t("mobilemenu.solutiondropdown4")}
                          </p>
                          <p
                            onClick={() => {
                              navigate("/retail");
                              setMenuOpen(!isMenuOpen);
                              setMobileSolution(false);
                              setMobileProduct(false);
                            }}
                          >
                            {t("mobilemenu.solutiondropdown5")}
                          </p>
                          <p
                            onClick={() => {
                              navigate("/transport-logistics");
                              setMenuOpen(!isMenuOpen);
                              setMobileSolution(false);
                              setMobileProduct(false);
                            }}
                          >
                            {t("mobilemenu.solutiondropdown6")}
                          </p>
                          <p
                            onClick={() => {
                              navigate("/");
                              setMenuOpen(!isMenuOpen);
                              setMobileSolution(false);
                              setMobileProduct(false);
                            }}
                          >
                            {t("mobilemenu.solutiondropdown7")}
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              </ul>

              <div className="px-4 py-4 space-y-4">
                <button
                  onClick={() => navigate("/login")}
                  className="w-full text-white bg-[#6559ff] px-4 py-3 rounded-xl text-sm hover:bg-blue-700"
                >
                  {t("header.Log in")}
                </button>
                <button
                  onClick={() => navigate("/signup")}
                  className="w-full text-[#6559ff] border border-[#d0d7e1] px-4 py-3 rounded-xl text-sm hover:bg-[#f3f6fb]"
                >
                  {t("header.Sign up for free")}
                </button>
              </div>
            </div>
          )}
        </header>
      </div>
    </div>
  );
};

export default Header;
