import "./App.css";
import "./i18n/i18n";
import "react-multi-carousel/lib/styles.css";
import Layout from "./shared/layout"; // Layout for WebRoutes
import WebRoutes from "./webRoutes";
import PortalLayout from "./shared/portalLayout"; // Layout for PortalRoutes
import PortalRoutes from "./portalRoutes";
import Breadcrumb from "./shared/breadcrumb";
import { ToastContainer } from "react-toastify"; // Import ToastContainer
import "react-toastify/dist/ReactToastify.css"; // Import Toastify styles
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ProtectedRoute from "./portalRoutes/ProtectedRoute";
import ScrollToTop from "./common/components/ScrollToTop";

function App() {
  return (
    <div className="App overflow-hidden">
      {/* ToastContainer added globally */}

      <ToastContainer />

      {/* Single Router wrapping the entire application */}
      <Router>
        <ScrollToTop />
        <Routes>
          {/* WebRoutes wrapped in Layout */}
          <Route
            path="/*"
            element={
              <Layout>
                <Breadcrumb />
                <WebRoutes /> {/* Ensure no Router is inside WebRoutes */}
              </Layout>
            }
          />

          {/* PortalRoutes wrapped in PortalLayout */}
          <Route
            path="/dashboard/*"
            element={
              <ProtectedRoute>
                <PortalLayout>
                  <PortalRoutes />
                </PortalLayout>
              </ProtectedRoute>
            }
          />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
