import React, { useState } from 'react';
import { FiArrowLeft, FiDownload } from 'react-icons/fi';
import { FaSearch } from 'react-icons/fa';
import GroupTab from './siteMatrixTabs/group';
import AreaTab from './siteMatrixTabs/area';
import CountryTab from './siteMatrixTabs/country';
import RegionTab from './siteMatrixTabs/region';
import SiteTab from './siteMatrixTabs/site';
import StateTab from './siteMatrixTabs/state';

const SiteMatrix = () => {
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [activeTab, setActiveTab] = useState("Site");

  const renderContent = () => {
    switch (activeTab) {
      case "Group":
        return <GroupTab/>;
      case "Notifications":
        return <AreaTab/>;
      case "Devices":
        return <CountryTab/>;
      case "Templates":
        return <RegionTab/>;
      case "State":
        return <StateTab/>;
      case "Site":
        return <SiteTab/>;
       
      default:
        return null;
    }
  };

  const toggleFilterDropdown = () => {
    setIsFilterOpen(!isFilterOpen);
  };

  return (
    <div className="container mx-auto p-4">
      {/* Top bar with back button, search, filter, and download */}
  
        {/* Back button */}
        <button className="flex items-center text-gray-500 pt-3">
          <FiArrowLeft className="mr-2" />
          <span>Back</span>
        </button>
        <div className="flex justify-between items-center mb-6 mt-5">
        {/* Search and filter */}
        <div className="flex items-center space-x-4">
          {/* Search box */}
          <div className="flex items-center border border-gray-300 rounded-md px-2 py-1">
            <FaSearch className="text-gray-500" />
            <input
              type="text"
              placeholder="Search users"
              className="ml-2 outline-none bg-transparent"
            />
          </div>

          {/* User status and filter */}
          <div className="flex items-center">
            <button className="bg-white border border-gray-300 text-gray-700 px-4 py-2 rounded-lg mr-2">
              User status: Active
              <span className="ml-2 text-purple-500">×</span>
            </button>

            <div className="relative">
              <button
                onClick={toggleFilterDropdown}
                className="text-[#6559ff] hover:text-blue-600"
              >
                + Add filter
              </button>
              {isFilterOpen && (
                <div className="absolute mt-2 w-48 rounded-md shadow-lg bg-white">
                  <ul className="py-1 text-gray-700">
                    <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer">Filter 1</li>
                    <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer">Filter 2</li>
                    <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer">Filter 3</li>
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Create group and download */}
        <div className="flex items-center space-x-4">
          <button className="bg-[#6559ff] text-white px-4 py-2 rounded-lg">
            + Create group
          </button>
          <button className="p-2 rounded-md border border-gray-300">
            <FiDownload />
          </button>
        </div>
      </div>

      {/* Tabs */}
      <div className="mt-6">
        <nav className="flex space-x-7 border-b border-gray-300">
          {["Group", "Country", "State", "Region","Area", "Site",].map(
            (tab) => (
              <button
                key={tab}
                onClick={() => setActiveTab(tab)}
                className={`pb-2 text-gray-600 ${
                  activeTab === tab ? "border-b-2 border-blue-600 text-blue-600" : ""
                }`}
              >
                {tab}
              </button>
            )
          )}
        </nav>

        {/* Tab Content */}
        <div className="mt-4">{renderContent()}</div>
      </div>
    </div>
  );
};

export default SiteMatrix;
