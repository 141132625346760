import React from "react";
import SupportImage from "../../../assets/secureCare/SCCareFastSupport.webp";
import TextImageFlex from "../../../shared/textImageFlex";
const ReliableSupport = () => {
  return (
    <div className="py-12 md:py-24 ">
      <TextImageFlex
        heading="Fast and reliable support whenever you need it"
        para=" Life happens and it’s important to know that you and your broker can count on SafetyCulture Care to support you from assessments to claims."
        img={SupportImage}
        reverse
      />
    </div>
  );
};

export default ReliableSupport;
