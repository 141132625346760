import React from "react";
import { useTranslation } from "react-i18next";

const StatsSection = ({ data }) => {
  const { t } = useTranslation();
  return (
    <div className="py-10 px-4 text-center">
      <h2 className="text-xl md:text-2xl lg:text-3xl font-semibold mb-16 text-[#293745]">
        {t("statesection.securityheading")}
      </h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 w-full mx-auto">
        {data?.map((item, index) => (
          <div key={index} className="flex flex-col items-center">
            <h3 className="text-[40px] md:text-[60px] lg:text-[72px] font-bold text-[#293745]">
              {item.value}
            </h3>
            <div className={`border-t-4 ${item.borderColor} w-full mt-4`}>
              <p className="pt-4 text-[#293745] text-sm md:text-base lg:text-lg">
                {item.description}
              </p>
            </div>
          </div>
        ))}
      </div>
      <p className="text-xs text-[#293745] mt-8 max-w-xl mx-auto">
        {t("statesection.securitypara")}
      </p>
    </div>
  );
};

export default StatsSection;
