import Worker from "../../../assets/home/manager.jpg";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import Image1 from "../../../assets/home/spoon.webp";
import { FaArrowRight, FaArrowLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const TabOneDetails = () => {
  const navigate = useNavigate();
  const isArabic = i18next.language === "ar";
  const { t } = useTranslation();

  return (
    <div
      className={`flex flex-col lg:flex-row items-center lg:items-start rounded-lg shadow-xl mx-3 md:mx-7 ${
        isArabic ? "text-right rtl" : "text-left"
      }`}
    >
      <div className="lg:w-[50%] text-left pt-[73px] ps-7 md:ps-14">
        <img src={Image1} alt="" className="w-[160px]" />
        <div className="flex flex-col sm:flex-row items-start sm:items-center justify-start my-4">
          <div className="w-full sm:w-auto">
            <h1 className="text-[57px] sm:text-[3.8125rem] font-[700] text-[#293745] border-b-4 border-[#6559ff]">
              3x
            </h1>
            <p className="text-[1rem] pt-2 text-[#293745]">
              {t("tabcardspage.tab1translation")}
            </p>
          </div>
          <div className="w-full sm:w-auto pt-4 sm:pt-0">
            <h1 className="text-[57px] sm:text-[3.8125rem] font-bold text-[#293745] border-b-4 border-[#6559ff] sm:ms-9">
              $20,000
            </h1>
            <p className="text-[1rem] pt-2 text-[#293745] sm:ps-9">
              {t("tabcardspage.tab1paper")}
            </p>
          </div>
        </div>

        <div className="h-[300px] flex items-center">
          <button
            className="flex mt-10 mb-10 lg:mb-0 text-[14px] sm:text-[1rem] font-[600] text-[#4740d4] border border-[#d0d7e1] py-2 px-4 rounded-lg"
            onClick={() => navigate("/manufacturing")}
          >
            {t("tabcardspage.explorebtn")}
            {isArabic ? (
              <FaArrowLeft className="me-1 mt-1" />
            ) : (
              <FaArrowRight className="ms-1 mt-1" />
            )}
          </button>
        </div>
      </div>
      <div className="hidden lg:block lg:w-[50%] mt-6 lg:mt-0 flex justify-end">
        <div className="rounded-s-full overflow-hidden">
          <img
            src={Worker}
            alt="Image"
            className="object-cover w-full h-[610px]"
          />
        </div>
      </div>
    </div>
  );
};

export default TabOneDetails;
