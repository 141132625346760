import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaEdit } from "react-icons/fa";
import { useAuth } from "../../context/AuthContext";
const UserCredential = () => {
  const { authData } = useAuth();
  const navigate = useNavigate();

  const [isPopupOpen, setIsPopupOpen] = useState(false); // State for popup visibility

  const handleNavigation = (tab) => {
    const routes = {
      "User settings": "/dashboard/user-setting",
      Training: "/dashboard/user-trainning",
      Notifications: "/dashboard/user-notification",
      Devices: "/dashboard/user-device",
      Templates: "/dashboard/user-template",
      Groups: "/dashboard/user-group",
      Sites: "/dashboard/user-site",
      Credentials: "/dashboard/user-credential",
    };

    navigate(routes[tab]);
  };

  const handleClick = () => {
    setIsPopupOpen(true); // Open popup
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false); // Close popup
  };
  const handleBack = () => {
    navigate(-1); // Moves back to the previous page
  };
  return (
    <div className="min-h-screen p-4">
      <div className="text-left">
        <button onClick={handleBack} className="text-sm text-gray-500 mb-4">
          &larr; Back
        </button>
      </div>
      <div className="bg-gradient-to-r from-blue-200 to-purple-200 rounded-t-lg p-4 flex items-center relative">
        <div className="w-16 h-16 rounded-full bg-gray-300 flex items-center justify-center text-2xl text-white font-bold relative">
          {(
            authData.userName.charAt(0) +
            authData.userName.charAt(authData.userName.length - 1)
          ).toUpperCase()}
          <FaEdit
            onClick={handleClick} // Opens popup
            className="absolute bottom-0 right-0 bg-white p-1 rounded-full text-blue-500 cursor-pointer text-lg"
          />
        </div>
        <div className="ml-4">
          <h2 className="text-xl font-semibold">{authData?.userName}</h2>
          <p className="text-gray-600">{authData?.userDetails?.email}</p>
        </div>

        {/* Popup */}
        {isPopupOpen && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
            <div className="bg-white p-6 rounded-lg shadow-lg w-80">
              <h2 className="text-xl font-semibold mb-4">Edit Profile</h2>
              <input
                type="text"
                placeholder="Enter new name"
                className="w-full mb-4 p-2 border border-gray-300 rounded"
              />
              <div className="flex justify-end">
                <button
                  onClick={handleClosePopup} // Closes popup
                  className="px-4 py-2 bg-gray-300 text-gray-700 rounded mr-2"
                >
                  Cancel
                </button>
                <button className="px-4 py-2 bg-blue-500 text-white rounded">
                  Save
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="bg-white border-b flex justify-start items-center space-x-4 px-4 py-2">
        {[
          "User settings",
          "Training",
          "Notifications",
          "Devices",
          "Templates",
          "Groups",
          "Sites",
          "Credentials",
        ].map((tab) => (
          <button
            key={tab}
            onClick={() => handleNavigation(tab)}
            className="text-sm text-gray-600 hover:text-blue-500 mx-4 my-2"
          >
            {tab}
          </button>
        ))}
      </div>
      <div className="flex items-center justify-between p-4 border-b border-gray-300">
        {/* Left side heading */}
        <h2 className="text-xl font-semibold">Credential</h2>

        {/* Right side buttons */}
        <div className="space-x-2">
          <button className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700">
            Send Feedback
          </button>
          <button className="px-4 py-2 bg-green-600 text-white rounded-md hover:bg-green-700">
            Add Credential
          </button>
        </div>
      </div>
    </div>
  );
};
export default UserCredential;
