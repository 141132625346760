import TestimonialCard from "../../../shared/testimonialCard";
const Testimonial = () => {
  return (
    <div className="py-24">
      <TestimonialCard
        para=" Since using SHEQSY, employees report that they feel much more secure knowing that they have SHEQSY monitoring their sessions. As a manager, I find it much easier to track the safety of my employees when working in the community."
        name="Jeremy B"
        post="Community Rehab Manager, Epworth Healthcare"
      />
    </div>
  );
};
export default Testimonial;
