import CustomizableCards from "../../../shared/customizableCards";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
const CustomizableCardsSection = () => {
  const { t } = useTranslation();
  const isArabic = i18next.language === "ar";
  const CardsData = [ 
    {
      
      badge: (
        <span className="border-t border-b border-r border-[3px] border-l-[.1875rem] border-[#293745] text-sm text-[#293745]  px-3 py-1 rounded-full">
          {t("hospitalitycustom.hospitalitycustombadge")}
        </span>
      ),
      title: t("hospitalitycustom.hospitalitytitle1"),
      description: t("hospitalitycustom.hospitalitydescription1"),
      linkText: "Learn more",
    },
    {
      badge: (
        <span className="border-t border-b border-r border-[3px] border-l-[.1875rem] border-[#293745] text-sm text-[#293745]  px-3 py-1 rounded-full">
          {t("hospitalitycustom.hospitalitycustombadge1")}
        </span>
      ),
      title: t("hospitalitycustom.hospitalitytitle2"),
      description:t("hospitalitycustom.hospitalitydescription2"),
      linkText: "Learn more",
    },
    {
      badge: (
        <span className="border-t border-b border-r border-[3px] border-l-[.1875rem] border-[#293745] text-sm text-[#293745]  px-3 py-1 rounded-full">
          {t("hospitalitycustom.hospitalitycustombadge2")}
        </span>
      ),
      title: t("hospitalitycustom.hospitalitytitle3"),
      description:t("hospitalitycustom.hospitalitydescription3"),
      linkText: "Learn more",
    },
    {
      badge: (
        <span className="border-t border-b border-r border-[3px] border-l-[.1875rem] border-[#293745] text-sm text-[#293745]  px-3 py-1 rounded-full">
          {t("hospitalitycustom.hospitalitycustombadge3")}
        </span>
      ),
      title: t("hospitalitycustom.hospitalitytitle4"),
      description:t("hospitalitycustom.hospitalitydescription4"),
      linkText: "Learn more",
    },
  ];
  return (
    <div
            className={` ${isArabic ? "text-right" : "text-left"} ${
              isArabic ? "rtl" : ""
            }`}
          >
    <div className="bg-[#F3F6FB] py-10">
      <CustomizableCards
        heading={t("hospitalitycustom.hospitalitycoustomheading")}
        CardsData={CardsData}
      />
    </div>
  </div>
  );
};
export default CustomizableCardsSection;
