import ImprovementSection from "../../../shared/improvementSection";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
const ImprovementTwo = () => {
  const isArabic = i18next.language === "ar";
  const { t } = useTranslation();
  return (
    <div
            className={` ${isArabic ? "text-right" : "text-left"} ${
              isArabic ? "rtl" : ""
            }`}
          >
    <ImprovementSection
      heading={t("manufacturingguest.manufacturingguesthead")}
      btn1={t("manufacturingguest.manufacturingguestbtn")}
      btn2={t("manufacturingguest.manufacturingguestbtn1")}
    />
    </div>
  );
};
export default ImprovementTwo;
