import ContactUsForm from "../../components/Enterprise/ContactUsForm";
import EnterpriseHero from "../../components/Enterprise/EnterpriseHero";

const EnterprisePage = () => {
  return (
    <div>
      <EnterpriseHero />
      <ContactUsForm />
    </div>
  );
};
export default EnterprisePage;
