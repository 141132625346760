const InspectionTab = () => {

        return (
          <div className="text-center bg-white text-start p-6 rounded-md border border-gray-300 mt-4">
            <p className="text-lg font-semibold text-gray-700">
              Inspections with the "My site is not listed here" response to the
              site question are listed here.
            </p>
            <p className="text-sm text-gray-500">
              The inspections you see will also depend on the access and permissions you have.
            </p>
          </div>
        );
   
    }
  export default InspectionTab;