import TransportHero from "../../components/solutions/transport/hero";
import States from "../../components/solutions/transport/states";
import BoostProductivity from "../../components/solutions/transport/boostProductivity";
import PreventIncidents from "../../components/solutions/transport/preventIncidents";
import GainVisibilty from "../../components/solutions/transport/gainvisibility";
import DeliverEffective from "../../components/solutions/transport/deliverEffective";
import ImproveUptime from "../../components/solutions/transport/improveUptime";
import EasyKnowledge from "../../components/solutions/transport/easyknowledge";
import ListDataSection from "../../components/solutions/transport/listData";
import Improvement from "../../components/solutions/transport/improvement";
import CustomizableCardsSection from "../../components/solutions/transport/customizableCards";
import IndustryCards from "../../components/solutions/transport/industryCards";
import ImprovementTwo from "../../components/solutions/transport/improvementTwo";
import Faqs from "../../components/solutions/transport/faqs";
const Transport = () => {
  return (
    <div>
      <TransportHero />
      <States />
      <BoostProductivity />
      <GainVisibilty/>
      <PreventIncidents/>
      <DeliverEffective/>
      <ImproveUptime/>
      <EasyKnowledge/>
      <ListDataSection/>
      <Improvement/>
      <CustomizableCardsSection/>
      <IndustryCards/>
      <ImprovementTwo/>
      <Faqs/>
    </div>
  );
};
export default Transport;
