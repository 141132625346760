import React, { useState } from "react";
import { BiPlus, BiQuestionMark } from "react-icons/bi";
import { FiDelete, FiSettings } from "react-icons/fi";
import { HiOutlineQuestionMarkCircle } from "react-icons/hi";
import { IoReturnDownBack } from "react-icons/io5";
import { RiArrowGoBackFill } from "react-icons/ri";
import useHttp from "../../common/hooks/useHttp";
import { Link } from "react-router-dom";
import { useAuth } from "../../components/context/AuthContext";
import { useNotification } from "../../common/hooks/useNotification";
import { TfiBackRight } from "react-icons/tfi";
const CreateBlankTemplate = () => {
  const { sendRequest } = useHttp();
  return (
    <>
      <div>
        <Navbar />
        <Navbar2 />
        <Tabs />
      </div>
    </>
  );
};
const Navbar = () => {
  return (
    <>
      <div className="h-min-screen  ">
        <nav class="bg-white dark:bg-gray-900 fixed w-full z-20 top-0 start-0 border-b border-gray-200 dark:border-gray-600">
          <div class=" flex flex-wrap items-center justify-between mx-auto p-4">
            <Link
              to={"/dashboard/create-template"}
              class="  flex items-center space-x-3 rtl:space-x-reverse" //flowbite.com/"
            >
              <IoReturnDownBack color="gray" cursor={"pointer"} />
              <span class=" text-purple-600 self-center text-2xl font-semibold whitespace-nowrap dark:text-white">
                Back
              </span>
            </Link>
            <div class="flex justify-around gap-6  md:order-2 space-x-3 md:space-x-0 rtl:space-x-reverse">
              <HiOutlineQuestionMarkCircle
                className="p-30"
                cursor={"pointer"}
                color="gray"
                size={18}
              />

              <FiSettings cursor={"pointer"} color="gray" size={16} />
              <button
                data-collapse-toggle="navbar-sticky"
                type="button"
                class="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
                aria-controls="navbar-sticky"
                aria-expanded="false"
              >
                <span class="sr-only">Open main menu</span>
              </button>
            </div>
            <div
              class="items-center justify-between hidden w-full md:flex md:w-auto md:order-1"
              id="navbar-sticky"
            >
              <ul class="flex flex-col p-4 md:p-0 mt-4 font-medium border border-gray-100 rounded-lg bg-gray-50 md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
                <li className="text-gray-200 hover:bg-blue-100  ">
                  <Link
                    to={"#"}
                    class="  block py-2 px-3 text-white  bg-blue-700 rounded md:bg-transparent md:text-blue-700 md:p-0 md:dark:text-blue-500"
                    aria-current="page"
                  >
                    1. Build
                  </Link>
                </li>
                <li className="text-gray-200 hover:bg-blue-100  ">
                  <Link
                    to={"#"}
                    class="  block py-2  px-12 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700"
                  >
                    2. Report
                  </Link>
                </li>
                <li className="text-gray-200 hover:bg-blue-100  ">
                  <Link
                    to={"#"}
                    class="  block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700"
                  >
                    3. Access
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </>
  );
};
const Navbar2 = ({ tamplateId }) => {
  console.log(tamplateId);

  const Publish = () => {};
  return (
    <nav class="bg-white border-gray-200 dark:bg-gray-900 relative mt-10  border-gray-200 dark:border-gray-600">
      <div class=" flex flex-wrap items-center justify-between mx-auto p-4 ">
        <Link to={"#"} class="flex items-center space-x-3 rtl:space-x-reverse">
          <button
            type="button"
            class="py-2.5 text-purple-500 px-5 me-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
          >
            Bulk Edit
          </button>
          <RiArrowGoBackFill color="black" />
          <TfiBackRight color="black" />
        </Link>
        <div class="flex gap-8 md:order-2 items-center space-x-3 md:space-x-0 rtl:space-x-reverse">
          <span class="bg-blue-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300">
            Unpublished changes saved
          </span>
          Last published: 11 Dec 2024 10:53 PM
          <button
            onClick={Publish}
            type="button"
            class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          >
            Publish
          </button>
        </div>
        <div
          class="items-center  justify-between hidden w-full md:flex md:w-auto md:order-1"
          id="navbar-cta"
        >
          <ul class="flex hidden flex-col font-medium p-4 md:p-0 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
            <li>
              <Link
                href="#"
                class="block py-2 px-3 md:p-0 text-white bg-blue-700 rounded md:bg-transparent md:text-blue-700 md:dark:text-blue-500"
                aria-current="page"
              >
                Home
              </Link>
            </li>
            <li>
              <Link
                href="#"
                class="block py-2 px-3 md:p-0 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700"
              >
                About
              </Link>
            </li>
            <li>
              <Link
                href="#"
                class="block py-2 px-3 md:p-0 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700"
              >
                Services
              </Link>
            </li>
            <li>
              <Link
                href="#"
                class="block py-2 px-3 md:p-0 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700"
              >
                Contact
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

const Tabs = () => {
  const [activeTab, setActiveTab] = useState("TemplateTitle");

  const renderTabContent = () => {
    switch (activeTab) {
      case "TemplateTitle":
        return (
          <>
            <Content />
          </>
        );
      case "Question":
        return;

      default:
        return <div>Select a tab to view content</div>;
    }
  };

  return (
    <div className="mx-12">
      <div className="border-b border-gray-200 dark:border-gray-700">
        <ul className="flex flex-wrap -mb-px text-sm font-medium text-center text-gray-500 dark:text-gray-400">
          <li className="me-2">
            <button
              onClick={() => setActiveTab("TemplateTitle")}
              className={`inline-flex items-center justify-center p-4 border-b-2 rounded-t-lg ${
                activeTab === "TemplateTitle"
                  ? "text-blue-600 border-blue-600 dark:text-blue-500 dark:border-blue-500"
                  : "border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300"
              }`}
            >
              <svg
                className="w-4 h-4 me-2 text-gray-400 group-hover:text-gray-500 dark:text-gray-500 dark:group-hover:text-gray-300"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z" />
              </svg>
              TemplateTitle
            </button>
          </li>
          <li className="me-2">
            <button
              onClick={() => setActiveTab("Question")}
              className={`inline-flex items-center justify-center p-4 border-b-2 rounded-t-lg ${
                activeTab === "Question"
                  ? "text-blue-600 border-blue-600 dark:text-blue-500 dark:border-blue-500"
                  : "border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300"
              }`}
            >
              Question
            </button>
          </li>
          <li className="me-2">
            <button
              onClick={() => setActiveTab("settings")}
              className={`inline-flex items-center justify-center p-4 border-b-2 rounded-t-lg ${
                activeTab === "settings"
                  ? "text-blue-600 border-blue-600 dark:text-blue-500 dark:border-blue-500"
                  : "border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300"
              }`}
            >
              Settings
            </button>
          </li>
          <li className="me-2">
            <button
              onClick={() => setActiveTab("contacts")}
              className={`inline-flex items-center justify-center p-4 border-b-2 rounded-t-lg ${
                activeTab === "contacts"
                  ? "text-blue-600 border-blue-600 dark:text-blue-500 dark:border-blue-500"
                  : "border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300"
              }`}
            >
              Contacts
            </button>
          </li>
        </ul>
      </div>
      <div className="mt-4">{renderTabContent()}</div>
    </div>
  );
};

const Content = () => {
  const [data, setData] = useState({
    title: "",
    description: "",
    pages: [
      {
        title: "",
        orderIndex: 0,
        sections: [
          {
            title: "",
            orderIndex: 0,
            repeatable: false,
            questions: [
              {
                question: "",
                orderIndex: 0,
                required: false,
                responseType: "SITE",
                multipleSelection: false,
                flaggedResponses: false,
                logic: [],
              },
            ],
          },
        ],
      },
    ],
  });
  console.log({ data });
  const [Id, setId] = useState();
  const { sendRequest } = useHttp();
  const { authData } = useAuth();
  const notify = useNotification();
  const AddinApi = async () => {
    try {
      await sendRequest(
        {
          url: "v1/template/create",
          method: "POST",
          accessToken: authData.accessToken,
          headers: {
            userName: authData.userName,
            organizationId: authData.organizationId,
            deviceId: authData.deviceId,
          },
          payload: {
            title: data.title,
            description: data.description,
            pages: data.pages,
          },
        },
        (response) => {
          console.log(response?.data?.templateId);
          if (response) {
            if (response.responseCode == "200") {
              notify(response.responseDesc, "success");
            } else {
              notify(response.responseDesc, "error");
            }
          }
        }
      );
    } catch (error) {
      console.error("Error getting:", error.response);
    }
  };
  console.log(Id);

  <Navbar2 templateId={Id} />;
  const addPage = () => {
    const newPage = {
      id: data.pages.length + 1,
      title: "",
      orderIndex: data.pages.length,
      sections: [
        {
          id: 1,
          title: "",
          orderIndex: 0,
          repeatable: false,
          questions: [
            {
              id: 1,
              question: "",
              orderIndex: 0,
              required: false,
              responseType: "SITE",
              multipleSelection: false,
              flaggedResponses: false,
              logic: [],
            },
          ],
        },
      ],
    };
    setData({ ...data, pages: [...data.pages, newPage] });
  };
  console.log(data);

  const addSection = (pageId) => {
    const updatedPages = [...data.pages];
    const pageIndex = updatedPages.findIndex((page) => page.id === pageId);
    const newSection = {
      id: updatedPages[pageIndex].sections.length + 1,
      title: "",
      orderIndex: updatedPages[pageIndex].sections.length,
      repeatable: false,
      questions: [],
    };
    updatedPages[pageIndex].sections.push(newSection);
    setData({ ...data, pages: updatedPages });
  };

  const addRowToSection = (pageId, sectionId) => {
    const updatedPages = [...data.pages];
    const pageIndex = updatedPages.findIndex((page) => page.id === pageId);
    const sectionIndex = updatedPages[pageIndex].sections.findIndex(
      (section) => section.id === sectionId
    );
    updatedPages[pageIndex].sections[sectionIndex].questions.push({
      id: updatedPages[pageIndex].sections[sectionIndex].questions.length + 1,
      question: "",
      orderIndex:
        updatedPages[pageIndex].sections[sectionIndex].questions.length,
      required: false,
      responseType: "SITE",
      multipleSelection: false,
      flaggedResponses: false,
      logic: [],
    });
    setData({ ...data, pages: updatedPages });
  };

  const deleteRow = (pageId, sectionId, rowId) => {
    const updatedPages = [...data.pages];
    const pageIndex = updatedPages.findIndex((page) => page.id === pageId);
    const sectionIndex = updatedPages[pageIndex].sections.findIndex(
      (section) => section.id === sectionId
    );
    updatedPages[pageIndex].sections[sectionIndex].questions = updatedPages[
      pageIndex
    ].sections[sectionIndex].questions.filter((row) => row.id !== rowId);
    setData({ ...data, pages: updatedPages });
  };

  const deleteSection = (pageId, sectionId) => {
    const updatedPages = [...data.pages];
    const pageIndex = updatedPages.findIndex((page) => page.id === pageId);
    updatedPages[pageIndex].sections = updatedPages[pageIndex].sections.filter(
      (section) => section.id !== sectionId
    );
    setData({ ...data, pages: updatedPages });
  };

  const deletePage = (pageId) => {
    const updatedPages = data.pages.filter((page) => page.id !== pageId);
    setData({ ...data, pages: updatedPages });
  };

  const handleInputChange = (pageId, sectionId, rowId, field, value) => {
    const updatedPages = [...data.pages];
    const pageIndex = updatedPages.findIndex((page) => page.id === pageId);

    if (pageIndex === -1) {
      console.error(`Page with id ${pageId} not found.`);
      return;
    }

    const sectionIndex =
      sectionId !== null
        ? updatedPages[pageIndex].sections.findIndex(
            (section) => section.id === sectionId
          )
        : null;

    if (sectionId !== null && sectionIndex === -1) {
      console.error(`Section with id ${sectionId} not found.`);
      return;
    }

    if (rowId !== null) {
      const rowIndex = updatedPages[pageIndex].sections[
        sectionIndex
      ].questions.findIndex((row) => row.id === rowId);

      if (rowIndex === -1) {
        console.error(`Row with id ${rowId} not found.`);
        return;
      }

      updatedPages[pageIndex].sections[sectionIndex].questions[rowIndex][
        field
      ] = value;
    } else if (sectionId !== null) {
      updatedPages[pageIndex].sections[sectionIndex][field] = value;
    } else {
      updatedPages[pageIndex][field] = value;
    }

    setData({ ...data, pages: updatedPages });
  };

  const renderSection = (pageId, section) => (
    <div
      key={section.id}
      className="border border-gray-200 rounded-lg p-6 shadow-sm bg-white"
    >
      <h2 className="text-xl font-semibold mb-4 flex items-center space-x-4">
        <input
          type="text"
          value={section.title}
          onChange={(e) =>
            handleInputChange(pageId, section.id, null, "title", e.target.value)
          }
          placeholder="Enter section title"
          className="w-full border rounded-lg p-3 focus:ring-2 focus:ring-blue-500"
        />
        <button
          onClick={() =>
            handleInputChange(
              pageId,
              section.id,
              null,
              "repeatable",
              !section.repeatable
            )
          }
          className={`px-4 py-2 rounded-lg ${
            section.repeatable
              ? "bg-green-500 text-white"
              : "bg-gray-300 text-black"
          } focus:ring-2 focus:ring-blue-500`}
        >
          {section.repeatable ? "Repeatable" : "Not Repeatable"}
        </button>
      </h2>

      {section.questions.map((row) => (
        <div key={row.id} className="flex items-start space-x-4 my-4 group">
          <div className="relative w-1/2">
            <input
              type="text"
              value={row.question}
              onChange={(e) =>
                handleInputChange(
                  pageId,
                  section.id,
                  row.id,
                  "question",
                  e.target.value
                )
              }
              placeholder="Enter question"
              className="w-full border rounded-lg p-3 focus:ring-2 focus:ring-blue-500"
            />
            {/* Checkbox appears on hover */}
            <div
              style={{ width: "fit-content" }}
              className=" top-1  transform  opacity-0 group-hover:opacity-100 transition-opacity"
            >
              <div class="flex mt-4 items-center mb-4">
                <input
                  id="default-checkbox"
                  type="checkbox"
                  checked={row.required}
                  onChange={() =>
                    handleInputChange(
                      pageId,
                      section.id,
                      row.id,
                      "required",
                      !row.required
                    )
                  }
                  value=""
                  class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <label
                  for="default-checkbox"
                  class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  Required
                </label>
              </div>
            </div>
          </div>
          <select
            value={row.responseType}
            onChange={(e) =>
              handleInputChange(
                pageId,
                section.id,
                row.id,
                "responseType",
                e.target.value
              )
            }
            className="w-1/3 border rounded-lg p-3 focus:ring-2 focus:ring-blue-500"
          >
            <option>Select Response type</option>
            <option value="INSPECTION_DATE"> INSPECTION_DATE</option>
            <option value="SITE"> SITE</option>

            <option value="PERSON">PERSON</option>
            <option value="YES_NO">YES_NO</option>
            <option value="TEXT">TEXT</option>
            <option value="NUMBER">NUMBER</option>
          </select>

          <button
            onClick={() => deleteRow(pageId, section.id, row.id)}
            className="text-red-500 hover:text-red-700 focus:outline-none"
          >
            <FiDelete />
          </button>
        </div>
      ))}

      <button
        onClick={() => addRowToSection(pageId, section.id)}
        className="bg-blue-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300"
      >
        Add Question
      </button>
      <button
        onClick={() => deleteSection(pageId, section.id)}
        className="bg-red-100 text-red-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-red-900 dark:text-red-300"
      >
        Delete Section
      </button>
    </div>
  );

  const renderPage = (page) => (
    <div
      key={page.id}
      className="my-8 p-6 border rounded-lg shadow-md bg-gray-50"
    >
      <h1 className="text-2xl font-bold mb-6">
        <input
          type="text"
          value={page.title}
          onChange={(e) =>
            handleInputChange(page.id, null, null, "title", e.target.value)
          }
          placeholder="Enter page title"
          className="w-full border rounded-lg p-3 focus:ring-2 focus:ring-green-500"
        />
      </h1>
      {page.sections.map((section) => renderSection(page.id, section))}
      <button
        onClick={() => addSection(page.id)}
        className="bg-green-100 text-green-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-green-900 dark:text-green-300"
      >
        Add Section
      </button>
      <button
        onClick={() => deletePage(page.id)}
        className="bg-red-100 text-red-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-red-900 dark:text-red-300"
      >
        Delete Page
      </button>
    </div>
  );

  return (
    <div className="container mx-auto p-8">
      <div className="container ">
        <div class="relative z-0 w-[50] mb-5 group">
          <button
            onClick={AddinApi}
            className="bg-blue-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300"
          >
            Create template
          </button>
          <h1 style={{ fontSize: "100px" }} className=" ">
            <input
              type="text"
              name="TemplateTitle"
              class="text-center outline-none"
              placeholder="Untitled template"
              required
              onChange={(e) => {
                setData({ ...data, title: e.target.value });
              }}
            />
          </h1>
          <h1 style={{ fontSize: "30px" }}>
            <input
              type="text"
              name="Description"
              onChange={(e) => {
                setData({ ...data, description: e.target.value });
              }}
              class="text-center text-gray-400 outline-none"
              placeholder="Add a  description"
              required
            />
          </h1>
        </div>
      </div>

      {data.pages.map(renderPage)}
      <button
        onClick={addPage}
        className="bg-blue-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300"
      >
        Add New Page
      </button>
    </div>
  );
};

export default CreateBlankTemplate;
