import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import RightImage from "../../../assets/home/Lowes.webp";
import { FaArrowRight, FaArrowLeft } from "react-icons/fa";
const TabSixDetails = () => {
  const isArabic = i18next.language === "ar";
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div
      className={`flex flex-col lg:flex-row items-center lg:items-start ps-7 md:ps-14 rounded-lg shadow-xl mx-3 md:mx-7 ${
        isArabic ? "text-right rtl" : "text-left"
      }`}
    >
      <div className="lg:w-[50%] pt-[35px] pe-3 md:pe-12">
        <div className="block sm:inline-block text-start">
          <h1 className="me-1 text-[3.8125rem] text-start font-[700] text-[#293745] border-b-4 border-[#293745] pb-2">
            $1M
          </h1>
          <p className="text-[1rem] pt-4 text-[#293745] text-start">
            {t("tabcardspage.transpara1")}
          </p>
        </div>

        {/* <p className="text-[#293745] italic mb-4 text-[1.25rem] pt-10 text-start">
          “Using paper checklists, no one could see results of audits or the
          risks associated with poor processes. SafetyCulture allows us to see
          the results immediately and identify where we need to focus.”
        </p>
        <p className="text-[#293745] font-semibold text-sm text-start">
          Enrique Espinoza,
        </p>
        <p className="text-[#293745] text-sm pt-1 text-start">
        Process Improvement Manager for Lowe’s
        </p> */}
        <div className="h-[300px] flex items-center" href>
          <button
            className="flex mt-10 mb-10 lg:mb-0 text-[14px] text-[1rem] font-[600] text-[#4740d4] border border-[#d0d7e1] py-2 px-4 rounded-lg"
            onClick={() => navigate("/retail")}
          >
            {t("tabcardspage.explorebtn6")}
            {isArabic ? (
              <FaArrowLeft className="me-1 mt-1" />
            ) : (
              <FaArrowRight className="ms-1 mt-1" />
            )}
          </button>
        </div>
      </div>
      <div className="lg:w-[50%] mt-6 lg:mt-0 flex justify-center lg:justify-end hidden lg:block">
        <div className=" rounded-s-full overflow-hidden">
          <img
            src={RightImage}
            alt="Image"
            className="object-cover w-full h-[540px]"
          />
        </div>
      </div>
    </div>
  );
};
export default TabSixDetails;
