import React from "react";
import TrackerImage from "../../../assets/loneWorker/SheqsyDesktopTracker.webp";
import TextImageFlex from "../../../shared/textImageFlex";
const StayConnected = () => {
  return (
    <div className="py-12 md:py-24">
      <TextImageFlex
        heading="Stay connected to your team and be there when it counts"
        para={
          <>
            SHEQSY is your companion on the ground, feeding critical information
            into one dynamic dashboard that makes it easy to manage and report
            on lone worker activities.
            <br />
            <br />
            Customize automated notification workflows and have confidence that
            alerts will be sent to the right team leaders every time.
          </>
        }
        img={TrackerImage}
      />
    </div>
  );
};

export default StayConnected;
