import EquipmentHero from "../../../assets/transport/TransportAssets.webp";
import TextImageFlex from "../../../shared/textImageFlex";
import { useTranslation } from "react-i18next";
const GainVisibilty = () => {
  const { t } = useTranslation();
  return (
    <div className="bg-[#F3F6FB] py-12 md:py-16">
      <TextImageFlex
        heading={t("transportpage.Gainheading")}
        para={t("transportpage.Gainpara")}
        img={EquipmentHero}
        widhtClass="w-full"
        reverse
      />
    </div>
  );
};

export default GainVisibilty;
