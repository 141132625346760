import { useNavigate } from "react-router-dom";

const Hero = ({ heading, para, btn1, btn2, img, imgWidth }) => {
  const navigate = useNavigate();

  return (
    <div className="bg-[#f3f6fb] w-full text-center py-10 md:py-16">
      <div className="mx-4 md:mx-8 lg:mx-12 xl:mx-16 2xl:mx-auto 2xl:container">
        <h1 className="text-2xl md:text-4xl lg:text-5xl font-extrabold text-[#293745] md:leading-tight px-4 md:px-12 lg:px-24">
          {heading}
        </h1>
        <p className="mx-auto text-base md:text-lg py-4 md:w-10/12">{para}</p>
        <div className="mt-4 mb-6">
          <button
            className="bg-[#6559ff] text-white py-3 px-5 rounded-2xl hover:bg-[#4740d4] transition duration-300 ease-in-out me-4"
            onClick={() => {
              navigate("/signup");
            }}
          >
            {btn1}
          </button>
          <button
            className="border-2 border-[#4740d4] text-[#4740d4] py-3 px-5 rounded-xl hover:bg-[#f0f0ff] transition duration-300 ease-in-out"
            onClick={() => {
              navigate("/book-demo");
            }}
          >
            {btn2}
          </button>
        </div>
        <div className="flex justify-center mt-12">
          <img
            src={img}
            alt="Platform hero"
            style={{ width: imgWidth }}
            className="transition duration-300 ease-in-out"
          />
        </div>
      </div>
    </div>
  );
};
export default Hero;
