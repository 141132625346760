import HeroSection from "../../components/products/assets/hero";
import ManageAssets from "../../components/products/assets/manageAssets";
import TeamAssets from "../../components/products/assets/assetManagement";
import FrontLine from "../../components/products/assets/frontLine";
import Accessinformation from "../../components/products/assets/accesinformation";
import GetInsight from "../../components/products/assets/getInsight";
import Features from "../../components/products/assets/features";
import TeamPrincipal from "../../components/products/assets/teamPrincipal";
import ImprovementSection from "../../components/products/assets/improvement";
import FaqsSection from "../../components/products/assets/faqs";
const Assets = () => {
  return (
    <div>
      <HeroSection />
      <ManageAssets />
      <TeamAssets />
      <FrontLine />
      <Accessinformation />
      <GetInsight />
      <Features />
      <TeamPrincipal />
      <ImprovementSection />
      <FaqsSection/>
    </div>
  );
};
export default Assets;
