import React, { useState } from "react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import i18next from "i18next"; // Assuming i18next is imported for language detection

const FAQSection = ({ faqData, heading }) => {
  const [openIndex, setOpenIndex] = useState(null);

  const toggleAccordion = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const isArabic = i18next.language === "ar"; // Detect if the language is Arabic

  return (
    <div className={`bg-[#F3F6FB] py-24 px-4 ${isArabic ? "rtl" : ""}`}>
      <div className="container mx-auto max-w-4xl">
        <h2
          className={`text-3xl md:text-5xl font-bold text-[#293745] text-center mb-14 ${
            isArabic ? "text-right" : ""
          }`}
        >
          {heading}
        </h2>

        {faqData.map((faq, index) => (
          <div key={index} className="mb-4">
            <div
              className={`group rounded-xl border-2 border-[#A2ACBD] transition-all duration-300 ${
                openIndex === index ? "p-4" : "p-3"
              }`}
            >
              <button
                onClick={() => toggleAccordion(index)}
                className={`flex w-full bg-transparent text-left hover:text-[#4740d4] transition-colors duration-300 ${
                  isArabic ? "text-right" : "text-left"
                }`}
              >
                <div
                  className={`text-md font-semibold text-[#293745] group-hover:text-[#4740d4] ${
                    isArabic ? "text-right" : "text-left"
                  }`}
                >
                  {faq.question}
                </div>
                <div className={`ml-auto ${isArabic ? "order-last" : ""}`}>
                  {openIndex === index ? (
                    <FaChevronUp className="text-gray-600 group-hover:text-[#4740d4]" />
                  ) : (
                    <FaChevronDown className="text-gray-600 group-hover:text-[#4740d4]" />
                  )}
                </div>
              </button>
              {openIndex === index && (
                <div
                  className={`mt-4 ${
                    isArabic ? "text-right" : "text-left"
                  } text-gray-700`}
                >
                  <p>{faq.answer}</p>
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FAQSection;
