// SiteDropdown.js
import React, { useState } from "react";
import { FaSearch } from "react-icons/fa";

const Site = ({ site, setSite, siteOptions }) => {
  const [isSiteDropdownOpen, setIsSiteDropdownOpen] = useState(false);
  const [siteSearchTerm, setSiteSearchTerm] = useState("");

  const filteredSiteOptions = siteOptions.filter((option) =>
    option.toLowerCase().includes(siteSearchTerm.toLowerCase())
  );

  return (
    <div className="relative">
      <label className="block text-sm font-medium text-gray-700 mt-12">
        Site
      </label>
      <button
        onClick={() => setIsSiteDropdownOpen(!isSiteDropdownOpen)}
        className="block w-full border border-gray-300 rounded-md h-12 px-3 bg-white text-left focus:ring-0 focus:border-indigo-500 hover:border-indigo-700 transition"
      >
        {site || "Select Site"}
      </button>

      {isSiteDropdownOpen && (
        <div className="absolute mt-2 w-full bg-white border border-gray-300 rounded-lg shadow-lg z-10 p-4">
          {/* Search Input with Icon */}
          <div className="relative mb-4">
            <input
              type="text"
              value={siteSearchTerm}
              onChange={(e) => setSiteSearchTerm(e.target.value)}
              placeholder="Search"
              className="w-full h-12 pl-10 pr-3 border border-gray-300 rounded-md focus:outline-none focus:border-indigo-500"
            />
            <FaSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
          </div>

          {/* Filtered Results */}
          {filteredSiteOptions.length > 0 ? (
            <ul className="max-h-48 overflow-y-auto mb-4">
              {filteredSiteOptions.map((option, index) => (
                <li
                  key={index}
                  onClick={() => {
                    setSite(option);
                    setIsSiteDropdownOpen(false);
                    setSiteSearchTerm("");
                  }}
                  className="px-3 py-2 cursor-pointer hover:bg-indigo-500 hover:text-white transition rounded-md"
                >
                  {option}
                </li>
              ))}
            </ul>
          ) : (
            <div className="text-center text-gray-500 mb-4">
              <p className="text-lg font-semibold">No sites</p>
              <p>You are not a member of any sites.</p>
            </div>
          )}

          {/* Footer with Buttons */}
          <div className="flex justify-between mt-4">
            <button
              onClick={() => {
                setSite("");
                setSiteSearchTerm("");
              }}
              className="text-indigo-500 hover:underline"
            >
              Clear selections
            </button>
            <button
              onClick={() => setIsSiteDropdownOpen(false)}
              className="px-4 py-2 bg-indigo-500 text-white rounded-md"
            >
              Done
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Site;
