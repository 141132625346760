import React, { useState } from "react";
import { IoMdAdd } from "react-icons/io";
import { MdOutlineFeedback } from "react-icons/md";
import { useNavigate } from "react-router-dom";

const Credentials = () => {
const navigate = useNavigate()
  return (
    <div className=" py-3">

      {/* Top Section: Header */}
      <div className="flex justify-between items-center mb-6">
        <div>
          <h1 className="text-2xl font-semibold text-gray-800 pt-2">
            Credentials{" "}
          </h1>
        </div>
        <div className="flex">
          <button className="bg-white text-[#6559ff] px-4 py-2.5 rounded-xl flex me-3 border border-[#bfc6d4] hover:bg-[#e0e4ff]">
            <MdOutlineFeedback className="me-2 mt-1" /> Send feedback
          </button>
          <button className="bg-[#6559ff] text-white px-4 py-2.5 rounded-xl hover:bg-[#7470f5] flex hover:bg-[##7470f5]" onClick={()=>navigate("/dashboard/add-credentials-process")}>
            <IoMdAdd className="me-2 mt-1" /> Add credentials
          </button>
        </div>
      </div>
      <p className="text-sm text-start">
        You have no uploaded credentials. Please speak to an organization admin
        to upload your credentials.
      </p>
    </div>
  );
};

export default Credentials;
