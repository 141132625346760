import TextImagewFlex from "../../../shared/textImageFlex";
import TrainingAI from "../../../assets/training/Traning-AI.webp";
const CreateWithAi = () => {
  return (
    <div className="py-24 ">
      <TextImagewFlex
        span="Create with AI"
        img={TrainingAI}
        heading="Create training with a click of a button"
        para="Convert paper docs to digital lessons in seconds. Or create training the moment you see an issue. All from your mobile device. Just enter a prompt or take a picture, and let the AI course creator do the rest. Easily access equipment manuals, SOPs, and new workplace training anywhere."
        show
      />
    </div>
  );
};
export default CreateWithAi;
