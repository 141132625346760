import React, { createContext, useContext, useState } from "react";
import Cookies from "js-cookie";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [authData, setAuthData] = useState(() => {
    const accessToken = localStorage.getItem("accessToken");
    const refreshToken = localStorage.getItem("refreshToken");
    const deviceId = localStorage.getItem("deviceId");
    const userName = localStorage.getItem("userName");
    const organizationId = localStorage.getItem("organizationId");
    const userDetails = localStorage.getItem("userDetails");

    return accessToken && deviceId && userName && organizationId && userDetails
      ? {
          accessToken,
          deviceId,
          userName,
          organizationId,
          refreshToken,
          userDetails: JSON.parse(userDetails),
        }
      : null;
  });

  const setAuth = (authDetails) => {
    setAuthData(authDetails);
    localStorage.setItem("accessToken", authDetails.accessToken);
    localStorage.setItem("refreshToken", authDetails.refreshToken);
    localStorage.setItem("deviceId", authDetails.deviceId);
    localStorage.setItem("userName", authDetails.userName);
    Cookies.set("accessToken", authDetails.accessToken, { expires: 1 });
    localStorage.setItem("organizationId", authDetails.organizationId);
    localStorage.setItem(
      "userDetails",
      JSON.stringify(authDetails.userDetails)
    );
  };

  const logout = () => {
    setAuthData(null);
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("deviceId");
    localStorage.removeItem("userName");
    localStorage.removeItem("organizationId");
    Cookies.remove("accessToken");
  };

  const updateAccessToken = (newAccessToken, newRefreshToken) => {
    setAuthData((prevAuthData) => {
      if (prevAuthData) {
        const updatedAuthData = {
          ...prevAuthData,
          accessToken: newAccessToken,
          refreshToken: newRefreshToken,
        };

        // Update localStorage and cookies with the new tokens
        localStorage.setItem("accessToken", newAccessToken);
        Cookies.set("accessToken", newAccessToken, { expires: 1 });

        localStorage.setItem("refreshToken", newRefreshToken);

        return updatedAuthData;
      }
      return prevAuthData; // If no previous authData, do nothing
    });
  };
  return (
    <AuthContext.Provider
      value={{ authData, setAuth, logout, updateAccessToken }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
