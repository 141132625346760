import { useState } from "react";
import { FaUsers, FaUser, FaPlus } from "react-icons/fa";

const Assign = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState("Assignee");
  const [showUserOptions, setShowUserOptions] = useState(false);
  const [showGroupOptions, setShowGroupOptions] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [showNestedSearch, setShowNestedSearch] = useState(false);
  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleSelect = (value) => {
    if (value === "Users") {
      setShowUserOptions(true);
      setShowGroupOptions(false);
    } else if (value === "Groups") {
      setShowGroupOptions(true);
      setShowUserOptions(false);
    } else if (value === "Based on Site & Group membership") {
      setShowPopup(true);
    } else {
      setSelected(value);
      setShowUserOptions(false);
      setShowGroupOptions(false);
      setIsOpen(false);
    }
  };

  const handleUserSelect = (user) => {
    setSelected(user);
    setIsOpen(false);
    setShowUserOptions(false);
  };

  const closePopup = () => setShowPopup(false);
  const toggleNestedSearch = () => setShowNestedSearch(!showNestedSearch);

  return (
    <div className="mt-2">
      <label className="block text-sm font-medium text-gray-700 mt-12">
        Assigned to
      </label>
      <div className="relative">
        <button
          onClick={toggleDropdown}
          className="w-full border border-gray-300 rounded-md h-12 px-3 bg-white text-left"
        >
          {selected}
        </button>
        {isOpen && (
          <div className="absolute mt-1 w-full border border-gray-300 rounded-md bg-white shadow-lg z-10">
            <div
              onClick={() => handleSelect("Groups")}
              className="flex items-center px-4 py-2 cursor-pointer hover:bg-indigo-50 transition"
            >
              <FaUsers className="mr-2 text-indigo-600" />
              Groups
            </div>
            <div
              onClick={() => handleSelect("Users")}
              className="flex items-center px-4 py-2 cursor-pointer hover:bg-indigo-50 transition"
            >
              <FaUser className="mr-2 text-indigo-600" />
              Users
            </div>
            <div
              onClick={() => handleSelect("Based on Site & Group membership")}
              className="flex items-center px-4 py-2 cursor-pointer hover:bg-indigo-50 transition"
            >
              <FaPlus className="mr-2 text-indigo-600" />
              Based on Site & Group membership
            </div>

            {/* Show User Options */}
            {showUserOptions && (
              <div className="border-t border-gray-300 mt-2 pt-2">
                <input
                  type="text"
                  placeholder="Search"
                  className="w-full p-2 border border-gray-300 rounded mb-2"
                />
                <button className="text-indigo-600 mb-2 w-full text-left pl-4">
                  Add user to team
                </button>
                <div
                  onClick={() => setSelected("Shaheer Imtiaz (you)")}
                  className="flex items-center px-4 py-2 cursor-pointer hover:bg-indigo-50 transition"
                >
                  <input type="checkbox" className="mr-2" />
                  Shaheer Imtiaz (you)
                </div>
                <button
                  onClick={() => setIsOpen(false)}
                  className="mt-2 w-full bg-indigo-600 text-white py-2 rounded"
                >
                  Done
                </button>
              </div>
            )}

            {/* Show Group Options */}
            {showGroupOptions && (
              <div className="border-t border-gray-300 mt-2 pt-2">
                <input
                  type="text"
                  placeholder="Search for Groups"
                  className="w-full p-2 border border-gray-300 rounded mb-2"
                />
                <p className="text-gray-500 text-center">No results</p>
              </div>
            )}
          </div>
        )}
      </div>

      {/* Popup */}
      {showPopup && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-20">
          <div className="bg-white p-6 md:p-8 rounded-lg shadow-lg w-11/12 max-w-md md:max-w-lg lg:max-w-xl mx-4 sm:mx-0">
            <h2 className="text-lg md:text-xl font-semibold mb-4">
              Add Site-based Assignment
            </h2>
            <div className="mb-4">
              <label className="flex items-center mb-2 cursor-pointer">
                <input type="radio" name="assignment" className="mr-2" />
                All members of selected sites
              </label>
              <label className="flex items-center cursor-pointer">
                <input type="radio" name="assignment" className="mr-2" />
                Members of selected site who are also in..
              </label>
            </div>

            {/* Main Search Field */}
            <input
              type="text"
              placeholder="Search"
              className="w-full p-2 border border-gray-300 rounded mb-4"
              onClick={toggleNestedSearch}
            />

            {/* Nested Search Dropdown */}
            {showNestedSearch && (
              <div className="absolute mt-1 w-full border border-gray-300 rounded-md bg-white shadow-lg z-10 p-4">
                <input
                  type="text"
                  placeholder="Nested Search"
                  className="w-full p-2 border border-gray-300 rounded"
                />
              </div>
            )}

            <div className="flex justify-end space-x-2 mt-4">
              <button
                onClick={() => {
                  closePopup();
                  setShowNestedSearch(false);
                }}
                className="w-24 bg-gray-300 text-gray-700 py-2 rounded hover:bg-gray-400 transition"
              >
                Cancel
              </button>
              <button className="w-24 bg-indigo-600 text-white py-2 rounded hover:bg-indigo-700 transition">
                Assign
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Assign;
