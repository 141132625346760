import React, { useState } from "react";
import { FaEdit } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import useHttp from "../../../common/hooks/useHttp";

import { useNotification } from "../../../common/hooks/useNotification";
import { ProfileHeader } from "./header";
const UserSettings = () => {
  const { sendRequest } = useHttp();
  const notify = useNotification();
  const navigate = useNavigate();
  const [isEditing, setIsEditing] = useState(false);
  const [mobileNumber, setMobileNumber] = useState("");
  const [isEditingDisplay, setIsEditingDisplay] = useState(false);
  const [theme, setTheme] = useState("Light theme");
  const [isSent, setIsSent] = useState(false);
  const [isEditingPassword, setIsEditingPassword] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const { authData, setAuth } = useAuth();
  console.log(authData);

  const [formData, setFormData] = useState({
    firstName: authData.userDetails?.firstName,
    lastName: authData.userDetails?.lastName,
    email: authData.userDetails?.email,
    language: authData.userDetails?.language,
    userId: authData.userDetails?.id,
    phoneNumber: authData.userDetails?.phoneNumber,
  });
  // useEffect(() => {
  //   if (authData?.userDetails) {
  //     setFormData((prev) => ({
  //       ...prev,
  //       firstName: authData.userDetails.firstName,
  //       lastName: authData.userDetails.lastName,
  //       email: authData.userDetails.email,
  //       userId: authData.userDetails?.id,
  //       phoneNumber: authData.userDetails.phoneNumber,
  //     }));
  //   }
  //   console.log(authData?.userDetails);
  // }, [authData]);
  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleCancelClick = () => {
    setIsEditing(false);
  };

  const handleInputChange = (e) => {
    setMobileNumber(e.target.value);
  };

  const handleEditDisplayClick = () => {
    setIsEditingDisplay(true);
  };

  const handleCancelDisplayClick = () => {
    setIsEditingDisplay(false);
  };

  const handleSaveDisplayClick = () => {
    setIsEditingDisplay(false);
  };

  const handleThemeChange = (e) => {
    setTheme(e.target.value);
  };

  const handleSendClick = () => {
    setIsSent(true);
    // Optionally, add any logic here to actually send an email or notification.
  };

  const handleEditPasswordClick = async () => {
    try {
      await sendRequest(
        {
          url: "v1/user/change-password",
          method: "PUT",
          accessToken: authData.accessToken,
          headers: {
            userName: authData.userName,
            organizationId: authData.organizationId,
            deviceId: authData.deviceId,
          },
          payload: {
            email: formData.email,
            newPassword: newPassword,
            currentPassword: oldPassword,
          },
        },
        (response) => {
          if (response) {
            if (response.responseCode == "200") {
              notify(response.responseDesc, "success");
              setIsEditingPassword(false);
            } else {
              notify(response.responseDesc, "error");
            }
          }
        }
      );
    } catch (err) {
      console.log("something wrong", err.response);
    }
  };

  // const handleSavePasswordClick = () => {
  //   // Add any logic here to save the password changes.
  //   setIsEditingPassword(false);
  // };

  const handleCancelPasswordClick = () => {
    setIsEditingPassword(false);
    setOldPassword("");
    setNewPassword("");
  };

  const handleSaveClick = async () => {
    try {
      const payloadUserData = {
        userId: formData.userId,
        firstName: formData.firstName,
        lastName: formData.lastName,
        phoneNumber: formData.phoneNumber,
        language: formData.language,
      };
      await sendRequest(
        {
          url: "v1/user/details/update",
          method: "PUT",
          accessToken: authData.accessToken,
          headers: {
            userName: authData.userName,
            organizationId: authData.organizationId,
            deviceId: authData.deviceId,
          },
          payload: payloadUserData,
        },
        (response) => {
          if (response) {
            if (response.responseCode == "200") {
              const newAuthData = {
                ...authData,
                userDetails: {
                  ...authData.userDetails,
                  ...payloadUserData,
                },
              };
              setAuth(newAuthData);
              notify(response.responseDesc, "success");
              setIsEditing(false);
            } else {
              notify(response.responseDesc, "error");
            }
          }
        }
      );
    } catch (err) {
      console.log(err, "thisi sis error");
    }
    setIsEditing(false);
    // Here you can handle saving the data, e.g. API call or local storage update.
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleClick = () => {
    setIsPopupOpen(true);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };
  const handleBack = () => {
    navigate(-1); // Moves back to the previous page
  };
  const handleNavigation = (tab) => {
    const routes = {
      "User settings": "/dashboard/user-setting",
      Training: "/dashboard/user-trainning",
      Notifications: "/dashboard/user-notification",
      Devices: "/dashboard/user-device",
      Templates: "/dashboard/user-template",
      Groups: "/dashboard/user-group",
      Sites: "/dashboard/user-site",
      Credentials: "/dashboard/user-credential",
    };

    navigate(routes[tab]);
  };

  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setSelectedImage(reader.result); // Store image as Base64 URL
      };
      reader.readAsDataURL(file);
      console.log(reader);
    }
    console.log(event.target.files[0]);
  };
  const saveImage = async () => {
    try {
      await sendRequest(
        {
          url: "v1/user/uploadProfileImage",
          method: "PUT",
          accessToken: authData.accessToken,
          headers: {
            userName: authData.userName,
            organizationId: authData.organizationId,
            deviceId: authData.deviceId,
          },
          payload: {
            userId: formData.userId,
            image: selectedImage,
          },
        },
        (response) => {
          if (response) {
            if (response.responseCode == "200") {
              notify(response.responseDesc, "success");
              setIsEditingPassword(false);
            } else {
              notify(response.responseDesc, "error");
            }
          }
        }
      );
    } catch (err) {
      console.log("something wrong", err.response);
    }
  };
  return (
    <div className="min-h-screen p-4">
      {/* Back Button */}
      <div className="text-left">
        <button onClick={handleBack} className="text-sm text-gray-500 mb-4">
          &larr; Back
        </button>
      </div>

      {/* Profile Header */}
      <div className="bg-gradient-to-r from-blue-200 to-purple-200 rounded-t-lg p-4 flex items-center relative">
        <ProfileHeader />
        {/* <div className="w-16 h-16 rounded-full bg-gray-300 flex items-center justify-center text-2xl text-white font-bold relative">
          {selectedImage ? (
            <img
              src={selectedImage}
              alt="Profile"
              className="w-full h-full object-cover rounded-full"
            />
          ) : (
            (
              authData.userName.charAt(0) +
              authData.userName.charAt(authData.userName.length - 1)
            ).toUpperCase()
          )}
          <FaEdit
            onClick={handleClick}
            className="absolute bottom-0 right-0 bg-white p-1 rounded-full text-blue-500 cursor-pointer text-lg"
          />
        </div>
        <div className="ml-4">
          <h2 className="text-xl text-left font-semibold">
            {authData?.userName}
          </h2>
          <p className="text-gray-600">{authData?.userDetails?.email}</p>
        </div> */}

        {/* Popup */}
        {/* {isPopupOpen && (
          <div
            style={{ zIndex: "99999999" }}
            className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
          >
            <div className="bg-white p-6 rounded-lg shadow-lg w-[40%] ">
              <h2 className="text-xl font-semibold mb-4">Edit Profile</h2>
              <div class="flex items-center justify-center w-full">
                <label
                  for="dropzone-file"
                  class="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-gray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
                >
                  <div class="flex flex-col items-center justify-center pt-5 pb-6">
                    <svg
                      class="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 20 16"
                    >
                      <path
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                      />
                    </svg>
                    <p class="mb-2 text-sm text-gray-500 dark:text-gray-400">
                      <span class="font-semibold">Click to upload</span> or drag
                      and drop
                    </p>
                    <p class="text-xs text-gray-500 dark:text-gray-400">
                      SVG, PNG, JPG or GIF (MAX. 800x400px)
                    </p>
                  </div>
                  <input
                    onChange={handleImageChange}
                    accept="image/*"
                    id="dropzone-file"
                    type="file"
                    class="hidden"
                  />
                </label>
              </div>

              <div className="flex justify-end mt-10">
                <button
                  onClick={() => setIsPopupOpen(false)}
                  className="px-4 py-2 bg-gray-300 text-gray-700 rounded mr-2"
                >
                  Cancel
                </button>
                <button
                  onClick={saveImage}
                  className="px-4 py-2 bg-blue-500 text-white rounded"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        )} */}
      </div>

      {/* Tabs */}
      <div className="bg-white border-b flex justify-start items-center space-x-4 px-4 py-2">
        {[
          "User settings",
          "Training",
          "Notifications",
          "Devices",
          "Templates",
          "Groups",
          "Sites",
          "Credentials",
        ].map((tab) => (
          <button
            key={tab}
            onClick={() => handleNavigation(tab)}
            className="text-sm text-gray-600 hover:text-blue-500 mx-4 my-2"
          >
            {tab}
          </button>
        ))}
      </div>

      {/* Content Grid */}
      <div className="p-4 grid grid-cols-1 md:grid-cols-2 gap-4">
        {/* Details Section */}
        <div className="bg-white rounded-lg p-4 shadow text-left">
          <div className="relative">
            <div className="absolute top-0 right-0 mr-2">
              <button
                onClick={handleEditClick}
                className="text-blue-500 py-2 px-4 rounded"
              >
                Edit
              </button>
            </div>
          </div>

          <h3 className="text-lg font-semibold mb-2">
            {isEditing ? "Edit Details" : "Details"}
          </h3>
          <div className="text-sm text-gray-700 space-y-1">
            {isEditing ? (
              <>
                <p className="mt-6">
                  <strong>First Name:</strong>
                  <input
                    type="text"
                    name="firstName"
                    value={formData.firstName}
                    onChange={handleChange}
                    className="w-full p-2 border border-gray-300 rounded mt-1"
                  />
                </p>
                <p className="mt-6">
                  <strong>Last Name:</strong>
                  <input
                    type="text"
                    name="lastName"
                    value={formData.lastName}
                    onChange={handleChange}
                    className="w-full p-2 border border-gray-300 rounded mt-1"
                  />
                </p>
                <p className="mt-6">
                  <strong>Email:</strong>
                  <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    className="w-full p-2 border border-gray-300 rounded mt-1"
                  />
                </p>
                {/* <p className="mt-6">
                  <strong>Time Zone:</strong>
                  <input
                    type="text"
                    name="timeZone"
                    value={formData.timeZone}
                    onChange={handleChange}
                    className="w-full p-2 border border-gray-300 rounded mt-1"
                  />
                </p>
                <p className="mt-6">
                  <strong>Date Format:</strong>
                  <input
                    type="text"
                    name="dateFormat"
                    value={formData.dateFormat}
                    onChange={handleChange}
                    className="w-full p-2 border border-gray-300 rounded mt-1"
                  />
                </p>
                <p className="mt-6">
                  <strong>Time Format:</strong>
                  <input
                    type="text"
                    name="timeFormat"
                    value={formData.timeFormat}
                    onChange={handleChange}
                    className="w-full p-2 border border-gray-300 rounded mt-1"
                  />
                </p>
                <p className="mt-6">
                  <strong>Temperature Metric:</strong>
                  <input
                    type="text"
                    name="temperatureMetric"
                    value={formData.temperatureMetric}
                    onChange={handleChange}
                    className="w-full p-2 border border-gray-300 rounded mt-1"
                  />
                </p>
                <p className="mt-6">
                  <strong>Distance Metric:</strong>
                  <input
                    type="text"
                    name="distanceMetric"
                    value={formData.distanceMetric}
                    onChange={handleChange}
                    className="w-full p-2 border border-gray-300 rounded mt-1"
                  />
                </p> */}
                <p className="mt-6">
                  <strong>Language:</strong>
                  <input
                    type="text"
                    name="language"
                    value={formData.language}
                    onChange={handleChange}
                    className="w-full p-2 border border-gray-300 rounded mt-1"
                  />
                </p>
                <p className="mt-6">
                  <strong>User ID:</strong>
                  <input
                    type="text"
                    name="userId"
                    disabled
                    value={formData.userId}
                    onChange={handleChange}
                    className="w-full p-2 border border-gray-300 rounded mt-1"
                  />
                </p>
                <p className="mt-6">
                  <strong>Mobile number:</strong>
                  <input
                    type="text"
                    name="phoneNumber"
                    value={formData.phoneNumber}
                    onChange={handleChange}
                    className="w-full p-2 border border-gray-300 rounded mt-1"
                  />
                </p>
                <div className="mt-6 flex space-x-4">
                  <button
                    onClick={handleCancelClick}
                    className="bg-gray-300 text-white py-2 px-4 rounded"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={handleSaveClick}
                    className="bg-blue-500 text-white py-2 px-4 rounded"
                  >
                    Save
                  </button>
                </div>
              </>
            ) : (
              <>
                <p className="mt-6">
                  <strong>First Name:</strong>
                  <br /> {formData.firstName}
                </p>
                <p className="mt-6">
                  <strong>Last Name:</strong>
                  <br /> {formData.lastName}
                </p>
                <p className="mt-6">
                  <strong>Email:</strong>
                  <br /> {formData.email}
                </p>
                {/* <p className="mt-6">
                  <strong>Time Zone:</strong>
                  <br /> {formData.timeZone}
                </p>
                <p className="mt-6">
                  <strong>Date Format:</strong>
                  <br /> {formData.dateFormat}
                </p>
                <p className="mt-6">
                  <strong>Time Format:</strong>
                  <br /> {formData.timeFormat}
                </p>
                <p className="mt-6">
                  <strong>Temperature Metric:</strong>
                  <br /> {formData.temperatureMetric}
                </p>
                <p className="mt-6">
                  <strong>Distance Metric:</strong>
                  <br /> {formData.distanceMetric}
                </p> */}
                <p className="mt-6">
                  <strong>Language:</strong> <br />
                  {formData.language}
                </p>
                <p className="mt-6">
                  <strong>User ID:</strong>
                  <br /> {formData.userId}
                </p>
                <div className="bg-white rounded-lg p-4 shadow text-left relative">
                  <h3 className="text-lg font-semibold mb-2">Mobile number</h3>
                  {isEditing ? (
                    <div>
                      <input
                        type="text"
                        value={mobileNumber}
                        onChange={handleInputChange}
                        placeholder="Enter your mobile number"
                        className="w-full p-2 border border-gray-300 rounded mb-2"
                      />
                      <div className="flex justify-end space-x-2">
                        <button
                          className="bg-gray-200 text-gray-700 py-1 px-3 rounded"
                          onClick={handleCancelClick}
                        >
                          Cancel
                        </button>
                        <button
                          className="bg-blue-500 text-white py-1 px-3 rounded"
                          onClick={() => setIsEditing(false)}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <p className="text-sm text-gray-700">
                        {formData.phoneNumber ? (
                          `  Mobile number  ${formData.phoneNumber}`
                        ) : (
                          <>
                            Add your mobile number to enable SMS notifications.
                          </>
                        )}
                      </p>
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
        </div>

        {/* Mobile Number Section */}
        <div className="space-y-4">
          {/* Mobile Number Section */}
          {/* <div className="bg-white rounded-lg p-4 shadow text-left relative">
            <h3 className="text-lg font-semibold mb-2">Mobile number</h3>
            {isEditing ? (
              <div>
                <input
                  type="text"
                  value={mobileNumber}
                  onChange={handleInputChange}
                  placeholder="Enter your mobile number"
                  className="w-full p-2 border border-gray-300 rounded mb-2"
                />
                <div className="flex justify-end space-x-2">
                  <button
                    className="bg-gray-200 text-gray-700 py-1 px-3 rounded"
                    onClick={handleCancelClick}
                  >
                    Cancel
                  </button>
                  <button
                    className="bg-blue-500 text-white py-1 px-3 rounded"
                    onClick={() => setIsEditing(false)}
                  >
                    Save
                  </button>
                </div>
              </div>
            ) : (
              <div>
                <button
                  className="absolute top-2 right-2 text-blue-500"
                  onClick={handleEditClick}
                >
                  Edit
                </button>
                <p className="text-sm text-gray-700">
                  Add your mobile number to enable SMS notifications.
                </p>
              </div>
            )}
          </div> */}

          {/* Display Settings Section */}
          <div className="bg-white rounded-lg p-4 shadow relative text-left">
            <h3 className="text-lg font-semibold mb-2">
              Display Settings
              <span className="text-xs text-blue-500 ml-2 px-2 bg-blue-100 rounded-full">
                EARLY ACCESS
              </span>
            </h3>
            {isEditingDisplay ? (
              <div>
                <select
                  value={theme}
                  onChange={handleThemeChange}
                  className="w-full p-2 border border-gray-300 rounded mb-2"
                >
                  <option value="Light theme">Light theme</option>
                  <option value="Dark theme">Dark theme</option>
                  <option value="System theme">System theme</option>
                </select>
                <div className="flex justify-end space-x-2">
                  <button
                    className="bg-gray-200 text-gray-700 py-1 px-3 rounded"
                    onClick={handleCancelDisplayClick}
                  >
                    Cancel
                  </button>
                  <button
                    className="bg-blue-500 text-white py-1 px-3 rounded"
                    onClick={handleSaveDisplayClick}
                  >
                    Save
                  </button>
                </div>
              </div>
            ) : (
              <div>
                <button
                  className="absolute top-2 right-2 text-blue-500"
                  onClick={handleEditDisplayClick}
                >
                  Edit
                </button>
                <p className="text-sm text-gray-700">
                  <strong>Theme:</strong> {theme}
                </p>
              </div>
            )}
          </div>

          {/* Confirm Email Section */}
          <div className="bg-white rounded-lg p-4 shadow relative text-left">
            <h3 className="text-lg font-semibold mb-2">
              Confirm your email address
            </h3>

            {!isSent ? (
              <>
                <button
                  className="absolute top-2 right-2 text-blue-500"
                  onClick={handleSendClick}
                >
                  Send
                </button>
                <p className="text-sm text-gray-700">
                  Your email address is required to send you notifications and
                  invites. Please check your spam folder if it's not showing in
                  your inbox.
                </p>
              </>
            ) : (
              <p className="text-sm text-green-600">
                A confirmation email has been sent. Please check your inbox.
              </p>
            )}
          </div>
        </div>

        {/* Password Section */}
        <div className="p-4 grid grid-cols-1 md:grid-cols-2 gap-4">
          <div className="col-span-1 md:col-span-2 flex justify-center">
            <div className="bg-white rounded-lg p-4 shadow relative w-full text-left">
              <h3 className="text-lg font-semibold mb-2">Password</h3>
              {isEditingPassword ? (
                <div>
                  <input
                    type="password"
                    value={oldPassword}
                    onChange={(e) => setOldPassword(e.target.value)}
                    placeholder="Old Password"
                    className="w-full p-2 border border-gray-300 rounded mb-2"
                  />
                  <input
                    type="password"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    placeholder="New Password"
                    className="w-full p-2 border border-gray-300 rounded mb-2"
                  />
                  <div className="flex justify-end space-x-2">
                    <button
                      className="bg-gray-200 text-gray-700 py-1 px-3 rounded"
                      onClick={handleCancelPasswordClick}
                    >
                      Cancel
                    </button>
                    <button
                      className="bg-blue-500 text-white py-1 px-3 rounded"
                      onClick={handleEditPasswordClick}
                    >
                      Save
                    </button>
                  </div>
                </div>
              ) : (
                <div>
                  <button
                    className="absolute top-2 right-2 text-blue-500"
                    onClick={() => {
                      setIsEditingPassword(true);
                    }}
                  >
                    Edit
                  </button>
                  <p className="text-sm text-gray-700">************</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserSettings;
