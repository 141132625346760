import React from "react";
import Carousel from "react-multi-carousel";

const cardData = [
  {
    id: 1,
    title: "Restaurant Manager Opening Checklist",
    downloads: 1473,
    image: "https://via.placeholder.com/150",
  },
  {
    id: 2,
    title: "Root Cause Analysis",
    downloads: 915,
    image: "https://via.placeholder.com/150",
  },
  {
    id: 3,
    title: "Kitchen Daily Checks",
    downloads: 1299,
    image: "https://via.placeholder.com/150",
  },
  {
    id: 4,
    title: "Kitchen Daily Checks",
    downloads: 1299,
    image: "https://via.placeholder.com/150",
  },
  {
    id: 5,
    title: "Kitchen Daily Checks",
    downloads: 1299,
    image: "https://via.placeholder.com/150",
  },
  // Add more items as needed
];

// Responsive breakpoints for the carousel
const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 1024 },
    items: 3,
  },
  desktop: {
    breakpoint: { max: 1024, min: 768 },
    items: 2,
  },
  tablet: {
    breakpoint: { max: 768, min: 640 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 640, min: 0 },
    items: 1,
  },
};

const MultiTemplates = () => {
  return (
    <div className="py-12 rounded-lg">
      {/* Top section */}
      <div className="flex justify-between items-center mb-4">
        <div className="flex items-center space-x-2">
          <span className="text-xl">📋</span>
          <h2 className="text-lg font-semibold text-[#1f2533]">
            Browse sample Hospitality templates
          </h2>
          <a href="" className="text-blue-500 ms-2">
            View all
          </a>
        </div>
        <button className="text-gray-500 text-xl">✖️</button>
      </div>

      {/* Carousel Section */}
      <Carousel responsive={responsive} infinite={true} className="z-10">
        {cardData.map((card) => (
          <div key={card.id} className="flex bg-white p-4 rounded-lg shadow-lg mx-2">
            {/* Added mx-2 for horizontal margin */}
            <div>
              <img
                src={card.image}
                alt=""
                className="rounded-lg mb-4 w-32 h-32 object-cover"
              />
            </div>
            <div className="text-start ms-3">
              <h3 className="text-base font-normal">{card.title}</h3>
              <p className="text-gray-500 text-xs mt-2">📥 {card.downloads}</p>
              <button
                type="submit"
                className="inline-flex items-center py-2.5 px-5 w-full text-center justify-center mt-5 text-sm font-medium text-[#4740d4] bg-white rounded-lg border border-[#bfc6d4] focus:ring-4 hover:bg-[#e0e4ff]"
              >
                Use template
              </button>
            </div>
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default MultiTemplates;
