import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const Time = ({ startDate, setStartDate, endDate, setEndDate }) => {
  return (
    <div className="flex flex-col md:flex-row md:space-x-4">
      <div className="w-full relative">
        <label className="block text-sm font-medium text-gray-700">
          Start Time
        </label>
        <DatePicker
          selected={startDate}
          onChange={(date) => setStartDate(date)}
          showTimeSelect
          timeFormat="HH:mm"
          timeIntervals={60}
          dateFormat="MMMM d, yyyy h:mm aa"
          className="mt-1 block w-full border border-grey rounded-md h-12 px-3 bg-white focus:ring-0 focus:border-black hover:border-indigo-700 transition"
          placeholderText="Select date and time"
        />
      </div>

      <div className="w-full relative">
        <label className="block text-sm font-medium text-gray-700">
          End Time
        </label>
        <DatePicker
          selected={endDate}
          onChange={(date) => setEndDate(date)}
          showTimeSelect
          timeFormat="HH:mm"
          timeIntervals={60}
          dateFormat="MMMM d, yyyy h:mm aa"
          className="mt-1 block w-full border border-grey rounded-md h-12 px-3 bg-white focus:ring-0 focus:border-black hover:border-indigo-700 transition"
          placeholderText="Select date and time"
        />
      </div>
    </div>
  );
};

export default Time;
