import React from "react";
import ToolsImage from "../../../assets/loneWorker/SheqsyTOOLS-1-8.webp";
import TextImageFlex from "../../../shared/textImageFlex";
const IntegrateTools = () => {
  return (
    <div className="py-12 md:py-24 bg-[#F3F6FB]">
      <TextImageFlex
        heading="Integrate with your tools"
        para=" Simplify workflows or enhance reporting capabilities by connecting to existing platforms, including employee calendars, and data visualization tools like Power BI."
        img={ToolsImage}
        reverse
      />
    </div>
  );
};

export default IntegrateTools;
