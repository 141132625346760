import React from "react";
import OperationImg from "../../assets/home/operations.webp";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

const OperationsSection = () => {
  const isArabic = i18next.language === "ar";
  const { t } = useTranslation();

  return (
    <section
      className={`py-12 ${isArabic ? "text-right rtl" : "text-left"} `}
    >
      <div className="container mx-auto flex flex-col-reverse lg:flex-row items-center gap-10 px-4 md:px-8 lg:px-16">
        {/* Left Text Section */}
        <div className="lg:w-1/2">
          <h2 className="text-2xl md:text-4xl lg:text-5xl font-bold text-gray-900 mb-6">
            {t("homepage.streamlinetitle")}
          </h2>
          <p className="text-lg md:text-xl text-gray-600 mb-6">
            {t("homepage.streamlinedescr")}
          </p>
        </div>

        {/* Right Image Section */}
        <div className="lg:w-1/2">
          <img
            src={OperationImg}
            alt="Streamlined Operations"
            className="w-full rounded-lg shadow-lg"
          />
        </div>
      </div>
    </section>
  );
};

export default OperationsSection;
