import "./PrivacyPolicy.css";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
const PrivacyPolicy = () => {
  const { t } = useTranslation();
  const isArabic = i18next.language === "ar";
  return (
    <div className="container mx-auto ">
      <div className="max-w-7xl mx-auto p-4">
        <div className="privacy-policy-container py-6 text-center">
          <p className="heading text-2xl sm:text-3xl md:text-4xl font-bold">
            {t("privacypage.privacyheading")}
          </p>
        </div>
        <div className={`py-6 ${isArabic ? "text-right rtl" : "text-left"}`}>
          <div className="text-center p-4 mt-4">
            <p className="text-blue-500 underline text-xl sm:text-2xl">
              {t("privacypage.privacypara1")}
              <a
                href="http://www.ssc.llc"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-600 hover:text-blue-800"
              >
                http://www.ssc.llc
              </a>
            </p>
            <p className="mt-2 font-semibold text-lg sm:text-xl">
              {t("privacypage.privacypara2")}
            </p>
          </div>

          <div className="over-container-1 mt-8">
            <h1 className="heading text-xl sm:text-2xl font-bold">
              {t("privacypage.purposeheading")}
            </h1>
            <p className="paragraph-privacy mt-4">
              {t("privacypage.purposepara1")}
            </p>
            <div className="p-4">
              <h2 className="text-lg sm:text-xl font-bold mb-4">
                {t("privacypage.offerheading")}
              </h2>
              <ul className="list-disc list-inside space-y-2">
                <li className="text-gray-700">{t("privacypage.offerli1")}</li>
                <li className="text-gray-700">{t("privacypage.offerli2")}</li>
                <li className="text-gray-700">{t("privacypage.offerli3")}</li>
                <li className="text-gray-700">{t("privacypage.offerli4")}</li>
                <li className="text-gray-700">{t("privacypage.offerli5")}</li>
                <li className="text-gray-700">{t("privacypage.offerli6")}</li>
              </ul>
            </div>
            <div className="p-4">
              <h2 className="text-lg sm:text-xl font-bold mb-4">
                {t("privacypage.tailorheading")}
              </h2>
              <ul className="list-disc list-inside space-y-2">
                <li className="text-gray-700">{t("privacypage.tailorli1")}</li>
                <li className="text-gray-700">{t("privacypage.tailorli2")}</li>
                <li className="text-gray-700">{t("privacypage.tailorli3")}</li>
                <li className="text-gray-700">{t("privacypage.tailorli4")}</li>
                <li className="text-gray-700">{t("privacypage.tailorli5")}</li>
                <li className="text-gray-700">{t("privacypage.tailorli6")}</li>
              </ul>
            </div>
          </div>

          <div className="over-container-2 mt-8">
            <h1 className="heading text-xl sm:text-2xl font-bold">
              {t("privacypage.coverheading")}
            </h1>
            <p className="paragraph-privacy mt-4">
              {t("privacypage.coverpara1")}
            </p>
            <div>
              <ul className="over-container-3 space-y-2">
                <li>{t("privacypage.coverli1")}</li>
                <li>{t("privacypage.coverli2")}</li>
                <li>{t("privacypage.coverli3")}</li>
                <li>{t("privacypage.coverli4")}</li>
                <li>{t("privacypage.coverli5")}</li>
                <li>{t("privacypage.coverli6")}</li>
                <li>{t("privacypage.coverli7")}</li>
              </ul>
            </div>
            <p className="paragraph-privacy mt-4">
              {t("privacypage.coverpara2")}
            </p>
            <p className="paragraph-privacy mt-4">
              {t("privacypage.coverpara3")}
            </p>
          </div>

          <div className="over-container-4 mt-8">
            <h1 className="heading text-xl sm:text-2xl font-bold">
              {t("privacypage.collectheading")}
            </h1>
            <p className="paragraph-privacy mt-4">
              {t("privacypage.collectpara1")}
            </p>
            <p className="paragraph-privacy mt-4">
              {t("privacypage.collectpara2")}
            </p>
            <div className="over-container-5 mt-4">
              {t("privacypage.collectpara3")}
            </div>
            <ul className="paragraph-privacy-2 mt-4 space-y-2">
              <li>{t("privacypage.collectli1")}</li>
              <li>{t("privacypage.collectli2")}</li>
              <li>{t("privacypage.collectli3")}</li>
            </ul>

            <div className="over-container-5 mt-4">
              {t("privacypage.collectpara8")}
            </div>
            <ul className="paragraph-privacy-2 mt-4 space-y-2">
              <li>{t("privacypage.collectli4")}</li>
              <li>{t("privacypage.collectli5")}</li>
              <li>{t("privacypage.collectli6")}</li>
              <li>{t("privacypage.collectli7")}</li>
              <li>{t("privacypage.collectli8")}</li>
            </ul>

            <div className="over-container-6 mt-4">
              <p className="paragraph-privacy">
                {t("privacypage.collectpara4")}
              </p>
              <p className="paragraph-privacy-2">
                {t("privacypage.collectpara5")}
              </p>
              <p className="paragraph-privacy-2">
                {t("privacypage.collectpara6")}
              </p>
              <p className="paragraph-privacy-2">
                {t("privacypage.collectpara7")}
              </p>
            </div>
          </div>

          <div className="over-container-7 mt-8 ">
            <h1 className="heading text-xl sm:text-2xl font-bold">
              {t("privacypage.cookieheading")}
            </h1>
            <p className="paragraph-privacy mt-4">
              {t("privacypage.cookiepara")}
            </p>
          </div>

          <div className="over-container-8 mt-8">
            <h1 className="heading text-xl sm:text-2xl font-bold">
              {t("privacypage.personaldataheading")}
            </h1>
            <p className="paragraph-privacy mt-4">
              {t("privacypage.personalpara")}
            </p>
            <p className="paragraph-privacy-3 mt-4">
              {t("privacypage.personalpara1")}
            </p>
            <p className="paragraph-privacy-3 mt-4">
              {t("privacypage.personalpara2")}
            </p>
          </div>

          <div className="over-container-9">
            <div className="over-container-9">
              <h1 className="heading text-2xl md:text-3xl">
                {t("privacypage.usepersonaldataheading")}
              </h1>
              <ul className="paragraph-privacy-4 list-disc list-inside space-y-2 mt-4">
                <li>{t("privacypage.usingli1")}</li>
                <li>{t("privacypage.usingli2")}</li>
                <li>{t("privacypage.usingli3")}</li>
                <li>{t("privacypage.usingli4")}</li>
                <li>{t("privacypage.usingli5")}</li>
                <li>{t("privacypage.usingli6")}</li>
                <li>{t("privacypage.usingli7")}</li>
                <li>{t("privacypage.usingli8")}</li>
                <li>{t("privacypage.usingli9")}</li>
                <li>{t("privacypage.usingli10")}</li>
                <li>{t("privacypage.usingli11")}</li>
                <li>{t("privacypage.usingli12")}</li>
                <li>{t("privacypage.usingli13")}</li>
                <li>{t("privacypage.usingli14")}</li>
                <li>{t("privacypage.usingli15")}</li>
                <li>{t("privacypage.usingli16")}</li>
                <li>{t("privacypage.usingli17")}</li>
                <li>{t("privacypage.usingli18")}</li>
                <li>{t("privacypage.usingli19")}</li>
                <li>{t("privacypage.usingli20")}</li>
                <li>{t("privacypage.usingli21")}</li>
                <li>{t("privacypage.usingli22")}</li>
              </ul>
            </div>

            <div className="over-container-10">
              <h1 className="heading text-2xl md:text-3xl">
                {t("privacypage.longdataforheading")}
              </h1>
              <p className="paragraph-privacy mt-4">
                {t("privacypage.longdatapara")}
              </p>
            </div>

            <div className="over-container-10">
              <h1 className="heading text-2xl md:text-3xl">
                {t("privacypage.shareyourdataheading")}
              </h1>
              <div className="over-container-5 mt-4">
                {t("privacypage.sharepara1")}
              </div>
              <ul className="paragraph-privacy-5 list-disc list-inside space-y-2 mt-4">
                <li>{t("privacypage.shareli1")}</li>
                <li>{t("privacypage.shareli2")}</li>
                <li>
                  {t("privacypage.shareli3")}
                  <ul className="space-y-2 pl-6">
                    <li>{t("privacypage.shareli4")}</li>
                    <li>{t("privacypage.shareli5")}</li>
                    <li>{t("privacypage.shareli6")}</li>
                  </ul>
                </li>
              </ul>

              <ul className="paragraph-privacy-5 list-disc list-inside space-y-2 mt-4">
                <li>{t("privacypage.shareli7")}</li>
                <li>{t("privacypage.shareli8")}</li>
              </ul>

              <ul className="paragraph-privacy-5 list-disc list-inside space-y-2 mt-4">
                <li>
                  {t("privacypage.shareli9")}
                  <ul className="space-y-2 pl-6">
                    <li>{t("privacypage.shareli10")}</li>
                    <li>{t("privacypage.shareli11")}</li>
                  </ul>
                </li>
                <li>{t("privacypage.shareli12")}</li>
                <li>
                  {t("privacypage.shareli13")}
                  <ul className="space-y-2 pl-6">
                    <li>{t("privacypage.shareli14")}</li>
                    <li>{t("privacypage.shareli15")}</li>
                    <li>{t("privacypage.shareli16")}</li>
                    <li>{t("privacypage.shareli17")}</li>
                  </ul>
                </li>
                <li>{t("privacypage.shareli18")}</li>
              </ul>
            </div>

            <div className="over-container-10">
              <h1 className="heading text-2xl md:text-3xl">
                {t("privacypage.thirdpartyheading")}
              </h1>
              <p className="paragraph-privacy-6 mt-4">
                <strong className="strong">9.1</strong>
                {t("privacypage.thirdpartypara1")}
                <br />
                {t("privacypage.thirdpartypara2")}
              </p>
            </div>

            <div className="over-container-10">
              <h1 className="heading text-2xl md:text-3xl">
                {t("privacypage.paymentmethodheading")}
              </h1>
              <p className="paragraph-privacy-6 mt-4">
                <strong className="strong">10.1</strong>
                {t("privacypage.methodpara1")}
                <br />
                {t("privacypage.methodpara2")}
              </p>
            </div>

            <div className="over-container-10">
              <h1 className="heading text-2xl md:text-3xl">
                {t("privacypage.securityheading")}
              </h1>
              <p className="paragraph-privacy-6 mt-4">
                {t("privacypage.securitypara")}
                <br />
                {t("privacypage.securitypara1")}
              </p>
            </div>

            <div className="over-container-10">
              <h1 className="heading text-2xl md:text-3xl">
                {t("privacypage.changesheading")}
              </h1>
              <p className="paragraph-privacy-6 mt-4">
                {t("privacypage.changespara")}
                <br />
                {t("privacypage.changespara1")}
              </p>
            </div>

            <div className="over-container-10">
              <h1 className="heading text-2xl md:text-3xl">
                {t("privacypage.rightheading")}
              </h1>
              <div className="over-container-10 mt-4">
                <p className="paragraph-privacy">
                  {t("privacypage.rightpara")}
                </p>
              </div>
              <p className="paragraph-privacy-5 mt-4">
                {t("privacypage.rightpara1")}
                <br />
                {t("privacypage.rightpara2")}
                <br />
                {t("privacypage.rightpara3")}
                <br />
                {t("privacypage.rightpara4")}
                <br />
                {t("privacypage.rightpara5")}
              </p>
              <p className="paragraph-privacy mt-4">
                {t("privacypage.rightpara6")}
              </p>
            </div>

            <div className="over-container-10">
              <h1 className="heading text-2xl md:text-3xl">
                {t("privacypage.contactusheading")}
              </h1>
              <p className="paragraph-privacy-5 mt-4">
                {t("privacypage.contactpara")}
                <strong className="strong">
                  {t("privacypage.email")}
                </strong>{" "}
                <a href="mailto:privacy@SecureSafetyCulturer.com">
                  {t("privacypage.email1")}
                </a>
                <br />
                <strong className="strong">{t("privacypage.notice")}</strong>
                <br />
                <strong className="strong">{t("privacypage.notice1")}</strong>
                <br />
                {t("privacypage.location")}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default PrivacyPolicy;
