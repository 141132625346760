import React from "react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

import IntegrationImage from "../../../assets/integrations/IntegrationsHero.webp";
import HeroFlex from "../../../shared/heroFlex";

const HeroSection = () => {
  const { t } = useTranslation();
  const isArabic = i18next.language === "ar";

  return (
    <div className={`py-24 ${isArabic ? "text-right rtl" : "text-left"}`}>
      <HeroFlex
        span={t("integrationpage.herospan")}
        heading={t("integrationpage.heroheading")}
        para={t("integrationpage.heropara")}
        btn1={t("integrationpage.herobtn1")}
        btn2={t("integrationpage.herobtn2")}
        img={IntegrationImage}
        btn1Navigate="/signup"
        btn2Navigate={"/book-demo"}
      />
    </div>
  );
};

export default HeroSection;
