import EquipmentHero from "../../../assets/safetyhealth/img2.png";
import TextImageFlex from "../../../shared/textImageFlex";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

const GainVisibilty = () => {
  const { t } = useTranslation();

  const isArabic = i18next.language === "ar";

  return (
    <div className={`bg-[#F3F6FB] py-12 md:py-16`}>
      <TextImageFlex
        heading={t("healthsafety.SafeWorkheading")}
        para={t("healthsafety.SafeWorkpara")}
        img={EquipmentHero}
        widhtClass="w-full"
        reverse
      />
    </div>
  );
};

export default GainVisibilty;
