import React from "react";

function DownloadCSVModal({ setDownloadCSVOpen, onClose }) {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 px-4 sm:px-0">
      <div className="bg-white text-start rounded-lg w-full max-w-sm p-6 relative shadow-lg">
        <button
          onClick={() => setDownloadCSVOpen(false)}
          className="absolute top-4 right-4 text-gray-400 hover:text-gray-600 focus:outline-none"
        >
          &times;
        </button>
        <h2 className="text-lg font-semibold text-gray-800 mb-2">
          Download group membership CSV
        </h2>
        <p className="text-sm text-gray-600 mb-6">
          Download and view group membership data in your organization as a CSV
          file, based on any filters you've applied.
        </p>
        <div className="flex justify-end space-x-2">
          <button
            onClick={() => setDownloadCSVOpen(false)}
            className="text-sm border border-[#bfc6d4] rounded-lg font-medium text-[#4740d4] hover:bg-[#e9edf6] py-3 px-5 rounded-lg"
          >
            Cancel
          </button>
          <button
            // onClick={() => setIsAddUserModalOpen(false)}
            className="flex items-center bg-[#4740d4] text-white text-sm font-medium py-2 px-5 rounded-lg  transition"
          >
            Download
          </button>
        </div>
      </div>
    </div>
  );
}

export default DownloadCSVModal;
