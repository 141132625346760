import FlexContent from "../../../shared/flexContent";
import QualityImage from "../../../assets/manufacturing/Quality-Mangement-img-02.webp";
import { useTranslation } from "react-i18next";
const BoostProductivity = () => {
  const { t } = useTranslation();
  return (
    <div className="bg-[#F3F6FB] py-24">
      <FlexContent
        heading={t("manufacturingpage.BoostProductivityheading")}
        para={t("manufacturingpage.BoostProductivitypara")}
        reverse
        italicText={t("manufacturingpage.BoostProductivityitalic")}
        name={t("manufacturingpage.BoostProductivityname")}
        position={t("manufacturingpage.BoostProductivity")}
        img={QualityImage}
      />
    </div>
  );
};
export default BoostProductivity;
