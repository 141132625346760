import React, { useState } from "react";
import { IoIosSearch } from "react-icons/io";
import WorldImage from "../../../../assets/notification/world.jpeg"
import { FaArrowDown, FaRegSmile, FaExternalLinkAlt } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const Sites = () => {
  const navigate = useNavigate()
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="relative py-3">
      {/* Top Section: Header */}
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-semibold text-gray-800">Sites</h1>
        <button
          onClick={openModal}
          className="bg-[#6559ff] text-white px-4 py-2.5 rounded-xl hover:bg-[#7470f5]"
        >
          Add user to sites
        </button>
      </div>

      {/* Search Bar */}
      <div className="flex justify-between items-center mb-4">
        {/* Search Input */}
        <div className="relative">
          <input
            type="text"
            placeholder="Search"
            className="w-64 p-2 pl-10 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500"
          />
          <IoIosSearch className="absolute top-3 left-3 text-gray-500" />
        </div>

        {/* Right Text: No results */}
        <span className="text-gray-500">No results</span>
      </div>

      {/* Center Box with Image and Text */}
      <div className="border border-gray-300 rounded-lg h-[400px] flex flex-col items-center text-center bg-white">
        <div className="bg-[#e9edf6] w-full py-4 flex ps-3">
          <div className="w-[60%] flex">
            <input type="checkbox" className="me-6" />
            <p className="text-start ps-3 text-sm underline flex">
              name <FaArrowDown className="ms-2 mt-1" />
            </p>
          </div>
          <div className="w-[20%] flex">
            <p className="text-start ps-3 text-sm flex">label</p>
          </div>
          <div className="w-[20%] flex">
            <p className="text-start ps-3 text-sm flex">members</p>
          </div>
        </div>
        <div className="flex flex-col justify-center items-center h-full px-5">
          <FaRegSmile className="text-[70px] text-gray-400 mb-6" />
          <p className="text-md text-gray-600">You are not a member of any sites.</p>
          <p className="text-md text-gray-600 py-2">
            Site membership gives users a more relevant experience when selecting
            sites in inspections, actions, issues, and more.
          </p>
          <p className="text-md text-gray-600 flex items-center">
            <FaExternalLinkAlt className="me-2" /> Learn more about site membership
          </p>
        </div>
      </div>

      {/* Pagination */}
      <div className="flex justify-end items-center mt-4 space-x-2">
        <button className="bg-gray-100 text-gray-600 px-3 py-1 rounded-md">1</button>
        <span className="text-gray-500">/</span>
        <button className="bg-gray-100 text-gray-600 px-3 py-1 rounded-md">1</button>
      </div>

      {/* Modal Overlay */}
      {isModalOpen && (
        <div className="fixed inset-0 bg-[#e9edf6] bg-opacity-50 flex justify-end">
          {/* Modal Content */}
          <div className="bg-white w-full max-w-lg h-full p-6 relative">
            <h2 className="text-lg font-semibold mb-4 text-start">Add user to sites</h2>

            {/* Image and Text as shown in the provided screenshot */}
            <div className="flex flex-col items-start justify-center text-start bg-white py-12">
              <img src={WorldImage} alt="No Sites" className="w-40 h-40 mb-4" />
              <p className="text-start text-xl text-gray-600 mb-2">
                Your team has not created any sites
              </p>
              <p className="text-sm text-gray-600 mb-2">
                Add sites to help your team manage and analyze the locations,
                projects, or assets that matter the most to your organization.
                Site membership makes it easier for users to select the right site
                and find relevant information.
              </p>
              <p className="text-sm text-[#6559ff] underline flex items-center mb-6">
                <FaExternalLinkAlt className="mr-1" /> Learn more about site membership
              </p>

              {/* Buttons at the bottom */}
              <div className="flex space-x-4">
                <button className="bg-[#6559ff] text-white px-4 py-2.5 rounded-xl hover:bg-[#7470f5]" onClick={()=>navigate("/dashboard/add-site")}>
                  Add sites
                </button>
                <button
                  onClick={closeModal}
                  className="bg-gray-100 text-gray-600 px-4 py-2.5 rounded-xl hover:bg-gray-200"
                >
                  Cancel
                </button>
              </div>
            </div>

            {/* Close Modal Button */}
            <button
              onClick={closeModal}
              className="absolute top-4 right-4 text-gray-600 hover:text-gray-800"
            >
              &#10005;
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Sites;
