import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FaCog } from "react-icons/fa";
const Assetimg = require("../../../assets/adminassets/trolli.png");

const Archive = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isAddAssetOpen, setIsAddAssetOpen] = useState(false);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);

  const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);
  const toggleAddAssetDropdown = () => setIsAddAssetOpen(!isAddAssetOpen);
  const toggleSettingsDropdown = () => setIsSettingsOpen(!isSettingsOpen);

  return (
    <div className="p-4 bg-white min-h-screen mr-10">
      <div className="flex items-center space-x-4 mb-6">
        <Link
          to="/dashboard/assets"
          className="px-4 py-2 font-semibold text-purple-600 border-b-2 border-purple-600"
        >
          Assets
        </Link>
        <button className="px-4 py-2 font-semibold text-gray-500">
          Archive
        </button>
      </div>

      <div className="mt-6 flex justify-between items-center">
        {/* Left Side: Search and Add Filter */}
        <div className="flex items-center space-x-4">
          <input
            type="text"
            placeholder="Search"
            className="px-4 py-2 border border-gray-300 rounded-md w-56"
          />
          <div className="relative">
            {/* Add Filter Button */}
            <button className="text-blue-500" onClick={toggleDropdown}>
              + Add filter
            </button>

            {/* Filter Dropdown */}
            {isDropdownOpen && (
              <div className="absolute mt-2 w-48 bg-white border border-gray-200 rounded-md shadow-lg p-4">
                <div className="mb-2">
                  <select className="w-full mt-1 p-2 border border-gray-300 rounded-md">
                    <option>Select option 1</option>
                    <option>Option 2</option>
                    <option>Option 3</option>
                  </select>
                </div>
              </div>
            )}
          </div>
        </div>

        {/* Right Side: Settings and Add Asset Buttons */}
        <div className="flex items-center space-x-4">
          <button className="text-purple-600 border border-purple-600 px-4 py-2 rounded-md">
            Settings
          </button>

          {/* Add Asset Button with Dropdown */}
          <div className="relative">
            <button
              className="bg-purple-600 text-white px-4 py-2 rounded-md"
              onClick={toggleAddAssetDropdown}
            >
              Add asset <span className="ml-1">&#9662;</span>
            </button>

            {/* Add Asset Dropdown */}
            {isAddAssetOpen && (
              <div className="absolute right-0 mt-2 w-48 bg-white border border-gray-200 rounded-md shadow-lg z-10">
                <button className="w-full text-left px-4 py-2 hover:bg-gray-100">
                  New Assets
                </button>
                <button className="w-full text-left px-4 py-2 hover:bg-gray-100">
                  Bulk upload (CSV)
                </button>
                <button className="w-full text-left px-4 py-2 hover:bg-gray-100">
                  Integrate other system
                </button>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="mt-4 rounded-md overflow-x-auto">
        <table className="w-full min-w-max text-left border border-gray-300">
          <thead>
            <tr className="bg-indigo-50 text-gray-700 font-thin h-14">
              <th className="p-2 text-xs sm:text-sm md:text-base">Unique id</th>
              <th className="p-2 text-xs sm:text-sm md:text-base">
                Display Name
              </th>
              <th className="p-2 text-xs sm:text-sm md:text-base">
                Last inspection date
              </th>
              <th className="p-2 text-xs sm:text-sm md:text-base">
                Option actions
              </th>
              <th className="p-2 text-xs sm:text-sm md:text-base">
                {/* Settings Button */}
                <button onClick={toggleSettingsDropdown} className="relative">
                  <FaCog className="text-gray-500 text-xl" />
                </button>

                {/* Settings Dropdown Menu */}
                {isSettingsOpen && (
                  <div className="absolute right-0 mt-2 w-32 bg-white border border-gray-200 rounded-md shadow-lg z-10 p-2">
                    <ul className="text-gray-700">
                      <li className="p-2 hover:bg-gray-100 cursor-pointer">
                        Edit
                      </li>
                      <li className="p-2 hover:bg-gray-100 cursor-pointer">
                        Delete
                      </li>
                      <li className="p-2 hover:bg-gray-100 cursor-pointer">
                        View Details
                      </li>
                    </ul>
                  </div>
                )}
              </th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td colSpan="5" className="text-center p-4 sm:p-6">
                <div className="flex flex-col items-center">
                  <img
                    src={Assetimg}
                    alt="No schedules"
                    className="mb-4 w-20 h-20 sm:w-32 sm:h-32 md:w-40 md:h-40"
                  />
                  <p className="text-gray-600 font-semibold text-sm sm:text-base md:text-lg">
                    No assets added yet
                  </p>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Archive;
