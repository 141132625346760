import TextImagewFlex from "../../../shared/textImageFlex";
import TrainingLibraryImage from "../../../assets/training/Training-libaray.webp";

const TrainingLibrary = () => {
  return (
    <div >
      <TextImagewFlex
        span="Training Library"
        img={TrainingLibraryImage}
        heading="Choose courses made for you by industry experts"
        para={
          <>
            Launch any of our 1,000+ training courses in our library to your
            teams in minutes – at no extra cost. So you can deliver{" "}
            <span className="text-[#4740D7] hover:underline">
              {" "}
              health and safety training{" "}
            </span>{" "}
            designed by industry experts like HACCP and ASSP to your teams,
            faster.
          </>
        }
        show
        reverse
      />
    </div>
  );
};

export default TrainingLibrary;
