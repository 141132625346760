import { useTranslation } from "react-i18next";
const EnterpriseHero = () => {
  const { t } = useTranslation();
  return (
    <div className="py-24 bg-[#F3F6FB]">
      <h1 className="text-xl md:text-4xl lg:text-6xl text-center font-bold text-[#293745]">
        {t("enterprisepage.enterpriseheading")}
      </h1>
      <p className="text-lg md:text-xl text-center text-[#293745] mt-4">
        {t("enterprisepage.enterprisepara")}
      </p>
    </div>
  );
};

export default EnterpriseHero;
