import ImprovementSection from "../../../shared/improvementSection";
import { useTranslation } from "react-i18next";

const Improvement = () => {
  const { t } = useTranslation();

  return (
    <ImprovementSection
      heading={t("inspectionpage.improvement")}
      btn1={t("inspectionpage.improvementbtn")}
      btn1Navigate="/signup"
    />
    
  );
};

export default Improvement;
