import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import RightImage from "../../../assets/home/Waldorf-Astoria.webp";
import Image2 from "../../../assets/home/waldorf.svg";
import { FaArrowRight, FaArrowLeft } from "react-icons/fa";
const TabFourDetails = () => {
  const isArabic = i18next.language === "ar";
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div
      className={`flex flex-col lg:flex-row items-center lg:items-start ps-7 md:ps-14 rounded-lg shadow-xl mx-3 md:mx-7 ${
        isArabic ? "text-right rtl" : "text-left"
      }`}
    >
      <div className="lg:w-[50%] pt-[73px] pe-3 md:pe-12">
        <img src={Image2} alt="" className="w-[160px]" />
        <div className="block sm:inline-block  pt-3">
          <h1 className="me-1 text-[3.8125rem] font-[700] text-[#293745] border-b-4 border-[#293745]">
            15
          </h1>
          <p className="text-[1rem] pt-3 text-[#293745]">
            {t("tabcardspage.transpara")}
          </p>
        </div>

        {/* <p className="text-[#293745] italic mb-4 text-[1.25rem] pt-7">
          “Food and beverage is up 15 points in terms of a guest satisfaction
          standpoint. To see that level of change speaks a lot about a system
          like SafetyCulture.”
        </p>
        <p className="text-[#293745] font-semibold text-sm">Kelly Vohs,</p>
        <p className="text-[#293745] text-sm pt-1">
        General Manager at Waldorf Astoria Boca Raton
        </p> */}
        <div className="h-[300px] flex items-center">
          <button
            className="flex mt-10 mb-10 lg:mb-0 text-[14px] text-[1rem] font-[600] text-[#4740d4] border border-[#d0d7e1] py-2 px-4 rounded-lg"
            onClick={() => navigate("/hospitality")}
          >
            {t("tabcardspage.explorebtn4")}
            {isArabic ? (
              <FaArrowLeft className="me-1 mt-1" />
            ) : (
              <FaArrowRight className="ms-1 mt-1" />
            )}
          </button>
        </div>
      </div>
      <div className="lg:w-[50%] mt-6  lg:mt-0 flex justify-center lg:justify-end hidden lg:block">
        <div className=" rounded-s-full overflow-hidden">
          <img
            src={RightImage}
            alt="Image"
            className="object-cover w-full h-[570px]"
          />
        </div>
      </div>
    </div>
  );
};
export default TabFourDetails;
