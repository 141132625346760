import ConstructionHero from "../../../assets/manufacturing/Quality-Mangement-img-01.webp";
import TextImageFlex from "../../../shared/textImageFlex";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
const PreventIncidents = () => {
  const { t } = useTranslation();
  const isArabic = i18next.language === "ar";
  return (
    <div
      className={`bg-[#F3F6FB] pt-4 pb-24 py-4 ${
        isArabic ? "text-right" : "text-left"
      } ${isArabic ? "rtl" : ""}`}
    >
      <TextImageFlex
        heading={t("manufacturingpage.PreventIncidentsheading")}
        para={t("manufacturingpage.PreventIncidentspara")}
        img={ConstructionHero}
        widhtClass="w-[98%]"
        
      />
    </div>
  );
};

export default PreventIncidents;
