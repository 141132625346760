import Hero from "../../components/products/inspection/hero";
import DigitizeSection from "../../components/products/inspection/digitizeProcess";
import CreateTemplate from "../../components/products/inspection/createTemplates";
import CustomizeTemplate from "../../components/products/inspection/Customize";
import EverythingClickAway from "../../components/products/inspection/everythingClickAway";
import FeaturesSection from "../../components/products/inspection/features";
import IndustriesSection from "../../components/products/inspection/endlessOppurtunities";
import FeaturesSCards from "../../components/products/inspection/featuresCards";
import DriveEngagement from "../../components/products/inspection/driveEngagement";
import Improvement from "../../components/products/inspection/improvement";
import ProfessionalReports from "../../components/products/inspection/professionalReports";
import AnalyzeResults from "../../components/products/inspection/analyzeresults";
import Industries from "../../components/products/inspection/topIndustries";
import Faqs from "../../components/products/inspection/faqs";
const Inspection = () => {
  return (
    <div>
      <Hero />
      <DigitizeSection />
      <CreateTemplate />
      <CustomizeTemplate />
      <EverythingClickAway />
      <FeaturesSection />
      <IndustriesSection />
      <FeaturesSCards />
      <Improvement />
      <DriveEngagement />
      <ProfessionalReports />
      <AnalyzeResults/>
      <Industries/>
      <Improvement />
      <Faqs/>
    </div>
  );
};
export default Inspection;
