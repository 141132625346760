import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";
const Safetycone = require("../../../assets/manageinvites/safetycone.jpg");

const ManageInvites = () => {
  const [showSidebar, setShowSidebar] = useState(false);
  const navigate = useNavigate();
  const [inviteName, setInviteName] = useState("");
  const [linkPasswordEnabled, setLinkPasswordEnabled] = useState(false);
  const [expirationEnabled, setExpirationEnabled] = useState(true);
  const [expirationDate, setExpirationDate] = useState("28 Nov 2024");
  const [inviteLimitEnabled, setInviteLimitEnabled] = useState(false);
  const [group, setGroup] = useState("");
  const [site, setSite] = useState("");

  const handleCreateTypeClick = () => {
    setShowSidebar(true);
  };

  const handleCloseSidebar = () => {
    setShowSidebar(false);
  };

  return (
    <div className="mr-14 relative">
      <div className="p-6 bg-white rounded-lg shadow-lg w-full mt-4">
        <div className="flex items-center mb-4">
          {/* Back Arrow Icon */}
          <FaArrowLeft
            className="text-black text-2xl cursor-pointer mr-2"
            onClick={() => navigate(-1)}
          />
          {/* Heading */}
          <h3 className="font-bold text-2xl text-left">Back to users list</h3>
        </div>
        <div className="flex justify-between items-center mb-6">
          <h1 className="text-2xl font-light">Invite links</h1>
          <div className="flex items-center space-x-4">
            <button
              className="bg-purple-600 text-white px-4 py-2 rounded-md"
              onClick={handleCreateTypeClick}
            >
              Create<span className="ml-1">+</span>
            </button>
          </div>
        </div>
        <div className="flex justify-left">
          <input
            type="text"
            placeholder="search"
            className="w-72 px-4 py-2 mb-4 border rounded-md focus:outline-none focus:ring-2 focus:ring-purple-600"
          />
        </div>
        <div className="mt-4 rounded-md overflow-x-auto">
          <table className="w-full min-w-max text-left border border-gray-300">
            <thead>
              <tr className="bg-indigo-50 text-gray-700 font-thin h-14">
                <th className="p-2 text-xs sm:text-sm md:text-base">Name</th>
                <th className="p-2 text-xs sm:text-sm md:text-base">Status</th>
                <th className="p-2 text-xs sm:text-sm md:text-base">
                  Expiration
                </th>
                <th className="p-2 text-xs sm:text-sm md:text-base">Usage</th>
                <th className="p-2 text-xs sm:text-sm md:text-base">
                  Created by
                </th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td colSpan="5" className="text-center p-4 sm:p-6">
                  <div className="flex flex-col items-center">
                    <img
                      src={Safetycone}
                      alt="No schedules"
                      className="mb-4 w-20 h-20 sm:w-32 sm:h-32 md:w-40 md:h-40"
                    />
                    <p className="text-gray-600 font-semibold text-sm sm:text-base md:text-lg">
                      No invite links have been created
                    </p>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      {/* Right Sidebar */}
      <div
        className={`fixed inset-y-0 right-0 bg-white w-2/5 p-6 shadow-lg z-50 transform transition-transform duration-300 ease-in-out ${
          showSidebar ? "translate-x-0" : "translate-x-full"
        }`}
      >
        {/* Cross button */}
        <button
          onClick={() => setShowSidebar(false)}
          className="absolute top-4 right-4 text-4xl text-gray-600 hover:text-gray-800"
        >
          &times;
        </button>

        <div className="p-6 max-w-lg mx-auto rounded-lg shadow-lg">
          <h2 className="text-xl font-semibold text-gray-800">
            Create invite link
          </h2>
          <p className="text-sm text-gray-500">
            Set up a link for users to join your organization with ease.
          </p>

          <div className="p-4 rounded-lg">
            <label className="block font-medium text-gray-700 mb-1">
              Invite name *
            </label>
            <input
              type="text"
              value={inviteName}
              onChange={(e) => setInviteName(e.target.value)}
              className="w-full px-3 py-2 border border-gray-300 rounded-md mb-4"
              placeholder="Enter invite name"
            />

            <div className="flex items-center justify-between mb-4">
              <label className="font-medium text-gray-700">Link password</label>
              <input
                type="checkbox"
                checked={linkPasswordEnabled}
                onChange={() => setLinkPasswordEnabled(!linkPasswordEnabled)}
                className="form-checkbox h-5 w-5 text-blue-600"
              />
            </div>

            <div className="flex items-center justify-between mb-4">
              <label className="font-medium text-gray-700">Expiration</label>
              <input
                type="checkbox"
                checked={expirationEnabled}
                onChange={() => setExpirationEnabled(!expirationEnabled)}
                className="form-checkbox h-5 w-5 text-blue-600"
              />
            </div>

            {expirationEnabled && (
              <input
                type="text"
                value={expirationDate}
                onChange={(e) => setExpirationDate(e.target.value)}
                className="w-full px-3 py-2 border border-gray-300 rounded-md mb-4"
                placeholder="Select expiration date"
              />
            )}

            <div className="flex items-center justify-between">
              <label className="font-medium text-gray-700">Invite limit</label>
              <input
                type="checkbox"
                checked={inviteLimitEnabled}
                onChange={() => setInviteLimitEnabled(!inviteLimitEnabled)}
                className="form-checkbox h-5 w-5 text-blue-600"
              />
            </div>
          </div>

          <div className="bg-white p-4 rounded-lg">
            <h3 className="text-lg font-semibold text-gray-700 mb-2">
              Add user membership
            </h3>
            <p className="text-sm text-gray-500 mb-4">
              Users joining via this link will be automatically assigned the
              selected permission set, groups, and sites.
            </p>

            <label className="block font-medium text-gray-700 mb-1">
              Groups
            </label>
            <select
              value={group}
              onChange={(e) => setGroup(e.target.value)}
              className="w-full px-3 py-2 border border-gray-300 rounded-md mb-4"
            >
              <option value="">Select</option>
              <option value="Group 1">Group 1</option>
              <option value="Group 2">Group 2</option>
            </select>

            <label className="block font-medium text-gray-700 mb-1">
              Sites
            </label>
            <select
              value={site}
              onChange={(e) => setSite(e.target.value)}
              className="w-full px-3 py-2 border border-gray-300 rounded-md"
            >
              <option value="">Select</option>
              <option value="Site 1">Site 1</option>
              <option value="Site 2">Site 2</option>
            </select>
          </div>

          <button className="w-full mt-6 py-2 bg-indigo-600 text-white font-semibold rounded-lg hover:bg-indigo-700">
            Create
          </button>
        </div>
      </div>
    </div>
  );
};

export default ManageInvites;
