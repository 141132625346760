import React, { useState } from "react";
import { IoIosSearch } from "react-icons/io";
import { FaArrowDown } from "react-icons/fa";
import { FaRegSmile } from "react-icons/fa";

const Groups = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const data = ["Option 1", "Option 2", "Option 3", "Option 4"];

  // Filtered options based on the search query
  const filteredOptions = data.filter((option) =>
    option.toLowerCase().includes(searchQuery.toLowerCase())
  );
  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="relative py-3">
      {/* Top Section: Header */}
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-semibold text-gray-800">Groups</h1>
        <button
          onClick={openModal}
          className="bg-[#6559ff] text-white px-4 py-2.5 rounded-xl hover:bg-[#7470f5]"
        >
          Add user to groups
        </button>
      </div>

      {/* Search Bar */}
      <div className="flex justify-between items-center mb-4">
        {/* Search Input */}
        <div className="relative">
          <input
            type="text"
            placeholder="Search"
            className="w-64 p-2 pl-10 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500"
          />
          <IoIosSearch className="absolute top-3 left-3 text-gray-500" />
        </div>

        {/* Right Text: No results */}
        <span className="text-gray-500">No results</span>
      </div>

      {/* Center Box with Image and Text */}
      <div className="border border-gray-300 rounded-lg h-[400px] flex flex-col items-center text-center bg-white">
        <div className="bg-[#e9edf6] w-full py-4">
          <p className="text-start ps-3 text-sm underline flex">
            Group name <FaArrowDown className="ms-2 mt-1" />
          </p>
        </div>
        <div className="flex flex-col justify-center items-center h-full">
          <FaRegSmile className="text-[70px] text-gray-400 mb-6" />
          <p className="text-lg text-gray-600">
            You are not a member of any groups.
          </p>
        </div>
      </div>

      {/* Pagination */}
      <div className="flex justify-end items-center mt-4 space-x-2">
        <button className="bg-gray-100 text-gray-600 px-3 py-1 rounded-md">
          1
        </button>
        <span className="text-gray-500">/</span>
        <button className="bg-gray-100 text-gray-600 px-3 py-1 rounded-md">
          1
        </button>
      </div>

      {/* Modal Overlay */}
      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-end">
          {/* Modal Content */}
          <div className="bg-[#e9edf6] w-full max-w-md h-full p-6 relative">
            <h2 className="text-lg font-semibold mb-4 text-start">
              Add uzair khan to groups
            </h2>

            {/* Select Box with Search Bar Inside */}
            <div className="mb-4 relative bg-white px-4 pb-4">
              <label className="block text-sm font-medium mb-2 text-start py-3">
                Add uzair khan to groups
              </label>
              <div className="relative border border-gray-300 rounded-lg">
                {/* Search Bar Inside Select */}
                <button
                  onClick={() => setIsOpen(!isOpen)}
                  className="inline-flex justify-between w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none"
                >
                  Select groups
                  <svg
                    className="ml-2 -mr-1 h-5 w-5"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>

                {/* Dropdown panel */}
                {isOpen && (
                  <div className="origin-top-right absolute mt-2 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10">
                    <div className="p-4">
                      {/* Search Input with Search Icon */}
                      <div className="relative mb-4">
                        <input
                          type="text"
                          value={searchQuery}
                          onChange={(e) => setSearchQuery(e.target.value)}
                          placeholder="Search..."
                          className="w-full border border-gray-300 rounded-md px-4 py-2 pr-10 focus:outline-none focus:ring-2 focus:ring-blue-600"
                        />
                        <svg
                          className="absolute right-3 top-3 h-5 w-5 text-gray-500"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fillRule="evenodd"
                            d="M8 4a6 6 0 100 12A6 6 0 008 4zm4.707 10.293a1 1 0 00-1.414-1.414L9 10.586V9a1 1 0 10-2 0v2a1 1 0 00.293.707l2.586 2.586a1 1 0 001.414 0z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </div>

                      {/* List of options */}
                      <ul className="max-h-40 overflow-auto">
                        {filteredOptions.length > 0 ? (
                          filteredOptions.map((option, index) => (
                            <li
                              key={index}
                              className="cursor-pointer px-4 py-2 hover:bg-blue-100"
                            >
                              {option}
                            </li>
                          ))
                        ) : (
                          <li className="px-4 py-2 text-gray-500">
                            No results found
                          </li>
                        )}
                      </ul>

                      {/* Small button at the bottom */}
                      <button
                        className="mt-4 w-fit flex justify-end p-3 bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700 text-sm"
                       
                      >
                        Done
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>

            {/* Done Button at Bottom */}
            <div className="flex">
              <button class="bg-[#f8f9fc] text-[#8d98a8] font-bold py-2 px-4 rounded-xl opacity-50 cursor-not-allowed">
                Save and Apply
              </button>
              <button class="!bg-white text-[#4740d4] font-bold py-2 px-4 rounded-xl opacity-50 border border-[#bfc6d4] hover:bg-[#e0e4ff]">
                Cancel
              </button>
            </div>

            {/* Close Modal */}
            <button
              onClick={closeModal}
              className="absolute top-4 right-4 text-gray-600 hover:text-gray-800"
            >
              &#10005;
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Groups;
