import React from "react";
import { useLocation } from "react-router-dom";

const FeaturesSection = ({ features, heading, para }) => {
  const location = useLocation();

  const renderImage = (imgSrc) => {
    return location.pathname === "/training" ||
      location.pathname === "/integrations" ? (
      <div className="w-14">
        <img src={imgSrc} alt="Feature" className="w-full h-full pb-7" />
      </div>
    ) : null;
  };

  return (
    <section className="bg-[#f3f6fb] py-24">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
        <h2 className="text-lg sm:text-xl md:text-3xl lg:text-5xl font-bold text-[#293745] !leading-[60px]">
          {heading}
        </h2>
        <p className="mt-4 text-md md:text-[1.25rem] text-[#293745] px-2 md:px-32 py-2">
          {para}
        </p>

        <div
          className={`mt-12 text-start grid grid-cols-1 sm:grid-cols-2 gap-6 ${
            location.pathname === "/integrations"
              ? "lg:grid-cols-3"
              : "lg:grid-cols-4"
          }`}
        >
          {features.map((feature, index) => (
            <div
              key={index}
              className="rounded-xl py-5 px-6 border border-[#D0D7E1]"
            >
              {renderImage(feature.img)}

              <h3 className="text-lg md:text-[1.5625rem] font-black text-[#293745]">
                {feature.title}
              </h3>
              <p className="mt-6 text-[#293745]">{feature.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default FeaturesSection;
