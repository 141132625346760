import CurveHeroFlex from "../../../shared/curveHero";
import HeroImage from "../../../assets/mining/imagehero.jpeg"
import Image1 from "../../../assets/mining/image1.svg";
import Image2 from "../../../assets/mining/image2.webp";
import Image3 from "../../../assets/construction/image3.svg";
import Image4 from "../../../assets/construction/image4.svg";
import Image5 from "../../../assets/construction/image5.svg";
const ImagesArray = [
    {
      id: 1,
      img: Image1,
    },
    {
      id: 2,
      img: Image2,
    },
    {
      id: 3,
      img: Image3,
    },
    {
      id: 4,
      img: Image4,
    },
    {
      id: 5,
      img: Image5,
    },
  ];
const ConstructionHero = () => {
  return (
  <CurveHeroFlex 
  span="Workplace operations platform" 
  img={HeroImage}
  heading="Mining" 
  para="Gain total visibility across your mining operations from mine to port while minimizing downtime, helping raise safety and quality standards, and driving engagement."
  btn1="Get started for FREE"
  btn2="Book A Demo"
  ImagesArray={ImagesArray}
  />
  
);
};
export default ConstructionHero;
