import HeroSection from "../components/heroSection";
import ExplorePlatfrom from "../components/explorePlatform";
import LearnMoreCards from "../components/learnMoreCards";
import Features from "../components/features";
import FeaturesSection from "../components/customizeableSolutions";
import AustralianOpen from "../components/AustralianOpen/Page";
import Workplace from "../components/connectedworkplace/workplace";
import OperationsSection from "../components/Operations/Operations";
import MakeDecisions from "../components/Makedecision/Makedecision";
import Relations from "../components/relations/Relations";
import ImprovementSection from "../components/ImprovementSection/ImprovementSection";
import TabContent from "../components/tabsCards/index";
const Homepage = () => {
  const scrollToSection = (id) => {
    document.getElementById(id)?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <>
      <HeroSection />
      <ExplorePlatfrom />
      <LearnMoreCards />
      <Features />
      <FeaturesSection scrollToSection={scrollToSection} />
      <AustralianOpen />
      <Workplace />
      <OperationsSection />
      <MakeDecisions />
      <Relations />
      <div id="tabContentSection">
        <TabContent />
      </div>
      <ImprovementSection />
    </>
  );
};
export default Homepage;
