import React from "react";
import { IoArrowBackOutline } from "react-icons/io5";
import {
  HiOutlineCheckCircle,
  HiOutlineClipboardCheck,
  HiOutlineShieldCheck,
  HiOutlineClipboardList,
} from "react-icons/hi";
import { useNavigate } from "react-router-dom";

function SetupStep() {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col items-center p-4 sm:p-8 min-h-screen mt-16">
      <div className="max-w-5xl w-full mx-auto">
        <header className="flex flex-col items-left text-left mb-8 px-4">
          <img
            src="/path/to/safetyculture-logo.png"
            alt="SafetyCulture Logo"
            className="w-24 sm:w-32 mb-4"
          />
          <h2 className="text-gray-500 text-xs sm:text-sm font-medium">
            Step 2 of 2
          </h2>
          <h1 className="text-xl sm:text-2xl font-bold mt-2">
            What is your main purpose for using SafetyCulture?
          </h1>
          <p className="text-gray-600 text-sm sm:text-base mt-2">
            Tell us more about you so we can help get you started.
          </p>
        </header>

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mb-8 px-4">
          <button className="w-full h-20 flex items-center justify-center p-4 border rounded-lg bg-white hover:shadow-md transition">
            <HiOutlineCheckCircle className="text-green-500 w-6 h-6 mr-2" />
            <span className="text-gray-700 font-medium text-center text-sm">
              Digitize & improve workplace efficiency
            </span>
          </button>
          <button className="w-full h-20 flex items-center justify-center p-4 border rounded-lg bg-white hover:shadow-md transition">
            <HiOutlineClipboardCheck className="text-yellow-500 w-6 h-6 mr-2" />
            <span className="text-gray-700 font-medium text-center text-sm">
              Ensure quality standards are met
            </span>
          </button>
          <button className="w-full h-20 flex items-center justify-center p-4 border rounded-lg bg-white hover:shadow-md transition">
            <HiOutlineShieldCheck className="text-red-500 w-6 h-6 mr-2" />
            <span className="text-gray-700 font-medium text-center text-sm">
              Increase workplace safety
            </span>
          </button>
          <button className="w-full h-20 flex items-center justify-center p-4 border rounded-lg bg-white hover:shadow-md transition">
            <HiOutlineClipboardList className="text-blue-500 w-6 h-6 mr-2" />
            <span className="text-gray-700 font-medium text-center text-sm">
              Provide training to your team
            </span>
          </button>
          <button className="w-full h-20 flex items-center justify-center p-4 border rounded-lg bg-white hover:shadow-md transition">
            <HiOutlineClipboardList className="text-gray-500 w-6 h-6 mr-2" />
            <span className="text-gray-700 font-medium text-center text-sm">
              Other
            </span>
          </button>
        </div>

        <div className="flex flex-col sm:flex-row sm:space-x-4 space-y-4 sm:space-y-0 px-4">
          <button
            className="flex items-center px-4 py-2 text-gray-600 bg-white border rounded-lg hover:shadow-md transition"
            onClick={() => navigate(-1)}
          >
            <IoArrowBackOutline className="w-5 h-5 mr-1" />
            Back
          </button>
          <button className="px-4 py-2 text-white bg-blue-600 rounded-lg hover:bg-blue-700 transition">
            Finish setup
          </button>
        </div>
      </div>
    </div>
  );
}

export default SetupStep;
