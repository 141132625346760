import React, { useRef } from "react";

const Loader = () => {
  const overlayRef = useRef(null);

  return (
    <div
      ref={overlayRef}
      tabIndex={-1}
      className="absolute inset-0  opacity-50 flex items-center justify-center z-50"
      style={{ zIndex: 1000 }}
    >
      {/* Loader */}
      <div className="loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-20 w-20"></div>
    </div>
  );
};

export default Loader;
