import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaCog, FaEllipsisV } from "react-icons/fa";
import { useAuth } from "../../context/AuthContext";
import { useNotification } from "../../../common/hooks/useNotification";
import useHttp from "../../../common/hooks/useHttp";
import Select from "react-dropdown-select";
// import { FiPlus } from "react-icons/fi";

const Assetimg = require("../../../assets/adminassets/trolli.png");

const Group = () => {
  const { authData } = useAuth();
  const notify = useNotification();
  const { sendRequest } = useHttp();
  const navigate = useNavigate();

  const [groups, setGroups] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [groupName, setGroupName] = useState("");
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(null);
  const [currentGroup, setCurrentGroup] = useState(null);
  const [isGroupPopupOpen, setIsGroupPopupOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [selectedValues, setSelectedValues] = useState([]);
  const dropdownRef = useRef(null);
  const dropdownButtonRef = useRef(null);
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  // const toggleAddUserPopup = () => {
  //   setIsAddUserPopupOpen(!isAddUserPopupOpen); // Toggle the Add User popup
  // };

  useEffect(() => {
    getOrganizationGroups();
  }, [authData]);

  const getOrganizationGroups = async () => {
    try {
      await sendRequest(
        {
          url: "v1/organization/groups",
          accessToken: authData.accessToken,
          headers: {
            userName: authData.userName,
            organizationId: authData.organizationId,
            deviceId: authData.deviceId,
          },
        },
        (response) => {
          setGroups(response.data.organizationGroups);
        }
      );
    } catch (error) {
      console.error("Error getting groups:", error);
    }
  };

  const handleCreateGroup = async () => {
    try {
      await sendRequest(
        {
          url: "v1/group/create",
          method: "POST",
          accessToken: authData.accessToken,
          headers: {
            userName: authData.userName,
            organizationId: authData.organizationId,
            deviceId: authData.deviceId,
          },
          payload: {
            organizationId: authData.organizationId,
            groupName,
          },
        },
        (response) => {
          if (response.responseCode === "200") {
            setGroupName("");
            setIsPopupOpen(false);
            getOrganizationGroups();
            notify(response.responseDesc, "success");
          } else {
            notify(response.responseDesc, "error");
          }
        }
      );
    } catch (error) {
      console.error("Error creating group:", error);
    }
  };

  const handleUpdateGroup = async (group) => {
    try {
      await sendRequest(
        {
          url: "v1/group/update",
          method: "POST",
          accessToken: authData.accessToken,
          headers: {
            userName: authData.userName,
            organizationId: authData.organizationId,
            deviceId: authData.deviceId,
          },
          payload: {
            organizationId: authData.organizationId,
            groupDescription: group.description,
            groupName: group.name,
            groupId: group.id,
          },
        },
        (response) => {
          if (response.responseCode === "200") {
            setGroupName("");
            setIsPopupOpen(false);
            getOrganizationGroups();
            closePopup();
            notify(response.responseDesc, "success");
          } else {
            notify(response.responseDesc, "error");
          }
        }
      );
    } catch (error) {
      console.error("Error creating group:", error);
    }
  };

  const handleDeleteGroup = async (groupId) => {
    try {
      await sendRequest(
        {
          url: `v1/group/${groupId}`,
          method: "DELETE",
          accessToken: authData.accessToken,
          headers: {
            userName: authData.userName,
            organizationId: authData.organizationId,
            deviceId: authData.deviceId,
          },
        },
        (response) => {
          if (response.responseCode === "200") {
            getOrganizationGroups();
            notify(response.responseDesc, "success");
          } else {
            notify(response.responseDesc, "error");
          }
        }
      );
    } catch (error) {
      console.error("Error deleting group:", error);
    }
  };

  const handleEditGroup = (groupId, groupName, groupDescription) => {
    setCurrentGroup({
      id: groupId,
      name: groupName,
      description: groupDescription,
    });
    setIsGroupPopupOpen(true);
  };

  const fetchUsers = async () => {
    try {
      await sendRequest(
        {
          url: `v1/organization/users?page=0&size=5`,
          method: "GET",
          accessToken: authData.accessToken,
          headers: {
            userName: authData.userName,
            organizationId: authData.organizationId,
            deviceId: authData.deviceId,
          },
        },
        (response) => {
          if (response.responseCode === "200") {
            const users = response.data.organizationUsers.map(
              (organizationUser) => ({
                value: organizationUser.user.id, // Accessing user id
                label: `${organizationUser.user.firstName} ${organizationUser.user.lastName}`, // Concatenating firstName and lastName
              })
            );
            setOptions(users);
          } else {
            notify(response.responseDesc, "error"); // Handling errors
          }
        }
      );
    } catch (error) {
      console.error("Error deleting group:", error);
    }
  };
  useEffect(() => {
    fetchUsers();
  }, []);

  const closePopup = () => {
    setIsPopupOpen(false);
    setIsGroupPopupOpen(false);
    setGroupName("");
    setCurrentGroup(null);
  };

  const filteredGroups = groups.filter((group) =>
    group.groupName.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const handleChange = (values) => {
    setSelectedValues(values);
    console.log("Selected Values:", values);
  };

  return (
    <div className="p-6 bg-white min-h-screen mr-10">
      <h1 className="text-left text-3xl font-semibold mt-10 text-gray-800">
        Groups
      </h1>

      <div className="mt-6 flex justify-between items-center">
        <div className="flex items-center space-x-4">
          <input
            type="text"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder="Search"
            className="px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-purple-500 w-56"
          />
        </div>

        <div className="flex items-center space-x-4">
          <button
            onClick={() => navigate("/dashboard/group-matrix")}
            className="text-purple-600 border border-purple-600 px-4 py-2 rounded-md hover:bg-purple-600 hover:text-white transition-colors"
          >
            Group Matrix
          </button>

          <button
            className="bg-purple-600 text-white px-4 py-2 rounded-md hover:bg-purple-700 transition-all"
            onClick={() => setIsPopupOpen(true)}
          >
            Create Group
          </button>
        </div>
      </div>

      <div className="mt-6 rounded-md overflow-x-auto">
        <table className="w-full min-w-max text-left border-collapse border-gray-300">
          <thead>
            <tr className="bg-indigo-50 text-gray-700 font-semibold h-14">
              <th className="p-3 text-sm">Name</th>
              <th className="p-3 text-sm">Description</th>
              <th className="p-3 text-sm">Active Users</th>
              <th className="p-3 text-sm text-center">
                <FaCog className="text-gray-500 text-xl hover:text-purple-600" />
              </th>
            </tr>
          </thead>

          <tbody>
            {filteredGroups.length > 0 ? (
              filteredGroups.map((group) => (
                <tr
                  key={group.groupId}
                  className="border-b border-gray-200 hover:bg-gray-50"
                >
                  <td className="p-3">{group.groupName}</td>
                  <td className="p-3">{group.groupDescription || "N/A"}</td>
                  <td className="p-3">{group.activeUsers || 0}</td>
                  <td className="p-4 text-center relative">
                    <button
                      ref={dropdownButtonRef}
                      onClick={(e) => {
                        e.stopPropagation();
                        setOpenDropdown(
                          openDropdown === group.groupId ? null : group.groupId
                        );
                      }}
                      className="text-gray-600 hover:text-purple-600 transition-all"
                    >
                      <FaEllipsisV />
                    </button>
                    {openDropdown === group.groupId && (
                      <div
                        ref={dropdownRef}
                        className="absolute right-0 mt-2 w-48 bg-white border border-gray-300 shadow-lg rounded-md z-10"
                      >
                        <ul className="py-1 text-sm text-gray-700">
                          <li
                            className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                            onClick={() =>
                              handleEditGroup(
                                group.groupId,
                                group.groupName,
                                group.description
                              )
                            }
                          >
                            Edit Group
                          </li>
                          <li
                            className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                            onClick={() => handleDeleteGroup(group.groupId)}
                          >
                            Delete Group
                          </li>
                        </ul>
                      </div>
                    )}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="4" className="text-center p-6 text-gray-600">
                  <p className="font-semibold text-lg">No groups found</p>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {isPopupOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-6 rounded-md shadow-lg w-2/6">
            <h2 className="text-xl font-bold mb-4">Create Group</h2>
            <div className="mb-4">
              <label
                htmlFor="groupName"
                className="block text-gray-700 text-sm font-medium mb-2"
              >
                Group Name
              </label>
              <input
                id="groupName"
                type="text"
                placeholder="Enter group name"
                value={groupName}
                onChange={(e) => setGroupName(e.target.value)}
                className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-500"
              />
              <Select
                isMulti
                options={options}
                onChange={handleChange}
                placeholder="Select users"
              />
            </div>
            <div className="mt-4 flex justify-end space-x-4">
              <button
                onClick={closePopup}
                className="bg-gray-300 px-4 py-2 rounded-md hover:bg-gray-400 transition"
              >
                Cancel
              </button>
              <button
                onClick={handleCreateGroup}
                className="bg-purple-600 px-4 py-2 text-white rounded-md hover:bg-purple-700 transition"
              >
                Create
              </button>
            </div>
          </div>
        </div>
      )}

      {isGroupPopupOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-6 rounded-md shadow-lg w-2/6">
            <h2 className="text-xl font-bold mb-4">Edit Group</h2>

            {/* Group Name Input */}
            <div className="mb-4">
              <label
                htmlFor="editGroupName"
                className="block text-gray-700 text-sm font-medium mb-2"
              >
                Group Name
              </label>
              <input
                id="editGroupName"
                type="text"
                placeholder="Enter group name"
                value={currentGroup?.name || ""}
                onChange={(e) =>
                  setCurrentGroup({ ...currentGroup, name: e.target.value })
                }
                className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-500"
              />
            </div>

            {/* Description Text Area */}
            <div className="mb-4">
              <label
                htmlFor="editGroupDescription"
                className="block text-gray-700 text-sm font-medium mb-2"
              >
                Group Description
              </label>
              <textarea
                id="editGroupDescription"
                placeholder="Enter group description"
                value={currentGroup?.description || ""}
                onChange={(e) =>
                  setCurrentGroup({
                    ...currentGroup,
                    description: e.target.value,
                  })
                }
                className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-500"
              ></textarea>
            </div>

            <div className="mt-4 flex justify-end space-x-4">
              <button
                onClick={closePopup}
                className="bg-gray-300 px-4 py-2 rounded-md hover:bg-gray-400 transition"
              >
                Cancel
              </button>
              <button
                onClick={() => {
                  handleUpdateGroup(currentGroup);
                }}
                className="bg-purple-600 px-4 py-2 text-white rounded-md hover:bg-purple-700 transition"
              >
                Update
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Group;
