import React from "react";
import MobileImage from "../../../assets/loneWorker/SheqsyMobileBlock.webp";
import TextImageFlex from "../../../shared/textImageFlex";
const MobileWorkforce = () => {
  return (
    <div className="py-12 md:py-24">
      <TextImageFlex
        heading="Get your mobile workforce home safely"
        para=" Whether your teams are healthcare workers providing home visits, surveyors completing site inspections or utility workers operating in dangerous or remote areas, SHEQSY makes it easy to safeguard your teams in real-time, no matter their environment."
        img={MobileImage}
        reverse
      />
    </div>
  );
};

export default MobileWorkforce;
