import React, { useState } from "react";
import InspectionImage from "../../../../assets/platform/inspections-audits-reports.webp";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { useNavigate } from "react-router-dom";
const Inspectiontab = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const isArabic = i18next.language === "ar";
  const [openDropdown, setOpenDropdown] = useState(null);

  // Function to handle opening/closing dropdown
  const toggleDropdown = (index) => {
    setOpenDropdown(openDropdown === index ? null : index);
  };

  // Dropdown content data
  const dropdownData = [ 
    {
      Key: "Digitize",
    },
    {
      Key: "Complete",
    },
    {
      Key: "Reports",
    },
    {
      Key: "Audits",
    },
  ];

  return (
    <div className="w-full py-4 lg:py-14 px-6 md:px-12 lg:px-12">
      <div className="container mx-auto flex flex-col lg:flex-row items-start justify-between">
        {/* Right Image Section */}
        <div className="lg:w-1/2 mt-10 lg:mt-0 relative">
          <img
            src={InspectionImage}
            alt="Operations"
            className="w-full rounded-lg"
          />
        </div>
        {/* Left Text Section */}

        <div className="lg:w-1/2 text-left ps-0 lg:ps-[40px] text-start pt-10 lg:pt-0">
          <div
            className={` ${isArabic ? "text-right" : "text-left"} ${
              isArabic ? "rtl" : ""
            }`}
          >
            <span className="inline-block border-t border-b border-r border-[3px] border-l-[.1875rem] border-[#293745] text-sm text-[#293745] px-3 py-1 rounded-full mb-7">
              {t("platformpage.Inspectiontitle")}
            </span>
            <h2 className="text-3xl sm:text-4xl lg:text-[2.4375rem] font-bold text-gray-900 mb-6">
              {t("platformpage.Inspectionheading")}
            </h2>
            <p className="text-[#293745] text-base mb-6">
              {t("platformpage.Inspectionpara")}
            </p>

            <div className="pt-2 pb-7">
              <div className="space-y-4">
                {dropdownData.map((item, index) => (
                  <div
                    key={index}
                    className="border-2 border-[#A2ACBD] rounded-lg"
                  >
                    {/* Dropdown Header */}
                    <div
                      className="flex justify-between items-center p-4 cursor-pointer"
                      onClick={() => toggleDropdown(index)}
                    >
                      <h3 className="text-[#293745] text-[1rem] font-semibold">
                        {item.title
                          ? item.title
                          : t(`platformpage.${item.Key}.title`)}
                      </h3>

                      {openDropdown === index ? (
                        <FaChevronUp className="text-[#293745]" />
                      ) : (
                        <FaChevronDown className="text-[#293745]" />
                      )}
                    </div>

                    {openDropdown === index && (
                      <div className="px-4 pb-4">
                        <ul className="space-y-2 list-disc list-inside">
                          {(
                            t(`platformpage.${item.Key}.content`, {
                              returnObjects: true,
                            }) || []
                          ).map((contentItem, i) => (
                            <li key={i} className="text-[1rem] font-[400]">
                              {contentItem}
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
            <div className="flex flex-col sm:flex-row space-y-3 sm:space-y-0 sm:space-x-3">
              <button
                className="bg-[#6559ff] text-white rounded-xl py-3 px-5"
                onClick={() => {
                  navigate("/inspection");
                }}
              >
                {t("platformpage.btn1")}
              </button>

              <button className="bg-transparent ms-0 sm:ms-5 text-[#4740d4] hover:bg-[#f0f0ff] border border-[#d0d7e1] rounded-xl py-3 px-5">
                {t("platformpage.btn2")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Inspectiontab;
