import React from "react";
import CustomizableCards from "../../../shared/customizableCards";
import { useTranslation } from "react-i18next";

const CustomizableCardsSection = () => {
  const { t } = useTranslation();

  const CardsData = [
    {
      badge: (
        <span className="border-t border-b border-r border-[3px] border-l-[.1875rem] border-[#293745] text-sm text-[#293745] px-3 py-1 rounded-full">
          {t("customizableCards.cards.ehs.badge")}
        </span>
      ),
      title: t("customizableCards.cards.ehs.title"),
      description: t("customizableCards.cards.ehs.description"),
      linkText: t("customizableCards.cards.ehs.linkText"),
    },
    {
      badge: (
        <span className="border-t border-b border-r border-[3px] border-l-[.1875rem] border-[#293745] text-sm text-[#293745] px-3 py-1 rounded-full">
          {t("customizableCards.cards.grc.badge")}
        </span>
      ),
      title: t("customizableCards.cards.grc.title"),
      description: t("customizableCards.cards.grc.description"),
      linkText: t("customizableCards.cards.grc.linkText"),
    },
    {
      badge: (
        <span className="border-t border-b border-r border-[3px] border-l-[.1875rem] border-[#293745] text-sm text-[#293745] px-3 py-1 rounded-full">
          {t("customizableCards.cards.quality.badge")}
        </span>
      ),
      title: t("customizableCards.cards.quality.title"),
      description: t("customizableCards.cards.quality.description"),
      linkText: t("customizableCards.cards.quality.linkText"),
    },
    {
      badge: (
        <span className="border-t border-b border-r border-[3px] border-l-[.1875rem] border-[#293745] text-sm text-[#293745] px-3 py-1 rounded-full">
          {t("customizableCards.cards.esg.badge")}
        </span>
      ),
      title: t("customizableCards.cards.esg.title"),
      description: t("customizableCards.cards.esg.description"),
      linkText: t("customizableCards.cards.esg.linkText"),
    },
  ];

  return (
    <div className="bg-[#F3F6FB] py-10">
      <CustomizableCards
        heading={t("customizableCards.heading")}
        CardsData={CardsData}
      />
    </div>
  );
};

export default CustomizableCardsSection;
